html.html__pwa {
  height: 100%;
}

@media (max-width: 768px) {
  html.html__pwa,
  body.entorno-pwa {
    overflow: hidden;
  }
}

/* -------------------------------------------------------------------- *\
 *  Contenedor                                                            *
\* -------------------------------------------------------------------- */

.entorno-pwa .container {
  width: 100%;
  min-width: 319px;
  max-width: none;
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
}

.entorno-pwa .container > h1 {
  color: var(--color-heading);
  font-family: var(--font-family-turismo-heading-bold);
  font-size: var(--font-size-26px);
}

@media (min-width: 768px) {
  .entorno-pwa .container {
    width: 100%; /* IE8 */
    max-width: 100%;
  }
  .entorno-pwa .container > h1 {
    font-size: var(--font-size-32px);
  }
}

@media (min-width: 992px) {
  .entorno-pwa .container {
    width: 100%; /* IE8 */
    max-width: 100%;
  }
}

@media (min-width: 1420px) {
  .entorno-pwa .container {
    width: 100%; /* IE8 */
    max-width: 1920px;
    margin-right: auto;
    margin-left: auto;
  }
}

.entorno-pwa div.even,
.entorno-pwa section.even {
  background-color: transparent;
}

.entorno-pwa .acronym-council-header {
  padding-right: .3em;
  font-size: var(--font-size-26px);
  font-weight: bolder;
}

@media (max-width: 340px) {
  .entorno-pwa .acronym-council-header {
    font-size: var(--font-size-18px);
  }
}

/* -------------------------------------------------------------------- *\
 *  Contenido                                                            *
\* -------------------------------------------------------------------- */

.entorno-pwa .main-content {
  display: flex;
  overflow: hidden;
  flex: 1 0 auto;
  flex-direction: column;
}

@media (max-width: 767px) {
  .entorno-pwa .main-content {
    flex-shrink: 1;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .entorno-pwa .main-content {
    position: relative;
    width: 100%;
    margin: var(--gutter-outer) auto 0;
  }
  .main.consulta-paradas {
    margin-top: var(--gutter-outer);
  }
}

.entorno-pwa .main {
  position: relative;
  display: flex;
  overflow: hidden;
  flex: 1 0 auto;
  flex-direction: column;
}

@media (max-width: 767px) {
  .entorno-pwa .main {
    position: relative;
    flex-shrink: 1;
  }
}

@media (min-width: 768px) {
  .entorno-pwa .main {
    border: 1px solid var(--background-color-alt-darker);
    border-bottom: 0;
    background-color: #fff;
  }
}

/**
* Contenido de la pestaña
*
**/

.entorno-pwa .tabs-contents {
  display: contents;
}

.entorno-pwa .tab-content[aria-hidden=true] {
  display: none;
}

.entorno-pwa .tab-content[aria-hidden=false] {
  display: flex;
}

.entorno-pwa .tab-content,
.entorno-pwa .detalle-tarjeta {
  position: relative;
  z-index: 3;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 0 var(--gutter-interior);
  overflow-x: hidden;
  overflow-y: auto;
}

.entorno-pwa .tab-content__header {
  position: sticky;
  z-index: 999;
  top: 0;
  display: flex;
  align-items: flex-start;
  padding: 0;
  background-color: #000;
  color: #fff;
}

.entorno-pwa .tab-content__nombre {
  align-self: center;
  margin-top: 0;
  color: inherit;
}

.entorno-pwa footer {
  display: none;
}
