/* ================================================================== *\
 *                                                                    *
 *  APLICACIÓN:                                                       *
 *  j16-02w                                                           *
 *  Nuevo GEO Vitoria-Gasteiz.                                        *
 *                                                                    *
 *  URL:                                                              *
 *                                                                    *
 * ------------------------------------------------------------------ *
 *  /pages/_j16-02w.css                                               *
\* ================================================================== */

.map-container {
  position: fixed;
  width: 100%;
  height: 100%;
}

.info-container {
  position: absolute;
  z-index: 1000;
  bottom: 0;
  left: 24px;
  width: 500px;
  height: 60vh;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, .3);
  color: rgba(0, 0, 0, .54);
  overflow-y: hidden;
  transform: translateY(calc(100% - 80vh));
  transition: all .4s ease-in-out;
}

@media (min-width: 767px) {
  .info-container {
    left: 0;
    width: 100%;
    height: 80vh;
  }
}

.info-container.info-active {
  transform: translateY(0);
}

/** CORRECIONES CON ESTILOS 2022 **/
.detalle-parada__header {
  align-items: center !important;
}

.consulta-paradas .llegada {
  display: grid;
  align-items: flex-start;
  margin-bottom: 1.5em;
  grid-column-gap: 1em;
  grid-template-columns: auto auto 1fr auto auto;
}

.consulta-paradas .llegada__btn::after {
  content: attr(data-text);
  font-weight: bold;
  white-space: nowrap;
}

.consulta-paradas .llegada-bus::before {
  width: 1.5em;
  height: 1.5em;
  background-color: var(--color-text);
  content: '';
  mask-image: url(../img/svg/sprite.svg#icon-bus-view);
}

.consulta-paradas .llegada-tram::before {
  width: 1.5em;
  height: 1.5em;
  background-color: var(--color-text);
  content: '';
  mask-image: url(../img/svg/sprite.svg#icon-tram-view);
}

.consulta-paradas .llegada__tiempo-realtime {
  color: #24b32b;
  font-weight: bold;
}

@media (max-width: 767px) {
  body > .even {
    padding-bottom: 0 !important;
  }
  .consulta-paradas .llegada__linea {
    grid-column: 2;
  }
  .consulta-paradas .llegada__destino {
    grid-column: 3;
  }

  /* .consulta-paradas .llegada__btn::before {
    width: 1.2em;
    height: 1.2em;
    background-color: var(--background-color);
    content: '';
    mask-image: url(../img/svg/sprite.svg#icon-route-view);
  } */
  .consulta-paradas .llegada__btn::after {
    content: attr(data-text);
  }
}

.consulta-paradas .tab-content[aria-hidden="true"] {
  display: none;
}

.consulta-paradas form {
  padding: 0;
  background-color: transparent;
}

.consulta-paradas .form.form--inline > * {
  display: inline-flex;
}

.mapasCompletos .zonaIconos {
  display: none;
}

.mapasCompletos .mapasGeoVitoria {
  margin-bottom: 0;
}

.mapasCompletos .mapasGeoVitoria > div {
  margin-bottom: var(--rhythm);
  font-size: inherit;
}


/* ------------------------------------------------------------------------ *\
 *  HEREDADOS DE j16-02.css                                                 *
 *  Esta parte es un copy-paste de los estilos necesarios                   *
 *  del fichero pero omitiendo todos aquellos de la versión antigua         *
 *  de GeoVitoria.                                                          *
\* ------------------------------------------------------------------------ */

body.j16-02 {
  background-color: var(--background-color-alt-darker);
}

#aplicacionGeo .main-content {
  width: 100%;
  margin: 0 auto;
  float: none;
}

.j16-02 .main-content > :first-child {
  padding: 0;
  background: none;
}

#aplicacionGeo .main-footer {
  display: none;
}


/* ------------------------------------------------------------------------ *\
 *  j16: MAPAS EMBEBIDOS EN OTRAS APP
\* ------------------------------------------------------------------------ */

.mapasCompletos {
  max-width: 100%;
  margin-bottom: var(--rhythm);
}

/* ------------------------------------------------------------------------ *\
 *  Lineas de trasporte
\* ------------------------------------------------------------------------ */
.L7,
.L9,
.T1,
.T2,
.TG2,
.TG3,
.E2,
.E3,
.E4,
.E5,
.E6,
.E7,
.E8,
.E9 {
  color: #fff;
}

.L1,
.L2,
.L3,
.L5,
.L6,
.L8,
.L10,
.G1,
.G2,
.G3,
.G5,
.G6,
.F,
.E1,
.L4,
.G4,
.TG1 {
  color: #000;
}

.L1,
.G1,
.E8,
.E7,
.N2 {
  background-color: #fd0;
}

.L2,
.F,
.G2 {
  background-color: #f499c1;
}

.L3,
.G3,
.FN {
  background-color: #44c7f4;
}

.L4,
.G4 {
  background-color: #ed1c24;
}

.L5 {
  background-color: #8cc63f;
}

.L6 {
  background-color: #a066aa;
}

.L7 {
  background-color: #a76534;
}

.L8,
.G6,
.E6,
.N4 {
  background-color: #faa61a;
}

.L9 {
  background-color: #0066b3;
}

.L10 {
  background-color: #00a54f;
}

.G5 {
  background-color: #96bd0d;
}

.T1,
.T2,
.TG1,
.TG2,
.TG3,
.E2,
.E9 {
  background-color: #5d5c60;
}

.E1 {
  background-color: #d8f400;
}

.E3,
.N1 {
  background-color: #ace000;
}

.E4 {
  background-color: #6cb500;
}

.E5,
.N3 {
  background-color: #abe424;
}

.E7,
.E8,
.N2 {
  background-color: #fd0;
}

.ES {
  background-color: #c39e13;
}

.T1,
.TG1 {
  background: #60ae27;
}

.T2,
.TG2 {
  background: #737277;
}

.T3,
.TG3 {
  background: #000;
}

.parada {
  display: grid;
  column-gap: .5em;
  grid-template-columns: 1fr minmax(0, 35%);
}

.parada__header {
  grid-column: 1;
  grid-row: 1;
}

.parada__alert {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  grid-row: 1;
}

.parada__content {
  grid-column: 1 / 3;
}

.alerta__aviso {
  display: inline-flex;
  align-items: center;
}

.alerta__aviso::before,
.alerta-title::after {
  display: block;
  width: 1.5em;
  height: 1.5em;
  background-image: url(../img/svg/sprite.svg#icon-alert-view);
  content: "";
}

.parada__alert .alerta__aviso::before {
  margin-right: .2em;
}

.alerta-title {
  display: inline-flex;
  align-items: center;
  margin-top: 0;
}

.alerta-title::after {
  margin-left: .2em;
}

.alerta-list {
  padding: 0;
  list-style: none;
}

.alertas__linea {
  padding: .556em;
  background-color: #ffb0bd;
  font-weight: bold;
}

.alerta-list details {
  margin: 0;
  background-color: inherit;
}

.alerta-list details summary {
  justify-content: flex-start;
  margin-top: 0;
  font-weight: bold;
}

.alerta-list details summary::after {
  margin-right: 0;
  margin-left: auto;
}

.alerta-list details::before {
  display: none;
}

.alerta-list details .alertas__detalle {
  display: grid;
  margin: 0 .5em;
  grid-template-columns: 25% 1fr;
}

.alerta-list details[open] dl::before {
  display: block;
  width: 100%;
  height: 2px;
  margin-bottom: .5em;
  background-color: #000;
  content: "";
  grid-column: 1 / 3;
}

.alerta-list details[open] dd {
  font-weight: normal;
}

.consulta-paradas .lineas-list {
  padding: 0;
  list-style-type: none;
}
