.media {
  margin-bottom: var(--rhythm);
}

.media::after {
  display: table;
  clear: left;
  content: '';
}

.media__img {
  margin-right: 1em;
  background-position: center center;
  float: left;
}

.media__img img,
img.media__img {
  max-width: 100%;
}
