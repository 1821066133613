/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE: ALERT                                                   *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *  /components/_alert.css                                              *
\* ==================================================================== */

/*
@styleguide

@title Alerta / Mensaje

## Alertas emergentes

**Mostrar el mensaje en forma de notificación** en la parte superior de la ventana.

Según el tipo de notificación se deberá usar una de estas tres llamadas JavaScript:

### Tipo: error

```javascript
vg.alert.error('Ejemplo de mensaje de error');
```

### Tipo: advertencia

```javascript
vg.alert.warn('Ejemplo de mensaje de advertencia');
```

### Tipo: informacion

```javascript
vg.alert.info('Ejemplo de mensaje informativo');
```

## Alerts dentro de la estructura HTML de una pantalla

### Tipo: error

```html
<p class="msg msg--error">
  [Mensaje de error mostrado al usuario <a href="#">con un enlace</a>]
</p>
```

Ejemplo de visualización:

<p class="msg msg--error">
  [Mensaje de error mostrado al usuario <a href="#">con un enlace</a>]
</p>

### Tipo: advertencia

```html
<p class="msg msg--warn">
  [Mensaje de advertencia mostrado al usuario <a href="#">con un enlace</a>]
</p>
```
Ejemplo de visualización:

<p class="msg msg--warn">
  [Mensaje de advertencia mostrado al usuario <a href="#">con un enlace</a>]
</p>

### Tipo: información

```html
<p class="msg msg--info">
  [Mensaje de información mostrado al usuario <a href="#">con un enlace</a>]
</p>
```

Ejemplo de visualización:

<p class="msg msg--info">
  [Mensaje de información mostrado al usuario <a href="#">con un enlace</a>]
</p>

 */

/**
 * Se añade aquí un selector para los errores de los formularios del "chino"
 * a expensas de refactorizar j31-01w.
 */
.alert,
.msg,
.j31-01w .errors {
  position: relative;
  padding: var(--gutter-interior-aside);
  margin: 0 0 var(--rhythm);
  border-radius: 0;
  color: black;
  font-size: inherit;
}

.alert a,
.msg a,
.j31-01w .errors a {
  color: var(--color-primario--dark);
  text-decoration: underline;
}

.alert a:hover,
.msg a:hover,
.j31-01w .errors a:hover {
  color: var(--color-primario);
}

.alert--error,
.msg--error,
.j31-01w .errors {
  background-color: var(--color-error--panel);
}

/**
 * 1. Hacer legible el texto de elementos del html interno con class "error"
 *    La clase .error es un helper que pone el color en rojo.
 */
.alert--error .error,
.msg--error .error,
.j31-01w .errors .error {
  color: currentColor; /* [1] */
}

.alert--info,
.msg--info {
  background-color: var(--color-info--panel);
}

.alert--warn,
.msg--warn {
  background-color: var(--color-warn--panel);
}

.alert--shake {
  animation-duration: 750ms;
  animation-iteration-count: 2;
  animation-name: shake-horizontal;
  animation-play-state: running;
  animation-timing-function: ease-in-out;
}

@keyframes shake-horizontal {
  25% {
    transform: translate(-.5%, 0);
  }
  50% {
    transform: translate(0, 0);
  }
  75% {
    transform: translate(.5%, 0);
  }
  0%,
  100% {
    padding-top: 1em;
    padding-bottom: 1em;
    transform: translate(0, 0);
  }
}

.alert-stack {
  z-index: 100;
  padding: 0;
  margin: 0;
  list-style: none;
  transition: transform .5s;
}

.alert-stack > .alert {
  max-height: 100vh;
  padding-right: 2.5em;
  margin: 0;
  cursor: pointer;
}

.alert-stack > .alert::before,
.alert-stack > .alert::after {
  position: absolute;
  top: 50%;
  right: .8em;
  width: 1em;
  height: 2px;
  background: white;
  content: '';
  transform: translateY(-50%);
}

.alert-stack > .alert::before {
  transform: rotate(45deg);
}

.alert-stack > .alert::after {
  transform: rotate(-45deg);
}

/* Al ser alertas pinchables (para cerrarlas) se añade un estado :active */
.alert-stack > .alert--error:active {
  background-color: var(--color-error--light);
}

.alert-stack > .alert--info:active {
  background-color: var(--color-info--light);
}

.alert-stack > .alert--warn:active {
  background-color: var(--color-warn--light);
}

.alert-stack--top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.alert-stack--top:empty {
  transform: translateY(-100vh);
}

.alert-stack--top > .alert {
  margin-bottom: 2px;
}

.alert.is-empty,
.msg.is-empty,
.msg:empty {
  display: none;
}

.msg--info > a {
  color: var(--color-primario--dark);
  text-decoration: underline;
}

.msg--info > a:hover {
  color: var(--color-primario);
}

.msg.msg-icon {
  display: flex;
  align-items: center;
}

.msg-icon::before {
  display: block;
  width: 2em;
  height: 2em;
  flex-shrink: 0;
  margin-right: 1em;
  background-color: var(--color-text-high-contrast);
  content: '';
}

.msg-icon.msg--info::before {
  mask-image: url(../img/svg/sprite.svg#icon-notification-view);
}

.msg-icon.msg--warn::before {
  mask-image: url(../img/svg/sprite.svg#icon-warning-view);
}

.msg-icon.msg--error::before {
  mask-image: url(../img/svg/sprite.svg#icon-error-view);
}
