@media (max-width: 1199px) {
  .header--congresos .header-main {
    border-bottom: 5px solid var(--color-entorno-congresos);
  }
}

.header--congresos .header-utils__nav li {
  border-color: var(--color-entorno-congresos);
}

.header--congresos .header-main__highlighted {
  justify-content: flex-start;
  padding-right: 0;
  padding-left: 2%;
  border: none;
  font-size: 2em;
}

.header--congresos .header-main__highlighted * {
  margin: 0;
  font-family: var(--font-family-alt);
  font-size: 1em;
  line-height: 1.1;
}

.header--congresos .header-main__menu-button::before {
  content: none;
}

.header--congresos .header-main__highlighted h1 {
  font-size: var(--font-size-31px);
}

@media (min-width: 992px) and (max-width: 1200px) {
  .header--congresos .header-main__highlighted h1 {
    font-size: fluidfont(strip(var(--font-size-40px)));
  }
}

@media (min-width: 1201px) and (max-width: 1419px) {
  .header--congresos .header-main__highlighted h1 {
    font-size: fluidfont(strip(var(--font-size-48px)));
  }
}

@media (min-width: 1420px) {
  .header--congresos .header-main__highlighted h1 {
    font-size: var(--font-size-48px);
  }
}

@media (max-width: 767px) {
  .header--congresos .header-main__highlighted * {
    display: none;
  }
}

.header--congresos .header-main__search {
  display: none;
}

.header--congresos .header-main__networks {
  grid-template-columns: repeat(3, 1fr);
}
