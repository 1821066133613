.shortcuts {
  display: grid;
  padding: 0;
  margin: 0;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  list-style: none;
  row-gap: calc(var(--rhythm) * 2);
}

.shortcuts--app {
  margin: 0 calc(var(--readable-padding-side) * -1) var(--rhythm);
  grid-template-columns: repeat(5, 1fr);
  overflow-y: auto;
}

.shortcuts li {
  margin: 0;
}

.btn-quick {
  display: flex;
  min-width: 8em;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #000;
  cursor: pointer;
  font-family: var(--font-family-base);
  font-size: var(--font-size-18px);
  justify-items: flex-end;
  line-height: var(--rhythm);
  text-align: start;
  text-align: center;
}

@media (min-width: 320px) and (max-width: 1419px) {
  .btn-quick {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .btn-quick {
    font-size: var(--font-size-24px);
  }
}

.btn-quick:active {
  background-color: transparent;
}

.btn-quick .svg {
  width: 3em;
  height: 3em;
  padding: .3em;
}

.btn-quick .btn-quick__span {
  display: block;
  width: 3em;
  height: 3em;
  margin-bottom: var(--rhythm);
  background-color: var(--background-color-alt-darker);
}

.shortcuts--app .btn-quick .btn-quick__span {
  width: 4em;
  height: 4em;
  background-color: var(--color-secundario);
}

.btn-quick .btn-quick__span::after {
  position: relative;
  z-index: -10;
  top: -13px;
  left: 15px;
  display: block;
  width: 3em;
  height: 1em;
  background-color: var(--color-secundario);
  content: "";
}

.shortcuts--app .btn-quick .btn-quick__span::after {
  width: 4em;
  background-color: #000;
}

.shortcuts--app .btn-quick .svg {
  width: 4em;
  height: 4em;
  padding: .7em;
  background-color: var(--color-secundario);
}

.shortcuts:not(.shortcuts--app) .btn-quick svg use {
  fill: #000;
}

.shortcuts:not(.shortcuts--app) .btn-quick:is(:hover, :focus) svg use {
  fill: var(--color-primario);
}
