/* Estilos comunes para la lista de resumen */

.summary {
  position: sticky;
  z-index: 900;
  top: 0;
  padding: 10px;

  /* box-shadow: 0 2px 5px rgba(0, 0, 0, .1); */
  border: 1px solid var(--color-primario);
  background-color: var(--background-color);
}

.summary details.details--summary {
  margin: 0 0 calc(var(--rhythm) * .5);
}

.summary details.details--summary[open] {
  margin: 0;
}

.summary details.details--summary[open] + .summary__current-element {
  display: none;
}

.summary details summary {
  --font: minimalfont(strip(var(--font-size-28px)));
  font-size: var(--font);
  font-weight: 700;
}

@media (min-width: 768px) {
  .summary {
    position: relative;
    z-index: auto;
    padding: 0;

    /* box-shadow: none; */
    border: none;
  }
  .summary__current-element {
    display: none;
  }
  .summary details summary {
    pointer-events: none;
  }
  .summary details summary::after {
    content: none;
  }
}

@media (min-width: 768px) and (max-width: 1419px) {
  .summary details summary {
    font-size: fluidfont(strip(var(--font-size-28px)));
  }
}

@media (min-width: 1420px) {
  .summary details summary {
    font-size: var(--font-size-28px);
  }
}

.summary-list {
  max-height: 80vh;
  padding: 0;
  margin: 0;
  overflow-y: auto;
}

.summary-list,
.summary-list ul {
  list-style: none;
}

.sub-summary-list {
  margin-top: calc(var(--rhythm) * .1);
  margin-bottom: calc(var(--rhythm) * .5);
}

li.summary-element {
  margin: 0 0 calc(var(--rhythm) * .3);
}

li.sub-summary-element {
  margin: 0 0 calc(var(--rhythm) * .1);
}

li.summary-element:last-child,
li.sub-summary-element:last-child {
  margin: 0;
}

.summary-element a {
  /* --font: minimalfont(strip(var(--font-size-21px)));
  font-size: var(--font); */
  font-size: var(--font-size-21px);
}

.sub-summary-element a {
  /* --font: minimalfont(strip(var(--font-size-21px)));
  font-size: var(--font); */
  font-size: var(--font-size-18px);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .summary-element a {
    font-size: fluidfont(strip(var(--font-size-24px)));
    line-height: fluidline(var(--rhythm), strip(var(--font-size-24px)));
  }
  .sub-summary-element a {
    font-size: fluidfont(strip(var(--font-size-21px)));
    line-height: fluidline(var(--rhythm), strip(var(--font-size-21px)));
  }
}

@media (min-width: 1420px) {
  .summary-element a {
    font-size: var(--font-size-21px);
  }
  .sub-summary-element a {
    font-size: var(--font-size-18px);
  }
}

@media screen and (min-width: 768px) {
  .summary {
    border-left: 2px solid var(--background-color-alt-darker);
    margin-bottom: var(--rhythm);
  }
  .summary-list {
    overflow: hidden;
    height: 100%;
    max-height: none;
  }
}
