:root {
  --litepicker-mobilefriendly-backdrop-color-bg: #000;
}

.litepicker-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: var(--litepicker-mobilefriendly-backdrop-color-bg);
  opacity: .3;
}

.litepicker-open {
  overflow: hidden;
}

.litepicker.mobilefriendly[data-plugins*=mobilefriendly] {
  font-size: 1.1rem;
  transform: translate(-50%, -50%);

  --litepicker-container-months-box-shadow-color: #616161;
}

.litepicker.mobilefriendly-portrait {
  --litepicker-day-width: 13.5vw;
  --litepicker-month-width: calc(var(--litepicker-day-width) * 7);
}

.litepicker.mobilefriendly-landscape {
  --litepicker-day-width: 5.5vw;
  --litepicker-month-width: calc(var(--litepicker-day-width) * 7);
}

.litepicker[data-plugins*=mobilefriendly] .container__months {
  overflow: hidden;
}

.litepicker.mobilefriendly[data-plugins*=mobilefriendly] .container__months .month-item-header {
  height: var(--litepicker-day-width);
}

.litepicker.mobilefriendly[data-plugins*=mobilefriendly] .container__days > div {
  display: flex;
  height: var(--litepicker-day-width);
  align-items: center;
  justify-content: center;
}

.litepicker[data-plugins*=mobilefriendly] .container__months .month-item {
  transform-origin: center;
}

.litepicker[data-plugins*=mobilefriendly] .container__months .month-item.touch-target-next {
  animation-duration: .5s;
  animation-name: lp-bounce-target-next;
  animation-timing-function: ease;
}

.litepicker[data-plugins*=mobilefriendly] .container__months .month-item.touch-target-prev {
  animation-duration: .5s;
  animation-name: lp-bounce-target-prev;
  animation-timing-function: ease;
}

@keyframes lp-bounce-target-next {
  from {
    transform: translateX(100px) scale(.5);
  }
  to {
    transform: translateX(0) scale(1);
  }
}

@keyframes lp-bounce-target-prev {
  from {
    transform: translateX(-100px) scale(.5);
  }
  to {
    transform: translateX(0) scale(1);
  }
}
