.eventCalendar-calendar-wrap > div {
  position: relative;
  margin-bottom: calc(var(--rhythm) * strip(var(--rhythm-increase, 1)));
  background-color: var(--background-color);
}

.eventCalendar-calendar-wrap .event-card {
  height: 100%;
}

.eventCalendar-calendar-wrap .calendarioagenda {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.eventCalendar-calendar {
  margin: 0 auto;
  font-family: var(--font-family-alt);
  font-size: var(--font-size-18px);
}

.eventCalendar__head {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 1.5em 0 1em;
}

.eventCalendar__controls {
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  background: transparent;
}

.eventCalendar__controls::before {
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--color-text);
  content: '';
  mask-image: url(../img/svg/sprite.svg#icon-arrow-view);
  mask-repeat: no-repeat;
  transform: rotate(90deg) translateY(0);
}

.eventCalendar__controls--prev::before {
  transform: rotate(-90deg) translateY(0);
}

.eventCalendar__controls:focus,
.eventCalendar__controls:active,
.eventCalendar__controls:hover {
  background: transparent;
}

.eventCalendar-calendar h1 {
  width: 8em;
  margin: 0;
  font-family: var(--font-family-alt-semi);
  font-size: var(--font-size-32px);
  text-align: center;
}

.eventCalendar-calendar ul {
  display: table;
  padding: 0;
  margin: 0 auto;
  list-style: none;
}

.eventCalendar-calendar li {
  display: table-cell;
  padding: 0 5px;
  margin: 0;
  vertical-align: middle;
}

.eventCalendar-next,
.eventCalendar-prev {
  cursor: pointer;
}

.eventCalendar-calendar table {
  height: 10.5em;
  margin-bottom: 0;
  border-collapse: collapse;
  border-spacing: 0;
  font-family: var(--font-family-alt);
}

.eventCalendar-calendar td {
  padding: 0;
  color: #fff;
}

/**
 * 1. Se añade tr al selector para sobreescribir el background del
 *    estilo básico heredado de 'tr:nth-child(odd) td'
 */
.eventCalendar-calendar table th,
.eventCalendar-calendar table tr td {
  display: table-cell;
  padding-top: 0;
  padding-bottom: 0;
  border: 2px solid transparent;
  background: none;
  text-align: center;
}

.eventCalendar-calendar table tr {
  display: table-row;
}

.eventCalendar-calendar .clickable {
  position: relative;
  background: #000;
}

.eventCalendar-calendar .selected {
  background: #fff;
  color: #000;
}

.eventCalendar-clickable-day {
  display: block;
  background: none;
  color: inherit;
}

/* .eventCalendar-clickable-day::after {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: block;

  content: '';

  background: #000;
} */
.eventCalendar-selectedDay-info {
  margin: 0;
  font-family: var(--font-family-alt);
}

.eventCalendar-selectedDay-info > span:not(:empty) {
  display: block;
  padding: .6em 1em;
  background: #000;
  color: #fff;
}

.eventCalendar-selectedDay-info > span:empty {
  padding: 0;
}

.eventCalendar-selectedDay-info .close,
.eventCalendar-selectedDay-info .close:hover,
.eventCalendar-selectedDay-info .close:focus {
  position: absolute;
  right: .6em;
  background: none;
  color: #fff;
  font: normal 1.2em var(--font-family-base);
  line-height: 1;
  opacity: 1;
  text-shadow: 0 1px 0 #fff;
  text-transform: lowercase;
}
