/*
===============================================================================

  COMPONENTE: NAVEGACION ENTRE PÁGINAS

-------------------------------------------------------------------------------
  /components/_pags.css
-------------------------------------------------------------------------------
  Tamaño de fuente base:
  - Movil:  16px
  - Tablet: 16px
  - Web:    16px
===============================================================================
*/

/*
styleguide

@title Paginación

```html
<nav class="pags pags--top" aria-labelledby="pags-caption-top">
  <hX id="pags-caption-top" class="pags__caption">Paginas de resultados / Emaitza orriak</hX>
  <p class="pags__summary">
    Del <span class="pags__summary-from">21</span> al <span class="pags__summary-to>40</span> de un total de <span class="pags__summary-total>299</span> resultados
  </p>
  <a class="pags__go-first pags__go" aria-disabled="true" href="[url primera página]">primera página</a>
  <a class="pags__go-prev pags__go" aria-disabled="true" rel="prev" href="[url página anterior]">página anterior</a>
  <ol>
    <li><span class="pags__page pags__page--current">1</span></li>
    <li><a class="pags__page pags__page--current" href="[#resultados]" aria-disabled="true" tabindex="-1">1</a></li>
    <li><a class="pags__page" href="[url página 2]">2</a></li>
    <li><a class="pags__page" href="[url página 3]">3</a></li>
  </ol>
  <a class="pags__go-next pags__go" rel="next" href="[url página siguiente]">página siguiente</a>
  <a class="pags__go-last pags__go" href="[url última página]">ultima página</a>
</nav>
```

```html
<nav class="pags pags--bottom" aria-labelledby="pags-caption-bottom">
  <hX id="pags-caption-bottom" class="pags__caption">Paginas de resultados / Emaitza orriak</hX>
  <a class="pags__go-first pags__go" aria-disabled="true" href="[url primera página]">primera página</a>
  <a class="pags__go-prev pags__go" aria-disabled="true" rel="prev" href="[url página anterior]">página anterior</a>
  <ol>
    <li><span class="pags__page pags__page--current">1</span></li>
    <li><a class="pags__page pags__page--current" href="[#resultados]" aria-disabled="true" tabindex="-1">1</a></li>
    <li><a class="pags__page" href="[url página 2]">2</a></li>
    <li><a class="pags__page" href="[url página 3]">3</a></li>
  </ol>
  <a class="pags__go-next pags__go" rel="next" href="[url página siguiente]">página siguiente</a>
  <a class="pags__go-last pags__go" href="[url última página]">ultima página</a>
</nav>
```

<div class="psg-responsive-sample">
  <nav class="pags" aria-labelledby="pags-caption-bottom">
    <h2 id="pags-caption-bottom" class="pags__caption">Paginas de resultados / Emaitza orriak</h2>
    <p class="pags__summary">
      Del <span class="pags__summary-from">21</span> al <span class="pags__summary-to">40</span> de un total de <span class="pags__summary-total">299</span> resultados
    </p>
    <a class="pags__go-first pags__go" aria-disabled="true" href="[url primera página]">primera página</a>
    <a class="pags__go-prev pags__go" aria-disabled="true" rel="prev" href="[url página anterior]">página anterior</a>
    <ol class="pags__pages">
      <!--li><span class="pags__page pags__page--current">1</span></li-->
      <li><a class="pags__page pags__page--current" href="[#resultados]" aria-disabled="true" tabindex="-1">1</a></li>
      <li><a class="pags__page" href="[url página 2]">2</a></li>
      <li>
        <button class="pags__page" href="[url página 3]">3</button>
      </li>
    </ol>
    <button class="pags__go-next pags__go" rel="next" href="[url página siguiente]">página siguiente</button>
    <button class="pags__go-last pags__go" href="[url última página]">ultima página</button>
  </nav>
</div>
*/
.pags {
  display: block;
  padding: 0;
  margin: 2em 0;
  list-style: none;
}

.pags--page-bottom {
  margin-bottom: 0;
}

.pags__caption {
  position: absolute !important;
  display: inline;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.pags__go,
.pags__pages,
.pags__pages > li {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
  border-radius: 0;
  vertical-align: middle;
}

.pags__page {
  display: block;
}

/**
 * 1. Anular estilos por defecto de botones para los casos en los que se
 *    utilicen <button> en vez de <a>.
 */
.pags__go,
.pags__page {
  height: 1.8em;
  padding: 0 .6em;
  margin: 0; /* [1] */
  background-color: var(--background-color-alt-dark);
  color: var(--color-text-high-contrast);
}

.pags__go:hover,
.pags__page:hover {
  background-color: var(--background-color-alt);
}

.pags__go:active,
.pags__page:active {
  background-color: var(--background-color-alt-darker);
}

.pags__page--current.pags__page--current {
  background-color: transparent;
  color: var(--color-secundario);
  cursor: default;
  font-weight: bold;
}

/**
 * 1. Detectado un comportamiento extraño en local (por no cargar las fuentes)
 *    En Firefox 42.0 se detecta que el "text-indent: 100%" no oculta
 *    correctamente el texto de los botones, por lo que se utiliza "2.1em"
 *    ya que es una medida conocida.
 *    Con "2.1em" aún sigue el texto visible en IE8 por lo que se aumenta
 *    a "4em".
 */
.pags__go-first,
.pags__go-last {
  overflow: hidden;
  width: 2.1em;
  text-indent: 4em; /* [1] */
  white-space: nowrap;
}

.pags__go-prev {
  padding-left: 1.4em;
}

.pags__go-next {
  padding-right: 1.4em;
}

/*
-------------------------------------------------------------------------------

  Triangulos CSS con pseudoelementos para los botones de avanzar o retroceder

-------------------------------------------------------------------------------
*/
.pags__go-first::before,
.pags__go-first::after,
.pags__go-prev::before,
.pags__go-next::after,
.pags__go-last::before,
.pags__go-last::after {
  position: absolute;
  top: 50%;
  display: block;
  width: 0;
  height: 0;
  border: .9em solid transparent;
  margin: 0;
  content: '';
  font-size: .4em;
  transform: translateY(-50%);
}

.no-csstransforms .pags__go::before,
.no-csstransforms .pags__go::after {
  margin-top: -.9em;
}

.pags__go::before {
  left: .9em;
}

.pags__go::after {
  right: .9em;
}

.pags__go-prev::before,
.pags__go-first::before,
.pags__go-first::after {
  border-right-width: 1.8em;
  border-right-color: black;
  border-right-color: currentColor;
  border-left-width: 0;
}

.pags__go-next::after,
.pags__go-last::before,
.pags__go-last::after {
  border-right-width: 0;
  border-left-width: 1.8em;
  border-left-color: black;
  border-left-color: currentColor;
}

/*
-------------------------------------------------------------------------------

  PAGINACION ANTIGUA

-------------------------------------------------------------------------------
*/

/**
 * 1. Eliminar el padding heredado de UL
 */
.pagination {
  padding: 0; /* [1] */
}

.entorno-turismo .pagination--top {
  display: none; /* Oculto temporalmente */
}

.pagination > li {
  display: inline-block;
  margin: 0;
}

/**
 * A  ->  Enlaces para cambiar de página
 * SPAN
 *    ->  Números de página (resultados de búsquedas)
 *        Sigue la estructura de Bootstrap
 * LI.seleccionado
 *    ->  Números de página (hemeroteca)
 *        Estructura propia del Ayuntamiento
 *
 * 1. Sobrescribir estilos de Bootstrap
 */
.pagination > li > span,
.pagination > li > a,
.pagination > li.seleccionado {
  display: inline-block;
  height: auto; /* 1 */
  padding-right: .5em; /* 1 */
  margin-right: 0; /* 1 */
  margin-left: 0; /* 1 */
  border-radius: 0; /* 1 */
  line-height: 1.8em; /* 1 */
}

/**
 * Sobreescribir el border-radius que Bootstrap aplica al primer y ultimo
 * Se pone en una regla aparte a la anterior para evitar problemas con IE8,
 * ya que si encuentra :first-child o :last-child no aplica los estilos ni
 * si quiera a declaraciones hermanas.
 */
.pagination > li:first-child > a,
.pagination > li:last-child > a {
  border-radius: 0;
}

/**
 * Estilos específicos de los números de página
 *
 * 1. Se aplica borde (invisible) para mantener altura con enlaces
 */
.pagination > li > span,
.pagination > li.seleccionado {
  border: 1px solid transparent; /* [1] */
  background-color: var(--background-color);
  color: var(--color-text-high-contrast);
}

/**
 * Sobreescribir el background que aplica Bootstrap en el :hover
 */
.pagination > li > span:hover {
  border-color: transparent;
  background-color: inherit;
}

/*
-------------------------------------------------------------------------------

  Iconos antiguos que acompañan a los enlaces de la paginación

-------------------------------------------------------------------------------
*/

/**
 * Iconos de flechas
 */
.pagination .glyphicon {
  margin-right: .1em;
  margin-left: .1em;
  color: var(--color-primario);
  font-size: .8em;
}

/**
 * Invertir glyphicons (flechas atrás)
 */
.pagination .glyphicon.inverse {
  transform: scaleX(-1);
}

/*
-------------------------------------------------------------------------------

  INFORMACION DE PAGINACIÓN Y BÚSQUEDA

-------------------------------------------------------------------------------
*/

/**
   * La información clave
   *    - Búsqueda realizada
   *    - Número de resultados
   *    - Resultado inicial y final de la paginación
   * se presenta destacada con otro color y un tamaño de fuente mayor.
   */
.result-count span {
  font-weight: bold;
}
