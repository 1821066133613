/* ==================================================================== *\
 *                                                                      *
 *  RESET                                                               *
 *                                                                      *
 *  ------------------------------------------------------------------  *
 *  /base/_reset.css                                                    *
\* ==================================================================== */

/**
 * Reset box-sizing preserving inheritance:;
 * https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
