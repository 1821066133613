
/* -------------------------------------------------------------------- *\
 *  _form.css                                                            *
\* -------------------------------------------------------------------- */
.entorno-turismo label {
  color: var(--input-turismo-color);
}

.entorno-turismo input[type="text"],
.entorno-turismo input[type="tel"],
.entorno-turismo select,
.entorno-turismo textarea {
  border-color: var(--input-turismo-border-color);
  background-color: var(--input-turismo-background-color);
  font-family: var(--font-family-turismo-base);
}

/* -------------------------------------------------------------------- *\
 *  Contenedor                                                            *
\* -------------------------------------------------------------------- */

.entorno-turismo .contents > .even .container {
  color: #000;
  font-family: var(--font-family-turismo-base);
  line-height: 1.5;
}

.entorno-turismo .container :is(h1, h2, h3, h4),
.entorno-turismo .container section :is(h1, h2, h3, h4) {
  color: var(--color-heading);
  font-family: var(--font-family-turismo-heading-bold);
}

.entorno-turismo .contents > .even .container .main--wb021 > header.section-header {
  right: auto;
  padding: calc(var(--gutter-outer) * 1.5) var(--readable-padding-side);
}

.entorno-turismo .odd {
  background-color: var(--color-entorno-turismo);
}

.entorno-turismo div.even,
.entorno-turismo section.even {
  background-color: transparent;
}

.entorno-turismo div:last-child section.container {
  margin-bottom: 5rem;
}

/* -------------------------------------------------------------------- *\
 *  Menus contextuales                                                  *
\* -------------------------------------------------------------------- */

/* .entorno-turismo .extra-content {
  margin-top: 8.5em;
} */

/* .entorno-turismo.wb021 .extra-content {
  margin-top: 5.1em;
} */

.entorno-turismo .menuContextual ul li {
  color: #000;
  font-family: var(--font-family-turismo-alt);
}

.entorno-turismo .menuContextual .titulo {
  color: #000;
  font-family: var(--font-family-turismo-heading);
}

.entorno-turismo .menuContextual .title-banner {
  background-color: var(--background-turismo-color);
}

.entorno-turismo .menuContextual .title-banner:hover {
  background-color: #0043be;
  color: white;
}

.entorno-turismo .menuContextual .title-banner a:hover {
  color: white;
  text-decoration-line: none;
}

.entorno-turismo .menuContextual .area-result :is(h1,h2) {
  background-color: var(--color-turismo-secundario);
  font-family: var(--font-family-turismo-alt);
}

.entorno-turismo .menuContextual .area-result p,
.entorno-turismo .menuContextual .area-result li {
  font-family: var(--font-family-turismo-base);
}

@media (max-width: 991px) {
  .entorno-turismo .extra-content::before {
    background-color: var(--color-entorno-turismo);
  }
}

/* -------------------------------------------------------------------- *\
 *  Carousel                                                            *
\* -------------------------------------------------------------------- */

.entorno-turismo .container .carousel h1 {
  margin-top: .2em;
}

/* -------------------------------------------------------------------- *\
 *  Card                                                                *
\* -------------------------------------------------------------------- */

.entorno-turismo .card:not(.card-event) {
  height: 100%;
  background-color: var(--background-turismo-color);
}

.entorno-turismo .contenido-card.contenido-card--mini {
  border: 0;
  background-color: var(--background-turismo-color);
  color: var(--color-text-high-contrast);
}

.entorno-turismo .contenido-card__text {
  overflow: hidden;
  padding: calc(var(--gutter-interior) / 2) var(--gutter-interior);
  background-color: transparent;
  color: currentColor;
  font-family: var(--font-family-turismo-heading);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.entorno-turismo .card__block-link {
  height: 100%;
  border: 0;
}

.entorno-turismo .event-card-notImage .card__block-link {
  border: 2px solid #d0dadc;
}

.entorno-turismo .event-card-notImage .card__block-link:hover {
  border: 2px solid var(--color-primario);
  color: var(--color-primario);
}

.entorno-turismo .card__block-link:not(.card-event__link):hover {
  border: 0;
  background-color: var(--color-primario);
  color: var(--color-text-inverse);
  text-decoration: none;
}

.entorno-turismo .event-card .card__meta {
  background-color: var(--color-entorno-turismo);
}

.entorno-turismo .event-card__time-date {
  color: var(--color-heading);
}

.entorno-turismo .contenido-card.contenido-card--mini:hover {
  background-color: var(--color-primario);
  color: var(--color-text-inverse);
  text-decoration: none;
}

.entorno-turismo .card__title {
  font-family: var(--font-family-turismo-heading);
}

.event-card-notImage .card__title {
  font-family: var(--font-family-turismo-heading-light);
}

.entorno-turismo .card__desc {
  padding-top: 0;
  font-family: var(--font-family-turismo-heading-light);
}

/* -------------------------------------------------------------------- *\
 *  _news.css                                                                *
\* -------------------------------------------------------------------- */

.entorno-turismo .news--highlight .news__title {
  background-image: linear-gradient(0deg, var(--color-link) 2px, transparent 2px, transparent);
  background-position-y: -2px;
}
