/**
 * Imagino que se genera HTML para utilizar al imprimir sólo. ???
 */
@media screen {
  article.homeimpresion {
    display: none !important;
  }
}

/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE: LINKSMENU                                               *
 *                                                                      *
 *  ------------------------------------------------------------------- *
 *  Tamaño de fuente base:                                              *
 *    - Movil:  28px                                                    *
 *    - Tablet: 17px                                                    *
 *    - Web:    20px                                                    *
 *  ------------------------------------------------------------------- *
 *                                                                      *
 *  Contiene enlaces de interés y se posiciona junto al carrusel de     *
 *  imágenes de la home.                                                *
 *                                                                      *
\* ==================================================================== */
.linksmenu {
  padding-top: .3em;
  font-family: var(--font-family-alt);
  font-size: 1.75rem;
  line-height: 1.33;
}

@media (min-width: 768px) {
  .linksmenu {
    font-size: 1.06rem;
  }
}

@media (min-width: 992px) {
  .linksmenu {
    font-size: 1.3rem;
  }
}

@media (min-width: 1420px) {
  .linksmenu {
    font-size: 1.64rem;
  }
}

.linksmenu ul {
  padding: 0;
  margin: 0;
}

@media (min-width: 768px) and (max-width: 991px) {
  .linksmenu ul {
    padding-bottom: .2em;
    text-align: center;
    white-space: nowrap;
  }
}

.linksmenu .item {
  padding: .3em .6em;
  margin: 0 0 .15em;
  background: var(--background-color);
  list-style: none;
}

@media (min-width: 768px) and (max-width: 991px) {
  .linksmenu .item {
    display: inline-block;
    width: 19.8%;
    text-align: center;
  }
}

@media (min-width: 992px) {
  .linksmenu .item {
    padding-top: .5em;
    padding-bottom: .5em;
  }
}

@media (min-width: 1420px) {
  .linksmenu .item {
    padding-top: .35em;
    padding-bottom: .35em;
  }
}

.linksmenu .icon-arrow_link2 {
  padding-left: 15px;
  background-position: 0 .4em;
}

@media (min-width: 768px) and (max-width: 991px) {
  .linksmenu .icon-arrow_link2 {
    padding-left: 0;
    background-image: none;
  }
}

@media (min-width: 1420px) {
  .linksmenu .icon-arrow_link2 {
    background-position: 0 .5em;
  }
}

/* ==================================================================== *\
 *  Secciones de la home: .container                                    *
\* ==================================================================== */

.main--www section.container,
.main--sede section.container {
  padding-top: 0;
  padding-right: var(--readable-padding-side);
  padding-bottom: 0;
  padding-left: var(--readable-padding-side);
  margin: 0 auto;
}

.main--www section.container:first-of-type {
  margin-top: 3rem;
}

.main--www section.container:last-of-type {
  margin-bottom: 5rem;
}

.moreNews,
.inboxAccess {
  --font: var(--font-size-18px);
  --line: var(--rhythm);
  display: inline-flex;
  align-items: center;
  margin-top: .5em;
  color: var(--color-text);
  font-family: var(--font-family-base-semi);
  font-size: var(--font);
  line-height: var(--line);
}

.moreNews:hover,
.inboxAccess:hover {
  color: var(--color-text);
}

.moreNews::before,
.inboxAccess::before {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin-right: .5em;
  background-color: var(--color-text);
  content: '';
  mask-image: url(../img/svg/sprite.svg#icon-arrow-right-view);
  vertical-align: middle;
}

.moreNews:hover::before,
.inboxAccess:hover::before {
  animation: slide-right .5s ease-in-out both;
}

@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(.5em);
    transform: translateX(.5em);
  }
}

@media (min-width: 320px) and (max-width: 1419px) {
  .moreNews,
  .inboxAccess {
    --font: fluidfont(strip(var(--font-size-24px)));
    line-height: fluidline(strip(var(--line)), strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .moreNews,
  .inboxAccess {
    font-size: var(--font-size-24px);
  }
}

@media (min-width: 768px) {
  .main--www section.container,
  .main--sede section.container {
    --readable-padding-side: 2em;
  }
}

/* header.title {
  margin-top: 0;
  margin-bottom: var(--rhythm);
} */

header.title > h2 {
  --font: minimalfont(strip(var(--font-size-48px)));
  --line: 1.2em;
  display: block;
  color: var(--color-heading);
  font-size: var(--font);
  font-weight: bold;
  line-height: var(--line);

  /* vertical-align: middle; */
}

@media (min-width: 320px) and (max-width: 1419px) {
  header.title > h2 {
    --font: fluidfont(strip(var(--font-size-48px)));
    line-height: fluidline(strip(var(--line)), strip(var(--font-size-48px)));
  }
}

@media (min-width: 1420px) {
  header.title > h2 {
    font-size: var(--font-size-48px);
  }
}

.main--www .news-list__group-items {
  display: grid;
  column-gap: clamp(3em, 8vw, 6em);
  grid-template-columns: repeat(2, 1fr);
  row-gap: calc(var(--rhythm) * 2);
}

@media (max-width: 767px) {
  .main--www .news-list__group-items {
    grid-template-columns: 1fr;
  }
}

.main--www .news-list__group-items > li {
  margin-bottom: 0;
}

.main--www .news__content {
  padding: 1.2rem 2.813rem;
}

.main--www .grid article,
.main--www .news-list__group-items article {
  max-width: 660px;
}

@media (max-width: 660px) {
  .main--www .news-list__group {
    margin-right: calc(var(--readable-padding-side) * -1);
    margin-left: calc(var(--readable-padding-side) * -1);
  }
}

@media (max-width: 767px) {
  .main--www .news__image {
    width: 100%;
  }
}
