/* ==================================================================== *\
 *                                                                      *
 *  BUSCADOR                                                            *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *  /components/form/field--search.css                                  *
 * -------------------------------------------------------------------- *
 *  Tamaño de fuente base:                                              *
 *    - Movil:  18px                                                    *
 *    - Tablet: 18px                                                    *
 *    - Web:    18px                                                    *
 * -------------------------------------------------------------------- *
 *  Descripción:                                                        *
 *  Caja de búsqueda con icono de lupa                                  *
 * -------------------------------------------------------------------- *
 *  Notas:                                                              *
 *  Utiliza un HTML basado en bootstrap que resulta confuso y no es     *
 *  exactamente igual entre diferentes apariciones de este componente   *
 *  a lo largo de la web. Se debería revisar y proponer un HTML.        *
 *                                                                      *
 *  Diferentes apariciones localizadas:                                 *
 *  - form.col-xs-12.search.smartphone-menu-search.has-feedback         *
 *  - form#buscadortexto.buscador                                       *
 *      div.form-group.has-feedback                                     *
 *  - form#formGoogle                                                   *
 *      div.form-group.has-feedback                                     *
 *  - form                                                              *
 *      div.busqytxt                                                    *
 *        div#buscadorGr.form-group.has-feedback                        *
 *                                                                      *
 *  Se utilizará por tanto ".has-feedback" como raiz, aunque puede      *
 *  causar errores futuros.                                             *
 *                                                                      *
\* ==================================================================== */

/**
 * 1. En el menu de smartphone se le aplica col-xs-12, estropeando el
 *    posicionamiento del icono. Se elimina el padding lateral para
 *    que el icono siempre aparezca en la misma posicion.
 */
.has-feedback {
  position: relative;
  padding-right: 0;
  padding-left: 0;
}

/**
 * Ocultar el label de forma accesible
 */
.field--search .field__label,
.has-feedback .control-label {
  position: absolute !important;
  display: inline !important; /* Invalidar "display: none" heredados */
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.field--search .field__input,
.has-feedback .form-control {
  width: 100%;
  padding-right: 1.8em;
  font-family: var(--font-family-base);
}

/**
 * Icono de acción que acompaña al input (en este caso una lupa)
 * 1. Eliminar estilos heredados de button por defecto
 */
.field--search .field__submit,
.has-feedback .form-control-feedback {
  position: absolute;
  right: .4em;
  display: block;
  overflow: hidden;
  width: 1em;
  height: 1em;
  padding: 0; /* [1] */
  margin: 0; /* [1] */
  background: transparent; /* [1] */
  color: var(--color-text); /* [1] */
  line-height: 1;
  text-align: center;
}

.entorno-turismo .field--search .field__submit,
.entorno-turismo .has-feedback .form-control-feedback,
.entorno-www .field--search .field__submit,
.entorno-www .has-feedback .form-control-feedback {
  color: var(--color-text-high-contrast); /* [1] */
}

.field--search .field__submit {
  overflow: hidden;
  border: 0;
  text-indent: 100%;
  white-space: nowrap;
}

.field--search .field__submit::before {
  display: block;
  width: 1em;
  height: 1em;
  margin-right: .5rem;
  background-color: #000;
  content: "";
  mask-image: url(../img/svg/sprite.svg#search-view);
}

.field--search .field__submit:hover {
  background-color: transparent;
}

@media (max-width: 1200px) {
  .field--search .field__submit > svg {
    display: none;
  }
}


/**
 * Eliminar estilos de listas si el campo erroneamente contiene UL
 */
.has-feedback ul,
.has-feedback li {
  padding: 0;
  margin: 0;
  list-style: none;
}

/* -------------------------------------------------------------------- *\
 *  RESULTADOS AUTOCOMPLETE                                             *
\* -------------------------------------------------------------------- */

#result_list li {
  padding: 0 1%;
  border: 1px solid #aaa;
  border-width: 1px 0;
  cursor: pointer;
  line-height: 2;
}

#result_list li + li {
  border-top: 0;
}

#result_list em {
  background: var(--background-color-alt-darker);
  color: var(--color-text-high-contrast);
  font-style: normal;
  font-weight: bold;
}

#result_list li:hover {
  background: var(--color-secundario);
  color: var(--color-text-inverse);
}

#result_list li:hover em {
  background: none;
}

.field--search {
  display: flex;
  align-items: center;
  justify-content: center;
}
