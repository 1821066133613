.link-card__footer {
  margin-top: var(--rhythm);
  font-size: var(--font-size-18px);
}

.link-card__footer-link {
  display: inline-flex;
  align-items: center;
  color: var(--color-text);
}

.link-card__footer-link:hover {
  color: var(--color-text);
}

.link-card__footer-link::before {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin-right: .5em;
  background-color: #000;
  content: '';
  mask-image: url(../img/svg/sprite.svg#icon-arrow-right-view);
  vertical-align: middle;
}

.link-card__footer-link:hover::before {
  animation: slide-right .5s ease-in-out both;
}

@media (min-width: 320px) and (max-width: 1419px) {
  .link-card__footer {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .link-card__footer {
    font-size: var(--font-size-24px);
  }
}

@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(.5em);
    transform: translateX(.5em);
  }
}
