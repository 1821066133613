.ctrlHolder,
.buttons {
  margin: 1em 0;
}

/*
 *  Imagen seleccionable
\* ------------------------------------------------------------------ */
.ctrlHolder ul.imgRadio {
  padding: 0;
}

.ctrlHolder li.imgRadio {
  display: inline-block;
  margin-right: 5%;
  margin-left: 0;
  list-style: none;
}

/*
 *  Campos de texto
\* ------------------------------------------------------------------ */
.ctrlHolder label {
  display: block;
  margin-top: 0;
  line-height: inherit;
}

.ctrlHolder label + br {
  display: none;
}

.ctrlHolder [type="radio"] + label + br,
.ctrlHolder [type="checkbox"] + label + br {
  display: block;
}

@media (min-width: 768px) {
  .cajaTextoXS {
    width: 4em;
  }
  .cajaTextoS,
  .phoneInput {
    width: 6em;
  }
  .cajaTextoM {
    width: 14em;
  }
  .cajaTextoL,
  .cajaTextoXL {
    width: 31em;
  }
}

@media (min-width: 1420px) {
  .cajaTextoXL {
    width: 40em;
  }
}

.cajaTextoL + .formHint,
.cajaTextoXL + .formHint {
  display: block;
}

/*
 *  Checkboxes y Radio buttons
\* ------------------------------------------------------------------ */
.ctrlHolder input[type="checkbox"],
.ctrlHolder input[type="radio"] {
  display: inline-block;
  margin: .6em 0;
}

.ctrlHolder input[type="checkbox"] {
  margin-right: .4em;
}

/*
.ctrlHolder input[type="text"] ~ input[type="checkbox"],
.ctrlHolder textarea ~ input[type="checkbox"],
.ctrlHolder input[type="text"] ~ input[type="radio"],
.ctrlHolder textarea ~ input[type="radio"] {
  margin-top: 1em;
} */

/*
 *  Archivo adjunto
\* ------------------------------------------------------------------ */
.Fichero {
  padding: .6em 0;
}

.botAdjunto {
  display: inline;
  margin-left: .6em;
}

/*
 * 1. Resetear estilos heredados de jQuery-UI
 */
#j31Adjunto {
  background-image: none; /* [1] */
  font-weight: normal; /* [1] */
}

#j31Adjunto span {
  display: inline;
  padding: 0;
}

/*
 *  Texto explicativo de un campo
\* ------------------------------------------------------------------ */
.j31-01w .fechaDesc,
.j31-01w .formHint,
.j31-01w .phoneDesc {
  display: block;
  margin: -.3em 0 .6em;
  font-size: .889em;
}

/*
 *  LOPD
\* ------------------------------------------------------------------ */
.j31-01w .fvLOPD {
  margin-top: 2em;
  font-size: 1rem;
}

.j31-01w #tituloFormulario {
  margin-top: 0;
}

.ui-datepicker-trigger {
  width: 1.6em;
  height: 1.6em;
  cursor: pointer;
}
