/*
styleguide

@title Contenido Card (beta)

Una Contenido Card es un contenedor con información sobre un determinado contenido
para presentar su información de forma clara (normalmente una imagen y su título)
y permitir un enlace destacado al mismo.

Una contenido card se adapta a la capa en la que se encuentra, ocupando el 100% de
la misma. Es por ello que lo habitual será que vaya dentro de otra capa que haga
uso de las clases de bootstrap (col-X-Y).

## Contenido Card básico

```
// Opción 1: Sólo título
<a class="contenido-card" href="[url a contenido]" title="[title]">
  <img class="contenido-card__image" src="[url de imagen]" alt="[texto alternativo]">
  <p class="contenido-card__text">[titulo de contenido]</p>
</a>

// Opción 2: Título y entradilla corta
<a class="contenido-card" href="[url a contenido]" title="[title]">
  <img class="contenido-card__image" src="[url de imagen]" alt="[texto alternativo]">
  <p class="contenido-card__text">
    <h3 class="contenido-card__text-title">[titulo de contenido]</h3>
    <p class="contenido-card__text-desc">[linea informativa de contenido]</p>
  </p>
</a>
```

<div class="psg-responsive-sample" data-styles="body { padding: 1em 0; background: #aaa; }">
  <section class="container">
    <ul class="card-collection has-2-cols&#64;sm has-3-cols&#64;md">
      <li class="card-collection__item">
        <article class="card">
          <a class="card__block-link" href="/wb021/was/we001Action.do?idioma=es&amp;aplicacion=wb021&amp;tabla=contenido&amp;uid=u65cf7bdb_13c1dc36016__7fbd" title="leer más (El secreto de los murales)">
            <div class="card__image">
              <img src="https://www.vitoria-gasteiz.org/wb021/http/img/gestor/13/57/gi_41357.jpg" alt="Murales">
            </div>
            <div class="card__content">
              <h3 class="card__title">El secreto de los murales</h3>
            </div>
          </a>
        </article>
      </li>
      <li class="card-collection__item">
        <article class="card">
          <a class="card__block-link" href="/wb021/was/we001Action.do?idioma=es&amp;aplicacion=wb021&amp;tabla=contenido&amp;uid=u65cf7bdb_13c1dc36016__7fbd" title="leer más (El secreto de los murales)">
            <div class="card__image">
              <img src="https://www.vitoria-gasteiz.org/wb021/http/img/gestor/13/57/gi_41357.jpg" alt="Murales">
            </div>
            <div class="card__content">
              <h3 class="card__title">El secreto de los murales</h3>
              <p>Descúbrelos en esta nueva ruta</p>
            </div>
          </a>
        </article>
      </li>
    </ul>
  </section>
</div>
*/

/**
Contenido Card mini
----------------------------------------------------------------------

No se trata de un patrón. Es un código HTML residual que se repite en varias
páginas y que no se ha adaptado a lo expuesto en _base.css por que se genera
en IB021.jar.

Visto en la home de TURISMO y CONGRESOS

```
<a class="contenido-card contenido-card--mini" href="[url a contenido]" title="[title]">
  <img class="contenido-card__image" src="[url de imagen]" alt="[texto alternativo]">
  <p class="contenido-card__text">[titulo de contenido]</p>
</a>
```

Visto en la home de TEATRO

```
<a class="contenido-card contenido-card--mini" href="[url a contenido]" title="[title]">
  <p class="contenido-card__text">[titulo de contenido]</p>
</a>
```
*/
.contenido-card.contenido-card--mini {
  display: block;
  border: 2px solid var(--background-color-alt-dark);
  margin-bottom: var(--rhythm);
  background-color: var(--background-color);
}

.contenido-card--mini .contenido-card__image {
  display: block;
  width: 100%;
}

.contenido-card--mini .contenido-card__text {
  display: block;
  padding: var(--gutter-interior-aside);
  margin: 0;
  background-color: var(--background-color);
  color: var(--color-text-high-contrast);
  font-family: var(--font-family-alt);
  font-size: var(--font-size-18px);
  line-height: var(--rhythm);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .contenido-card--mini .contenido-card__text {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .contenido-card--mini .contenido-card__text {
    font-size: var(--font-size-24px);
  }
}

.contenido-card.contenido-card--mini:hover {
  border-color: var(--color-link);
  color: currentColor;
}

.contenido-card.contenido-card--mini:focus {
  outline: 2px solid var(--color-primario);
  outline-offset: .1em;
}
