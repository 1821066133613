.card-header {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--rhythm);
}

.card-header__imagen {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  margin-bottom: calc(var(--rhythm) * .5);
  background-color: var(--background-color-alt-darker);
}

.card-header__imagen figure {
  position: relative;
  overflow: hidden;
  margin: 0;
}

.card-header__imagen img {
  display: flex;
  object-fit: cover;
  object-position: center center;
}

.card-header__content {
  padding: var(--gutter-interior-aside);
  background-color: var(--background-color-alt-dark);
}

.card-event-time__list {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style: none;
}

.card-event-time__row {
  margin-bottom: .3em;
}

.evento .main__header h3 {
  margin-top: 0;
}
