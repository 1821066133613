/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE:   FIGURE                                               *
 *                                                                      *
 *  ------------------------------------------------------------------- *
 *  /components/_figure.css                                               *
 *  ------------------------------------------------------------------- *
 *  Tamaño de fuente base: heredado                                     *
 * -------------------------------------------------------------------- *
 *  DOM:                                                                *
 *                                                                      *
 *    figure.figure                                                      *
 *      img.figure_image
 *      figcaption.figure__caption                                      *
 *                                                                      *
\* ==================================================================== */
.figure {
  display: table;
  min-width: 310px;
  max-width: 100%;
  padding: 0;
  margin: var(--rhythm) 0;
}

.figure__caption {
  display: table-caption;
  padding: .7em .8em;
  margin: 0;
  background: #f6f6f7;
  caption-side: bottom;
  color: #4b4a4a;
  font-style: italic;
}

.figure__image {
  display: block;
  width: 100%;
  margin: 0;
}
