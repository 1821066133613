.ui-widget {
  border-radius: 0;
  font-family: inherit;
  font-size: inherit;
}

/**
 * 1. Deshacer la fuente que establece por defecto jQuery UI
 */
.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-family: inherit; /* [1] */
}

.ui-widget-header {
  padding: .2em .5em;
  border: none;
  background: var(--background-color-alt-darker);
  border-radius: 0;
  font-family: var(--font-family-heading);
  font-weight: normal;
}

.ui-widget-content a {
  color: var(--color-link);
}

/* ==================================================================== *\
 *  JQUERY-UI: DATEPICKER                                               *
\* ==================================================================== */

/**
 * 1. Para que no salga por debajo de un mapa embebido (con z-index:999) ni
 *    detras de los controles del mismo (z-index: 1016).
 * 2. El important es para sobreescribir el z-index: 1 que se eapecifica
 *    por JS en el atributo style.
 */
.ui-datepicker {
  z-index: 1100 !important; /* [1, 2] */
  width: 18em;
  background: var(--background-color-alt-darker);
  border-radius: 0;
  font-family: var(--font-family-base);
  font-size: var(--font-size-18px);
}

.ui-datepicker-header {
  border-color: var(--color-primario);
  background: var(--color-primario);
  border-radius: 0;
  color: #fff;
  font-family: var(--font-family-base);
  font-weight: bold;
}

.ui-datepicker .ui-datepicker-title select {
  font-family: Trebuchet MS, Tahoma, Verdana, Arial, sans-serif;
  font-size: .9em;
}

/* .ui-datepicker th,
.ui-datepicker td {
  background: inherit !important;
} */

.ui-datepicker td a,
.ui-datepicker td span {
  display: block;
  padding-right: .5em;
  padding-left: .5em;
}

.ui-datepicker .ui-state-default {
  border-color: #ccc;
  background: var(--background-color-alt);
  color: var(--color-text);
  font-weight: bold;
}

.ui-datepicker .ui-datepicker-week-end .ui-state-default {
  color: var(--color-secundario);
}

.ui-datepicker .ui-state-highlight.ui-state-highlight {
  background: var(--background-color-alt);
  color: #dd630e;
}

.ui-datepicker .ui-state-hover.ui-state-hover {
  border-color: var(--color-focused);
  background: var(--background-color-alt);
}

.ui-datepicker .ui-icon {
  background-image: url(/http/comun/libs/jquery/jquery-ui/last/images/ui-icons_ffffff_256x240.png);
}

.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-next-hover {
  top: 2px;
  right: 2px;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-prev-hover {
  top: 2px;
  left: 2px;
}

.ui-datepicker .ui-datepicker-header .ui-state-hover {
  border-color: transparent;
  background: none;
  cursor: pointer;
}

.ui-datepicker .ui-datepicker-header .ui-state-hover .ui-icon {
  background-image: url(/http/comun/libs/jquery/jquery-ui/last/images/ui-icons_222222_256x240.png);
}

.ui-datepicker .ui-state-active.ui-state-active {
  background: var(--color-primario);
  color: var(--color-text-inverse);
}

/**
 * 1. j31-01w carga dos botones extra bajo el calendario ("hoy" y "cerrar").
 *    El primero no funciona y no se ven bien. Se anula.
 */
.ui-datepicker-buttonpane {
  display: none;
}

/* ==================================================================== *\
 *  JQUERY-UI: DIALOG                                                   *
 * -------------------------------------------------------------------- *
 *  Se mueven las reglas a components/_dialog.css                       *
\* ==================================================================== */

/* ==================================================================== *\
 *  JQUERY-UI: ACCORDION                                                *
\* ==================================================================== */
.ui-accordion {
  margin-bottom: 2%;
  color: #5b5b5b;
  font-size: 1em;
}

.ui-accordion .ui-accordion-header {
  position: relative;
  padding: 1% 1.8em 1% 2%;
  border: none;
  margin: .6em 0 0;
  background: #e7e7dd;
  border-radius: 0;
  color: var(--color-text-high-contrast);
  font-family: var(--font-family-heading);
  font-size: 1.33em;
}

.ui-accordion .ui-accordion-header:hover {
  background: #f2f2e9;
}

/* Icono por defecto: abrir accordion */
.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
  position: absolute;
  top: 0;
  right: .3em;
  bottom: 0;
  left: auto;
  display: block;
  width: 1.2em;
  height: 1.2em;
  margin: auto 0;
  background: none;

  /*
  Original:
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="https://www.w3.org/2000/svg" xmlns:svg="https://www.w3.org/2000/svg" x="0" y="0" width="1em" height="1em" viewBox="0 0 50 50" xml:space="preserve"><line x1="8" y1="25" x2="42" y2="25" stroke="#0043be" stroke-width="8"></line><line x1="25" y1="8" x2="25" y2="42" stroke="#0043be" stroke-width="8"></line></svg>');
  Traducido a base64 para que lo entienda Firefox
  */
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjAiIHk9IjAiIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgNTAgNTAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxsaW5lIHgxPSI4IiB5MT0iMjUiIHgyPSI0MiIgeTI9IjI1IiBzdHJva2U9IiMwMDQzYmUiIHN0cm9rZS13aWR0aD0iOCI+PC9saW5lPjxsaW5lIHgxPSIyNSIgeTE9IjgiIHgyPSIyNSIgeTI9IjQyIiBzdHJva2U9IiMwMDQzYmUiIHN0cm9rZS13aWR0aD0iOCI+PC9saW5lPjwvc3ZnPg==);
  background-size: cover;
}

/* Icono cerrar accordion */
.ui-accordion .ui-accordion-header .ui-accordion-header-icon.ui-icon-triangle-1-s,
.ui-accordion .ui-accordion-header[aria-expanded="true"] .ui-accordion-header-icon {
  /*
  Original:
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="https://www.w3.org/2000/svg" xmlns:svg="https://www.w3.org/2000/svg" x="0" y="0" width="1em" height="1em" viewBox="0 0 50 50" xml:space="preserve"><line x1="8" y1="25" x2="42" y2="25" stroke="#0043be" stroke-width="8"></line></svg>');
  Traducido a base64 para que lo entienda Firefox
  */
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjAiIHk9IjAiIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgNTAgNTAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxsaW5lIHgxPSI4IiB5MT0iMjUiIHgyPSI0MiIgeTI9IjI1IiBzdHJva2U9IiMwMDQzYmUiIHN0cm9rZS13aWR0aD0iOCI+PC9saW5lPjwvc3ZnPg==);
}

.ui-accordion .ui-accordion-content {
  padding: 1% 2% 2%;
  border: none;
  color: inherit;
}

/* ==================================================================== *\
 *  JQUERY-UI: AUTOCOMPLETE                                             *
\* ==================================================================== */
.ui-autocomplete {
  padding: 0;
  background: var(--background-color);
  font-size: var(--font-size-18px);
}

.ui-autocomplete .ui-menu-item {
  padding: .4em .3em;
  border-width: 0;
  color: var(--color-text);
  cursor: pointer;
  font-family: var(--font-family-base);
  font-size: var(--font-size-24px);
}

.ui-autocomplete .ui-state-focus {
  background: var(--background-color-alt-dark);
  color: var(--color-text-high-contrast);
}

/* Sugerencias búsqueda */
.ui-autocomplete.ui-front {
  z-index: 1011;
}

:is(input, select, textarea).ui-widget {
  border: 2px solid #000 !important;
  background-color: #fff !important;
}

.custom-combobox .ui-button {
  background-color: #fff !important;
}
