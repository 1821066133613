/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE: MENU PRINCIPAL DE SEDE                                  *
 *                                                                      *
 *  ------------------------------------------------------------------- *
 *  /layout/header/_nav.css                                             *
 *  ------------------------------------------------------------------- *
 *  Tamaño de fuente base:                                              *
 *    - Movil:  28px                                                    *
 *    - Tablet: 21px                                                    *
 *    - Web:    21px                                                    *
\* ==================================================================== */

/* .header-menu__nav.header-menu__nav {
  --font: 1.75rem;
  margin-top: .5em;
  font-family: var(--font-family-alt-semi);
  font-size: var(--font);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .header-menu__nav.header-menu__nav {
    --font: fluidfont(strip(var(--font-size-31px)));
  }
}

@media (min-width: 1420px) {
  .header-menu__nav.header-menu__nav {
    --font: var(--font-size-31px);
  }
}

.header-menu__nav ul {
  display: flex;
  flex-direction: column;
  margin: 0;
}

@media (min-width: 1200px) {
  .header-menu__nav ul {
    flex-direction: row;
    padding: 10px 5px;
    background-color: var(--color-entorno-sede);
  }
}

.header-menu__nav li {
  display: flex;
  flex: auto 1 1;
}

@media (min-width: 768px) {
  .header-menu__nav li {
    margin: 0 5px;
  }
}

.header-menu__nav a {
  display: flex;
  flex-basis: 100%;
  align-content: center;
  padding: .3em;
  border-left: 10px #000 solid;
  background: var(--background-color);
  color: var(--color-link-alt);
}

@media (min-width: 768px) {
  .header-menu__nav a {
    justify-content: center;
    border: none;
    text-align: center;
  }
}

.header-menu__nav a:hover {
  color: var(--color-link-alt--hover);
}

.header-menu__utils {
  display: flex;
  flex-direction: column;
}

.header-menu__utils li {
  list-style: none;
}

.header-menu__utils a {
  display: block;
  padding: .7em .5em;
  margin: 0;
  background-color: transparent;
  font-family: var(--font-family-alt);
  font-size: 1.5625rem;
} */

.entorno-sede .main-menu__item-action {
  line-height: 1em;
}

@media (min-width: 1200px) {
  .entorno-sede .main-menu {
    justify-content: center;
    border-top: 10px solid var(--color-secundario);
    border-bottom: 10px solid var(--color-secundario);
    background-color: var(--background-color);
  }
  .main-menu__item--sede {
    flex-grow: 1;
    border-right: 10px solid var(--color-secundario);
  }
  .main-menu__item--sede:first-child {
    border-left: 10px solid var(--color-secundario);
  }
}
