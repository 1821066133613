.litepicker[data-plugins*=ranges][data-ranges-position=top] > .container__main > .container__predefined-ranges {
  width: calc(var(--litepicker-month-width) + 10px);
  flex-wrap: wrap;
}

.litepicker[data-plugins*=ranges] > .container__main > .container__predefined-ranges button {
  padding: 5px 10px;
}

.litepicker .container__months .month-item-header div > .month-item-year {
  padding: 0 var(--gutter-interior);
}
