/* ==================================================================== *\
 *                                                                      *
 *  RESULTADOS DE BÚSQUEDA                                              *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *  /pages/_search-results.css                                          *
 * -------------------------------------------------------------------- *
 *  Tamaño de fuente base:                                              *
 *    - Movil:  18px                                                    *
 *    - Tablet: 18px                                                    *
 *    - Web:    18px                                                    *
 *                                                                      *
 *  No se especifíca en los estilos porque lo hereda del contenedor:    *
 *    Ej: .main-content                                                 *
\* ==================================================================== */

/**
 * 1. Sobreescribir los estilos de una clase de bootstrap mal aplicada
 *    (.row), ya que no existen divisiones en columnas.
 */
.result-elements {
  margin: 0; /* 1 */

  /* padding: calc(var(--gutter-interior-main-one-col) * 1.5) var(--gutter-interior-main-one-col); */
  background-color: var(--background-color);
}

.result-elements a {
  color: var(--color-link);
}

/* -------------------------------------------------------------------- *\
 *  Titulo de la pantalla de búsqueda                                   *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  Aparece lo primero dentro de <section class="row result-elements">  *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *    header                                                            *
 *      h1                                                              *
 *        "Resultado de la búsqueda"                                    *
 *                                                                      *
 *      form.form (formulario opcional de búsqueda. Ej: j34-01w)        *
 *        div.form__field                                               *
 *          input.form__input                                           *
 *        input.form__submit                                            *
 *                                                                      *
 *      p.result-count (ver components/_paginacion-info.css)            *
 *                                                                      *
\* -------------------------------------------------------------------- */

/**
 * 1. Bajo el título puede haber un formulario de búsqueda (ej: j34-01w).
 *    Para adaptar el espacio entre éste y la capa .result-count (ver siguiente
 *    regla) se especifica un margen inferior.
 */
.result-elements > header + form {
  margin-bottom: .6em; /* [1] */
}

/**
 * 1. Bajo el título se presenta la información de la paginación con poca
 *    separación, por lo que se especifíca que en esta página el .result-count
 *    tenga margen superior negativo y se estreche el espacio con el elemento
 *    anterior.
 *    El elemento anterior puede ser:
 *    - caso 1:
 *        El header ("Resultados de la búsqueda")
 *        selector: .result-elements > header + .result-count
 *    - caso 2:
 *        Un form de busqueda (aparece en j34-01w)
 *        selector: .result-elements > header + form + .result-count
 */
.result-elements > header + .result-count,
.result-elements > header + form + .result-count {
  margin-top: -1.2em; /* [1] */
}

/* -------------------------------------------------------------------- *\
 *  ELEMENTO: Resultado de búsqueda                                     *
 * -------------------------------------------------------------------- *
 *  DOM:                                                                *
 *                                                                      *
 *    article                                                           *
 *      h1                                                              *
 *        span                                                          *
 *          ([formato])                                                 *
 *        a                                                             *
 *      p.life_span                                                     *
 *      p.description                                                   *
 *                                                                      *
 *      p                                                               *
 *        (Última modificación...)                                      *
 *                                                                      *
 *      p                                                               *
 *        (Incluido en los siguientes calendarios...)                   *
 *      ul.additions-items                                              *
 *        li.glyphicon.glyphicon-calendar                               *
 *          a                                                           *
 *      p.categoria                                                     *
 *                                                                      *
\* -------------------------------------------------------------------- */
.result-elements article {
  --rhythm-increase: 1.5;

  /* margin: fluidmargin(var(--rhythm-increase, 1)); */
}

/**
 * Margen por defecto para elementos sin identificar,
 * normalmente dentro de un parrafo anónimo
 */
.result-elements article p:not(:empty) {
  margin: 0 0 calc(var(--rhythm) * .5);
}

/* Nombre */
.result-elements article :is(h1,h2) {
  --font: minimalfont(strip(var(--font-size-31px)));
  --margin: factormargin(strip(var(--font-size-31px)));
  --line: 1.3em;
  margin: 0;
  margin-top: calc(var(--margin) * var(--margin-top-factor));
  margin-bottom: calc(var(--margin) * var(--margin-bottom-heading-factor));
  color: var(--color-heading3);
  font-family: inherit;
  font-size: var(--font);
  line-height: var(--line);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .result-elements article :is(h1,h2) {
    --margin: fluidmargin(strip(var(--font-size-31px)));
    font-size: fluidfont(strip(var(--font-size-31px)));
    line-height: fluidline(strip(var(--line)), strip(var(--font-size-31px)));
  }
}

@media (min-width: 1420px) {
  .result-elements article :is(h1,h2) {
    font-size: var(--font-size-31px);
  }
}

/**
 * 1. Eliminar el subrayado verde que hereda de _base.css
 */
.result-elements article :is(h1,h2) a {
  background-image: none; /* [1] */
}

.entorno-turismo .result-elements article :is(h1,h2) a {
  font-family: var(--font-family-turismo-base);
  font-weight: bold;
}

/**
 * Tipo de documento (en caso de que no sea HTML)
 * Ej: pdf, doc...
 */
.result-elements article :is(h1,h2) span {
  color: var(--color-text);
  font-size: .762em;
  vertical-align: top;
}

.entorno-turismo .result-elements article :is(h1,h2) span {
  color: var(--color-text-high-contrast);
  font-family: var(--font-family-turismo-base);
  font-size: var(--font-size-18px);
  font-weight: bold;
}

/* Fecha de evento o acto */
.result-elements article .life_span {
  font-family: var(--font-family-base-semi);
}

/* Descripción breve */

/* .result-elements article .description {} */

/**
 * Ultima modificación del contenido.
 * Elemento sin identificar con clase.
 */

/* .result-elements article p .cursiva {} */

/**
 * Enlaces complementarios del resultado
 *
 * 1. Se presentan en una lista, así que se eliminan el posicionamiento
 *    por defecto de la misma.
 */
.result-elements article .additions-items {
  padding: 0; /* 1 */
  margin: 0 0 .6em; /* 1 */
}

.result-elements article .additions-items li {
  display: block;
  padding: 0; /* 1 */
  margin: 0 0 .3em; /* 1 */
}

.result-elements article .additions-items .glyphicon {
  color: var(--color-secundario);
}

/* 1. Alinear verticalmente el icono con el enlace */
.result-elements article .additions-items .glyphicon::before {
  vertical-align: middle;
}

/**
 *  1. Aliner verticalmente el enlace al icono
 *  2. Eliminar un extraño espacio entre icono y enlace
 */
.result-elements article .additions-items .glyphicon a {
  display: inline-block; /* 1 */
  margin-top: 2px; /* 1 */
  margin-left: -10px; /* 2 */
  color: var(--color-link);
  vertical-align: middle; /* 1 */
}

.entorno-turismo .result-elements article .additions-items .glyphicon a {
  font-family: var(--font-family-turismo-base);
}

/**
 * Categorías a las que pertenece el resultado
 */
.result-elements article .categoria {
  --line: 1.714em;
  --font: minimalfont(strip(var(--font-size-21px)));
  color: var(--color-text-grey);
  font-size: var(--font);
  line-height: var(--line);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .result-elements article .categoria {
    font-size: fluidfont(strip(var(--font-size-21px)));
    line-height: fluidline(strip(var(--line)), strip(var(--font-size-21px)));
  }
}

@media (min-width: 1420px) {
  .result-elements article .categoria {
    font-size: var(--font-size-21px);
  }
}

.entorno-turismo .result-elements article .categoria {
  font-size: var(--font-size-18px);
}

/* -------------------------------------------------------------------- *\
 *  ELEMENTO: Resultado de búsqueda                                     *
 * -------------------------------------------------------------------- *
 *  DOM:                                                                *
 *                                                                      *
 *    article.outstanding                                               *
 *      h1                                                              *
 *      span.recomended_link                                            *
 *                                                                      *
\* -------------------------------------------------------------------- */
.result-elements .recomended_link {
  padding: .2em .6em;
  background-color: var(--background-color-alt-darker);
  color: var(--color-text);
  font-size: inherit;
}

.result-elements .recomended_link + h2 {
  --margin: factormargin(strip(var(--font-size-31px)));
  margin-top: calc(var(--margin) * .3);
  margin-bottom: var(--margin);
}

.main__results > article.outstanding + :not(.outstanding) {
  margin-top: calc(var(--rhythm) * 2);
}

.result-elements article .additions-items .glyphicon-calendar {
  display: flex;
  align-items: flex-start;
}

.result-elements article .additions-items .glyphicon-calendar::before {
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
  flex-shrink: 0;
  background-color: var(--color-primario);
  content: "";
  mask-image: url(../img/svg/sprite.svg#icon-calendar-view);
}

.result-elements article .additions-items .glyphicon-calendar a {
  margin-left: .5em;
}
