
/** TURISMO */
.header--turismo .header-main__highlighted * {
  display: flex;
  justify-content: flex-end;
  font-family: var(--font-family-turismo-heading);
  font-size: var(--font-size-18px);
}

.header--turismo .header-main__highlighted li:first-child {
  padding-right: 1em;
  border-right: 3px solid #d0dadc;
  margin-right: 1em;
}

@media (max-width: 1199px) {
  .header--turismo .header-main {
    border-bottom: 1px solid var(--input-turismo-border-color);
    background: var(--color-entorno-turismo);
  }
}

.header--turismo .header-main__menu-button::before {
  content: none;
}

@media (max-width: 991px) {
  .header--turismo .header-main__highlighted a {
    justify-content: center;
    text-align: center;
  }
}

.header--turismo .header-main__highlighted img {
  align-self: center;
  margin-bottom: .3em;
}

@media (min-width: 992px) {
  .header--turismo .header-main__highlighted img {
    margin: 0 .5em 0 0;
  }
}

@media (min-width: 1200px) {
  .header--turismo .header-main__highlighted {
    padding-right: 0;
    border-right: 0;
    margin-right: 0;
  }
  .header--turismo .header-main__menu-button {
    display: none;
  }
}
