/* ==================================================================== *\
 *                                                                      *
 *  FICHA: ANILLO VERDE                                                 *
 *                                                                      *
 *  ------------------------------------------------------------------  *
 *  /pages/_ficha--anilloVerde.css                                      *
 *  ------------------------------------------------------------------  *
 *  Estilos propios de la ficha                                         *
\* ==================================================================== */

/* -------------------------------------------------------------------- *\
 *  NAVEGACIÓN                                                          *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  Situada en una columna, sobre el mapa del anillo                    *
 *                                                                      *
 *  Todos los li tienen la clase "icon-arrow_link1Ani", menos el        *
 *  último (Zadorra) que tiene la clase "arrow_link1Ani100" por         *
 *  controlar el float del último elemento.                             *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *    ul.list                                                           *
 *      li.icon-arrow_link1Ani                                          *
 *        a                                                             *
 *      li:last-child.arrow_link1Ani100                                 *
 *        a                                                             *
 *                                                                      *
\* -------------------------------------------------------------------- */
.list__items--column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px;
  margin: 0;
}

.entorno-turismo .list__items--column {
  background-color: var(--background-turismo-color);
}

.icon-arrow_link1Ani,
.icon-arrow_link1Ani100 {
  width: 45%;
  margin-left: 15px;
  list-style-image: url(../img/arrow_link2.png);
}

.icon-arrow_link1Ani a,
.icon-arrow_link1Ani100 a,
.icon-arrow_link1Ani a:active,
.icon-arrow_link1Ani100 a:active {
  color: var(--color-text-high-contrast);
}

.icon-arrow_link1Ani a:hover,
.icon-arrow_link1Ani100 a:hover {
  color: var(--color-link--hover);
}
