/*

@styleguide

@title Cards: Event Card Not Image(beta)

Un Event Card Not Image es un contenedor con información sobre un determinado acto o evento que sirve
para presentar su información de forma clara y permitir un enlace destacado al mismo. La diferencia
con un Event Card es que no lleva imagen.

```html
<article class="event-card-notImage card">
  <a class="card__block-link" href="[url del evento]">

    // IMAGEN DE CABECERA

    // Si tiene imagen
    <div class="card__image">
       <img src="..." alt="...">
    </div>
    // Si no tiene imagen
    <div class="card__image not-found"></div>

    // CONTENIDO DE LA TARJETA
    <div class="card__content">
      // Titulo del evento (h3, h4, h5, h6 segun corresponda)
      <hX class="card__title">[titulo de la tarjeta]</hX>
      <p class="card__meta">
        <span class="card__meta-content">
          <time class="event-card__time">
            <span class="event-card__time-hour">[hora (opcional)]</span>
            <span class="event-card__time-date">[dia y mes abreviado (opcional) (formato: dd MMM)]</span>
            <span class="event-card__time-year">[año (opcional)]</span>
          </time>
          // Si tiene fecha inicio y fin añadir una segunda
          <time class="event-card__time">
            <span class="event-card__time-hour">[hora (opcional)]</span>
            <span class="event-card__time-date">[dia y mes abreviado (opcional) (formato: dd MMM)]</span>
            <span class="event-card__time-year">[año (opcional)]</span>
          </time>
        </span>
      </p>
      // Descripcion corta (opcional)
      <p>[descripcion corta]</p>
    </div>

  </a>
</article>
```

En el siguiente ejemplo se muestran cuatro `event-card` con diferente información:

<div class="psg-responsive-sample">
  <section class="container">
    <ul class="card-collection has-2-cols&#64;sm has-3-cols&#64;md">
      <li class="card-collection__item">
        <article class="event-card card">
          <a class="card__block-link" href="">
            <div class="card__image">
               <img src="https://www.vitoria-gasteiz.org/wb021/http/img/gestor/01/61/gi_40161.jpg" alt="Alberto Canet Muga">
            </div>
            <div class="card__content">
              <h3 class="card__title">Pregon de Semana Santa</h3>
              <p class="card__meta">
                <time class="event-card__time">
                  <span class="event-card__time-hour">22:30</span>
                  <span class="event-card__time-date">30 ABR</span>
                  <span class="event-card__time-year">2016</span>
                </time>
              </p>
            </div>
          </a>
        </article>
      </li>
      <li class="card-collection__item">
        <article class="event-card card">
          <a class="card__block-link" href="">
            <div class="card__image">
              <img src="https://www.vitoria-gasteiz.org/wb021/http/img/gestor/90/13/gi_39013.jpg" alt="Clicks Playmobil">
            </div>
            <div class="card__content">
              <h3 class="card__title">Exposición Click</h3>
              <p class="card__meta">
                <time class="event-card__time">
                  <span class="event-card__time-hour">20:30</span>
                </time>
                <time class="event-card__time">
                  <span class="event-card__time-hour">22:30</span>
                  <span class="event-card__time-date">30 ABR</span>
                  <span class="event-card__time-year">2016</span>
                </time>
              </p>
              <p>Creaciones en 7,5 centímetros</p>
            </div>
          </a>
        </article>
      </li>
      <li class="card-collection__item">
        <article class="event-card card">
          <a class="card__block-link" href="">
            <div class="card__image not-found"></div>
            <div class="card__content">
              <h3 class="card__title">Exposición Click</h3>
              <p class="card__meta">
                <time class="event-card__time">
                  <span class="event-card__time-date">10 ABR</span>
                </time>
                <time class="event-card__time">
                  <span class="event-card__time-date">30 MAY</span>
                  <span class="event-card__time-year">2016</span>
                </time>
              </p>
              <p>Creaciones en 7,5 centímetros</p>
            </div>
          </a>
        </article>
      </li>
      <li class="card-collection__item">
        <article class="event-card card">
          <a class="card__block-link" href="">
            <div class="card__image not-found">
            </div>
            <div class="card__content">
              <h3 class="card__title">Exposición Click</h3>
              <p class="card__meta">
                <time class="event-card__time">
                  <span class="event-card__time-hour">22:30</span>
                  <span class="event-card__time-date">30 MAY</span>
                  <span class="event-card__time-year">2016</span>
                </time>
              </p>
              <p>Creaciones en 7,5 centímetros</p>
            </div>
          </a>
        </article>
      </li>
      <li class="card-collection__item">
        <article class="event-card card">
          <a class="card__block-link" href="">
            <div class="card__image">
              <img src="https://www.vitoria-gasteiz.org/wb021/http/img/gestor/90/13/gi_39013.jpg" alt="Clicks Playmobil">
            </div>
            <div class="card__content">
              <h3 class="card__title">Exposición Click</h3>
              <p class="card__meta">
                <time class="event-card__time">
                  <span class="event-card__time-date">10 MAY</span>
                </time>
                <time class="event-card__time">
                  <span class="event-card__time-date">30 MAY</span>
                  <span class="event-card__time-year">2016</span>
                </time>
              </p>
              <p>Creaciones en 7,5 centímetros</p>
            </div>
          </a>
        </article>
      </li>
    </ul>
  </section>
</div>

*/

.event-card-notImage {
  position: relative;
  font-size: var(--font-size-18px);
}

.event-card-notImage .card__block-link {
  display: block;
  padding: 0;
  background-color: var(--background-turismo-color-alt-dark);
  color: currentColor;
}

.event-card-notImage .card__content {
  display: flex;
  flex-direction: column-reverse;
  padding: 0;
  margin: 15px;
  background-color: var(--background-turismo-color);
}

.event-card-notImage .card__title {
  display: block;
  display: -webkit-box;
  overflow: hidden;
  height: 3.2rem;
  margin: 10px 15px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-align: center;
  white-space: normal;
}

.event-card-notImage .card__date {
  display: flex;
  height: 6em;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.event-card-notImage .card__date--calendario {
  background-image: url(../img/calendario/calendario.png);
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
}

.event-card-notImage .card__date > :nth-child(2) > .event-card__time-date::before {
  margin: 0 10px;
  content: '-';
}

.event-card-notImage .event-card__time {
  display: inline;
  padding: 0;
  margin: 0;
  color: var(--color-text-high-contrast);
  font-family: "Oswald Bold", sans-serif;
  font-size: 0;
  font-style: normal;
  line-height: 1;
  text-align: left;
}

.event-card-notImage .event-card__time > * {
  padding-top: .4rem;
}

.event-card-notImage .event-card__time-date,
.event-card-notImage .event-card__time-hour,
.event-card-notImage .event-card__time-year {
  display: block;
  font-size: 1.5rem;
  font-style: normal;
}

.event-card-notImage .event-card__time-hour {
  display: inline;
}

.event-card-notImage .event-card__time-year {
  color: var(--color-text-high-contrast);
}

.event-card-notImage .event-card__time-date {
  color: var(--color-text-high-contrast);
  font-size: var(--font-size-24px);
}

@media (min-width: 768px) {
  .event-card-notImage .event-card__time-date {
    font-size: var(--font-size-28px);
  }
}

.event-card-notImage .event-card__time + .event-card-notImage .event-card__time {
  min-width: 5.1em;
  margin-top: 0;
}

.event-card-notImage .event-card__time + .event-card-notImage .event-card__time .event-card__time-hour::before {
  content: '-';
}
