/*

@styleguide

@title Cards: Event Card (beta)

Un Event Card es un contenedor con información sobre un determinado acto o evento que sirve
para presentar su información de forma clara y permitir un enlace destacado al mismo.

```html
<article class="event-card card">
  <a class="card__block-link" href="[url del evento]">

    // IMAGEN DE CABECERA

    // Si tiene imagen
    <div class="card__image">
       <img src="..." alt="...">
    </div>
    // Si no tiene imagen
    <div class="card__image not-found"></div>

    // CONTENIDO DE LA TARJETA
    <div class="card__content">
      // Titulo del evento (h3, h4, h5, h6 segun corresponda)
      <hX class="card__title">[titulo de la tarjeta]</hX>
      <p class="card__meta">
        <span class="card__meta-content">
          <time class="event-card__time">
            <span class="event-card__time-hour">[hora (opcional)]</span>
            <span class="event-card__time-date">[dia y mes abreviado (opcional) (formato: dd MMM)]</span>
            <span class="event-card__time-year">[año (opcional)]</span>
          </time>
          // Si tiene fecha inicio y fin añadir una segunda
          <time class="event-card__time">
            <span class="event-card__time-hour">[hora (opcional)]</span>
            <span class="event-card__time-date">[dia y mes abreviado (opcional) (formato: dd MMM)]</span>
            <span class="event-card__time-year">[año (opcional)]</span>
          </time>
        </span>
      </p>
      // Descripcion corta (opcional)
      <p>[descripcion corta]</p>
    </div>

  </a>
</article>
```

En el siguiente ejemplo se muestran cuatro `event-card` con diferente información:

<div class="psg-responsive-sample">
  <section class="container">
    <ul class="card-collection has-2-cols&#64;sm has-3-cols&#64;md">
      <li class="card-collection__item">
        <article class="event-card card">
          <a class="card__block-link" href="">
            <div class="card__image">
               <img src="https://www.vitoria-gasteiz.org/wb021/http/img/gestor/01/61/gi_40161.jpg" alt="Alberto Canet Muga">
            </div>
            <div class="card__content">
              <h3 class="card__title">Pregon de Semana Santa</h3>
              <p class="card__meta">
                <time class="event-card__time">
                  <span class="event-card__time-hour">22:30</span>
                  <span class="event-card__time-date">30 ABR</span>
                  <span class="event-card__time-year">2016</span>
                </time>
              </p>
            </div>
          </a>
        </article>
      </li>
      <li class="card-collection__item">
        <article class="event-card card">
          <a class="card__block-link" href="">
            <div class="card__image">
              <img src="https://www.vitoria-gasteiz.org/wb021/http/img/gestor/90/13/gi_39013.jpg" alt="Clicks Playmobil">
            </div>
            <div class="card__content">
              <h3 class="card__title">Exposición Click</h3>
              <p class="card__meta">
                <time class="event-card__time">
                  <span class="event-card__time-hour">20:30</span>
                </time>
                <time class="event-card__time">
                  <span class="event-card__time-hour">22:30</span>
                  <span class="event-card__time-date">30 ABR</span>
                  <span class="event-card__time-year">2016</span>
                </time>
              </p>
              <p>Creaciones en 7,5 centímetros</p>
            </div>
          </a>
        </article>
      </li>
      <li class="card-collection__item">
        <article class="event-card card">
          <a class="card__block-link" href="">
            <div class="card__image not-found"></div>
            <div class="card__content">
              <h3 class="card__title">Exposición Click</h3>
              <p class="card__meta">
                <time class="event-card__time">
                  <span class="event-card__time-date">10 ABR</span>
                </time>
                <time class="event-card__time">
                  <span class="event-card__time-date">30 MAY</span>
                  <span class="event-card__time-year">2016</span>
                </time>
              </p>
              <p>Creaciones en 7,5 centímetros</p>
            </div>
          </a>
        </article>
      </li>
      <li class="card-collection__item">
        <article class="event-card card">
          <a class="card__block-link" href="">
            <div class="card__image not-found">
            </div>
            <div class="card__content">
              <h3 class="card__title">Exposición Click</h3>
              <p class="card__meta">
                <time class="event-card__time">
                  <span class="event-card__time-hour">22:30</span>
                  <span class="event-card__time-date">30 MAY</span>
                  <span class="event-card__time-year">2016</span>
                </time>
              </p>
              <p>Creaciones en 7,5 centímetros</p>
            </div>
          </a>
        </article>
      </li>
      <li class="card-collection__item">
        <article class="event-card card">
          <a class="card__block-link" href="">
            <div class="card__image">
              <img src="https://www.vitoria-gasteiz.org/wb021/http/img/gestor/90/13/gi_39013.jpg" alt="Clicks Playmobil">
            </div>
            <div class="card__content">
              <h3 class="card__title">Exposición Click</h3>
              <p class="card__meta">
                <time class="event-card__time">
                  <span class="event-card__time-date">10 MAY</span>
                </time>
                <time class="event-card__time">
                  <span class="event-card__time-date">30 MAY</span>
                  <span class="event-card__time-year">2016</span>
                </time>
              </p>
              <p>Creaciones en 7,5 centímetros</p>
            </div>
          </a>
        </article>
      </li>
    </ul>
  </section>
</div>

*/

.event-card {
  position: relative;

  --line: var(--rhythm);
  font-size: var(--font-size-18px);
  line-height: var(--line);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .event-card {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .event-card {
    font-size: var(--font-size-24px);
  }
}

.event-card .card__block-link {
  display: block;
  padding: 50.5% var(--gutter-interior) var(--gutter-interior);
  color: currentColor;
}

/*
@media (min-width: 768px) {
  .event-card .card__block-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
} */

.event-card .card__content {
  min-height: inherit;
  padding: 0;
  margin: 0;
  font-size: inherit;
}

.event-card .card__title {
  --font: minimalfont(strip(var(--font-size-24px)));
  margin: 0;
  color: currentColor;
  font-size: var(--font);
  line-height: var(--rhythm);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .event-card .card__title {
    --font: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .event-card .card__title {
    --font: var(--font-size-24px);
  }
}

/* .event-card .card__image {
  position: absolute;
  top: calc(var(--gutter-interior) / 2);
  right: 50%;
  left: calc(var(--gutter-interior) / 2);
  width: initial;
  margin-right: calc(var(--gutter-interior) / 2);
}

.event-card .card__image::before {
  display: block;
  padding-top: 100%;
  content: '';
  float: left;
} */

.event-card .card__image.not-found {
  border: 1px solid var(--background-color-alt-dark);
  background-color: rgb(255, 255, 255);
  background-image: url("../img/logo-vg-green-capital.png");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 75%;
}

.event-card .card__meta {
  position: absolute;
  top: var(--gutter-interior);
  right: auto;
  left: var(--gutter-interior);
  margin-left: calc(var(--gutter-interior) / 2);

  /* background-color: var(--color-primario); */
  font-size: 0;
  text-align: center;
}

/* .event-card .card__meta::before {
  display: inline-block;

  width: 0;
  padding-top: 100%;

  content: '';
  vertical-align: middle;
}

.event-card .card__meta-content {
  display: inline-block;

  text-align: left;
  vertical-align: middle;

  font-size: var(--font-size-18px);
} */

.event-card__time {
  display: flex;
  flex-direction: column;
  margin: 0;
  color: var(--color-text-inverse);
  font-family: 'Source Sans Pro Regular', sans-serif;
  font-size: minimalfont(strip(var(--font-size-18px)));
  line-height: 1.5;
  text-align: center;
}

.event-card__time-day {
  font-family: var(--font-family-alt);
  font-size: minimalfont(strip(var(--font-size-36px)));
}

@media (min-width: 320px) and (max-width: 1419px) {
  .event-card__time {
    font-size: fluidfont(strip(var(--font-size-18px)));
  }
  .event-card__time-day {
    font-size: fluidfont(strip(var(--font-size-36px)));
  }
}

@media (min-width: 1420px) {
  .event-card__time {
    font-size: var(--font-size-18px);
  }
  .event-card__time-day {
    font-size: var(--font-size-36px);
  }
}

.event-card__time > :nth-child(n) {
  padding: 0 1em;
  background-color: var(--color-primario);
}

.event-card__time-date {
  line-height: 1;
}

.event-card__time > span:first-child {
  padding-top: .5em;
}

.event-card__time > span:last-child {
  padding-bottom: .5em;
}

.event-card__time + .event-card__time {
  min-width: 5.1em;
  margin-top: 0;
}

.event-card__time + .event-card__time .event-card__time-hour::before {
  content: '-';
}
