.link-list__items {
  /* padding-left: 14px; */
}

.link-list__footer {
  margin-top: var(--rhythm);
}

.link-list__footer-link {
  display: inline-flex;
  align-items: center;
  color: var(--color-text);
}

.link-list__footer-link:hover {
  color: var(--color-text);
}

.link-list__footer-link::before {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin-right: .5em;
  background-color: #000;
  content: '';
  mask-image: url(../img/svg/sprite.svg#icon-arrow-right-view);
  vertical-align: middle;
}

.link-list__footer-link:hover::before {
  animation: slide-right .5s ease-in-out both;
}

@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(.5em);
    transform: translateX(.5em);
  }
}
