/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE: CAROUSEL DE FECHAS                                              *
 *                                                                      *
 *  ------------------------------------------------------------------- *
 *  /components/_carousel-fechas.css                                           *
 *  ------------------------------------------------------------------- *
\* ==================================================================== */

.carousel__fechas {
  position: relative;
  padding: 0 32px;
  margin: 0 0 var(--rhythm);
  color: var(--color-text-high-contrast);
  font-family: var(--font-family-alt);
  font-size: var(--font-size-18px);
}

.carousel__fechas .nav__button {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  width: 35px;
  height: auto;
  align-items: center;
  padding: 0;
  border: 0;
  margin: 2em 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
}

.carousel__years.carousel__fechas .nav__button,
.carousel__months.carousel__fechas .nav__button {
  margin: 0;
  background-color: #f2f2e9;
}

.nav__button:active,
.nav__button:hover,
.nav__button:focus {
  border: 0;
  background-color: transparent;
  background-image: none;
  opacity: 1;
}

.nav__button .button__svg {
  width: 12px;
  height: 18px;
  margin: auto;
  fill: #004ddb;
  stroke: #004ddb;
  stroke-width: 0;
}

.nav__button--prev {
  left: 0;
}

.nav__button--next {
  right: 0;
}

.carousel__container {
  overflow: hidden;
  margin: 0 .2em;
}

.carousel__list {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease;
}

.carousel__list.unwrap {
  flex-wrap: nowrap;
}

.carousel__item {
  position: relative;
  flex: 1 0 85px;
  border: 0;
  background-color: transparent;
  line-height: 25px;
  text-align: center;
}

@media (min-width: 768px) {
  .carousel__item {
    flex: 1 0 120px;
  }
}

.carousel__item .item__button {
  display: inline-flex;
  width: 60px;
  height: 42px;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  margin: 1em 0;
  background-color: #f2f2e9;
  color: #004ddb;
  cursor: pointer;
}

.carousel__item .item__button:hover {
  background-color: var(--color-primario);
  color: var(--color-text-inverse);
}

.carousel__years .carousel__item .item__button,
.carousel__months .carousel__item .item__button {
  width: 70px;
  margin: 0;
}

.carousel__item .item__button--days {
  margin: 1.9em 0;
}

.carousel__item .item__button.is-selected {
  border: 2px solid black;
  background-color: white;
  color: black;
  cursor: default;
  font-weight: bold;
  outline: 0;
}

.carousel__item .item__button.disable {
  z-index: -1;
  border: 2px solid #f2f2e9;
  background-color: white;
  color: #777676;
  cursor: not-allowed;
  font-weight: normal;
  outline: 0;
}

.carousel__item .item__button--days::before,
.carousel__item .item__button--days::after {
  position: absolute;
  left: 0;
  display: block;
  width: 100%;
  color: black;
  white-space: nowrap;
}

.carousel__item .item__button--days::before {
  top: 6px;
  content: attr(data-diasemanacorto);
}

.carousel__item .item__button--days::after {
  bottom: 6px;
  content: attr(data-mescorto);
}

.carousel__item .item__button--days.is-selected::before,
.carousel__item .item__button--days.is-selected::after {
  font-weight: bold;
}

.carousel__item .item__button--days.disable::before,
.carousel__item .item__button--days.disable::after {
  color: #777676;
}

/* ------------------------------------------------------------------ *\
 *  Load: Al seleccionar un dia muestra una animacion                 *
\* ------------------------------------------------------------------ */

.carousel__item .item__button .load {
  width: 25px;
  height: 25px;
  border: 5px solid #000;
  border-top: 5px solid transparent;
  -webkit-animation: load-animate infinite linear 1s;
  animation: load-animate infinite linear 1s;
  border-radius: 100%;
}

@-webkit-keyframes load-animate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    opacity: 1;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load-animate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    opacity: 1;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  .carousel__left {
    display: flex;
    max-width: 18em;
    flex: 1 0 auto;
    flex-direction: column;
    margin-right: 1em;
  }
  .carousel__right {
    flex: 1 1 auto;
  }
  .carousel__years .carousel__item .item__button,
  .carousel__months .carousel__item .item__button {
    width: 70px;
    margin: 0;
  }
  .carousel__item .item__button--days::before {
    content: attr(data-diasemanalargo);
  }

  /* .carousel__item .item__button--days::after {
    content: attr(data-meslargo);
  } */
}
