.back-to-top {
  position: fixed;
  z-index: 997;
  right: 25px;
  bottom: 5em;
  display: inline-flex;
  width: 44px;
  height: 44px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  background-color: var(--color-primario);
  cursor: pointer;
  transition: all .25s ease-in-out;
}

.back-to-top--show {
  visibility: visible;
}

.back-to-top--hide {
  opacity: 0;
  visibility: hidden;
}

.back-to-top svg {
  width: 22px;
  height: 22px;
  fill: #fff;
}
