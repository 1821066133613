/* ==================================================================== *\
 *                                                                      *
 *  FICHA                                                               *
 *                                                                      *
 *  ------------------------------------------------------------------  *
 *  /pages/_ficha.css                                                   *
 *  ------------------------------------------------------------------  *
 *  Estilos para fichas guías (y cuadros de mando)                      *
\* ==================================================================== */

/* -------------------------------------------------------------------- *\
 *  CONTENIDO DE UN CUADRO DE MANDO                                     *
 * -------------------------------------------------------------------- *
 *  Estructura del contenido (excluyendo menu contextual)               *
\* -------------------------------------------------------------------- */

/**
 * Se utiliza el selector .main-content > :first-child para aplicar los mismos
 * espacios en las aplicaciones no adaptadas al uso de .main
 */

/* .main */

/* .main-content > .main,
.main-content > :first-child {
  padding: calc(10px * 1.5) 10px;
}

.entorno-turismo .main-content > .main.main--wb021,
.entorno-turismo .main-content > :first-child {
  padding: calc(var(--gutter-interior-main-one-col) * 1.5) 0;
}

.entorno-turismo .main-content > .main {
  padding: 0;
}

@media (min-width: 767px) {
  .main-content > .main,
  .main-content > :first-child {
    padding: calc(var(--gutter-interior-main-one-col) * 1.5) var(--gutter-interior-main-one-col);
  }
  .entorno-turismo .main-content > .main,
  .entorno-turismo .main-content > .main.main--wb021,
  .entorno-turismo .main-content > :first-child {
    padding: calc(var(--gutter-interior-main-one-col) * 1.5) var(--gutter-interior-main-one-col);
  }
} */

.entorno-pwa .main-content > .main,
.entorno-pwa .main-content > :first-child {
  padding: 0;
}

/* .main__header {} */
.main__body::after {
  clear: both;
}

.main__body::before,
.main__body::after {
  display: table;
  content: " ";
}

article.main__grid-box,
section.main__grid-box {
  margin: calc(var(--rhythm) * var(--margin-top-factor)) 0 0;
}

article.main__grid-box::before,
section.main__grid-box::before {
  display: block;
  clear: both;
  content: "";
}

article.main__grid-box > header::before,
section.main__grid-box > header::before {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000;
  content: "";
}

.main__grid-box::after {
  display: block;
  clear: both;
  content: "";
}

/* -------------------------------------------------------------------- *\
 *  ESTRUCTURA CON COLUMNAS Y CAJAS                                     *
 *                                                                      *
 *  MODIFICADOR: main--grid                                             *
 * -------------------------------------------------------------------- *
 *  El main está estructurado en una grid de cajas separadas con        *
 *  espacio entre ellas.                                                *
\* -------------------------------------------------------------------- */

/**
 * 1. Si el main está estructurado en una grid de cajas el color de
 *    fondo será definido en cada caja (main__grid-box)
 */
.main.main--grid {
  padding: 0;
  background-color: transparent; /* [1] */
}

.main--grid .main__grid-box {
  padding: 0;
  margin-top: calc(var(--rhythm) * var(--margin-top-factor));
  margin-bottom: 0;
  background-color: var(--background-color);
}

.main--grid .main__header.main__grid-box {
  margin-top: 0;
}

.main--grid .main__grid-box header {
  cursor: pointer;
}

.entorno-turismo .main--grid .main__grid-box {
  padding: 0;
  margin-bottom: 0;
}

/* .main--grid .main__header,
.main--grid .main__grid-col .main__grid-box,
.entorno-turismo .result-elements .main__header {
  padding: 0 var(--gutter-interior-main-two-cols);
}

.entorno-turismo .result-elements .main__results {
  padding: 0 var(--gutter-interior);
  margin-bottom: 0;
} */

/* -------------------------------------------------------------------- *\
 *  ESTRUCTURA CON COLUMNAS SIN SEPARACION INTERMEDIA                   *
 *                                                                      *
 *  Modificador: --grid-column-collapse                                 *
 * -------------------------------------------------------------------- *
 *  Se separa el contenido en dos columnas, pero no existe espacio      *
 *  entre ellas. Ambas forman parte de la misma masa blanca.            *
\* -------------------------------------------------------------------- */
.main--grid-column-collapse .main__grid-row {
  margin-right: 0;
  margin-left: 0;
  background-color: var(--background-color);
}

.main--grid-column-collapse .main__grid-col {
  padding-right: 0;
  padding-left: 0;
}

/* -------------------------------------------------------------------- *\
 *  Titulos                                                             *
\* -------------------------------------------------------------------- */

/**
 * Titulo principal del content
 *
 * 1. Tamaño de referencia: 30px
**/
.main__title {
  --font: minimalfont(strip(var(--font-size-48px)));
  --margin: factormargin(strip(var(--font-size-48px)));
  --line: 1.2em;
  margin: 0;
  margin-top: calc(var(--margin) * var(--margin-top-factor));
  margin-bottom: calc(var(--margin) * var(--margin-bottom-heading-factor));
  font-size: var(--font);
  line-height: var(--line);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .main__title {
    --font: fluidfont(strip(var(--font-size-48px)));
    --margin: fluidmargin(strip(var(--font-size-48px)));
    line-height: fluidline(strip(var(--line)), strip(var(--font-size-48px)));
  }
}

@media (min-width: 1420px) {
  .main__title {
    --font: var(--font-size-48px);
  }
}

/**
 * Titulo h2
 *
 * 1. Tamaño de referencia: 24px
 * 2. Padding de referencia: 12px
 * 3. En ocaciones (ej: suscripcionesRSS) se usa incorrectamente un h1
 *    como titulo de una caja de contenido
 * 4. Relacionado con 3: Se añade .main__body en el selector de h1s
 *    para evitar aplicarle el estilo al .main__title
 */
.main__grid-box > h2,
.main__grid-box > header > h2,
.main__grid-box > div > h2,
.main__body .main__grid-box > h1,
.main__body .main__grid-box > header > h1 {
  --font: minimalfont(strip(var(--font-size-39px)));
  --margin: factormargin(strip(var(--font-size-39px)));
  --line: 1.2em;
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: calc(var(--margin) * .5);
  margin-bottom: calc(var(--margin) * var(--margin-bottom-heading-factor));
  color: var(--color-heading2);
  font-size: var(--font);
  line-height: var(--line);
}

.main__grid-box > h2[aria-expanded="false"],
.main__grid-box > header > h2[aria-expanded="false"],
.main__grid-box > div > h2[aria-expanded="false"],
.main__body .main__grid-box > h1[aria-expanded="false"],
.main__body .main__grid-box > header > h1[aria-expanded="false"] {
  margin-bottom: 0;
}

@media (min-width: 320px) and (max-width: 1419px) {
  .main__grid-box > h2,
  .main__grid-box > header > h2,
  .main__grid-box > div > h2,
  .main__body .main__grid-box > h1,
  .main__body .main__grid-box > header > h1 {
    --font: fluidfont(strip(var(--font-size-39px)));
    --margin: fluidmargin(strip(var(--font-size-39px)));
    line-height: fluidline(strip(var(--line)), strip(var(--font-size-39px)));
  }
}

@media (min-width: 1420px) {
  .main__grid-box > h2,
  .main__grid-box > header > h2,
  .main__grid-box > div > h2,
  .main__body .main__grid-box > h1,
  .main__body .main__grid-box > header > h1 {
    font-size: var(--font-size-39px);
  }
}

.main__grid-box > header > h2::after {
  display: block;
  min-width: 56px;
  height: 56px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  background-image: url(../img/svg/sprite.svg#icon-arrow-view);
  content: '';
  transform: rotate(180deg) translateY(0);
  transition: all 300ms;
}

@media (max-width: 1199px) {
  .main__grid-box > header > h2::after {
    min-width: 42px;
    height: 42px;
  }
}

.main__grid-box > header > h2[aria-expanded="true"]::after {
  transform: rotate(0) translateY(10px);
}

/**
 * Titulo h3
 *
 * 1. Tamaño de referencia: 21px
 * 2. Padding de referencia: 12px
 */
.main__grid-box > h3,
.main__grid-box > header > h3,
.main__grid-box > div > h3 {
  margin-bottom: .5em; /* 2 */

  /* .main h3 { */
  color: var(--color-heading3);
  font-size: 1.167em; /* 1 */
}

/**
 * Titulo h4
 *
 * 1. Tamaño de referencia: 18px
 * 2. Padding de referencia: 9px
 */
.main__grid-box > h4,
.main__grid-box > div > h4 {
  margin-bottom: .5em; /* 2 */

  /* .main h4 { */
  color: var(--color-heading4);
  font-size: 1em; /* 1 */
}

/* -------------------------------------------------------------------- *\
 *  CABECERA DE CUADRO DE MANDO                                         *
\* -------------------------------------------------------------------- */

/**
 * Separación con respecto al banner. Se añade a la introducción para que,
 * en caso de no haber haber texto introductorio, el banner no genere un
 * espacio inferior extra en la cabecera.
 */
.main__banner {
  margin-top: 0;
  margin-bottom: var(--rhythm);
}

.main__banner picture {
  display: flex;
  flex-grow: 1;
}

.main__banner picture > img {
  flex-grow: 1;
}

@media (max-width: 768px) {
  .main__banner {
    margin-right: calc(var(--readable-padding-side) * -1);
    margin-left: calc(var(--readable-padding-side) * -1);
  }
}

/* -------------------------------------------------------------------- *\
 *  MAPA GEO VITORIA-GASTEIZ DENTRO DE UN CUADRO DE MANDO               *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *    article.main__grid-box.map.content                                *
 *      header.row                                                      *
 *        h2                                                            *
 *      noscript                                                        *
 *      div#mapaCompleto.mapasCompletos                                 *
 *        div#mapaGeoVitoria.mapasGeoVitoria                            *
 *          div.j16Map.olMap                                            *
 *        div.zonaIconos                                                *
 *          ul                                                          *
 *            li                                                        *
 *              span                                                    *
 *                img                                                   *
 *              (nombre capa)                                           *
 *        nav                                                           *
 *          p.pie                                                       *
 *            ("Si quiere realizar más búsquedas visite")               *
 *            strong                                                    *
 *              a                                                       *
 *                                                                      *
\* -------------------------------------------------------------------- */

/**
 * 1. Eliminar mal uso de .row
 */
.main__grid-box.map > .row {
  margin-right: 0; /* 1 */
  margin-left: 0; /* 1 */
}

/* .mapasCompletos .zonaIconos {} */
.mapasCompletos .zonaIconos ul {
  padding: 0;
}

.mapasCompletos .zonaIconos li {
  padding: 0;
  margin-left: 0;
  list-style: none;
}

/** Se ocultan los iconos que acompañan al nombre de cada colección */
.mapasCompletos .zonaIconos li span {
  display: none;
}


/* Añadir mano en las cabeceras desplegables */
h2[aria-expanded] {
  cursor: pointer;
}
