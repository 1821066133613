/* ==================================================================== *\
 *                                                                      *
 *  BUZÓN CIUDADANO                                                     *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *  /pages/_wb021-buzon.css                                             *
\* ==================================================================== */

/**
 * 1. Hay sitios donde a .mailbox-base le acompaña .row sin tener .col-...
 *    dentro. Otro mal uso de la grid de Bootstrap. Se anulan los efectos
 *    de .row.
 */
.mailbox-base {
  margin-right: 0; /* [1] */
  margin-left: 0; /* [1] */

  /* font-size: var(--font-size-21px); */
}

/**
 * Evitar mala visualización debido a floats en los encabezados de páginas
 * Ej: Listado de asuntos de un tema
 */
.mailbox-base header::after {
  display: table;
  clear: both;
  content: "";
}

/**
 * Evitar que elementos que se ocultan ocupen espacio.
 * Ej: hr.invisible que acompaña a la paginación en las lista de asuntos
 */
.mailbox-base .invisible {
  display: none;
}

/**
 * En la pantalla de presentación los botones de actualizar cada cinco
 * minutos no tienen margen inferior al estar en un div. Solucionarlo.
 *
 * 1. Se le aplica el mismo margin-bottom que a los asuntos, ya que
 *    en la pantalla va seguido de un listado de aquellos.
 */
.mailbox-base .buttons {
  margin-bottom: 2em;
}

/* Quitar el margen superiror del titulo */
.mailbox-base .mainTitle {
  margin-top: 0;
}

/* -------------------------------------------------------------------- *\
 *  Bloque: TITULO CON BOTON MOSTRAR/OCULTAR                            *
 *  Pantalla: PRESENTACION                                              *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *    div.container-hide.container-hide-mail                            *
 *      h4                                                              *
 *      button#imagen.btn-show.glyphicon-minus                          *
 *    div.hidden-element                                                *
 *      div                                                             *
 *                                                                      *
\* -------------------------------------------------------------------- */

/**
 * 1. El margen superior del titulo se elimina al tener el h4 un float
 *    left. Se le aplica el margen superior de este a la cabecera de
 *    la leyenda.
 */
.container-hide {
  margin-top: 1.6em; /* [1] */
}

.container-hide::after {
  display: table;
  clear: both;
  content: "";
}

.container-hide > h2 {
  margin-top: 0;
}

.container-hide > button {
  float: right;
}

.container-hide-legend > h2 {
  --font: minimalfont(strip(var(--font-size-39px)));
  --margin: factormargin(strip(var(--font-size-39px)));
  --line: 1.2em;
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: calc(var(--margin) * .5);
  margin-bottom: calc(var(--margin) * var(--margin-bottom-heading-factor));
  color: var(--color-heading2);
  line-height: var(--line);
}

.container-hide-legend > h2:hover {
  cursor: pointer;
}

.container-hide-legend > h2::after {
  display: block;
  min-width: 56px;
  height: 56px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  background-image: url(../img/svg/sprite.svg#icon-arrow-view);
  content: '';
  transform: rotate(180deg) translateY(0);
  transition: all 300ms;
}

.container-hide-legend h2[aria-expanded="true"]::after {
  transform: rotate(0) translateY(10px);
}

@media (max-width: 1199px) {
  .container-hide-legend > h2::after {
    min-width: 42px;
    height: 42px;
  }
}

/* -------------------------------------------------------------------- *\
 *  Bloque: FORMULARIO MIS ASUNTOS                                      *
 *  Pantalla: PRESENTACION                                              *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *    form#miForm                                                       *
 *      input[type="submit"].ocultar                                    *
 *      p.input-title                                                   *
 *        label                                                         *
 *      div.form-container                                              *
 *        input[type="text"]#identificadorPrivado.form-control          *
 *        button                                                        *
 *        p.hidden-xs                                                   *
 *          a                                                           *
 *                                                                      *
\* -------------------------------------------------------------------- */

/**
 * El label del input type="text" se encuentra en un párrafo:
 *  p.input-title > label
 *
 * 1. Eliminar el margin de los p de Bootstrap
 */
.mailbox-base #miForm .input-title {
  padding-bottom: .5em;
  margin: 0; /* [1] */
}

/* Todo lo contenido en div.form-container se presenta en una linea */
.mailbox-base #miForm .form-container > * {
  display: inline-block;
  margin-right: 1em;
}

@media (max-width: 768px) {
  .mailbox-base #miForm .fieldset.fieldset--inline > :last-child {
    display: block;
  }
}

/* -------------------------------------------------------------------- *\
 *  Bloque: LEYENDA DE ICONOGRAFIA                                      *
 *  Pantalla: PRESENTACION                                              *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *    article.iconLegend                                                *
 *      header.container-hide.container-hide-legend                     *
 *        h4                                                            *
 *        button#imagen.btn-show.glyphicon-plus                         *
 *      div.hidden-element                                              *
 *        div.col-xs-12.col-sm-6.col-md-5                               *
 *          h5                                                          *
 *          ul.iconsComments                                            *
 *            li.icon-comunicado_council                                *
 *            li.icon-answerd_council                                   *
 *            li.icon-zz033-comments                                    *
 *            li.icon-state-emoticonopersonas                           *
 *        div.col-xs-12.col-sm-6.col-md-4.visible-xs                    *
 *          h5                                                          *
 *          ul.iconsInput                                               *
 *            li.col-xs-6.icon-zz042-web                                *
 *            li.col-xs-6.icon-zz039-smartphone                         *
 *            li.col-xs-6.icon-zz034-arroba                             *
 *            li.col-xs-6.icon-zz037-inperson                           *
 *            li.col-xs-6.icon-zz036-post                               *
 *            li.col-xs-6.icon-zz038-phone                              *
 *        div.col-xs-12.col-sm-6.col-md-3                               *
 *          h5                                                          *
 *          ul.iconsStates                                              *
 *            li.icon-state-emoticonoNormal                             *
 *            li.icon-state-emoticonoAlegre                             *
 *            li.icon-state-emoticonoEnfadado                           *
 *            li.icon-state-emoticonoIracundo                           *
 *        div.col-xs-12.col-sm-6.col-md-4.hidden-xs                     *
 *          h5                                                          *
 *          ul.iconsInput                                               *
 *            li.col-xs-6.icon-zz042-web                                *
 *            li.col-xs-6.icon-zz039-smartphone                         *
 *            li.col-xs-6.icon-zz034-arroba                             *
 *            li.col-xs-6.icon-zz037-inperson                           *
 *            li.col-xs-6.icon-zz036-post                               *
 *            li.col-xs-6.icon-zz038-phone                              *
 *                                                                      *
\* -------------------------------------------------------------------- */

/**
 * 1. Contienen capas que usan la grid de Bootstrap pero no tienen la
 *    clase .row. Es un mal uso de Bootstrap que provoca un padding
 *    incorrecto en los hijos.
 *    Se les aplica por tanto los estilos de .row para solucionarlo
 *    sin tocar el HTML.
 *
 *    Eliminar estos estilos si se refactoriza correctamente el HTML
 */

.iconLegend::before {
  display: block;
  border-top: 2px solid #000;
  content: "";
}

.iconLegend .iconsInput {
  display: inline;
}

.iconLegend .hidden-element,
.iconLegend .iconsInput {
  margin-right: calc(var(--gutter-outer) / -2); /* [1] */
  margin-left: calc(var(--gutter-outer) / -2); /* [1] */
}

.iconLegend .iconsComments,
.iconLegend .iconsInput,
.iconLegend .iconsStates {
  padding: 0;
  font-size: .889em;
  line-height: 2;
  list-style: none;
}

/**
 * 1. Los iconos de información de comentarios tienen una anchura muy
 *    dispar. Se igualan los li:before a los que se aplican los iconos
 *    en vez de hacerlo directamente en el estilo del icono, ya que
 *    sólo es en este listado donde queremos el texto que los acompaña
 *    se alinee igual. Así los mantenemos independiente para otros usos.
 */
.iconLegend .iconsComments li::before {
  width: 32px; /* [1] */
  margin-top: .5rem;
}

.iconLegend .iconsComments li {
  display: flex;
}

/* -------------------------------------------------------------------- *\
 *  Bloque: ASUNTO                                                      *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *    ----------------------------------                                *
 *    Pantalla:                                                         *
 *    - Fichas guía                                                     *
 *    ----------------------------------                                *
 *                                                                      *
 *    div.mailbox (capa padre)                                          *
 *                                                                      *
 *      article (if respuesta: .respuesta)                              *
 *        h1                                                            *
 *          a                                                           *
 *            (titulo asunto)                                           *
 *        p                                                             *
 *          (informacion asunto: num. comentarios + fecha último)       *
 *        article (if respuesta: .respuestaInterior)                    *
 *          p (if respuesta: .respNegrita)                              *
 *            (comentario)                                              *
 *        p.pie                                                         *
 *          if respuesta                                                *
 *            i.icon-answerd_council                                    *
 *          else                                                        *
 *            span.naranja                                              *
 *              (autor)                                                 *
 *          end if                                                      *
 *          i.icon-state-...                                            *
 *          span.fechascomentario                                       *
 *            (fecha)                                                   *
 *                                                                      *
 *    ------------------------------------                              *
 *    Pantalla:                                                         *
 *    - Buzón: Presentación                                             *
 *    ------------------------------------                              *
 *                                                                      *
 *    div.comment (if respuesta: .answer)                               *
 *      div.comment-title                                               *
 *        p                                                             *
 *          strong                                                      *
 *            a.asunto (titulo asunto)                                  *
 *            "("                                                       *
 *            a                                                         *
 *              "Ver contenido"                                         *
 *            ")"                                                       *
 *      p                                                               *
 *        (comentario)                                                  *
 *      p.pie                                                           *
 *        p.comment-subject                                             *
 *          a (Area) "/" a (Tema)                                       *
 *        p.comment-icons                                               *
 *          span (autor)                                                *
 *          span (fecha)                                                *
 *          img (animo)                                                 *
 *          img (respondido)                                            *
 *                                                                      *
 *    ------------------------------------                              *
 *    Pantalla:                                                         *
 *    - Buzón: Asuntos de un tema                                       *
 *    ------------------------------------                              *
 *                                                                      *
 *    div.asuntos                                                       *
 *      dl (capa padre)                                                 *
 *                                                                      *
 *        dt                                                            *
 *          a.asunto                                                    *
 *            (titulo asunto)                                           *
 *          "("                                                         *
 *          a                                                           *
 *            "Ver contenido"                                           *
 *          ")"                                                         *
 *        dd                                                            *
 *          p                                                           *
 *            (comentario)                                              *
 *          p.pie                                                       *
 *            span.naranja (autor)                                      *
 *            &nbsp;(fecha)                                             *
 *            img (respuestas) ": X"                                    *
 *            img (num comentarios) ": X"                               *
 *            img (participantes) ": X"                                 *
 *                                                                      *
\* -------------------------------------------------------------------- */

/**
 * Separación entre asuntos/comentarios
 * -------------------------------------------------------------------- */

/**
 * 1. Comentarios en asuntos con maquetación antigua basada en:
 *      ul.comentarios
 *        li
 *          (comentario)
 *        li.respuesta
 *          (respuesta)
 */
.comment,
.asuntos dd,
ul.comentarios li {
  /* padding: .6rem var(--gutter-interior); */
  margin-bottom: 4rem;
}

/*
* Listado de comunicados en la presentacion de areas.
* Es para quitar el margen inferior y poner el de por
*defecto de los listados
*/
ul.comentarios.comunicados li {
  margin: 0 0 calc(var(--rhythm) * .5);
}

.comment p {
  margin-bottom: calc(var(--rhythm) * .5);
}

/**
 * 1. En las fichas guía, la separación es menor.
 */
.mailbox > article {
  margin-bottom: 4rem; /* [1] */
}

.mailbox > article:last-of-type {
  margin-bottom: var(--rhythm);
}

/**
 * Titulo de asunto
 * -------------------------------------------------------------------- */
.asuntos dt {
  font-weight: bold;
}

/**
 * 1. Quitar el tamaño de fuente de titulo, se visualiza como texto
 */
.mailbox h3 {
  margin-top: 0;
  margin-bottom: calc(var(--rhythm) * .5);
  font-size: var(--font-size-18px); /* [1] */
  line-height: var(--rhythm);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .mailbox h3 {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .mailbox h3 {
    font-size: var(--font-size-24px);
  }
}

/**
 * 1. Visualizar enlace como uno de texto normal
 * 2. Dejar espacio con los posibles iconos de respuestas
 */
.mailbox h3 > a {
  margin-right: 7px; /* [2] */
  background-image: none;
  color: var(--color-link); /* [1] */
  font-family: var(--font-family-base); /* [1] */
}

/**
 * Pie de un asunto: Iconos e información
 * -------------------------------------------------------------------- */

/**
 * 1. Eliminar margin-bottom del último párrafo para no añadir espacio
 *    extra (afecta en respuestas)
 * 2. En el caso de la pantalla de presentación hay un párrafo dentro
 *    del pie al que también hay que anular el margen inferior.
 */
.asuntos .pie,
.comment .pie,
.comment .comment-icons,
.mailbox .pie {
  margin-bottom: 0; /* [1] */
}

/**
 * 1. En la ficha guía, la información del asunto es más pequeña
 * 2. Aunque extrictament no pertenece al pie, en las fichas guía, la
 *    información del asunto se visualiza tambien bajo el titulo.
 */
.mailbox .pie,
.mailbox h3 + p {
  font-size: .889em; /* [1] */
}

.comentarios .pie,
.mailbox .pie {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  color: var(--color-text-grey);
  column-gap: 1em;
}

/**
 * LISTADOS DE ASUNTOS: ESTILOS A APLICAR A LAS CAPAS CONTENEDORAS
 * -------------------------------------------------------------------- */

/**
 * 1. Sobre el listado existe un br que aumenta demasiado la separación
 *    con la paginación.
 * 1.1. Se anula la regla porque en "Mis asuntos" no existe dicho <br>
 *      y se visualiza el listado muy pegado al titulo
 */

/* .asuntos dl {
  margin-top: -1em; /* [1, 2] */

/* } */

/* -------------------------------------------------------------------- *\
 *  Bloque: COMENTARIO                                                  *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *    ------------------------------------                              *
 *    Pantallas:                                                        *
 *    - Buzón: Asunto                                                   *
 *    - Contenidos: Comentarios recibidos                               *
 *    ------------------------------------                              *
 *                                                                      *
 *    div.comment (if respuesta: .answer)                               *
 *      p                                                               *
 *        (comentario)                                                  *
 *      p.pie                                                           *
 *        (autor)                                                       *
 *        img (animo)                                                   *
 *        (fecha)                                                       *
 *        img (respondido)                                              *
 *                                                                      *
\* -------------------------------------------------------------------- */

/**
 * 1. Comentarios en asuntos con maquetación antigua basada en:
 *      ul.comentarios
 *        li
 *          (comentario)
 *        li.respuesta
 *          (respuesta)
 */
.comment,
ul.comentarios li {
  clear: both;
  list-style: none; /* [1] */
}

/**
 * 1. Eliminar el padding heredado de las listas por defecto
 */
ul.comentarios {
  padding-left: 0; /* [1] */
}

/* ul.comentarios li p:first-child {
  margin-bottom: .5em;
} */

/* -------------------------------------------------------------------- *\
 *  Bloque: RESPUESTA                                                   *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *    div.comment.answer                                                *
 *      p                                                               *
 *        (comentario)                                                  *
 *      p.pie                                                           *
 *        (autor)                                                       *
 *        img (animo)                                                   *
 *        (fecha)                                                       *
 *        img (respondido)                                              *
 *                                                                      *
\* -------------------------------------------------------------------- */

/**
 * 1. Respuestas en la app Buzón e incrustadas en contenidos
 * 2. Respuestas incrustadas en fichas guía
 * 3. Respuestas en asuntos con maquetación antigua basada en:
 *      ul.comentarios
 *        li
 *          (comentario)
 *        li.respuesta
 *          (respuesta)
 */
.comment.answer,
.response,
ul.comentarios li.respuesta {
  padding: var(--gutter-interior-aside);
  background-color: var(--background-color-alt);
}

@media print {
  .comment.answer,
  .response,
  ul.comentarios li.respuesta {
    padding: 0;
  }
}



/**
 * Los comunicados aparecen en el html en ambos idiomas.
 * Por defecto se ocultan para usuarios y lectores de pantalla.
 */
.comunicado-es,
.comunicado-eu {
  display: none;
}

/**
 * Mostrar comunicados en euskera cuando el idioma es "eu"
 */
html[lang="eu"] .comunicado-eu {
  display: block;
}

/**
 * Mostrar comunicados en castellano cuando el idioma no es "eu"
 */
html[lang="es"] .comunicado-es,
html[lang="en"] .comunicado-es,
html[lang="fr"] .comunicado-es {
  display: block;
}

/* -------------------------------------------------------------------- *\
 *  Nuevo comentario: formulario                                        *
\* -------------------------------------------------------------------- */
.mailbox-base .radioCaras label {
  display: inline;
}

/* -------------------------------------------------------------------- *\
 *  Elemento: AVISO DE LOPD                                             *
\* -------------------------------------------------------------------- */

/**
  * 1. En algunas páginas .lopd contiene UL en vez de párrafos, pero su visualización
  *    es como texto, no como lista, así que deben anularse los estilos básicos de UL.
  */
.mailbox-base .lopd {
  padding: 0; /* [1] */
  margin-top: 1em;
  list-style: none; /* [1] */
}

/* -------------------------------------------------------------------- *\
 *  Mensajes de error                                                   *
\* -------------------------------------------------------------------- */
#buzonerror {
  color: var(--color-error);
}

.mailbox .responseInterior .respNegrita,
.mailbox article p:first-child {
  margin-bottom: calc(var(--rhythm) * .5);
}

/* Temas Areas temáticas */
.temas > h2 > a,
.areas h2 > a {
  color: var(--color-link);
}
