/* ==================================================================== *\
 *                                                                      *
 *  PAGINAS INTERIORES                                                  *
 *                                                                      *
 *  ------------------------------------------------------------------  *
 *  /layout/_interior.css                                               *
 *  ------------------------------------------------------------------  *
 *  Estilos para la capa contenedora de paginas interiores              *
\* ==================================================================== */

/**
 * 1. Sobreescribir los estilos de una clase de bootstrap mal aplicada
 *    (.row), ya que no existen divisiones en columnas.
 */
.interior {
  padding: calc(var(--gutter-interior-main-one-col) * 1.5) var(--gutter-interior-main-one-col);
  margin: 0; /* 1 */
  background-color: var(--background-color);
  font-size: var(--font-size-16px);
}

@media (min-width: 768px) {
  .interior {
    font-size: var(--font-size-18px);
  }
}
