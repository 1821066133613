/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE: TREE                                                    *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *  /components/_tree.css                                               *
\* ==================================================================== */

/*
@styleguide

@title Árbol

La estructura de un árbol se basa en listas no ordenadas anidadas.

El ul principal debe incluir la clase `tree`.

```html
<ul class="tree">
  ...[nodos]...
</ul>
```
*/
:root {
  --size-button-tree: 42px;
  --size-button-tree-desktop: 22px;
}

.tree {
  padding-left: 0;
}

/* -------------------------------------------------------------------- *\
 *  Bloque: TREE-NODE                                                   *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  Descripción:                                                        *
 *    Cada nodo padre o hijo de un arbol.                               *
 *                                                                      *
 *  Selector: .tree-node                                                *
 *                                                                      *
 *  DOM:                                                                *
 *    li.tree-node                                                      *
 *      [Modificadores:                                                 *
 *        .tree-node--has--children]                                    *
 *        .tree-node--has--icons]                                       *
 *        .tree-node--root]                                             *
 *                                                                      *
 *      a.tree-node__btn-toggle.btn-toggle.btn[.is-expanded]            *
 *        span                                                          *
 *      .tree-node__name[.tree-node__name--link]                        *
 *                                                                      *
 *      if (tieneHijos)                                                 *
 *        ul.tree-node__children[.tree-node__children--hidden]          *
 *          ...                                                         *
 *      end if                                                          *
 *                                                                      *
\* -------------------------------------------------------------------- */

/*
@styleguide

## Nodos del árbol

Los diferentes nodos (`li`) del árbol tendrán la clase `tree-node`. A continuación se
muestra el HTML de las diferentes variantes de nodos.

```html
// Nodo básico
<li class="tree-node">
  <span class="tree-node__name">[nombre del nodo]</span>
</li>

// Nodo raiz
// A la raiz se le aplicará el modificador: "tree-node--root"
<li class="tree-node tree-node--root">...</li>

// Nodo con enlace
// Al nombre del nodo (ahora en un enlace) se le aplicará el modificador: "tree-node__name--link"
<li class="tree-node">
  <a href="[url]" class="tree-node__name tree-node__name--link">[nombre del nodo]</a>
</li>

// Nodo con hijos
// Al nodo se le aplicará el modificador: "tree-node--has--children"
// Se añade:
//   - Un botón `tree-node__btn-toggle btn-toggle btn` para mostrar u ocultar los hijos (antes del nombre)
//       1. Usa `is-expanded` si los hijos se muestran inicialmente.
//   - Una lista anidada para contener los hijos
<li class="tree-node tree-node--has--children">
  <button class="tree-node__btn-toggle btn-toggle btn [is-expanded]">[Texto accesible (ej: mostrar / ocultar hijos)]</button>
  <span class="tree-node__name">[nombre del nodo]</span>
  <ul class="tree-node__children">
    ...[Nodos hijos repitiendo los esquemas vistos anteriormente]...
  </ul>
</li>
```

**Nota importante**: No es necesario ningún Javascript extra para mostrar u ocultar las listas
anidadas. La funcionalidad se añadirá por defecto a los botones `tree-node__btn-toggle`. Sólo
hay que vigilar el estado inicial de las mismas mediante el modificador `is-expanded` (ver explicación en el código de ejemplo anterior).

<div class="psg-responsive-sample">
  <ul class="tree">
    <li class="tree-node tree-node--root tree-node--has--children">
      <button class="tree-node__btn-toggle btn-toggle btn is-expanded">mostrar / ocultar hijos</button>
      <span class="tree-node__name">Arbol de ejemplo</span>
      <ul class="tree-node__children">
        <li class="tree-node">
          <a class="tree-node__name tree-node__name--link" href="">Nodo 1</a>
        </li>
        <li class="tree-node tree-node--has--children">
          <button class="tree-node__btn-toggle btn-toggle btn is-expanded">mostrar / ocultar hijos</button>
          <a class="tree-node__name tree-node__name--link" href="">Nodo 2</a>
          <ul class="tree-node__children">
            <li class="tree-node">
              <span class="tree-node__name">Nodo 2.1</span>
            </li>
            <li class="tree-node">
              <a class="tree-node__name tree-node__name--link" href="">Nodo 2.2</a>
            </li>
          </ul>
        </li>
        <li class="tree-node tree-node--has--children">
          <button class="tree-node__btn-toggle btn-toggle btn is-expanded">mostrar / ocultar hijos</button>
          <a class="tree-node__name tree-node__name--link" href="">Nodo 3</a>
          <ul class="tree-node__children">
            <li class="tree-node tree-node--has--children">
              <button class="tree-node__btn-toggle btn-toggle btn">mostrar / ocultar hijos</button>
              <span class="tree-node__name">Nodo 3.1</span>
              <ul class="tree-node__children">
                <li class="tree-node">
                  <a class="tree-node__name tree-node__name--link" href="">Nodo 3.1.1</a>
                </li>
              </ul>
            </li>
            <li class="tree-node">
              <a class="tree-node__name tree-node__name--link" href="">Nodo 3.2</a>
            </li>
            <li class="tree-node">
              <a class="tree-node__name tree-node__name--link" href="">Nodo 3.3</a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</div>
 */
.tree-node {
  padding: 0 0 0 1em;
  margin: 0 0 .5em;
  background: url(../img/arrow_link1.png) no-repeat .2em .6em;
  list-style: none;
  text-indent: 0;
}

.tree-node:last-child {
  margin: 0;
}

/**
 * 1. Fallback para los navegadores que no soportan
 *    `background-image: linear-gradient`
 *    Evita que se repita en eje 'y' el background-image heredado de .tree-node
 */
.tree-node--has--children {
  padding: 0 0 0 2em;
  margin: 0;
  background-image: none;
  background-image: linear-gradient(to right, #ddd 1px, transparent 0);
  background-position: calc((var(--size-button-tree) - var(--size-button-tree-desktop)) / 2) 0;
  background-repeat: repeat-y;
  background-size: 1px;
  text-indent: -2em;
}

.tree-node--root .tree-node--has--children:last-child {
  padding-bottom: 0;
}

.tree-node__btn-toggle.btn-toggle {
  display: inline-block;
  margin-left: calc((var(--size-button-tree) - var(--size-button-tree-desktop)) / -2);
  background: none;
  vertical-align: super;
}

.tree-node__btn-toggle.btn-toggle::before,
.tree-node__btn-toggle.btn-toggle::after {
  position: absolute;
  top: 50%;
  right: 50%;
  display: block;
  width: var(--size-button-tree-desktop);
  height: var(--size-button-tree-desktop);
  background: #bfd9e2;
  content: '';
  transform: translate(50%, -50%);
}

.tree-node__btn-toggle.btn-toggle::after {
  background-color: black;

  /* background-image: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6NDJweDtoZWlnaHQ6NDJweCIgdmlld0JveD0iMCAwIDI0IDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiMwMDAiPg0KICAgIDxwYXRoIGZpbGw9IiMwMDQzQkUiIGQ9Ik0xOSwxOVY1SDVWMTlIMTlNMTksM0EyLDIgMCAwLDEgMjEsNVYxOUEyLDIgMCAwLDEgMTksMjFINUEyLDIgMCAwLDEgMywxOVY1QzMsMy44OSAzLjksMyA1LDNIMTlNMTcsMTFWMTNIN1YxMUgxN1oiIC8+DQo8L3N2Zz4=); */
  background-repeat: no-repeat;
  mask-image: url(../img/svg/sprite.svg#icon-plus-view);
}

.no-svg .tree-node__btn-toggle.btn-toggle {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAHlJREFUOI1jYBhsgBGf5P///3kZGBgEodz3jIyMn8my5f///wX/EaAAn1omsmwYEgaxwBjQgE1Gk7dHZv///x9d/1yMCPj//7/cf9KBHEw/9b3GwMDwnoGBoRBN3p6BgSEAyt7AwMBwEE3+PVG2jKYjBgYGemXaAQEAwpNqjcfilm4AAAAASUVORK5CYII=');
}

.no-svg .tree-node__btn-toggle.is-expanded {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAERJREFUOI1jYBgFIxgwwhj////nZWBgSCZR/1xGRsbPKCL///+X+086kIPpZ6LIP0iABYn9noGBoZBE/e+p5ZBRMKQBAJQZPV3QoPc6AAAAAElFTkSuQmCC');
}

.no-svg .tree-node__btn-toggle.btn-toggle::after {
  content: none;
}

.tree-node__name.tree-node__name--link {
  vertical-align: middle;
}

.tree-node__name--icon--calendario::after,
.tree-node__name--icon--noticias::after,
.tree-node__name--icon--boletin::after,
.tree-node__name--icon--tramiteweb::after {
  display: inline-block;
  width: 1.3em;
  height: 1.3em;
  margin-left: .4em;
  background: transparent none no-repeat center center;
  background-size: contain;
  content: '';
  vertical-align: middle;
}

.tree-node__name--icon--calendario::after {
  background-image: url(../img/arbolSusAgenda.png);
}

.tree-node__name--icon--noticias::after {
  background-image: url(../img/arbolSusNoticia.png);
}

.tree-node__name--icon--boletin::after {
  background-image: url(../img/arbolSusBoletin.png);
}

.tree-node__name--icon--tramiteweb::after {
  background-image: url(../img/iconoteclado_des.png);
}

.tree-node__children {
  padding: calc(var(--gutter-outer) * .5) 0 var(--gutter-outer);
  margin: 0;
}

.tree-node--root > .tree-node__children {
  padding-bottom: 0;
}

.tree-node__children--hidden {
  display: none;
}

@media (min-width: 768px) {
  .tree-node__btn-toggle.btn-toggle {
    top: 0;
    left: 0;
    width: var(--size-button-tree-desktop);
    height: var(--size-button-tree-desktop);
    margin: 0;
  }
}
