.div-networks .label-data li:nth-child(2n+1) ul li:first-of-type {
  background-color: var(--background-color-alt-dark);
}

.div-networks:not(:first-of-type) .label-data li:nth-child(2n+2) ul li:first-of-type {
  background-color: var(--background-color-alt) !important;
}

@media (min-width: 767px) {
  .div-networks .label-data li ul li {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.div-networks .label-data ul {
  display: flex;
  margin-bottom: .7em;
}

.div-networks .label-data .sublist ul:not(:last-of-type) {
  margin-bottom: 0;
}

.div-networks .label-data li:nth-child(2n+1).sublist ul li {
  padding-bottom: .5em;
  background-color: var(--background-color-alt-dark);
}

.div-networks:not(:first-of-type) .label-data li:nth-child(2n+2).sublist ul li {
  padding-bottom: .5em;
  background-color: var(--background-color-alt) !important;
}

.div-networks .label-data ul li:first-of-type {
  flex: 1;
}

.div-networks ul,
.div-networks li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.div-networks ul::after {
  display: table;
  clear: right;
  content: '';
}

/* ul.label-data > li.first-row  */
.div-networks .first-row {
  margin-bottom: 1em;
  background-color: var(--color-primario);
}

.div-networks .first-row h2 {
  margin: 0;
  color: var(--color-text-inverse);
  line-height: .9;
}

/* ul.label-data > li > ul */
.div-networks .dark-even-row {
  /* background-color: var(--background-color-alt); */
  color: var(--color-text-high-contrast);
}

/* ul.label-data > li > ul > li */
.div-networks li li {
  padding: 1rem var(--gutter-interior);
}

@media (min-width: 480px) {
  .div-networks li li {
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .div-networks li li {
    font-size: var(--font-size-18px);
    line-height: 1;
  }
  .div-networks .label-data ul {
    flex-direction: column;
  }
}

.div-networks p {
  margin: 0;
}

.div-networks .network-data {
  padding-top: 0;
  padding-bottom: 0;
  float: right;
}

@media (max-width: 479px) {
  .div-networks .network-data {
    padding-top: 5px !important;
    padding-bottom: 10px !important;
    float: none;
  }
  .div-networks .first-row .network-data {
    padding-top: 10px;
  }
  .div-networks .label-data li:nth-child(2n+1) ul li {
    border-top: 1px solid var(--background-color-alt-dark);

    /* padding-top: 1px; */
    background-color: var(--background-color-alt-dark);
  }
  .div-networks:not(:first-of-type) .label-data li:nth-child(2n+2) ul li {
    border-top: 1px solid var(--background-color-alt);

    /* padding-top: 1px; */
    background-color: var(--background-color-alt) !important;
  }
}

.div-networks .network-data > a {
  margin-right: 12px;
  vertical-align: middle;
}

@media (max-width: 479px) {
  .div-networks .network-data > a {
    margin-right: 10px;
    margin-bottom: 12px;
  }
}

.div-networks .network-data .btn-quick__span svg {
  width: 2.5em;
  height: 2.5em;
  padding: .4em;
  background-color: var(--color-secundario);
}

.textoApp {
  font-weight: bold;
}
