.m01-08s .card-collection__item > form {
  padding: 0;
  background-color: transparent;
}

.m01-08s .event-card__time > .event-card__time-year {
  order: 1;
  padding-top: .5em;
  padding-bottom: 0;
}

.m01-08s .event-card__time > .event-card__time-day {
  order: 2;
}

.m01-08s .event-card__time > .event-card__time-date {
  order: 3;
  padding-bottom: .5em;
}

.m01-08s .event-card__time > .event-card__time-hour {
  order: 4;
  padding-top: 0;
  padding-bottom: 0;
  background-color: var(--color-text-high-contrast);
}
