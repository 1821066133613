@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: #000 !important;
    text-shadow: none !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  body {
    min-width: 768px;
    padding: 0 !important;
    margin: 0;
  }
  .container {
    width: auto;
    min-width: 750px;
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
  }
  a,
  a:visited {
    text-decoration: none;
  }
  .main-content a[href^="http://"]::after,
  .main-content a[href^="https://"]::after {
    content: " (" attr(href) ")";
    font-size: 90%;
    font-style: italic;
  }

  /**
   * CABECERA
   */
  #intranet-header {
    display: none;
  }
  .main-header {
    margin-bottom: 1em;
  }
  .main-header .nav-aux,
  .main-header .subheader :not(:first-child) {
    display: none;
  }
  .main-header .subheader :first-child {
    width: auto;
    height: 50px;
    padding: 0 !important;
    margin: 0 !important;
    float: left;
  }
  .main-menu,
  .mainMenu,
  .linksmenu,
  .header-utils,
  .breadcrumb,
  .tablist {
    display: none;
  }
  .header-main__search,
  .header-main__highlighted,
  .header-main__networks,
  .header-main__menu-button {
    display: none;
  }

  /**
   * MENU CONTEXTUAL
   */
  .extra-content,
  .aside-tabletMobile,
  .aside-control,
  .extra-content__content,
  .extra-content__toogle-btn {
    display: none;
  }

  /**
   * CUERPO DEL CONTENIDO
   */
  .main-content {
    width: auto !important;
    float: none !important;
  }
  .main,
  .main-content > :first-child {
    padding: 0;
  }
  .rsbtn_colorskin,
  .main-content #redessociales {
    display: none;
  }
  .shareComments,
  #wb021contenido .main__grid-box,
  .stick-info {
    display: none;
  }

  /**
   * PIE
   */
  .main-footer {
    display: none;
  }

  /**
   * HELPERS
   */
  .hidden-print,
  .noprint,
  .noPrint,
  .no-print {
    display: none !important;
  }
  a[href]::after {
    content: none !important;
  }
  td,
  th {
    border: 1px solid #000;
  }
  div[role="tabpanel"][aria-hidden="false"] {
    border: none;
  }
}
