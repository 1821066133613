
@media (min-width: 768px) {
  .card__event-collection {
    column-gap: var(--rhythm);
    row-gap: var(--rhythm);
  }
  .card__event-collection.has-2-cols\@sm {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1420px) {
  .card__event-collection.has-2-cols\@xl {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card__event-collection .card-collection__item {
  max-width: 541px;
  border: 2px solid transparent;
  margin-bottom: calc(var(--rhythm) * 2);
  background-color: transparent;
}

.card__event-collection .card-collection__item:hover {
  border-color: var(--color-primario);
}

@media (min-width: 768px) {
  .card__event-collection .card-collection__item {
    margin-bottom: 0;
  }
}

.card__event-collection .event-card {
  height: 100%;
}

.card__event-collection .event-card .card__block-link {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 0;
}

.card__event-collection .event-card .card__block-link:hover {
  text-decoration: none;
}

.card__event-collection .event-card .card__block-link:hover > .card__content {
  background-color: var(--color-primario);
  color: var(--color-text-inverse);
}

.card__event-collection .event-card .card__image {
  position: initial;
  margin: 0;
}

.card__event-collection .event-card .card__image::before {
  padding: 0;
}

.card__event-collection .event-card .card__content {
  flex-grow: 1;
  padding: var(--gutter-interior-aside);
  margin-top: .5em;
  background-color: var(--background-color-alt-dark);
}

.card__event-collection .event-card .card__content .square__content {
  display: inline-flex;
}

.card__meta-content__separator::after {
  position: absolute;
  top: 43%;
  left: 43%;
  width: 1rem;
  height: 1rem;
  background-color: #000;
  content: "";
  mask-image: url(../img/svg/sprite.svg#icon-minus-view);
}

.card__event-collection .event-card .card__title {
  font-family: var(--font-family-base);
}

.card__event-collection .event-card .card__meta {
  top: 0;
  right: auto;
  left: 0;
  margin: 1rem;
  background-color: #fff;
}

.card__event-collection .square::before {
  padding-top: 0;
}

.card__event-collection .event-card .event-card__time {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 0;
}

.card__event-collection .event-card .event-card__time > * {
  padding: 0;
}

.card__event-collection .event-card .event-card__time-date {
  color: var(--color-text);
  font-family: var(--font-family-alt-semi);
  font-size: minimalfont(strip(var(--font-size-21px)));
}

.card__event-collection .event-card .event-card__time-dateDay {
  font-family: var(--font-family-alt);
  font-size: minimalfont(strip(var(--font-size-36px)));
}

@media (min-width: 320px) and (max-width: 1419px) {
  .card__event-collection .event-card .event-card__time-date {
    font-size: fluidfont(strip(var(--font-size-21px)));
  }
  .card__event-collection .event-card .event-card__time-dateDay {
    font-size: fluidfont(strip(var(--font-size-36px)));
  }
}

@media (min-width: 1420px) {
  .card__event-collection .event-card .event-card__time-date {
    font-size: var(--font-size-21px);
  }
  .card__event-collection .event-card .event-card__time-dateDay {
    font-size: var(--font-size-36px);
  }
}
