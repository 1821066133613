.listaSinEstilos {
  display: block;
}

/**
 * 1. Separacion con el enlace volver que precede al formulario en "Detalle de un tributo"
 */
#Si207FrmTributos {
  margin-top: 1em; /* [1] */
}

#Si207FrmTributos .margenSuperior {
  margin-top: .6em;
}

#notificacionesForm select {
  width: 100%;
}
