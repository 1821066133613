/**
 * Estandarizar el aspecto de los formularios nuevos de alto contraste.
 */

@custom-selector :--dark-form form, .form__alt-dark;

:--dark-form {
  padding: var(--gutter-interior-aside);
  background-color: var(--background-color-new);
}

@custom-selector :--dark-form__button form input[type="button"], form input[type="reset"], form button;

/* Este estilo se aplica a los enlaces que simulan botones. Estos tienen que verse
como si fueran botones secundarios, nunca como los principales para diferenciarlos. */
@custom-selector :--dark-form__link-button form a.boton, form a.btn;

:--dark-form__button,
:--dark-form__link-button {
  border: 2px solid var(--color-text-high-contrast);
  background-color: var(--background-color);
  color: var(--color-text);
  transition: all .3s ease;
}

:--dark-form__button:hover,
:--dark-form__button:focus,
:--dark-form__button:active,
:--dark-form__link-button:hover,
:--dark-form__link-button:focus,
:--dark-form__link-button:active {
  border: 2px solid var(--color-primario);
  background-color: var(--background-color);
  color: var(--color-primario);
}

:--dark-form__link-button {
  display: inline-flex;
  min-height: 2.625rem;
  align-items: center;
  padding: 0 var(--gutter-interior-form);
}

@custom-selector :--dark-form__submit form input[type=submit], .form__alt-dark input[type=submit],  form button[type=submit];

:--dark-form__submit {
  border: 2px solid var(--color-text-high-contrast);
  background-color: var(--color-text-high-contrast);
  color: var(--color-text-inverse);
  transition: background-color .3s ease;
}

:--dark-form__submit:hover,
:--dark-form__submit:focus,
:--dark-form__submit:active {
  border-color: #4c4c4c;
  background-color: #4c4c4c;
}

@custom-selector :--dark-form__input form input[type=text], form .field__input, .form__alt-dark input[type=text], .form__alt-dark .field__input, form input[type=email], .form__alt-dark input[type=email];
@custom-selector :--dark-form__select form select, form .field__select, .form__alt-dark select, .form__alt-dark .field__select;
@custom-selector :--dark-form__textarea form textarea, form .field__textarea, .form__alt-dark textarea, .form__alt-dark .field__textarea;

:--dark-form__input,
:--dark-form__select {
  min-height: 2.625rem;
}

:--dark-form__input,
:--dark-form__select,
:--dark-form__textarea {
  max-width: 100%;
  border: 2px solid #000;
  background-color: var(--background-color);
}

@custom-selector :--dark-form__label form .field__label, .form__alt-dark .field__labell, form label;

:--dark-form__label {
  margin: 0 0 calc(var(--rhythm) * .2);
  font-family: var(--font-family-base-semi);
}

form input[type=radio] + .field__label,
.form__alt-dark input[type=radio] + .field__label,
form input[type=radio] + label,
.form__alt-dark input[type=radio] + label {
  font-family: var(--font-family-base);
}

@custom-selector :--dark-form__legend form .fieldset__legend, .form__alt-dark .fieldset__legend, form fieldset legend;

:--dark-form__legend {
  margin: 0 0 calc(var(--rhythm) * .2);
  font-family: var(--font-family-base-semi);
}

/** Modificadores para los label que no deben de ir en negrita **/

/* form fieldset label, */
input[type="checkbox"] + label,
.form__alt-dark .field--radio label,
.form__alt-dark .field--checkbox label {
  font-family: var(--font-family-base);
}

/**
 ** Los RADIO BUTTONS y los CHECKBOX tienen que tener la estructura correcta de html para heredar los estilos.
 **/

.form__alt-dark input[type="radio"],
.form__alt-dark input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.form__alt-dark input[type="radio"]:checked ~ .field__radio-btn,
.form__alt-dark input[type="checkbox"]:checked ~ .field__checkbox-btn {
  background-color: #000;
}

.form__alt-dark input[type="radio"]:checked ~ .field__radio-btn::after,
.form__alt-dark input[type="checkbox"]:checked ~ .field__checkbox-btn::after {
  display: block;
}

.form__alt-dark .field__radio-btn {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  flex: 0 0 15px;
  order: -1;
  order: -1;
  border: 2px solid #000;
  margin-right: 1em;
  background-color: transparent;
  border-radius: 50%;
}

.form__alt-dark .field__radio-btn::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: none;
  width: 5px;
  height: 5px;
  background: #f4f8fa;
  border-radius: 50%;
  content: "";
}

.form__alt-dark .field__checkbox-btn {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  flex: 0 0 15px;
  order: -1;
  border: 2px solid #000;
  margin-right: 1em;
  background-color: transparent;
}

.form__alt-dark .field__checkbox-btn::after {
  position: absolute;
  top: -2px;
  left: -2px;
  display: none;
  width: 15px;
  height: 15px;
  background-color: var(--color-text-inverse);
  content: "";
  mask-image: url(../img/svg/sprite.svg#icon-check-view);
  mask-position: center;
}

.form__alt-dark .field--radio label,
.form__alt-dark .field--checkbox label {
  display: inline-flex;
  align-items: center;
  margin: 0;
  cursor: pointer;
}

.form__alt-dark .field--radio label img {
  margin-right: .2em;
}

@media (max-width: 767px) {
  .form__alt-dark .field__select-long,
  .form__alt-dark .field__input-long,
  form .field__select-long,
  form .field__input-long {
    width: 100%;
  }
}

@media (min-width: 767px) {
  .form__alt-dark .field__select-long,
  .form__alt-dark .field__input-long,
  form .field__select-long,
  form .field__input-long {
    width: 80%;
  }
}

/* .form__alt-dark input[type=file]::before {
    background-color: #000;
    content: attr(data-text);
  } */

#avisoCookies form {
  background-color: transparent;
}

form > input[type='hidden'] + :is(h1, h2, h3, h4, h5, h6) {
  margin-top: 0;
}
