/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE: DIALOG                                                  *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *  /components/_dialog.css                                             *
\* ==================================================================== */

/*
@styleguide

@title Dialog (beta)

El siguiente componente tiene como propósito **mostrar un cuadro de dialog**.
En este caso, al contrario que los Alert, el contenido del mensaje sí puede
incluir enlaces, botones o elementos interactivos.

## Dialog: Confirm

Su funcionamiento es muy similar al estandar `window.confirm()`, pero no interrumpe
el trabajo del navegador.

```javascript
// Ejemplo básico
vg.dialog.confirm('Cuadro de dialogo de prueba.')
  .then(function (result) {
    var accion = result ? 'ACEPTADO' : 'CANCELADO';
    alert('Has ' + accion + ' y se cerrará el dialogo');
  });
```
*/

/*
## Dialog: Stepper

Dialog con varios pasos

```javascript
  var dialog = vg.dialog.createStepper('<p>¿Llamar por AJAX?</p>', { title: 'Proceso' });

  dialog
    .open()
      .then(function onready() {
        console.log('Dialog ready');
      })

    .wait()
      .then(function ajaxYahoo() {
        return $.ajax({
          url: "http://query.yahooapis.com/v1/public/yql",
          jsonp: "callback",
          dataType: "jsonp",
          data: {
              q: "select title,abstract,url from search.news where query=\"cat\"",
              format: "json"
          }
        });
      })
      .then(function printAjaxResult(data) {
        dialog.content('<p>' + data.error.description + '</p>');
        dialog.buttonContinue('siguiente');
      })

    .wait()
      .then(function preguntarNombre(data) {
        dialog.content('<p><label>¿Te llamabas?</label> <input id="nombre" name="nombre" type="text" placeholder="escribe tu nombre"></p>');
        dialog.buttonContinue('adios');
      })

    .wait()
      .then(function leerNombre(){
        return document.getElementById('nombre').value;
      })
      .then(function pedirConfirmacionAdios(nombre) {
        nombre = nombre || 'misterioso usuario';
        dialog.title('Confirmación');
        dialog.content('¿En serio, ' + nombre + '? :(');
        dialog.buttonContinue('bye bye ;)');
      })

    .wait()
      .then(function onclose() {
        console.log('Pues venga, adios. :(')
      });
```
*/

/* -------------------------------------------------------------------- *\
 *  Personalización de jQuery UI Dialog                                 *
\* -------------------------------------------------------------------- */

/**
 * 1. Para que no salga por debajo de un mapa embebido (con z-index:999) ni
 *    detras de los controles del mismo (z-index: 1016).
 */

@media (min-width: 320px) and (max-width: 1419px) {
  .ui-widget.ui-dialog,
  .ui-dialog .ui-dialog-content {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .ui-widget.ui-dialog,
  .ui-dialog .ui-dialog-content {
    font-size: var(--font-size-24px);
  }
}

.ui-widget.ui-dialog {
  z-index: 1100; /* [1] */
  top: 50%;
  left: 50%;
  width: 80%;
  max-width: 90vw;
  max-width: 1420px;
  height: auto;
  padding: 0;
  border-color: #aaa;
  font-family: var(--font-family-base);
  font-size: var(--font-size-18px);
  transform: translate(-50%, -50%);
}

.ui-dialog input,
.ui-dialog select,
.ui-dialog textarea,
.ui-dialog button,
.ui-widget button {
  font-family: var(--font-family-base);
  font-size: var(--font-size-18px);
}

.ui-dialog .ui-dialog-titlebar {
  --font: minimalfont(strip(var(--font-size-31px)));
  --margin: factormargin(strip(var(--font-size-31px)));
  --line: 1.3em;
  padding: 1% 3%;
  border: 1px solid #aaa;
  border-width: 0 0 1px;
  color: var(--color-heading);
  font-size: var(--font);
  line-height: var(--line);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .ui-dialog .ui-dialog-titlebar {
    font-size: fluidfont(strip(var(--font-size-31px)));
    line-height: fluidline(strip(var(--line)), strip(var(--font-size-31px)));
  }
}

@media (min-width: 1420px) {
  .ui-dialog .ui-dialog-titlebar {
    font-size: var(--font-size-31px);
  }
}

.ui-dialog .ui-dialog-title {
  overflow: inherit;
  float: none;
  text-overflow: inherit;
}

.ui-dialog .ui-dialog-titlebar-close {
  position: absolute;
  top: 0;
  right: 1%;
  bottom: 0;
  display: inline-block;
  overflow: hidden;
  width: 42px;
  height: 42px;
  border: none;
  margin: auto 0;
  appearance: inherit;
  background: none;
}

.ui-dialog .ui-dialog-titlebar-close:hover::before,
.ui-dialog .ui-dialog-titlebar-close:hover::after {
  background: var(--color-primario);
}

.ui-dialog .ui-dialog-titlebar-close::before,
.ui-dialog .ui-dialog-titlebar-close::after {
  position: absolute;
  top: 50%;
  left: 25%;
  width: 50%;
  height: 2px;
  margin-top: -1px;
  background: #000;
  content: '';
}

.ui-dialog .ui-dialog-titlebar-close::before {
  transform: rotate(45deg);
}

.ui-dialog .ui-dialog-titlebar-close::after {
  transform: rotate(-45deg);
}

.ui-dialog .ui-dialog-titlebar-close > span {
  display: none;
}

.ui-dialog .ui-widget-content {
  background: var(--background-color);
}

.ui-dialog .ui-dialog-content {
  padding: 3%;
}

.ui-dialog .ui-dialog-content > :last-child {
  margin-bottom: 0;
}

.ui-dialog .ui-dialog-buttonpane {
  padding: 0% 3% 1%;
  border: none;
  margin: 0;
  font-size: 1em;
}

.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
  display: flex;
  float: none;
}

.ui-dialog .ui-dialog-buttonpane button {
  margin: .5em .4em .5em 0;
}

@custom-selector :--button-primary .ui-dialog .ui-dialog-buttonpane button.ui-button--primary;
@custom-selector :--button-secundary .ui-dialog .ui-dialog-buttonpane button.ui-button--secundary;

:--button-primary,
:--button-secundary {
  padding: 0 1.5rem;
  border: 2px solid #000;
  background-color: var(--color-text-high-contrast);
  color: var(--color-text-inverse);
  text-transform: capitalize;
}

:--button-primary {
  order: 0;
}

:--button-secundary {
  order: 1;
  background-color: var(--color-text-inverse);
  color: var(--color-text-high-contrast);
}

:--button-primary:hover,
:--button-primary:focus,
:--button-primary:active {
  border-color: var(--color-primario);
  background-color: var(--color-primario);
}

:--button-secundary:hover,
:--button-secundary:focus,
:--button-secundary:active {
  border-color: var(--color-primario);
  color: var(--color-primario);
}

.ui-dialog .ui-dialog-buttonpane button span {
  display: inline;
  padding: 0;
}

.ui-dialog + .ui-widget-overlay {
  background: #000;
  opacity: .5;
}
