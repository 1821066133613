.conexion {
  position: relative;
}

@media (max-width: 1199px) {
  .conexion {
    display: flex;
    align-items: center;
    margin-left: 0;
  }
  .conexion::before {
    height: 1.5em;
    border-left: 2px solid #fff;
    content: "";
  }
}

/**
 * 1. Se aumenta la especifidad doblando la clase para evitar que la
 *    personalización de entornos sobrescriba este boton especial.
 */
.conexion__btn.conexion__btn[data-username],
.conexion__btn.conexion__btn[data-username]:hover {
  background: none;
  color: var(--color-text-inverse);
}

@media (max-width: 1199px) {
  .conexion__btn[data-username]::after {
    content: none;
  }
  .conexion__btn.conexion__btn[data-username],
  .conexion__btn.conexion__btn[data-username]:hover {
    padding: 0;
    margin-right: 0;
  }
}

@media (min-width: 1199px) {
  .conexion__btn.conexion__btn[data-username],
  .conexion__btn.conexion__btn[data-username]:hover {
    padding-right: 0;
  }
}

.entorno-turismo .conexion__btn.conexion__btn[data-username],
.entorno-turismo .conexion__btn.conexion__btn[data-username]:hover {
  color: var(--color-text-inverse);
}

.conexion__btn.conexion__btn[data-username] > .conexion__btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1199px) {
  .conexion__btn[data-username]::after {
    display: inline;
    content: attr(data-username);
  }
  .entorno-www.conexion__btn[data-username]::after {
    display: inline;
    content: attr(data-username);
  }
}

.conexion__info {
  position: absolute;
  z-index: 1011;
  top: 1.5em;
  right: 0;
  padding: .7em .8em;
  border: 3px solid var(--color-link);
  background: var(--background-color-alt);
  text-align: right;
  transform: translateY(10px);
  white-space: nowrap;
}

.conexion__info > :first-child {
  margin-top: 0;
}

.conexion__info > :last-child {
  margin-bottom: 0;
}

.conexion__info::before,
.conexion__info::after {
  position: absolute;
  top: 0;
  right: .1em;
  display: block;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--color-link);
  border-left: 10px solid transparent;
  content: '';
  transform: translateY(-100%);
}

.conexion__info::after {
  top: 4px;
  border-bottom: 10px solid var(--background-color-alt);
}

.conexion__botones,
.conexion__botones .btn {
  margin: 0;
}

@media (max-width: 767px) {
  .conexion__btn-text {
    position: absolute !important;
    display: inline !important; /* Invalidar "display: none" heredados */
    overflow: hidden;
    width: 1px;
    height: 1px;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
  }
  .conexion__btn-text:focus {
    position: static !important;
    clip: auto;
  }
}
