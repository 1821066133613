/* -------------------------------------------------------------------- *\
 *  PORTADA                                                             *
\* -------------------------------------------------------------------- */

/**
 * 1. La capa tiene asignada la clase row incorrectamente. No se usan cols
 *    en sus hijos directos. Se invalidan los margenes de .row para no descuadrar
 *    el fondo blanco.
 */
.sede-base {
  padding: 0;
  margin-right: 0; /* [1] */
  margin-bottom: 0;
  margin-left: 0; /* [1] */
  background-color: var(--background-color);
}

/* -------------------------------------------------------------------- *\
 *  PORTADA > GUIA DE TRAMITES Y SERVICIOS                              *
\* -------------------------------------------------------------------- */

/**
 * 1. Separación con respecto a la caja de busqueda con autocomplete
 */
.sede-base .guideGroup {
  margin-top: 3em; /* [1] */
}

.sede-base .guideGroup .card-collection__item {
  padding: 0;
  border: 0;
}

/*
 *  OBJETO AGRUPACIÓN DE TRAMITES POR TEMATICA
 *  ------------------------------------------
 *  caja compuesta de icono y listado de enlaces
\* -------------------------------------------------------------------- */
.sede-base .guideGroup article {
  display: flex;
  flex-direction: row;
  border-top: 4px solid #4b5554;
  font-family: var(--font-family-base);
}

.sede-base .guideGroup .image {
  display: flex;
  min-width: 2em;
  align-items: flex-start;
  margin-right: 1em;
}

.sede-base .guideGroup h2,
.sede-base .guideGroup h3 {
  --font: var(--font-size-18px);
  margin: 0;
  font-family: var(--font-family-base);
  font-size: var(--font);
  line-height: var(--rhythm);
}

.sede-base .guideGroup a:hover h2 {
  color: var(--color-link);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .sede-base .guideGroup h2,
  .sede-base .guideGroup h3 {
    --font: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .sede-base .guideGroup h2,
  .sede-base .guideGroup h3 {
    --font: var(--font-size-24px);
  }
}

.sede-base .guideGroup li {
  margin-bottom: 0;
}

/* -------------------------------------------------------------------- *\
*  PORTADA > TRAMITES Y SERVICIOS DEL MOMENTO                          *
\* -------------------------------------------------------------------- */

/**
* 1. La capa tiene asignada la clase .col-xs-12 incorrectamente. Se invalidan
*    las declaraciones heredadas de .col-xs-12.
*/
.sede-base .box-container {
  width: 100%; /* [1] */
  padding-right: 0; /* [1] */
  padding-left: 0; /* [1] */
}

/*
*  OBJETO TRAMITE DESTACADO
*  -----------------
*  Caja con enlace al tramite en cuestión
\* -------------------------------------------------------------------- */
.sede-base .labels {
  --font: var(--font-size-18px);
  display: block;
  margin-bottom: 0;
  font-family: var(--font-family-base);
  font-size: var(--font);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .sede-base .labels {
    --font: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .sede-base .labels {
    --font: var(--font-size-24px);
  }
}

.sede-base .card-collection__item {
  padding: var(--gutter-interior-aside);
  border-width: 4px 2px 2px;
  border-style: solid;
  border-top-color: var(--color-secundario);
  border-right-color: var(--background-color-alt-darker);
  border-bottom-color: var(--background-color-alt-darker);
  border-left-color: var(--background-color-alt-darker);
}

.sede-base .guideGroup .card-collection__item article {
  padding: var(--gutter-interior-aside) 0 0;
  margin-bottom: calc(var(--rhythm) * 2);
}

.entorno-sede .field__input {
  border: 2px solid #000;
  background-color: var(--background-color);
}

.guideGroup .card-collection {
  grid-column-gap: max(3em, min(8vw, 6em));
  grid-row-gap: 0;
}

.entorno-sede .home .guide form {
  padding: 0;
  background-color: var(--background-color);
}

.guideGroup .card-collection a {
  display: block;
}
