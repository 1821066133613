/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE: PESTAÑAS DE NAVEGACIÓN FIJAS PARA MOVIL                 *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *  /components/_navBottom.css                                            *
 * -------------------------------------------------------------------- *
 *  Tamaño de fuente base:                                              *
 *    - Movil:  21px                                                    *
 *    - Tablet: 21px                                                    *
 *    - Web:    21px                                                    *
 * -------------------------------------------------------------------- *
 *  Descripción:                                                        *
 *                                                                      *
 *  Componente .navBottom                                               *
 *                                                                      *
 *  Se utiliza en:                                                      *
 *    - Detalle de Eventos o Actos                                      *
 *                                                                      *
\* ==================================================================== */

/* Pestañas de navegación inferior */
.navBottom {
  position: fixed;
  z-index: 100;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  height: 60px;
  flex: 0 0 auto;
  flex-direction: row;
  order: 1;
  padding: 0;
  border-bottom: none;
  margin: 0;
  background-color: var(--color-primario);
  font-size: var(--font-size-18px);
  list-style: none;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .navBottom {
    display: none;
  }
}

.navBottom::after {
  display: table;
  clear: both;
  content: '';
}

.navBottom > .is-selected {
  order: 0;
}

.navBottom > li {
  min-width: 80px;
  flex-grow: 1;
  margin: 0;
}

@custom-selector :--navbottom__tab .navBottom__tab;
@custom-selector :--navbottom__tab--selected .navBottom__tab.is-selected;

/**
 * Pestañas
 * 1. Se elimina regla de Bootstrap "margin-bottom: -1px" y se añade 1px de separación
 *    entre pestañas.
 * 2. Selectores para sobrecargar estilos de li en menus contextuales.
 */
:--navbottom__tab {
  display: flex;
  width: auto;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: #000;
  margin: 0; /* 1 */
  background-color: transparent;
  color: #a9c0e8;
  cursor: pointer;
}

:--navbottom__tab:hover,
:--navbottom__tab:active {
  background-color: transparent;
  color: #a9c0e8;
  text-decoration: none;
}

/**
 * Pestaña actual
 */
:--navbottom__tab--selected,
:--navbottom__tab--selected:active,
:--navbottom__tab--selected:hover,
:--navbottom__tab--selected:focus {
  color: var(--color-text-inverse);
  cursor: default;
}

:--navbottom__tab svg {
  width: 1.4em;
  height: 1.4em;
  margin: 5px 0;
  fill: #4f79c1;
}

:--navbottom__tab span {
  line-height: var(--font-size-18px);
}

:--navbottom__tab--selected svg {
  fill: var(--color-text-inverse);
  stroke: var(--color-text-inverse);
}
