/* ==================================================================== *\
 *                                                                      *
 *  BASE                                                                *
 *                                                                      *
 *  ------------------------------------------------------------------  *
 *  /base/_base.css                                                     *
 *  ------------------------------------------------------------------  *
 *  Estilos generales de algunos elementos HTML                         *
\* ==================================================================== */


/**
 * 1. Restablezco los espacios por defecto del navegador para gestionar
 *    el ritmo vertical.
 */
* {
  padding: 0;
  margin: 0;
}


/**
 * 1. Forzar la aparicion de la barra de desplazamiento vertical para que
 *    no haya flash de desplazamiento horizontal en las páginas que hacen el
 *    primer 'paint' del navegador sólo con el menú principal.
 */
html {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

/**
 * 1. Deshabilitar las ligaduras. Por ej: "fi" (solo Chrome y Mozilla. ver 2)
 * 2. Anular el uso de font-feature-settings en IE por postcss-define-functionun bug de IE10 e IE11
 *    en Win7.
 *    http://stackoverflow.com/questions/22151835/msie-10-web-font-and-font-feature-settings-causes-invisible-text
 */
body {
  margin: 0;
  background: var(--background-color);
  color: var(--color-text);
  font-family: var(--font-family-base);
  -moz-font-feature-settings: 'liga' 0, 'kern' 1; /* [1, 2] */
  -webkit-font-feature-settings: 'liga' 0, 'kern' 1; /* [1, 2] */
}

body.entorno-turismo {
  font-family: var(--font-family-turismo-base);
}

body.entorno-pwa {
  display: flex;
  height: 100%;
  flex: 1 0 auto;
  flex-direction: column;
}

@media (max-width: 767px) {
  body.entorno-pwa,
  body.entorno-www.j16-02 {
    overflow: hidden;
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
    background: #fff;
  }
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
}

figure {
  margin: 0 0 var(--rhythm);
}

/* -------------------------------------------------------------------- *
 *  Titulos                                                             *
 * -------------------------------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin-top: calc(var(--rhythm) * var(--margin-top-factor));
  margin-bottom: calc(var(--rhythm) * var(--margin-bottom-heading-factor));
  color: var(--color-heading);
  font-weight: normal;
}

:is(h1, h2, h3, h4, h5, h6) {
  font-family: var(--font-family-alt);
}

h1,
h2,
h3,
h4 {
  line-height: 1.2em;
}

h5,
h6 {
  line-height: 1.3;
}

.entorno-www h1,
.entorno-www h2,
.entorno-www h3,
.entorno-www h4,
.entorno-www h5,
.entorno-www h6 {
  font-weight: bold;
}

/**
 *  Titulos: Colores y margenes por defecto
 *
 *  Utilizado para html sin clases.
 *   Ej: Contenidos de wb021
 *
 *  Los titulos comienzan por defecto en h3
 *   h1 -> Vitoria-Gasteiz (Titulo general)
 *   h2 -> Contenido (oculto)
 * -------------------------------------------------------------------- */

/**
 * Titulo principal del contenido
 *
 * 1. Tamaño de referencia: 30px
 * 2. Espacio inferior de referencia: 20px
 */

h1,
h3 {
  --font: minimalfont(strip(var(--font-size-48px)));
  --margin: factormargin(strip(var(--font-size-48px)));
  --line: 1.2em;
  --top: 1.6;
  margin: 0;
  margin-top: calc(var(--margin) * var(--top));
  margin-bottom: calc(var(--margin) * var(--margin-bottom-heading-factor));
  font-size: var(--font);
  line-height: var(--line);
}

@media (min-width: 320px) and (max-width: 1419px) {
  h1,
  h3 {
    --font: fluidfont(strip(var(--font-size-48px)));
    --margin: fluidmargin(strip(var(--font-size-48px)));
    line-height: fluidline(strip(var(--line)), strip(var(--font-size-48px)));
  }
}

@media (min-width: 1420px) {
  h1,
  h3 {
    --font: var(--font-size-48px);
  }
}

/**
 * Titulo 2
 *
 * 1. Tamaño de referencia: 24px
 * 2. Espacio inferior de referencia: 12px
 */

h2 {
  --font: minimalfont(strip(var(--font-size-39px)));
  --margin: factormargin(strip(var(--font-size-39px)));
  --line: 1.2em;
  --top: 1.5;
  margin: 0;
  margin-top: calc(var(--margin) * var(--top));
  margin-bottom: calc(var(--margin) * var(--margin-bottom-heading-factor));
  color: var(--color-heading2);
  font-size: var(--font);
  line-height: var(--line);
}

@media (min-width: 320px) and (max-width: 1419px) {
  h2 {
    --margin: fluidmargin(strip(var(--font-size-39px)));
    font-size: fluidfont(strip(var(--font-size-39px)));
    line-height: fluidline(strip(var(--line)), strip(var(--font-size-39px)));
  }
}

@media (min-width: 1420px) {
  h2 {
    font-size: var(--font-size-39px);
  }
}

/**
 * Titulo 3
 *
 * 1. Tamaño de referencia: 21px
 * 2. Espacio inferior de referencia: 12px
 */

h3 {
  --font: minimalfont(strip(var(--font-size-31px)));
  --margin: factormargin(strip(var(--font-size-31px)));
  --line: 1.3em;
  --top: 1.4;
  margin: 0;
  margin-top: calc(var(--margin) * var(--top));
  margin-bottom: calc(var(--margin) * var(--margin-bottom-heading-factor));
  color: var(--color-heading3);
  font-size: var(--font);
  line-height: var(--line);
}

@media (min-width: 320px) and (max-width: 1419px) {
  h3 {
    --margin: fluidmargin(strip(var(--font-size-31px)));
    font-size: fluidfont(strip(var(--font-size-31px)));
    line-height: fluidline(strip(var(--line)), strip(var(--font-size-31px)));
  }
}

@media (min-width: 1420px) {
  h3 {
    font-size: var(--font-size-31px);
  }
}


/**
 * Titulo 4
 *
 * 1. Tamaño de referencia: 18px
 * 2. Espacio inferior de referencia: 9px
 */
h4 {
  --font: minimalfont(strip(var(--font-size-25px)));
  --margin: factormargin(strip(var(--font-size-25px)));
  --line: 1.3em;
  --top: 1.3;
  margin: 0;
  margin-top: calc(var(--margin) * var(--top));
  margin-bottom: calc(var(--margin) * var(--margin-bottom-heading-factor));
  color: var(--color-heading4);
  font-size: var(--font);
  line-height: var(--line);
}

@media (min-width: 320px) and (max-width: 1419px) {
  h4 {
    font-size: fluidfont(strip(var(--font-size-25px)));
    line-height: fluidline(strip(var(--line)), strip(var(--font-size-25px)));

    --margin: fluidmargin(strip(var(--font-size-25px)));
  }
}

@media (min-width: 1420px) {
  h4 {
    font-size: var(--font-size-25px);
  }
}

/* -------------------------------------------------------------------- *
 *  Enlaces                                                             *
 * -------------------------------------------------------------------- */

/**
 * Enlaces
 * (por defecto: verdes)
 */
a {
  color: var(--color-link);
  text-decoration: none;
}

a:active,
a:focus,
a:hover {
  text-decoration: none;
}

.keynav-mode a:focus {
  outline: 2px solid var(--outline-color);
  outline-offset: .1em;
}

a:hover {
  color: var(--color-link--hover);
  text-decoration: underline;
}

a:active {
  background-color: var(--background-color-alt-darker);
  color: #000;
}

/**
 * Enlaces del menu de la home
 * (por defecto: negros)
 */
.linksmenu a {
  color: var(--color-link-alt);
}

/* .linksmenu a:active,
.linksmenu a:focus, */
.linksmenu a:hover {
  color: var(--color-link-alt--hover);
}

/**
 * Enlaces del pie
 * (por defecto: negros)
 */
.main-footer a {
  color: var(--color-link-alt);
}

/* .main-footer a:active,
.main-footer a:focus, */
.main-footer a:hover {
  color: var(--color-link-alt--hover);
}

/**
 * Enlaces de menus laterales
 * (por defecto: negros)
 */
.extra-content a,
.aside-tabletMobile a {
  color: var(--color-link-alt);
}

/* .extra-content a:active,
.extra-content a:focus, */

/* .aside-tabletMobile a:active,
.aside-tabletMobile a:focus, */
.extra-content a:hover,
.aside-tabletMobile a:hover {
  color: var(--color-link-alt--hover);
}

/**
 * Enlaces dentro de títulos
 * 1. Color negros
 * 2. y con subrayado en verde
 */
h1 > a,
h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a {
  /* background-image: linear-gradient(0deg, var(--color-link) 1px, transparent 1px, transparent);
  background-repeat: repeat-x;  [2] */
  color: var(--color-link-alt); /* [1] */
  font-family: var(--font-family-heading);
}

/**
 * 1. Enlaces dentro de títulos que se corresponden a un enlace a RSS
 *    (normalmente con icono). Se elimina el subrayado.
 */
h1 > a[href*="rssAction.do"],
h2 > a[href*="rssAction.do"],
h3 > a[href*="rssAction.do"],
h4 > a[href*="rssAction.do"],
h5 > a[href*="rssAction.do"],
h6 > a[href*="rssAction.do"] {
  background-image: none; /* [1] */
}

/**
 * 1. Algunos enlaces se localizan dentro de elementos con clase "glyphicon".
 *    Para evitar que hereden la fuente 'Glyphicons Halflings' de dicha clase
 *    se especifica que utilicen la de por defecto.
 */
.glyphicon a {
  font-family: var(--font-family-base); /* 1 */
}

/* -------------------------------------------------------------------- *
 *  Texto                                                               *
 * -------------------------------------------------------------------- */

p,
dl {
  margin: 0 0 var(--rhythm);
}

dd {
  margin: 0 0 calc(var(--rhythm) * .5);
}

dt {
  --margin: fluidmargin(strip(var(--font-size-24px)));
  margin: 0;
  margin-bottom: calc(var(--margin) * var(--margin-bottom-heading-factor));
  font-weight: bold;
}

/* -------------------------------------------------------------------- *
 *  Listas                                                              *
 * -------------------------------------------------------------------- */

/**
 * 1. Por defecto las listas tienen sangría hacia adentro
 *    para que el list-style se alinee a la izquierda con el resto
 *    de elementos
 */
ul,
ol {
  padding: 0 0 0 19px; /* 1 */
  margin: 0 0 var(--rhythm);
}

ol {
  padding-left: 22px;
}

li {
  padding: 0;
  margin: 0 0 calc(var(--rhythm) * .5);
}

li:last-child {
  margin: 0;
}

/**
 * Listas anidadas.
 *
 * 1. Separación con el contenido de la LI padre.
 * 2. El list-style es un guión.
 *    Lo añadimos como imagen en base64 para aplicarlo a list-style-image.
 *
 *    Nota: Escogemos esta solución en vez de usar pseudoelemento ::before { content: '-' }
 *    porque no nos obliga a sobrecargar ::before para quitarlo en las UL que se usen
 *    a lo largo de la interfaz. Con list-style-image, se anula el guión de forma automática
 *    cuando dichas UL apliquen "list-style: none" como hasta ahora.
 */
li > ul {
  margin-top: calc(var(--rhythm) * .5);
  list-style-image: url('data:image/gif;base64,R0lGODlhBQAKAIABAAAAAP///yH5BAEAAAEALAAAAAAFAAoAAAIIjI+ZwKwPUQEAOw==');
}

li > ol {
  margin-top: calc(var(--rhythm) * .5);
  list-style: decimal;
}

li > ul > li {
  margin: 0 0 calc(var(--rhythm) * .35);
}


/* -------------------------------------------------------------------- *
 *  Embed                                                               *
 * -------------------------------------------------------------------- */
iframe {
  border: none;
}

object {
  width: 100%;
  max-width: 480px;
  margin: 0 0 var(--rhythm);
}

/* -------------------------------------------------------------------- *
 *  Nuevas columnas                                                     *
 * -------------------------------------------------------------------- */
.rowflex {
  --separation: 5.3125rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: var(--separation);
}

/* Clases genericas de flexbox */
.flex {
  display: flex;
}

.columnflex {
  display: flex;
  flex-direction: column;
}

/* justificar los elementos flex con espacio entre ellos */
.space-between {
  justify-content: space-between;
}

@media (min-width: 991px) and (max-width: 1419px) {
  .rowflex {
    column-gap: clamp(2.3125rem, 2vw, var(--separation));
  }
}

.grid {
  display: grid;
  column-gap: clamp(3em, 8vw, 6em);
  grid-template-columns: repeat(2, 1fr);
  row-gap: calc(var(--rhythm) * 2);
}

@media (max-width: 767px) {
  .grid {
    grid-template-columns: 1fr;
  }
}

/* -------------------------------------------------------------------- *
 *  Customización del espacio entre columnas de Bootstrap               *
 * -------------------------------------------------------------------- */
.row {
  margin-right: 0;
  margin-left: 0;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  padding-right: calc(var(--gutter-outer) / 2);
  padding-left: calc(var(--gutter-outer) / 2);
}

.col-md-full {
  position: relative;
  padding-right: calc(var(--gutter-outer) / 2);
  padding-left: calc(var(--gutter-outer) / 2);
}

audio {
  max-width: 100%;
}

/* -------------------------------------------------------------------- *
 *  Iconos svg integrados en texto                                      *
 * -------------------------------------------------------------------- */
.svg-icon {
  /* Place the icon on the text baseline. */
  position: relative;
  top: .125em;

  /* Scale the icon to match the font-size of the parent element. */
  width: 1em;
  height: 1em;

  /* Prevent the icon from shrinking inside a flex container. */
  flex-shrink: 0;

  /* Left margin. */
  margin-left: .2rem;

  /* Let the icon take whatever color the parent has. */
  fill: currentColor;

  /*
   * If the icon is used on a link, which has a color transition,
   * we can also use a transition on the fill value.
  */
  transition: fill .3s;
}

/* -------------------------------------------------------------------- *
 *  Superíndice                                                         *
 * -------------------------------------------------------------------- */

.super {
  display: inline-block;
  padding-left: 5px;
  color: var(--color-text);
  font-size: 75%;
  text-decoration: none;
  vertical-align: super;
}

/* -------------------------------------------------------------------- *
 *  SVG                                                                 *
 * -------------------------------------------------------------------- */

.entorno-pwa svg.sprite {
  position: absolute;
  z-index: -1;
}

.entorno-pwa svg .primary-fill {
  fill: inherit;
  stroke-width: 0;
}

.entorno-pwa svg .primary-stroke {
  stroke: inherit;
}

/* .contents {
  background-image: repeating-linear-gradient(rgb(255, 255, 255) 0, rgb(255, 255, 255)  calc(1.5em - 1px), rgb(255, 62, 62) 1.5em);
} */


/* Quitar el padding de Bootstrap a la izq */
.no-left-padding {
  padding-left: 0 !important;
}
