/**
   * FORMULARIO
   * -------------------------------------- */

.searchbox {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0;
}

@media (min-width: 768px) {
  .searchbox {
    /* max-width: 80%; */
    flex-direction: row;
  }
}

.searchbox__search {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  border: 3px solid #000;
  margin-bottom: 0;
}

@media (max-width: 1000px) {
  .searchbox__search > * {
    border-bottom: 3px solid #000;
  }
  .searchbox__search :last-child {
    border-bottom: 0;
  }
}

.searchbox__search .field {
  position: relative;
  margin-bottom: 0;
}

.searchbox__search .field--text {
  display: flex;
  flex-grow: 6;
  align-items: center;
}

.searchbox__search .field--dateRange::before {
  position: absolute;
  z-index: 4;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: .5em;
  background-color: var(--color-text-high-contrast);
  content: '';
  mask-image: url(../img/svg/sprite.svg#icon-calendar-view);
}

.searchbox__search .field--dateRange {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.searchbox__search .field__input {
  width: 100%;
  border: 0;
  background-color: #fff;
}

@media (max-width: 768px) {
  .searchbox__search .field__input {
    text-align: center;
  }
}

.searchbox__search .field--dateRange .field__input {
  z-index: 3;
  background-color: #fff;
}

@media (min-width: 768px) {
  .searchbox__search .field--dateRange .field__input {
    padding-left: 50px;
  }
}

.searchbox__search .btn {
  display: inline-block;
  width: 100%;
  height: 1.667em;
  margin: 0;
}

@media (max-width: 768px) {
  .searchbox__search .btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .searchbox__search .btn::after {
    position: relative;
    display: inline-flex;
    width: 1.5em;
    height: 1.5em;
    margin-left: .5em;
    background-color: var(--color-text-inverse);
    content: '';
    mask-image: url(../img/svg/sprite.svg#search-view);
  }
}

@media (min-width: 1000px) {
  .searchbox {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  .searchbox__search > * {
    border-right: 3px solid #000;
    border-bottom: 0;
  }
  .searchbox__search :last-child {
    border-right: 0;
  }
  .searchbox .btn {
    width: auto;
    flex-grow: 1;
    line-height: unset;
  }
}

/**
* FILTROS
* -------------------------------------- */

/* .searchbox__filters {
  margin-bottom: var(--rhythm);
} */

.filters:empty,
.filters-selected:empty {
  display: none;
}

.filters,
.filters-selected {
  display: flex;
  align-items: center;
  padding: 0 var(--readable-padding-side);
  margin: var(--rhythm) calc(var(--readable-padding-side) * -1);
  gap: 1em;
}

.filters {
  margin-bottom: 0;
}

.filters > *:first-child,
.filters > .field--checkbox:last-child > .field__label {
  font-family: var(--font-family-base-semi);
}

.filters-selected {
  overflow-x: auto;
}

.filters-selected::-webkit-scrollbar {
  display: none;
}

@media (max-width: 768px) {
  .filters-selected {
    max-width: 100vw;
    background-attachment: local, local, scroll, scroll;
    background-color: white;
    background-image:
      linear-gradient(to right, white, white),
      linear-gradient(to right, white, white),
      linear-gradient(to right, rgb(231, 242, 244), rgba(255, 255, 255, 0)),
      linear-gradient(to left, rgb(231, 242, 244), rgba(255, 255, 255, 0));
    background-position: left center, right center, left center, right center;
    background-repeat: no-repeat;
    background-size: 20px 100%, 20px 100%, 1.5em 100%, 1.5em 100%;
    overflow-x: scroll;
  }
}

@media (min-width: 768px) {
  .filters-selected {
    flex-wrap: wrap;
  }
}

.filters .filter {
  position: relative;
  border: 1px solid rgba(0, 0, 0, .15);
}

.filter .filter__button {
  display: flex;
  width: auto;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #000;
  margin: 0;
  background: #fff;
  color: black;
}

.filter .filter__button * {
  pointer-events: none;
}

.filter .filter__button::after {
  width: 1.5em;
  height: 1.5em;
  margin-left: 1.5rem;
  background-color: #000;
  content: '';
  mask-image: url(../img/svg/sprite.svg#icon-arrow-view);
  mask-position: top;
  transform: rotate(180deg) translateY(4px);
  transition: transform .15s;
}

.filter .filter__button[aria-expanded="true"]::after {
  transform: rotate(0deg) translateY(4px);
}

.filter .filter__list {
  position: absolute;
  z-index: 9999;
  width: auto;
  min-width: 16em;
  padding: var(--gutter-interior-aside);
  border: 1px solid rgba(0, 0, 0, .15);
  margin: .2em 0 0;
  background-color: white;
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
  list-style: none;
}

.filter .filter__list .field__checkbox {
  margin: 0;
}

.fieldset--checkboxset.filter__list > .field:not(:last-child) {
  margin-bottom: .5em;
}

.filters-selected .btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--gutter-interior-filtros);
  margin: 0;
  background-color: var(--background-color-alt-dark);
  color: #000;
  font-family: var(--font-family-base);
  white-space: nowrap;
}

.filters-selected .btn::after {
  width: .6em;
  height: .6em;
  margin-left: 1.5em;
  background-color: #000;
  content: '';
  mask-image: url(../img/svg/sprite.svg#close-view);
}

.filters-selected .btn:first-child:is(#clearAllFilters) {
  font-weight: bold;
}

.filters-selected .btn:hover,
.filters-selected .btn:focus {
  color: var(--color-primario);
  text-decoration: underline;
}

.filters-selected .btn:hover::after,
.filters-selected .btn:focus::after {
  background-color: var(--color-primario);
}


/* .filters-selected .btn:focus {
  animation: slide-in-left .5s cubic-bezier(.25, .46, .45, .94) reverse;
} */

.filters-selected__texto--ellipsis {
  display: flex;
  max-width: 200px;
  align-items: center;
  justify-content: space-between;
  padding-right: 0;
  margin: 0;
  gap: 10px;
}

.filters-selected__item-title--ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filters-selected__svg {
  width: 42px;
  height: 28px;
}

.filters .button__svg--filter {
  width: 10px;
  height: 13px;
  margin-left: 10px;
  fill: #004ddb;
  stroke: #004ddb;
  stroke-width: 0;
}

.filters-header {
  display: flex;
  width: 100%;
  height: 4rem;
  flex-direction: row;
  align-items: center;
  padding: 0 1em;
  background-color: #000;
}

.filters-header .filters-header__title {
  margin: 0;
  color: var(--color-text-inverse);
  font-family: var(--font-family-alt-semi);
  white-space: nowrap;
}

.filters-header__close {
  padding: 0;
  margin: 0;
  margin-left: auto;
  background: transparent;
  line-height: 0;
}

.filters-header__close:hover,
.filters-header__close:focus {
  background: transparent;
}

.filters-header__close::after {
  display: block;
  width: 1.2em;
  height: 1.2em;
  margin-left: auto;
  background-color: var(--color-text-inverse);
  content: "";
  mask-image: url(../img/svg/sprite.svg#close-view);
}

.filters-footer {
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: center;
}

.filters-footer__watch {
  width: 90%;
}

.searchbox__filters .btn-filtrar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--gutter-interior);
  border: 3px solid var(--color-text);
  background-color: var(--background-color-alt-dark);
  color: var(--color-text);
  font-weight: bold;
}

.searchbox__filters .btn-filtrar::after {
  width: 1.5em;
  height: 1.5em;
  margin-left: .5em;
  background-color: var(--color-text);
  content: '';
  mask-image: url(../img/svg/sprite.svg#icon-filter-view);
}

/**
   * FILTROS MÓVIL
   * -------------------------------------- */

@media (max-width: 768px) {
  ._openfilters {
    position: fixed;
    z-index: 99999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    margin: 0;
    background: white;
  }
  ._openfilters .filters {
    flex-direction: column;
    align-items: normal;
    gap: 0;
  }
  ._openfilters .filter .filter__button {
    width: 100%;
    border: 0;
    font-family: var(--font-family-alt-semi);
    font-size: var(--font-size-24px);
    font-weight: normal;
  }
  ._openfilters .filter .filter__list {
    position: static;
    width: 100%;
    border: 0;
    margin: 0;
    box-shadow: none;
  }
}

@media (min-width: 768px) {
  .subtype__filters {
    width: 40%;
  }
}

.searchbox__form > header > h2 {
  margin-top: 0;
}

.searchbox__views {
  margin-top: var(--rhythm);
}

.events .fieldset--segmented {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
