/* ================================================================== *\
 *                                                                    *
 *  APLICACIÓN: m01-01s                                               *
 *  NOMBRE: Reserva de instalaciones                                  *
 *                                                                    *
 * ------------------------------------------------------------------ *
 *  /pages/_m01-01s.css                                               *
\* ================================================================== */

/**
 * URLS DE PRUEBA
 *
 * Reserva de instalaciones
 * https://dsedeelectronica.vitoria-gasteiz.org/m01-01s/reservaAction.do?accion=alquilar&idioma=es
 */

.m01-01s .listaHorizontal ul {
  padding: 0;
  margin-bottom: 1.5em;
  column-count: 2;
}

@media (min-width: 768px) {
  .m01-01s .listaHorizontal ul {
    column-count: 3;
  }
}

@media (min-width: 992px) {
  .m01-01s .listaHorizontal ul {
    column-count: 4;
  }
}

@media (min-width: 1420px) {
  .m01-01s .listaHorizontal ul {
    column-count: 5;
  }
}

.m01-01s .listaHorizontal li {
  display: block;
  min-height: 10px;
  padding-top: .6em;
  padding-left: 15px;
  margin: 0;
  background: url(../img/arrow_link1.png) no-repeat;
  background-position: 0 1em;
}

/* ================================================================== *\
 *                                                                    *
 *  APLICACIÓN: m01-01s                                               *
 *  NOMBRE: ESTILOS REFACTORIZADOS DEL CSS PROPIO DE LA APLICACIÓN    *
\* ================================================================== */

.entorno-pwa.m01-01s .tab-content {
  padding: 0;
}

@media (min-width: 768px) {
  .entorno-sede.entorno-pwa.m01-01s footer {
    display: block;
  }
  .entorno-pwa.m01-01s .tab-content {
    overflow-y: hidden;
  }
}

/** HEADER **/
.entorno-pwa.m01-01s .tab-content__header {
  align-items: center;
  padding: var(--gutter-interior-aside);
  margin: 0;
  margin-bottom: var(--rhythm);
}

.entorno-pwa.m01-01s .tab-content__nombre {
  margin-bottom: 0;
}

.entorno-pwa.m01-01s .tab-content__header + section,
#content-resultados-buscador {
  padding: 0 var(--gutter-interior);
  margin-top: .5em;
}

.entorno-pwa.m01-01s .detalle-buscador__volver {
  order: -1;
  margin-right: .2em;
  margin-left: 0;
}

.entorno-pwa.m01-01s .detalle-buscador__filtrar,
.entorno-pwa.m01-01s .detalle-buscador__eliminar,
.entorno-pwa.m01-01s .tab-content__header > div {
  margin: 0 0 0 auto;
}

@media (max-width: 500px) {
  .entorno-pwa.m01-01s .tab-content__header > div > .btn + .btn {
    margin-top: .5em;
  }
}

@media (min-width: 500px) {
  .entorno-pwa.m01-01s .tab-content__header > div > .btn + .btn {
    margin-left: .5em;
  }
}

.m01-01s .ico-back,
.m01-01s .ico-fav {
  display: block;
  width: 1em;
  height: 1em;
  margin: 0 auto;
  fill: none;
  stroke: #fff;
  text-decoration: none;
}

.m01-01s .ico-fav--checked use {
  fill: #fff;
}

.m01-01s .btn-ico,
.m01-01s .btn-ico:hover,
.m01-01s .btn-ico:focus,
.m01-01s .btn-ico:active {
  padding: 0;
  margin-bottom: 0;
  background-color: transparent;
  font-size: 2em;
  outline: none;
}

.m01-01s .detalle-buscador__volver {
  order: -1;
  margin-left: 0;
}

/* ================================================================== *\
 *                                                                    *
 *  APLICACIÓN: m01-01s                                               *
 *  NOMBRE: RESERVAR
\* ================================================================== */

.entorno-pwa.m01-01s #usos .tab-content,
.entorno-pwa.m01-01s #detalle-buscador .tab-content {
  padding: 0;
  padding-top: var(--rhythm);
  border-right: 1px solid #bfd9e2;
  border-left: 1px solid #bfd9e2;
}

.m01-01s .selFechas {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 3vh 3vw;
  margin: 0;
  background-color: var(--background-color-alt-darker);
  list-style: none;
}

.m01-01s .selFechas li {
  padding: var(--gutter-interior-aside);
  background-color: var(--background-color);
}

.m01-01s .selFechas li.selFecha.instalacion:hover {
  background-color: var(--color-primario);
  cursor: pointer;
}

.m01-01s .selFechas label {
  text-transform: uppercase;
}

.m01-01s .selFechas li:hover > label {
  color: var(--color-text-inverse);
}

.m01-01s .field--search--container {
  padding: 0 3vw;
}

/* ================================================================== *\
 *                                                                    *
 *  APLICACIÓN: m01-01s                                               *
 *  NOMBRE: MIS FAVORITOS                                             *
\* ================================================================== */

.m01-01s .resultados-buscador:empty {
  transform: translateX(110%);
}

.m01-01s .resultados-buscador:not(:empty) {
  transform: translateX(0);
}

.m01-01s .resultados-buscador:not(:empty) + .tablist {
  transform: translateY(100%);
}

@media (min-width: 768px) {
  .m01-01s .resultados-buscador:not(:empty) + .tablist {
    transform: translateY(-100%);
  }
}

.m01-01s .reserva-list {
  padding: 0;
}

.m01-01s .reserva {
  padding: 1em;
  background-color: var(--background-color-alt);
  color: #000;
  list-style: none;
}

.m01-01s .reserva__header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: .6em;
}

.m01-01s .reserva__centro {
  display: inline-block;
  margin-right: .4em;
  font-weight: bold;
}

.m01-01s .reserva__duracion {
  width: 50px;
  height: 50px;
  padding-top: .6em;
  padding-right: .2em;
  padding-left: .2em;
  background-color: black;
  color: white;
  text-align: center;
}

.m01-01s .reserva__content {
  flex: 0 0 auto;
  align-items: flex-end;
  justify-content: space-between;
}

.m01-01s .reserva__content * {
  margin-bottom: 0;
}

.m01-01s .horas {
  max-width: 40em;
  padding: 0;
  margin-top: .2em;
  margin-bottom: 1em;
}

.m01-01s .horas_hora {
  display: inline-flex;
  height: 1.4em;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-top: .2em;
  margin-right: .6em;
  line-height: 1.1;
}

.m01-01s .horas_hora[role="checkbox"] {
  border: none;
}

.m01-01s .horas_hora[aria-checked] {
  background-color: green;
  color: white;
}

.m01-01s .horas_hora[aria-checked="false"] {
  background-color: transparent;
  color: black;
}

.m01-01s .detalle_fav {
  margin-left: auto;
}

.m01-01s #content-detalle > .capaInfo {
  margin-bottom: var(--rhythm);
}

/* ================================================================== *\
 *                                                                    *
 *  APLICACIÓN: m01-01s                                               *
 *  NOMBRE: MIS RESERVAS                                              *
\* ================================================================== */

.m01-01s .field__label {
  font-weight: bold;
}

.m01-01s .detalle-misReservas form,
.m01-01s #content-detalle form {
  padding: 0;
  background-color: transparent;
}

.m01-01s .resultados-detalle .horas {
  max-width: 40em;
  padding: 0;
  margin-top: -.2em;
  margin-bottom: 1em;
  font-size: 1.1rem;
}

.m01-01s .resultados-detalle .horas_hora {
  display: inline-flex;
  width: 55px;
  height: 55px;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 1px solid black;
  margin-top: 12px;
  margin-right: 12px;
  background-color: var(--background-color-alt);
  color: #004ddb;
  line-height: 1.1;
}

.m01-01s .resultados-detalle .horas_hora:hover {
  background-color: var(--color-primario);
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.m01-01s .resultados-detalle .horas_hora[aria-checked] {
  border: 2px solid black;
  background-color: white;
  color: black;
  font-weight: 700;
}

.m01-01s .reserva__disabled {
  background-color: var(--background-color-alt);
  opacity: .5;
  pointer-events: none;
}

.m01-01s .reserva__active {
  background-color: white;
  text-decoration: none;
}

.m01-01s .titleForm {
  font-weight: bold;
}

.m01-01s .subForm {
  margin-bottom: var(--rhythm);
  cursor: pointer;
}

.m01-01s .tablist__ico2 {
  display: block;
  width: 1em;
  height: 1em;
  margin: 0;
}

@media (min-width: 768px) {
  .m01-01s .tablist__ico2 {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    margin: 0;
    vertical-align: sub;
  }
}

.m01-01s .selFecha.instalacion > input[type=checkbox] {
  margin: 0 0 0 1em;
}

.entorno-pwa.m01-01s .searchForm {
  padding: 0 var(--gutter-interior);
}
