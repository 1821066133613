/* ==================================================================== *\
 *                                                                      *
 *  FICHA: MEDIOS DE COMUNICACION                                       *
 *                                                                      *
 *  ------------------------------------------------------------------  *
 *  /pages/_ficha--mediosComunicacion.css                               *
 *  ------------------------------------------------------------------  *
 *  Estilos propios de la ficha                                         *
\* ==================================================================== */

/* -------------------------------------------------------------------- *\
 *  NAVEGACIÓN ENTRE AGENDAS:                                           *
 *  Global, Alcalde, Ruedas de prensa y Convocatorias.                  *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *    ul.mediosAgendas                                                  *
 *      li.icon-arrow_link1                                             *
 *        if (seleccionado) {                                           *
 *          span                                                        *
 *        } else {                                                      *
 *          a                                                           *
 *        }                                                             *
 *                                                                      *
\* -------------------------------------------------------------------- */
.mediosAgendas {
  margin-bottom: 1em;
}

@media (min-width: 768px) {
  .mediosAgendas {
    display: block;
    padding-left: 0;
  }

  /**
   * 1. Como a las LI se les aplica la clase "icon-arrow_link1":
   *    posicionar icono e indentar texto
   */
  .mediosAgendas li {
    display: inline-block;
    padding-left: 12px;
    margin: 0 .8em 0 0;
    background: url(../img/arrow_link1.png) no-repeat;
    background-position: left center;
  }
}

/* -------------------------------------------------------------------- *\
 *  DESCARGAR iCALENDAR                                                 *
\* -------------------------------------------------------------------- */

/**
   * 1. Como no está en un párrafo no coge el margin-bottom por defecto.
   */
div#medios-agenda-ecalendar {
  margin-bottom: .6em; /* 1 */
  font-size: var(--font-size-21px);
}
