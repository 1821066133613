/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE> CAMPO DE FORMULARIO                                     *
 *                                                                      *
\* ==================================================================== */

/**
 * Elemento: Campo de un formulario (par label/campo)
 * -------------------------------------------------------------------
 * DOM:
 *    div.field.field--[inputtype]
 *      input.field__input
 *      label.field__label
 *
 *    div.field.field--textarea
 *      textarea.field__textarea
 *      label.field__label
 *
 *    div.field.field--select
 *      textarea.field__select
 *      label.field__label
\* ------------------------------------------------------------------- */

/*
@styleguide

@title Formularios: Campos (beta)

Todos los campos, al margen de encontrarse en un formulario o no, deben de tener una
capa contenedora con la clase `field`. A dicha clase se le añadirá un modificador
dependiente del tipo de campo (ver siguientes puntos).

Dentro habrá, **por este orden**:

* Un `label` cuyo atributo `for` apunte al `id` del elemento anterior
* Un `input`, `textarea` o `select`

**Para los checkbox o radio buttons el orden será inverso.**

```html
// Modificadores segun el tipo de campo
// - field--text
// - field--file
// - field--date
// - field--email
// - field--number
// - field--tel
// - field--select
// - field--textarea
// - field--checkbox
// - field--radio

// Modificadores de estado
// - is-required
// - is-disabled
// - is-readonly

<div class="field [modificador del tipo de campo] [modificador de estado]">
  <label class="field__label" for="[id]">[label]</label>
  [html del campo]
</div>

// Para checkbox o radio buttons, el orden es a la inversa
<div class="field [modificador del tipo de campo] [modificador de estado]">
  [html del campo]
  <label class="field__label" for="[id]">[label]</label>
</div>
```

## Estados de un campo

Los campos pueden tener varios estados extra:

* *disabled*: El campo no es editable (ni recibe el foco) y su contenido no se envia en el formulario.

  `<input class"field__input" disabled aria-disabled="true">`

* *readonly*: El campo no es editable pero su contenido es enviado junto con el formulario.

  `<input class"field__input" readonly aria-readonly="true">`

* *required*: El campo es obligatorio

  `<input class"field__input" required aria-required="true">`

### Ejemplo de visualización

<fieldset class="fieldset fieldset--inline">
  <div class="field field--text is-disabled">
    <label class="field__label" for="campo-disabled">Campo deshabilitado</label>
    <input id="campo-disabled" class"field__input" disabled aria-disabled="true" value="Texto de ejemplo">
  </div>
  <div class="field field--text is-readonly">
    <label class="field__label" for="campo-readonly">Campo de solo lectura</label>
    <input id="campo-readonly" class"field__input" readonly aria-readonly="true" value="Texto de ejemplo">
  </div>
  <div class="field field--text is-required">
    <label class="field__label" for="campo-required">Campo obligatorio</label>
    <input id="campo-required" class"field__input" required aria-required="true" value="Texto de ejemplo">
  </div>
</fieldset>

## Campo tipo: Texto

Campo de texto básico acompañado de etiqueta.

```html
<div class="field field--text">
  <label class="field__label" for="[inputId]">[label]</label>
  <input class="field__input" id="[inputId]" name="[inputName]" placeholder="[placeholder]" type="text">
</div>
```

<div class="psg-responsive-sample">
  <div class="field field--text">
    <label class="field__label" for="input-sample-05">Label</label>
    <input class="field__input" id="input-sample-05" placeholder="Escribe aquí" type="text">
  </div>
</div>

## Campo tipo: Textarea

```html
<div class="field field--textarea">
  <label class="field__label" for="[textareaId]">[label]</label>
  <textarea class="field__textarea" id="[textareaId]" name="[textareaName]" placeholder="[placeholder]"></textarea>
</div>
```

<div class="psg-responsive-sample">
  <div class="field field--textarea">
      <label class="field__label" for="textarea-sample-01">Label</label>
      <textarea class="field__textarea" id="textarea-sample-01" placeholder="Escribe el texto aquí"></textarea>
  </div>
</div>

## Campo tipo: Fecha

Campo para seleccionar una fecha con la ayuda de un calendario.

```html
<div class="field field--date">
  <label class="field__label" for="[inputId]">[label]</label>
  <input class="field__input" id="[inputId]" name="[inputName]" type="date">
</div>
```

<div class="psg-responsive-sample">
  <div class="field field--date">
    <label class="field__label" for="input-sample-07">Fecha</label>
    <input class="field__input" id="input-sample-07" type="date">
  </div>
</div>

## Campo tipo: Lista desplegable

```html
<div class="field field--select">
  <label class="field__label" for="[selectId]">[label]</label>
  <select class="field__select id="[selectId]" name="[selectName]">
    <option value=""></option>
    <option value="1">Opción 1</option>
    ...
  </select>
</div>
```

<div class="psg-responsive-sample">
  <div class="field field--select">
      <label class="field__label" for="select-sample-01">Lista</label>
      <select class="field__select" id="select-sample-01">
        <option value=""></option>
        <option value="1">Opción 1</option>
        <option value="2">Opción 2</option>
        <option value="3">Opción 3</option>
      </select>
  </div>
</div>

## Campo tipo: Archivo

Campo para enviar un archivo

```html
<div class="field field--file">
  <label class="field__label" for="[inputId]">[label]</label>
  <input class="field__input" id="[inputId]" name="[inputName]" type="file">
</div>
```

<div class="psg-responsive-sample">
  <div class="field field--file">
    <label class="field__label" for="input-sample-07">Fecha</label>
    <input class="field__input" id="input-sample-07" type="file">
  </div>
</div>

## Campo tipo: Checkbox

Checkbox acompañado de etiqueta.

```html
<div class="field field--checkbox">
  <input class="field__checkbox" id="[inputId]" name="[inputName]" type="checkbox">
  <label class="field__label" for="[inputId]">[label]</label>
</div>
```

<div class="psg-responsive-sample">
  <div class="field field--checkbox">
    <input class="field__checkbox" id="checkbox-sample-01" type="checkbox">
    <label class="field__label" for="checkbox-sample-01">Este es un ejemplo de checkbox</label>
  </div>
</div>

## Campo tipo: Radio

Radio button acompañado de etiqueta.

```html
<div class="field field--radio">
  <input class="field__radio" id="[inputId]" name="[inputName]" type="radio">
  <label class="field__label" for="[inputId]">[label]</label>
</div>
```

<div class="psg-responsive-sample">
  <div class="field field--radio">
    <input class="field__radio" id="radio-sample-01" type="radio">
    <label class="field__label" for="radio-sample-01">Este es un ejemplo de radio button</label>
  </div>
</div>
*/
.field {
  position: relative;
  flex-wrap: wrap;
  margin-bottom: var(--rhythm);
  font-size: var(--font-size-18px);
  line-height: var(--rhythm);
  list-style: none;
}

@media (min-width: 320px) and (max-width: 1419px) {
  .field {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .field {
    font-size: var(--font-size-24px);
  }
}

/**
 * 1. Ocultar los field vacios para que no ocupen espacio blanco.
 *    Pensado para el caso de field dinamicos que rellenan su input
 *    y label con AJAX.
 * 2. Se deja comentado de momento por problemas con IE11 (repaint bug)
 */

/* .field:empty {
  display: none; /* [1, 2] *
} */

.field__input,
.field__select,
.field__textarea {
  /* width: 100%; */
  max-width: 100%;
  order: 1;
  margin-bottom: 0;
}

/* Caso especial en TMC */
#tmc-form .field__input {
  width: auto !important;
}

.field__input + label + *,
.field__select + label + *,
.field__textarea + label + * {
  margin-top: var(--rhythm);
}

@media (max-width: 767px) {
  .field {
    width: 100%;
  }
}

.field--checkbox,
.field--radio {
  width: 100%;
  padding-bottom: 0;
}

.field__label {
  display: block;
  margin: 0 0 .3em;
}

.field--checkbox > .field__label,
.field--radio > .field__label {
  width: auto;
  margin-bottom: 0;
}

/*
.field__checkbox,
.field__radio {
  top: .4em;
  left: 0;
}
*/

/*
.field--checkbox {
}

.field--radio {
}

.field--select {
}

.field--text {
}

.field--date {

} */

.field--date > .field__input.hasDatepicker {
  width: var(--input-date-width);
}

.entorno-turismo .field--date > .field__input.hasDatepicker {
  width: var(--input-turismo-date-width);
}

.field--date > .field__input.hasDatepicker[size] {
  width: auto;
}

.field--date > img,
.field--date > picture > img {
  width: 1.6em;
  height: 1.6em;
  order: 1;
  vertical-align: bottom;
}

@media (max-width: 767px) {
  .field--date > .field__input.hasDatepicker.hasDatepicker {
    width: calc(100% - 2.2em);
  }
}

/* ------------------------------------------------------------------ *\
 *  field.is-required                                                 *
\* ------------------------------------------------------------------ */
.field.is-required .field__label::after {
  display: inline;
  margin-left: .4em;
  content: '*';
  font-weight: bold;
  line-height: 1;
  vertical-align: middle;
}

.field__msg {
  margin-top: calc(var(--rhythm) * .5);
}

.field__msg:empty,
.field__msg.is-empty {
  display: none;
}


/* ==================================================================== *\
 *                                                                      *
 *  Modificadores                                                       *
 *                                                                      *
\* ==================================================================== */
.field--last {
  margin-bottom: 0;
}

/* ==================================================================== *\
 *                                                                      *
 *  COMPATIBILIDAD CON ANTERIOR PROPUESTA (.form__field)                *
 *                                                                      *
\* ==================================================================== */
@media (max-width: 767px) {
  .form__field {
    width: 100%;
  }
}
