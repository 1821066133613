/* ================================================================== *\
 *                                                                    *
 *  APLICACIÓN: A16-01I                                               *
 *                                                                    *
 * ------------------------------------------------------------------ *
 *  /pages/_a16-01i.css                                               *
\* ================================================================== */

/*
Nombre:
Agenda de sesiones

URL de acceso:
Contenidos con uid "app_ib004_claveSesion"
 */

.a16-01i-firma {
  text-align: center;
}
