/*
@styleguide

@title Formularios: Fieldsets

Para agrupaciones de varios campos se debe utilizar la clase base `fieldset`.

**¿Qué etiqueta HTML se debe utilizar?**

* **`<fieldset>`**: En caso de ser una **agrupación lógica de campos relacionados**.
*(Ejemplo: Campos calle, numero, piso... dentro de un fieldset con legend "Dirección".)*

* **`<div>`**: Si la **agrupación es simplemente estética**.
*(Ejemplo: Tres campos no relacionados que queremos mostrar en linea. Nota: Ver siguiente punto.)*

```html
// etiqueta fieldset (campos relacionados) o div (campos no relacionados)
<fieldset class="fieldset">
  <div class="field">...</div>
  <div class="field">...</div>
  ...
</fieldset>
```

<div class="psg-responsive-sample">
  <fieldset class="fieldset">
    <div class="field field--text">
      <label class="field__label" for="fieldset-inline-sample-01">Label</label>
      <input class="field__input" id="fieldset-inline-sample-01" placeholder="Escribe aquí" type="text">
    </div>
    <div class="field field--text">
      <label class="field__label" for="fieldset-inline-sample-02">Label</label>
      <input class="field__input" id="fieldset-inline-sample-02" placeholder="Escribe aquí" type="text">
    </div>
    <div class="field field--select">
        <label class="field__label" for="fieldset-inline-sample-03">Lista</label>
        <select class="field__select" id="fieldset-inline-sample-03">
          <option value=""></option>
          <option value="1">Opción 1</option>
          <option value="2">Opción 2</option>
          <option value="3">Opción 3</option>
        </select>
    </div>
  </fieldset>
</div>

*/

/**
 * 1. El fieldset se hace cargo del margen inferior que correspondería al
 *    último elemento para evitar que el margen inferior de éste se sume
 *    al del elemento que sigue al fieldset.
 *    Dos márgenes contiguos no se suman.
 *    Ver regla: .fieldset > :last-child
 */
.fieldset {
  margin-right: 0;
  margin-bottom: var(--rhythm); /* [1] */
  margin-left: 0;
}

ul.fieldset {
  padding-right: 0;
  padding-left: 0;
}

@media (max-width: 767px) {
  .fieldset {
    width: 100%;
  }
}

/**
 * 1. Ocultar los field vacios para que no ocupen espacio blanco.
 *    Pensado para el caso de field dinamicos que rellenan su input
 *    y label con AJAX.
 * 2. Se deja comentado de momento por problemas con IE11 (repaint bug)
 */

/* .fieldset:empty {
  display: none; /* [1, 2] *
} */

/**
 * El selector se duplica para ganar en especificidad a una apaño para los
 * legend mal utilizados de la sede presente en:
 * pages/_sede.css => .main--sede legend
 */
.fieldset__legend.fieldset__legend {
  display: block;
  margin: 0 0 calc(var(--rhythm) * .2);
  color: var(--color-text);
  font-family: var(--font-family-base-semi);
  font-weight: normal;
}

.entorno-turismo .fieldset__legend.fieldset__legend {
  color: var(--color-text-high-contrast);
}

/**
 * 1. El último elemento no debe tener margen inferior.
 *    Ver regla: .fieldset
 */
.fieldset > :last-child {
  margin-bottom: 0; /* [1] */
}

/*
@styleguide

## Fieldset tipo: En linea

Se debe añadir la clase modificador `fieldset--inline`.

```html
// etiqueta fieldset (campos relacionados) o div (campos no relacionados)
<fieldset class="fieldset fieldset--inline">
  <div class="field">...</div>
  <div class="field">...</div>
  ...
</fieldset>
```

<div class="psg-responsive-sample">
  <fieldset class="fieldset fieldset--inline">
    <div class="field field--text">
      <label class="field__label" for="fieldset-inline-sample-01">Label</label>
      <input class="field__input" id="fieldset-inline-sample-01" placeholder="Escribe aquí" type="text">
    </div>
    <div class="field field--text">
      <label class="field__label" for="fieldset-inline-sample-02">Label</label>
      <input class="field__input" id="fieldset-inline-sample-02" placeholder="Escribe aquí" type="text">
    </div>
    <div class="field field--select">
        <label class="field__label" for="fieldset-inline-sample-03">Lista</label>
        <select class="field__select" id="fieldset-inline-sample-03">
          <option value=""></option>
          <option value="1">Opción 1</option>
          <option value="2">Opción 2</option>
          <option value="3">Opción 3</option>
        </select>
    </div>
  </fieldset>
  <div class="fieldset fieldset--inline">
    <div class="field field--text">
      <label class="field__label" for="fieldset-inline-sample-01">Label</label>
      <input class="field__input" id="fieldset-inline-sample-01" placeholder="Escribe aquí" type="text">
    </div>
    <input type="reset" class="btn" value="boton 1">
    <input type="submit" class="btn" value="boton 2">
    <a href="#">Volver</a>
  </div>
</div>
*/
@media (min-width: 768px) {
  .fieldset--inline {
    margin-bottom: 0;
    letter-spacing: -1px;
  }
  .fieldset--inline > .field,
  .fieldset--inline > .btn {
    display: inline-block;
    margin-right: 1em;
    letter-spacing: normal;
    white-space: normal;
  }
  .fieldset--inline > a,
  .fieldset--inline > .btn {
    display: inline-block;
    margin-bottom: var(--rhythm);
    line-height: 1.667;
  }

  /**
   * 1. Al ser en línea se debe recumerar el margen del último elemento
   *    para una correcta alineación vertical
   *    Ver regla: .fieldset
   */
  .fieldset--inline.fieldset--inline > :last-child {
    margin-right: 0;
    margin-bottom: var(--rhythm); /* [1] */
  }
}

/*
@styleguide
## Fieldset tipo: Rango de fechas

Se debe añadir la clase modificador `fieldset--daterange`.

```html
// siempre fieldset (campos relacionados)
<fieldset class="fieldset fieldset--daterange">
  <div class="field field--date">
    <label class="field__label" for="[input1-id]">Desde</label>
    <input class="field__input" id="[input1-id]" placeholder="Desde" type="date">
  </div>
  <div class="field field--date">
    <label class="field__label" for="[input2-id]">Hasta</label>
    <input class="field__input" id="[input2-id]" placeholder="Hasta" type="date">
  </div>
</fieldset>
```

<div class="psg-responsive-sample">
  <fieldset class="fieldset fieldset--daterange">
    <div class="field field--date">
      <label class="field__label" for="datefilter-sample-input-01">Desde</label>
      <input class="field__input" id="datefilter-sample-input-01" placeholder="Desde" type="date">
    </div>
    <div class="field field--date">
      <label class="field__label" for="datefilter-sample-input-02">Hasta</label>
      <input class="field__input" id="datefilter-sample-input-02" placeholder="Hasta" type="date">
    </div>
  </fieldset>
</div>
*/

.fieldset--daterange {
  font-size: 1px;
  letter-spacing: -1px;
}

.fieldset--daterange > .field {
  display: inline-block;
  width: var(--input-date-width); /* 7.6em; */
  margin-right: 4%;
  letter-spacing: normal;
  white-space: normal;
}

.fieldset--daterange > .field--has-datepicker {
  width: calc(var(--input-date-width) + 2.2em);
}

.entorno-turismo .fieldset--daterange > .field {
  width: var(--input-turismo-date-width); /* 7.6em; */
}

.entorno-turismo .fieldset--daterange > .field--has-datepicker {
  width: calc(var(--input-turismo-date-width) + 2.2em);
}

@media (min-width: 380px) and (max-width: 767px) {
  .fieldset--daterange > .field {
    width: 48%;
    margin-right: 4%;
  }
  .entorno-turismo .fieldset--daterange > .field {
    width: 45%;
  }
  .fieldset--daterange > :last-child {
    margin-right: 0;
  }
}

/* @media (min-width: 768px) {
  .no-flexbox .fieldset--daterange > .field {
    margin-right: 1em;
  }
} */

/*
@styleguide

## Fieldset tipo: Set de checkboxes

Se debe añadir la clase modificador `fieldset--checkboxset`.

```html
// siempre fieldset (campos relacionados)
<fieldset class="fieldset fieldset--checkboxset">
  <div class="field field--checkbox">...</div>
  <div class="field field--checkbox">...</div>
  ...
</fieldset>
```

<div class="psg-responsive-sample">
  <fieldset class="fieldset fieldset--checkboxset">
    <legend class="fieldset__legend">Opciones disponibles</legend>
    <div class="field field--checkbox">
      <input class="field__checkbox" id="checkboxset-sample-01" type="checkbox">
      <label class="field__label" for="checkboxset-sample-01">Primera opción</label>
    </div>
    <div class="field field--checkbox">
      <input class="field__checkbox" id="checkboxset-sample-02" type="checkbox">
      <label class="field__label" for="checkboxset-sample-02">Segunda opción</label>
    </div>
    <div class="field field--checkbox">
      <input class="field__checkbox" id="checkboxset-sample-03" type="checkbox">
      <label class="field__label" for="checkboxset-sample-03">Tercera opción</label>
    </div>
    <div class="field field--checkbox">
      <input class="field__checkbox" id="checkboxset-sample-04" type="checkbox">
      <label class="field__label" for="checkboxset-sample-04">Cuarta opción</label>
    </div>
    <div class="field field--checkbox">
      <input class="field__checkbox" id="checkboxset-sample-05" type="checkbox">
      <label class="field__label" for="checkboxset-sample-05">Quinta opción</label>
    </div>
  </fieldset>
</div>

## Fieldset tipo: Set de radio buttons

Se debe añadir la clase modificador `fieldset--radioset`.

```html
// siempre fieldset (campos relacionados)
<fieldset class="fieldset fieldset--radioset">
  <div class="field field--radio">...</div>
  <div class="field field--radio">...</div>
  ...
</fieldset>
```

<div class="psg-responsive-sample">
  <fieldset class="fieldset fieldset--radioset">
    <div class="field field--radio">
      <input class="field__radio" type="radio" id="radioset-sample-01" name="radioset-sample">
      <label class="field__label" for="radioset-sample-01">Primera opción</label>
    </div>
    <div class="field field--radio">
      <input class="field__radio" type="radio" id="radioset-sample-02" name="radioset-sample">
      <label class="field__label" for="radioset-sample-02">Segunda opción</label>
    </div>
    <div class="field field--radio">
      <input class="field__radio" type="radio" id="radioset-sample-03" name="radioset-sample">
      <label class="field__label" for="radioset-sample-03">Tercera opción</label>
    </div>
    <div class="field field--radio">
      <input class="field__radio" type="radio" id="radioset-sample-04" name="radioset-sample">
      <label class="field__label" for="radioset-sample-04">Cuarta opción</label>
    </div>
    <div class="field field--radio">
      <input class="field__radio" type="radio" id="radioset-sample-05" name="radioset-sample">
      <label class="field__label" for="radioset-sample-05">Quinta opción</label>
    </div>
  </fieldset>
</div>
*/
.fieldset--checkboxset > .field:not(:last-child),
.fieldset--radioset > .field:not(:last-child) {
  margin-bottom: calc(var(--rhythm) * .5);
}

.fieldset--segmented {
  display: flex;
  width: fit-content;
  padding: .2em;
  border: 2px solid #000;
  transition: all .3s ease;
}

@media (max-width: 768px) {
  .fieldset--segmented {
    width: 100%;
  }
}

.fieldset--segmented input[type=radio] {
  display: none;
}

.fieldset--segmented .field {
  margin-bottom: 0;
}

.fieldset--segmented input[type=radio] + label {
  display: flex;
  min-height: 2.625rem;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0 .556em;
  margin: 0;
  background-color: var(--background-color);
  color: var(--color-text);
  cursor: pointer;
  transition: inherit;
  white-space: nowrap;
}

.fieldset--segmented input[type=radio]:checked + label {
  padding-right: calc(.556em + .5em);
  padding-left: 50px;
  border-right: 0;
  background-color: var(--background-color-alt-darker);
  pointer-events: none;
}

.fieldset--segmented input[type=radio]:checked + label::before {
  position: absolute;
  left: 0;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: .5em;
  background-color: #000;
  content: "";
  mask-image: url(../img/svg/sprite.svg#icon-check-view);
  mask-position: top;
}

/* .fieldset--segmented .field:not(:last-child) {
  border-right: 1px solid #000;
}

.fieldset--segmented .field:has(> input[type=radio]:checked) {
  border-right: 0;
} */

.fieldset--segmented input[type=radio] + label:hover {
  background-color: #f4f8fa;
  color: var(--color-primario);
  text-decoration: none;
}

.fieldset--segmented label:not(:last-child) {
  border-right: 1px solid #000;
}
