/* ==================================================================== *\
 *                                                                      *
 *  GRID                                                                *
 *                                                                      *
 *  ------------------------------------------------------------------  *
 *  /layout/_grid.css                                                   *
 *  ------------------------------------------------------------------  *
 *  Estilos que definen el posicionamiento de la estrutura del sitio    *
\* ==================================================================== */

/* ------------------------------------------------------------------ *\
   *  Estructura principal                                              *
  \* ------------------------------------------------------------------ */

/**
 * 1. Se añade div al selector para distinguirlo de otros usos de even.
 *    Por ejemplo: tr.even que hay en las tablas
 */

/* div.even,
section.even {
  background-color: var(--background-color-alt-darker);
} */

.container {
  width: 100%;
  min-width: 319px;
  max-width: none;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
}

.container.content {
  width: 100%;
  min-width: 319px;
  max-width: calc(var(--readable-area) + (var(--readable-padding-side) * 2));
  padding-right: var(--readable-padding-side);
  padding-left: var(--readable-padding-side);
}

.contents > .even .container {
  padding-right: var(--readable-padding-side);
  padding-left: var(--readable-padding-side);
}

@media (min-width: 768px) and (max-width: 991px) {
  .container {
    width: 100%; /* IE8 */
    max-width: 100%;
  }
  .container.content {
    width: 100%; /* IE8 */

    --readable-area: 920px;
    --readable-padding-side: 2em;
  }
  .contents > .even .container {
    width: 100%; /* IE8 */

    --readable-area: 920px;
    --readable-padding-side: 2em;
    max-width: calc(var(--readable-area) + (var(--readable-padding-side) * 2));
  }
}

@media (min-width: 992px) and (max-width: 1420px) {
  .container {
    width: 100%; /* IE8 */
    max-width: 100%;
  }
  .container.content {
    width: 100%; /* IE8 */

    --readable-area: 1220px;
    --readable-padding-side: 2em;
  }
  .contents > .even .container {
    width: 100%; /* IE8 */

    --readable-area: 1220px;
    --readable-padding-side: 2em;
    max-width: calc(var(--readable-area) + (var(--readable-padding-side) * 2));
  }
}

@media (min-width: 1420px) {
  .container {
    width: 100%; /* IE8 */
    max-width: 1920px;
  }
  .container.content {
    width: 100%; /* IE8 */

    --readable-area: 1420px;
    --readable-padding-side: 2em;
  }
  .contents > .even .container {
    width: 100%; /* IE8 */

    --readable-area: 1420px;
    --readable-padding-side: 2em;
    max-width: calc(var(--readable-area) + (var(--readable-padding-side) * 2));
  }

  /* Centrar el banner */
  .main--www .even > .container .rowflex--center {
    justify-content: center;
  }
}

.main-header > div {
  padding: 0;
}

.main-content {
  margin-top: 1rem;
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .main-content {
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
}
