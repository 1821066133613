/*
@styleguide

@title Cards: Card (beta)

Una Card es un componente cuyo objetivo es el de exhibir una información breve
de forma destacada y servir de punto de entrada a una determinada url.

Se compone siempre de:

- una imagen de cabecera (480px x 213px)
- un contenido compuesto de
  * un título
  * una breve descripción (opcional).

A tener en cuenta que el titulo y la descripción están limitados a una linea
para controlar que todas miden igual. Para ello se recomienda que, al utilizar
varias seguidas, o todas o ninguna tengan la descripción opcional.

```html
<article class="card">
  <a class="card__block-link" href="[url del evento]">
    <div class="card__image">
       <img src="..." alt="...">
    </div>
    <div class="card__content">
      // Titulo del evento (h3, h4, h5, h6 segun corresponda)
      <hX class="card__title">[titulo de la tarjeta]</hX>
      // Descripcion corta (opcional)
      <p>[descripcion corta]</p>
    </div>

  </a>
</article>
```

<div class="psg-responsive-sample">
  <section class="container">
    <ul class="card-collection has-2-cols&#64;sm has-3-cols&#64;md">
      <li class="card-collection__item">
        <article class="card">
          <a class="card__block-link" href="/wb021/was/we001Action.do?idioma=es&amp;aplicacion=wb021&amp;tabla=contenido&amp;uid=u65cf7bdb_13c1dc36016__7fbd" title="leer más (El secreto de los murales)">
            <div class="card__image">
              <img src="https://www.vitoria-gasteiz.org/wb021/http/img/gestor/13/57/gi_41357.jpg" alt="Murales">
            </div>
            <div class="card__content">
              <h3 class="card__title">El secreto de los murales</h3>
              <p>Descúbrelos en esta nueva ruta</p>
            </div>
          </a>
        </article>
      </li>
      <li class="card-collection__item">
        <article class="card">
          <a class="card__block-link" href="/wb021/was/we001Action.do?idioma=es&amp;aplicacion=wb021&amp;tabla=contenido&amp;uid=u65cf7bdb_13c1dc36016__7fbd" title="leer más (El secreto de los murales)">
            <div class="card__image">
              <img src="https://www.vitoria-gasteiz.org/wb021/http/img/gestor/13/57/gi_41357.jpg" alt="Murales">
            </div>
            <div class="card__content">
              <h3 class="card__title">El secreto de los murales</h3>
              <p>Descúbrelos en esta nueva ruta</p>
            </div>
          </a>
        </article>
      </li>
    </ul>
  </section>
</div>
*/

/**
 * 1. Basado en que las dimensiones de imágen son 480px x 213px
 * 2. Se estaban limitando en móvil sin tener imagen, de momento lo quito.
 */
.card {
  display: block;

  /* max-width: 32rem; */

  /* [1] */
  max-width: none; /* [2] */
  padding: 0;
  margin: 0;
  background-color: var(--background-color);
  color: var(--color-text-high-contrast);
  text-align: left;
}

.card__block-link {
  display: block;

  /* border: 2px solid var(--background-color-alt-darker); */
  color: currentColor;
}

.card__block-link:active {
  background-color: transparent;
}

.card__block-link:hover {
  color: var(--color-link--hover);

  /* border-color: var(--color-link); */
  text-decoration: none;
}

.card__block-link:hover .card__title {
  text-decoration: underline;
}

.card__block-link:focus {
  outline-offset: 0;
  outline-width: 4px;
}

/*
 * Ocultar los svg de external link que
 * esten dentro de las cards
\* ------------------------------------------------------------------ */
.card__block-link .svg__external {
  position: absolute !important;
  display: inline;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.card__image {
  width: 100%;
  background-color: var(--background-color-alt-darker);
}

.card__image > img,
.card__image > picture > img {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.entorno-congresos .card__image {
  display: flex;
  width: 100%;
  background-color: #fff;
}

.entorno-congresos .card__image > img,
.entorno-congresos .card__image > picture > img {
  flex-grow: 1;
}

.card__content {
  display: block;
  padding: var(--gutter-interior-aside);
  margin: 0;
  font-family: var(--font-family-base);
  font-size: var(--font-size-18px);
  line-height: var(--rhythm);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .card__content {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .card__content {
    font-size: var(--font-size-24px);
  }
}

.entorno-turismo .card__content {
  padding: var(--gutter-interior-aside);
  margin: 0;
}

/* @media (min-width: 768px) {
  .card__content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .card__content > h1,
  .card__content > h2,
  .card__content > h3,
  .card__content > h4,
  .card__content > h5,
  .card__content > p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
} */

.card__content :last-child {
  margin-bottom: 0;
}

.card__title {
  display: block;
  margin: 0 0 .2em 0;
  color: currentColor;
  font-family: var(--font-family-base-semi);
}

/**
 * CARD con categoria
 */
.card__category {
  overflow: hidden;
  padding: 0;
  margin: var(--gutter-interior) var(--gutter-interior) .2em;
  color: var(--color-text-high-contrast);
  font-family: var(--font-family-alt);
  text-transform: uppercase;
  white-space: nowrap;
}

.card__category::after {
  display: inline-block;
  width: 100%;
  height: .8em;
  margin: 0 0 .2em 10px;
  background-color: var(--color-primario);
  content: "";
  vertical-align: middle;
}

.card__category + .card__image {
  padding: 0 var(--gutter-interior);
}
