/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE:   FIGURE                                               *
 *                                                                      *
 *  ------------------------------------------------------------------- *
 *  /components/_read-more.css                                          *
 *  ------------------------------------------------------------------- *
 *  Tamaño de fuente base: heredado                                     *
 * -------------------------------------------------------------------- *
 *  DOM:                                                                *
 *                                                                      *
 *    input.read-more-state type="radio"                             *
 *      .read-more-wrap      // visible                                 *
 *      .read-more-target    //oculto                                   *
 *    label.read-more-trigger  data-show-more data-show-less            *
\* ==================================================================== */

input[type=radio].read-more__state,
.read-more__target {
  display: none;
}

.read-more__target {
  transition: .25s ease;
}

input[type=radio].read-more__state:checked ~ .read-more__wrap .read-more__target {
  /* max-height: 999em; */
  display: block;
  opacity: 1;
}

input[type=radio].read-more__state:checked ~ .read-more__wrap li.read-more__target {
  display: list-item;
}

/* input[type=radio].read-more-state ~ .read-more-trigger::before {
  content: attr(data-show-more);
} */

input[type=radio].read-more__state:checked ~ .read-more__trigger {
  display: none;
}

.read-more__trigger {
  display: inline-flex;
}
