.entorno-teatro .odd {
  background-color: var(--color-entorno-teatro);
}

.entorno-teatro .odd h2 {
  color: #fff;
}

.entorno-teatro .main-menu:not(.main-menu--smartphone-utils) .main-menu__item-action:hover,
.entorno-teatro .main-menu:not(.main-menu--smartphone-utils) .main-menu__item-action[aria-expanded=true],
.entorno-teatro .calendarioagenda .clickable:hover,
.entorno-teatro .calendarioagenda .clickable.seleccionado {
  background-color: var(--color-link-alt);
}

.entorno-teatro .main-footer a:hover {
  color: var(--color-link-alt);
}

/* -------------------------------------------------------------------- *\
 *  Contenido Card                                                      *
\* -------------------------------------------------------------------- */

/* .entorno-teatro .contenido-card {
  background: transparent;
}

.entorno-teatro .contenido-card__text {
  margin-top: 5px;

  color: var(--color-text-inverse);
  background-color: #000;
}

.entorno-teatro .contenido-card__text-desc {
  color: #c5b1a2;
} */

.entorno-teatro .contenido-card--mini {
  border: none;
  border-top: 10px solid #000;
  background-color: var(--background-color-alt-darker--teatro);
  color: var(--color-text-high-contrast);
}

.entorno-teatro .contenido-card--mini .contenido-card__text {
  background-color: transparent;
}

/* -------------------------------------------------------------------- *\
 *  Event Card                                                          *
\* -------------------------------------------------------------------- */
.entorno-teatro .card-collection__item {
  max-width: 450px;
  border: 0;
  background-color: #000;
}

.entorno-teatro .card-collection__item.eventCalendar-calendar-wrap {
  background-color: var(--background-color-alt-darker--teatro);
}

.entorno-teatro .card {
  background-color: unset;
}

.entorno-teatro .card__image {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  width: unset;
  margin-right: 0;
  background-color: unset;
}

.entorno-teatro .contenido-card.contenido-card--mini {
  margin-bottom: 0;
}

.entorno-teatro .card__block-link {
  border: none;
  color: var(--color-text-inverse);
}

.entorno-teatro .card-event__link:hover {
  border-color: var(--color-entorno-teatro) !important;
}

.entorno-teatro .card__content {
  color: var(--background-color-alt-darker--teatro);
}

@media (min-width: 768px) {
  .entorno-teatro .card__content {
    min-height: 3.62em;
  }
}

.entorno-teatro .card__title {
  color: var(--color-text-inverse);
}

.event-card .card__block-link {
  padding: 1rem;
}

.entorno-teatro .event-card .card__title {
  padding-top: 1rem;
}

.entorno-teatro .event-card .card__image::before {
  display: none;
}

.entorno-teatro .event-card .card__image.not-found {
  height: 18rem;
  background-image: url("../img/teatro/logo.svg");
  background-position: 79% 50%;
  background-repeat: no-repeat;
  background-size: 60%;
}

.entorno-teatro .event-card .card__meta {
  top: 2rem;
  right: auto;
  left: 1rem;
}

.entorno-teatro .event-card__time > :nth-child(n) {
  background-color: var(--color-entorno-teatro);
}

.entorno-teatro .event-card .card__meta .event-card__time .event-card__time-date {
  color: var(--background-color-alt-darker--teatro);
}

.entorno-teatro .event-card .card__meta .event-card__time.izq .event-card__time-day {
  align-self: flex-end;
}

.entorno-teatro .event-card .card__meta .event-card__time.der .event-card__time-day {
  align-self: baseline;
}

.entorno-teatro .event-card .card__meta .event-card__time > :nth-child(3) {
  padding-bottom: .5em;
}

.entorno-teatro .event-card .card__meta .event-card__time-hour {
  padding-bottom: 0;
  background-color: var(--color-text-high-contrast);
}

/* .entorno-teatro .event-card .card__meta.index .event-card__time.izq,
.entorno-teatro .event-card .card__meta .event-card__time.izq .event-card__time-date {
  align-self: flex-start;
  padding-right: 0;
  padding-left: .5rem;
}

.entorno-teatro .event-card .card__meta.index .event-card__time.der,
.entorno-teatro .event-card .card__meta .event-card__time.der .event-card__time-date {
  align-self: flex-end;
  padding-right: .5rem;
  padding-left: 0;
} */

/* -------------------------------------------------------------------- *\
 *  Event Calendar                                                      *
\* -------------------------------------------------------------------- */

/* .entorno-teatro .eventCalendar-calendar {
  padding-bottom: 3rem;
} */

.entorno-teatro .eventCalendar-calendar-wrap .event-card {
  margin: 0;
  background-color: var(--background-color-alt-darker--teatro);
}

@media (max-width: 767px) {
  .entorno-teatro .eventCalendar-selectedDay-info {
    position: relative;
  }
}

.entorno-teatro .eventCalendar-selectedDay-info > span {
  padding: .3em 1em;
}

.entorno-teatro .eventCalendar-calendar th {
  color: var(--color-entorno-teatro);
}

.entorno-teatro .eventCalendar-calendar td {
  color: var(--color-text);
}

.entorno-teatro .eventCalendar-calendar td.clickable {
  background-color: var(--color-entorno-teatro);
  color: var(--color-text-inverse);
}

.entorno-teatro .separator {
  color: var(--color-text-inverse);
  font-size: var(--font-size-39px);
}

.entorno-teatro .card__meta.index .square__content {
  display: flex;
  align-items: center;
  background-color: var(--color-entorno-teatro);
}

/* -------------------------------------------------------------------- *\
 *  Titulo Agenda                                                             *
\* -------------------------------------------------------------------- */

.entorno-teatro .calendarioagenda .clickable a:hover {
  color: var(--background-color) !important;
}

.title-agenda,
.title-agenda:hover {
  background-image: none !important;
  text-decoration: none !important;
}

.entorno-teatro div:last-child section.container {
  padding-bottom: 5rem;
}

.entorno-teatro .card__meta.index.square::before {
  display: none;
}

.entorno-teatro .contenido-card.contenido-card--mini:hover {
  border-color: var(--color-text-inverse);
  color: currentColor;
}

@media (min-width: 1200px) and (max-width: 1419px) {
  .entorno-teatro .main-menu__item:first-child:nth-last-child(1) {
    width: 100%;
  }
  .entorno-teatro .main-menu__item:first-child:nth-last-child(2),
  .entorno-teatro .main-menu__item:first-child:nth-last-child(2) ~ .main-menu__item {
    width: 50%;
  }
  .entorno-teatro .main-menu__item:first-child:nth-last-child(3),
  .entorno-teatro .main-menu__item:first-child:nth-last-child(3) ~ .main-menu__item {
    width: 33.3333%;
  }
  .entorno-teatro .main-menu__item:first-child:nth-last-child(4),
  .entorno-teatro .main-menu__item:first-child:nth-last-child(4) ~ .main-menu__item {
    width: 25%;
  }
  .entorno-teatro .main-menu__item:first-child:nth-last-child(5),
  .entorno-teatro .main-menu__item:first-child:nth-last-child(5) ~ .main-menu__item {
    width: 20%;
  }
  .entorno-teatro .main-menu__item:first-child:nth-last-child(6),
  .entorno-teatro .main-menu__item:first-child:nth-last-child(6) ~ .main-menu__item {
    width: 16.6666%;
  }
  .entorno-teatro .main-menu__item-action > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.entorno-teatro .header-main__logo svg {
  max-width: 10em;
}

@media (max-width: 768px) {
  .entorno-teatro .header-main__logo svg {
    max-width: 7em;
  }
}

.entorno-teatro .header-main__logo img,
.entorno-teatro .header-main__logo svg {
  max-height: 5em;
}
