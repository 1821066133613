/* Area Defecto */
.area-related--area--0,
.area-related--area--102,
.area-related--area--218,
.area-related--area--235,
.area-related--area--0 *,
.area-related--area--102 *,
.area-related--area--218 *,
.area-related--area--235 *,
.labels,
.labels.label-0,
.labels.label-102,
.labels.label-218,
.labels.label-235 {
  border-color: #000;
  color: #000;
}

/* Area Trasporte y Mapas ID AREA 11,1 */
.area-related--area--1,
.area-related--area--11,
.area-related--area--1 *,
.area-related--area--11 *,
.labels.label-1,
.labels.label-11 {
  border-color: #238551;
  color: #238551;
}

/* Area Comercio y desarrollo sostenible ID AREA 21,76,166,69,68,84 */
.area-related--area--21,
.area-related--area--68,
.area-related--area--69,
.area-related--area--76,
.area-related--area--84,
.area-related--area--166,
.area-related--area--21 *,
.area-related--area--68 *,
.area-related--area--69 *,
.area-related--area--76 *,
.area-related--area--84 *,
.area-related--area--166 *,
.labels.label-21,
.labels.label-68,
.labels.label-69,
.labels.label-76,
.labels.label-84,
.labels.label-166 {
  border-color: #0e74e0;
  color: #0e74e0;
}

/* Area Trámites y gobierno local ID AREA 47,27,63,163 */
.area-related--area--27,
.area-related--area--47,
.area-related--area--63,
.area-related--area--163,
.area-related--area--27 *,
.area-related--area--47 *,
.area-related--area--63 *,
.area-related--area--163 *,
.labels.label-27,
.labels.label-47,
.labels.label-63,
.labels.label-163 {
  border-color: #661989;
  color: #661989;
}

/* Area Participa con tu propuesta ID AREA 102 */
.area-related--area--102,
.area-related--area--102 *,
.labels.label-102 {
  border-color: #d63900;
  color: #d63900;
}

/* Area Personas y Colectivos ID AREA 88,43 */
.area-related--area--43,
.area-related--area--88,
.area-related--area--43 *,
.area-related--area--88 *,
.labels.label-43,
.labels.label-88 {
  border-color: #967103;
  color: #967103;
}

/* Area Actividades y equipamientos ID AREA 34,38,58 */
.area-related--area--34,
.area-related--area--38,
.area-related--area--58,
.area-related--area--34 *,
.area-related--area--38 *,
.area-related--area--58 *,
.labels.label-34,
.labels.label-38,
.labels.label-58 {
  border-color: #d90d7a;
  color: #d90d7a;
}

.area-related-group,
.area-related-group > ul {
  --rhythm-increase: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  /* margin: fluidmargin(var(--rhythm-increase, 1)); */
}

.area-related-group > ul {
  flex-basis: 100%;
  padding-left: 0;
  margin: 0;
}

.area-related {
  display: flex;
  flex-basis: 100%;
  margin-top: 0;
  margin-bottom: var(--rhythm);
  font-family: var(--font-family-base-semi);
  font-size: inherit;
  line-height: inherit;
}

@media (min-width: 768px) {
  .area-related {
    flex-basis: 49%;
  }
  .area-related-group.has-1-cols\@sm .area-related {
    flex-basis: 100%;
  }
  .area-related-group.has-2-cols\@sm .area-related {
    flex-basis: 49%;
  }
  .area-related-group.has-3-cols\@sm .area-related {
    flex-basis: 32%;
  }
}

@media (min-width: 992px) {
  .area-related {
    flex-basis: 32%;
  }
  .area-related-group.has-2-cols\@md .area-related {
    flex-basis: 49%;
  }
  .area-related-group.has-3-cols\@md .area-related {
    flex-basis: 32%;
  }
  .area-related-group.has-4-cols\@md .area-related {
    flex-basis: 24%;
  }
}

@media (min-width: 1420px) {
  .area-related {
    flex-basis: 32%;
  }
  .area-related-group.has-2-cols\@lg .area-related {
    flex-basis: 49%;
  }
  .area-related-group.has-3-cols\@lg .area-related {
    flex-basis: 32%;
  }
  .area-related-group.has-4-cols\@lg .area-related {
    flex-basis: 24%;
  }
}

.area-related * {
  color: currentColor;
}

a.area-related,
.area-related > a {
  display: flex;
  flex-direction: column;
  padding: 1em;
  border-width: 4px 2px 2px;
  border-style: solid;
  border-right-color: #e7e7dd;
  border-bottom-color: #e7e7dd;
  border-left-color: #e7e7dd;
  background-color: var(--background-color);
  color: #000;
}

.area-related > a {
  flex-grow: 1;
}

a.area-related:focus,
a.area-related:hover,
.area-related > a:focus,
.area-related > a:hover {
  border-color: var(--color-link);
  text-decoration: none;
}

.area-related :last-child {
  margin-bottom: 0;
}

.box-container {
  font-family: var(--font-family-alt);
  font-size: var(--font-size-21px);
}

.rowflex.diaryGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: unset;

  /* justify-content: center; */
}

.grid.diaryGroup article {
  position: relative;
}

.grid.diaryGroup article::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 45px;
  height: 12px;
  background-color: #000;
  content: "";
}

@media (min-width: 600px) {
  .rowflex.diaryGroup article {
    width: 48%;
  }
}

@media (min-width: 1420px) {
  .rowflex.diaryGroup article {
    width: 24%;
  }
}

@media (max-width: 768px) {
  .diaryGroup .labels,
  .box-container .labels {
    border-top-width: 8px !important;
  }
  .grid.diaryGroup article::before {
    height: 8px;
  }
}

.diaryGroup .labels,
.box-container .labels {
  display: flex;
  overflow: hidden;
  height: 100%;
  flex-direction: column;
  padding: 0 2.813rem;
  border-top-width: 12px;
  border-top-style: solid;

  /* border-right-color: #e7e7dd;
  border-bottom-color: #e7e7dd;
  border-left-color: #e7e7dd; */
  border-top-color: var(--color-secundario);
  background-color: var(--background-color);
}

@media (max-width: 660px) {
  .diaryGroup .labels,
  .box-container .labels {
    padding: 0 1.563rem;
  }
  .grid.diaryGroup article::before {
    width: 25px;
  }
}

.box-container .labels:focus,
.box-container .labels:hover {
  border-color: var(--color-link);
  text-decoration: none;
}

/* Titulo del enlace */
.diaryGroup .labels h1,
.diaryGroup .labels h2,
.diaryGroup .labels h3,
.box-container .labels h1,
.box-container .labels h2,
.box-container .labels h3 {
  overflow: hidden;
  color: inherit;
  font-size: var(--font-size-18px);
  line-height: var(--rhythm);
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (min-width: 320px) and (max-width: 1419px) {
  .diaryGroup .labels h1,
  .diaryGroup .labels h2,
  .diaryGroup .labels h3,
  .box-container .labels h1,
  .box-container .labels h2,
  .box-container .labels h3 {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .diaryGroup .labels h1,
  .diaryGroup .labels h2,
  .diaryGroup .labels h3,
  .box-container .labels h1,
  .box-container .labels h2,
  .box-container .labels h3 {
    font-size: var(--font-size-24px);
  }
}

/* [0] ESTILOS JULIO 2021 */
.entorno-www .diaryGroup .labels h2,
.entorno-www .diaryGroup .labels h3 {
  overflow: hidden;
  margin: calc(var(--rhythm) * 1) 0 0;
  color: #000;
  font-family: var(--font-family-base);
  font-size: var(--font-size-18px);
  font-weight: normal;
  line-height: var(--rhythm);
  text-overflow: unset;
  white-space: initial;
}

@media (min-width: 320px) and (max-width: 1419px) {
  .entorno-www .diaryGroup .labels h2,
  .entorno-www .diaryGroup .labels h3 {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .entorno-www .diaryGroup .labels h2,
  .entorno-www .diaryGroup .labels h3 {
    font-size: var(--font-size-24px);
  }
}

.entorno-www .diaryGroup .labels:focus h2,
.entorno-www .diaryGroup .labels:hover h2,
.entorno-www .diaryGroup .labels:focus h3,
.entorno-www .diaryGroup .labels:hover h3 {
  color: var(--color-primario);
  text-decoration: underline;
}


/* descripcion del enlace */
.diaryGroup .labels p,
.box-container .labels p {
  margin: 0;
  color: var(--color-heading);
  font-size: var(--font-size-18px);
  line-height: var(--rhythm);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .entorno-www .diaryGroup .labels h2,
  .entorno-www .diaryGroup .labels h3 {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .entorno-www .diaryGroup .labels h2,
  .entorno-www .diaryGroup .labels h3 {
    font-size: var(--font-size-24px);
  }
}
