/* -------------------------------------------------------------------- *\
 *  Contenido Card                                                      *
\* -------------------------------------------------------------------- */
.entorno-congresos .card__category::after {
  background-color: var(--color-entorno-congresos);
}

/* .entorno-congresos .card__content {
  min-height: none;
} */

.entorno-congresos .contenido-card--mini .contenido-card__text {
  background-color: var(--color-entorno-congresos);
  color: var(--color-text-inverse);

  /* font-family: var(--font-family-congresos-heading); */
  font-size: var(--font-size-18px);
}

@media (min-width: 1200px) {
  .entorno-congresos .main--congresos .carousel {
    margin-top: 0;
  }
}

.entorno-congresos div.odd section.container {
  margin-top: 3rem;
}

.entorno-congresos div:last-child section.container {
  margin-bottom: 5rem;
}
