/* ------------------------------------------------------------------------- *\
 *  NAVEGACION PRINCIPAL:
 *    - Búsqueda (smartphone)
 *    - Menu principal
 *    - Menu de utilidades (smartphone)
\* ------------------------------------------------------------------------- */
.main-nav {
  z-index: 1010;
  clear: both;
  font-family: var(--font-family-alt);
  font-size: var(--font-size-18px);
}

.main-nav__search-form,
.main-nav-overlay {
  display: none;
}

@keyframes main-menu-show {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes main-menu-hide {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

@media (max-width: 1199px) {
  .main-nav {
    position: fixed;
    top: 0;
    right: 20%;
    bottom: 0;
    left: 0;
    background-color: white;
    opacity: 0;
    overflow-y: scroll;
  }
  .main-nav[hidden] {
    pointer-events: none;
  }
  .main-nav--visible {
    padding-bottom: var(--rhythm);
    animation: main-menu-show .4s ease-in-out 0s;
    opacity: 1;
    overflow-x: hidden;
  }
  .entorno-www .main-nav--visible {
    width: 100%;
  }
  .main-nav__search-form {
    display: block;
    margin: 1em var(--readable-padding-side) .5em;
  }
  body:not(.entorno-www) .main-nav-overlay--visible {
    position: fixed;
    z-index: 1009;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background-color: rgba(0, 0, 0, .73);
  }
  .entorno-www .main-nav-overlay--visible {
    display: block;
  }
  .main-nav-overflow--hidden {
    overflow-y: hidden;
  }
  .entorno-www .main-nav-overflow--hidden {
    width: 100%;
  }
  .main-nav-overlay-close {
    position: fixed;
    z-index: 1011;
    top: 1rem;
    right: 5%;
    display: block;
    margin-left: 15px;
  }
}

@media (min-width: 1199px) {
  .main-nav,
  .main-nav[hidden] {
    display: block;
    height: 100%;
  }
}

/* ------------------------------------------------------------------------- *\
 *  MENU PRINCIPAL: Sólo botones principales de la cabecera
\* ------------------------------------------------------------------------- */

.main-menu__item {
  position: relative;
  display: block;
  padding: 0;
  margin: 0 0 .7em;
  list-style: none;
}

.entorno-www .main-menu__item {
  border-style: none;
}

.entorno-teatro .main-menu__item,
.entorno-congresos .main-menu__item {
  border-width: 0 0 0 2px;
  border-style: solid;
  border-color: #fff;
}

@media (min-width: 1200px) {
  .entorno-turismo .main-menu {
    padding: 0;
    border-top: 10px solid;
    background-color: var(--background-turismo-menu-header-color);
    border-image-slice: 25;
    border-image-source: linear-gradient(#fff, #106e84);
  }
  .entorno-www .main-menu {
    z-index: 99;
    width: 100%;
    height: 100%;
    padding: 0;
    column-gap: 1rem;
  }
  .main-menu__item {
    padding: 0;
    border-left-width: 2px;
    margin: 0;
  }
  .entorno-teatro .main-menu__item:first-child,
  .entorno-congresos .main-menu__item:first-child {
    border-left-width: 0;
  }
  .entorno-turismo .main-menu__item {
    border-left: 5px solid var(--background-color);
  }
  .entorno-turismo .main-menu__item:first-child {
    border-left: 0;
  }
  .entorno-www .main-menu__item {
    z-index: 1010;
    border-width: 0;
  }
}

.main-menu__item-action {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--gutter-interior-aside);
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  margin: 0;
  background-color: transparent;
  color: #000;
  font-family: var(--font-family-alt-semi);
  font-size: var(--font-size-28px);
  font-weight: normal;
  letter-spacing: -.03em;
  line-height: 1.2em;
}

.entorno-www .main-menu__item-action {
  align-items: center;
  padding: 0 var(--gutter-interior);
  background-color: #fff;
  color: #000;
  font-family: var(--font-family-alt-semi);
  text-align: left;
  white-space: nowrap;
}

.entorno-turismo .main-menu__item-action {
  justify-content: flex-start;
  background-color: var(--background-turismo-menu-header-color);
  color: #000;
  font-family: var(--font-family-turismo-heading);
  font-size: var(--font-size-28px);
}

@media (min-width: 1200px) {
  .main-menu__item-action {
    font-family: var(--font-family-alt-semi);
  }
}

@media (min-width: 1200px) and (max-width: 1419px) {
  .main-menu__item-action {
    font-size: fluidfont(strip(var(--font-size-31px)));
  }
  .entorno-turismo .main-menu__item-action {
    font-size: fluidfont(strip(var(--font-size-28px)));
  }
}

@media (min-width: 1200px) and (max-width: 1680px) {
  .entorno-sede .main-menu__item-action {
    padding: 0 var(--gutter-interior);
  }
}

.main-menu--smartphone-utils .main-menu__item-action {
  padding: 0 var(--gutter-interior);
  background-color: transparent;
  font-family: var(--font-family-base);
  font-size: var(--font-size-24px);
}

.entorno-turismo .main-menu--smartphone-utils .main-menu__item-action {
  font-family: var(--font-family-turismo-heading);
}

@media (min-width: 1200px) and (max-width: 1199px) {
  .main-menu__item-action {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (max-width: 1200px) {
  .main-menu--smartphone-utils .main-menu__item-action:hover {
    background-color: transparent;
    color: var(--color-primario);
  }
  .entorno-www .main-menu--smartphone-utils .main-menu__item-action:hover svg use {
    fill: var(--color-primario);
  }
}

@media (min-width: 1200px) {
  .main-menu__item-action {
    flex-direction: row;
    justify-content: center;
    font-weight: normal;
    text-align: center;
  }
}

/* ------------------------------------------------------------------------- *\
 *  UTILIDADES EXTRA DEL MENU EN MÓVILES
\* ------------------------------------------------------------------------- */


/* ------------------------------------------------------------------------- *\
 *  MENU DESPLEGABLE
\* ------------------------------------------------------------------------- */
.dropdown {
  position: relative;
}

.dropdown-menu {
  position: relative;
  display: none;
  padding: .8em;
  margin: 0 0 1em;
  font-family: var(--font-family-base);
  font-size: var(--font-size-18px);
  line-height: var(--rhythm);
  list-style: none;
  overflow-y: hidden;
}

.entorno-turismo .dropdown-menu {
  font-family: var(--font-family-turismo-heading);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .dropdown-menu {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .dropdown-menu {
    font-size: var(--font-size-24px);
  }
}

.entorno-turismo .main-menu__item--turismo .dropdown-menu {
  font-family: var(--font-family-turismo-heading);
}

.entorno-www .main-menu__item--www .dropdown-menu {
  font-family: var(--font-family-base);
}

.dropdown-menu__item-break {
  list-style: none;
}

@media (max-width: 1200px) {
  .dropdown-menu__item-break {
    margin-top: var(--rhythm);
    margin-bottom: 0;
  }
  .dropdown-menu__item-break:not(:last-child)::before {
    display: block;
    width: 100%;
    height: 1px;
    margin-bottom: var(--rhythm);
    background-color: #000;
    content: "";
  }
  .entorno-teatro .dropdown-menu__item-break:not(:last-child)::before {
    background-color: var(--color-entorno-teatro);
  }
  .entorno-congresos .dropdown-menu__item-break:not(:last-child)::before {
    background-color: var(--color-entorno-congresos);
  }
}

@media (min-width: 1200px) {
  .dropdown-menu {
    position: absolute;
    z-index: 1000;
    top: 100%;
    display: none;
    width: 100%;
    min-width: max-content;
    background-color: #fff;
    float: left;
    opacity: 0;
    text-align: left;
    transition: .2s opacity ease-in;
  }
  .entorno-www .dropdown-menu,
  .entorno-teatro .dropdown-menu,
  .entorno-congresos .dropdown-menu {
    border-radius: 0;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  }
  .dropdown-menu.submenu {
    position: relative !important;
    display: flex;
    overflow: hidden;
    height: 0;
    padding: 0;
    margin: 0;
    font-family: var(--font-family-base);
  }
  .dropdown-menu.submenu[aria-hidden="true"] {
    display: none;
  }
  .dropdown-menu.submenu[aria-hidden="false"] {
    display: flex;
    height: auto;
    flex-direction: row;
    justify-content: center;
    padding: 2em;
  }
  .dropdown-menu.submenu .submenu__title {
    --font: minimalfont(strip(var(--font-size-48px)));
    --margin: factormargin(strip(var(--font-size-48px)));
    --line: 1.2em;
    width: 25%;
    padding: 0;
    margin: 0;
    margin-right: 3rem;
    font-size: var(--font);
    font-size: var(--font);
    font-weight: 600;
    line-height: var(--line);
    text-align: right;
  }
  .dropdown-menu.submenu .submenu__list {
    display: inherit;
    flex-grow: 1;
    padding-left: 3rem;
    border-left: 3px solid#000;
  }
  .dropdown-menu.submenu .submenu__list ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    column-gap: 2.5rem;
  }
  .dropdown-menu:not(.submenu) .submenu__list ul {
    padding: 0;
    margin-bottom: 0;
  }
  .dropdown-menu[aria-hidden="false"] {
    opacity: 1;
    transition: .1s opacity ease-out;
  }
  .main-menu__item:nth-child(n) .dropdown-menu {
    left: 0;
  }
  .main-menu__item:last-child .dropdown-menu {
    right: 0;
    left: unset;
  }
  .entorno-turismo .dropdown-menu {
    background-color: var(--background-turismo-color);
    color: var(--color-link-alt);
  }
  .entorno-www .main-menu__item--www .dropdown-menu {
    box-shadow: 0 5px 10px 0 rgb(0 0 0 / 50%);
  }
  .entorno-www .submenu__list .dropdown-menu__item-break {
    flex-basis: 100%;
    margin-bottom: 0;
    visibility: collapse;
  }
}

@media (min-width: 320px) and (max-width: 1419px) {
  .dropdown-menu.submenu .submenu__title {
    --font: fluidfont(strip(var(--font-size-48px)));
    --line: 1.2em;
    line-height: fluidline(strip(var(--line)), strip(var(--font-size-48px)));
  }
}

@media (min-width: 1024px) and (max-width: 1120px) {
  .dropdown-menu.submenu .submenu__list ul {
    flex-wrap: nowrap !important;
  }
}

@media (min-width: 1200px) and (max-width: 1419px) {
  .entorno-www .dropdown-menu.submenu[aria-hidden=false] {
    height: 22em;
  }
}

@media (min-width: 1420px) {
  .entorno-www .dropdown-menu.submenu[aria-hidden=false] {
    height: 22em;
  }
  .dropdown-menu.submenu .submenu__title {
    font-size: var(--font-size-48px);
  }
  .dropdown-menu.submenu .submenu__list ul {
    column-gap: 2.5rem;
  }
}

.open > .dropdown-menu {
  display: block;
}

.dropdown-menu--has-cols {
  padding: 0 4px;
  margin: 0;
}

@media (min-width: 1200px) {
  .dropdown-menu--has-cols {
    right: 0;
    left: 0;
    padding: var(--gutter-interior);
    border-width: 2px 0;
    border-style: solid;
    border-color: transparent;
  }
}

.dropdown-menu__item:last-child {
  margin-bottom: 0;
}

/* ------------------------------------------------------------------------- *\
 *  MODIFICADORES GENÉRICOS de colores de fuentes y bordes
\* ------------------------------------------------------------------------- */
.menu-border-color-one {
  border-color: #967103;
}

.menu-color-one {
  color: #967103;
}

.menu-border-color-two {
  border-color: #d90d7a;
}

.menu-color-two {
  color: #d90d7a;
}

.menu-border-color-three {
  border-color: #238551;
}

.menu-color-three {
  color: #238551;
}

.menu-border-color-four {
  border-color: #0e74e0;
}

.menu-color-four {
  color: #0e74e0;
}

.menu-border-color-five {
  border-color: #661989;
}

.menu-color-five {
  color: #661989;
}

.menu-border-color-six {
  border-color: #d63900;
}

.menu-color-six {
  color: #d63900;
}

/* ------------------------------------------------------------------------- *\
 *  PERSONALIZACIONES DE ENTORNO
\* ------------------------------------------------------------------------- */

.main-menu {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
}

@media (max-width: 1199px) {
  .entorno-www .main-nav .main-menu:first-of-type,
  .entorno-www .main-nav .main-nav__search-form {
    margin-top: calc(5rem + 1.5em);
  }
  .main-menu {
    flex-direction: column;
  }
  .dropdown-menu__item.dropdown-menu__item-back {
    font-weight: 600;
  }
}

@media (min-width: 1200px) {
  .entorno-www .main-menu__item-action > span > strong {
    display: block;
    font-weight: bold;
  }
  .entorno-www .dropdown-menu__group-item {
    margin-bottom: 0;
  }
  .entorno-www .dropdown-menu__group-heading {
    font-size: 1.38em;
  }
  .entorno-congresos .main-menu__item-action--congresos {
    display: flex;
    justify-content: center;
  }
  .entorno-congresos .main-menu__item-action {
    background-color: var(--color-entorno-congresos);
    color: #fff;
  }
  .entorno-teatro .main-menu__item-action {
    align-items: center;
    background-color: var(--color-entorno-teatro);
    color: #fff;
    white-space: nowrap;
  }
  .entorno-teatro .main-menu__item-action > span > strong {
    font-weight: normal;
  }
  .entorno-teatro .dropdown-menu__item:not(.dropdown-menu__group-item) {
    color: var(--color-entorno-teatro);
  }
  .dropdown-menu__item-back {
    display: none;
  }
}

/* ------------------------------------------------------------------------- *\
 *  NAVEGACIÓN PARA SMARTPHONE EN ENTORNO DE TURISMO:
\* ------------------------------------------------------------------------- */
@media (max-width: 1199px) {
  .field--search .field__input {
    border-width: 3px;
    border-color: var(--input-turismo-border-color);
  }
  .main-nav {
    overflow-x: hidden;
  }
  .main-menu,
  .main-menu__item {
    position: initial;
  }
  .main-menu {
    flex-direction: column;
    padding: 0 var(--readable-padding-side);
  }
  .entorno-www .main-menu:first-of-type::after {
    display: block;
    width: 100%;
    height: 1px;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    background-color: #000;
    content: "";
  }
  .dropdown-menu .submenu__title {
    display: none;
  }
  .main-menu__item-action {
    display: flex;
    width: 100%;
    min-height: 2.625rem;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  body:not(.entorno-sede) .main-menu:not(.main-menu--smartphone-utils) .main-menu__item-action::after,
  .main-menu .main-menu__item--lang .main-menu__item-action::after {
    display: inline-block;
    width: 35px;
    height: 35px;
    flex: 0 0 auto;
    align-self: center;
    margin-left: auto;
    background-color: #000;
    content: '';
    mask-image: url(../img/svg/sprite.svg#icon-arrow-right-view);
  }
  body:not(.entorno-sede) .main-menu:not(.main-menu--smartphone-utils) .main-menu__item-action:hover::after,
  .main-menu .main-menu__item--lang .main-menu__item-action:hover::after {
    background-color: #fff;
  }
  body.entorno-www .main-menu:not(.main-menu--smartphone-utils) .main-menu__item-action:hover::after,
  body.entorno-www .main-menu .main-menu__item--lang .main-menu__item-action:hover::after {
    background-color: #000;
  }
  body.entorno-teatro .main-menu:not(.main-menu--smartphone-utils) .main-menu__item-action::after,
  body.entorno-congresos .main-menu:not(.main-menu--smartphone-utils) .main-menu__item-action::after {
    background-color: #fff;
  }
  .dropdown {
    position: static;
  }

  /* ------------------------------------------------------------------------- *\
 *  NAVEGACIÓN PARA SMARTPHONE COMÚN PARA ENTORNOS
\* ------------------------------------------------------------------------- */
  .dropdown-menu {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: var(--background-color);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    transition: .6s;
    visibility: hidden;
  }
  .main-menu__item.open > .dropdown-menu {
    z-index: 1011;
    display: block;
    overflow-y: scroll;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    visibility: visible;
  }
  .dropdown-menu .dropdown-menu__item-back {
    display: flex;
    min-height: 2.5em;
    align-items: center;
    padding: var(--gutter-interior-aside);
    margin: 0 0 1em;
    background-color: #000;
    font-family: var(--font-family-alt-semi);
    font-size: var(--font-size-28px);
  }
  .entorno-www .dropdown-menu .dropdown-menu__item-back {
    position: sticky;
    top: 0;
    width: 100%;
    height: 5rem;
  }
  .entorno-turismo .dropdown-menu .dropdown-menu__item-back {
    font-family: var(--font-family-turismo-heading-bold);
  }
  .dropdown-menu .dropdown-menu__item-back .dropdown-menu__item-action::before {
    display: inline-block;
    width: 35px;
    height: 35px;
    flex: 0 1 auto;
    margin-right: .5em;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTIwIDExSDcuODNsNS41OS01LjU5TDEyIDRsLTggOCA4IDggMS40MS0xLjQxTDcuODMgMTNIMjB2LTJ6Ii8+PC9zdmc+");
    background-repeat: no-repeat;
    background-size: 35px;
    content: '';
  }
  .dropdown-menu .dropdown-menu__item-back .dropdown-menu__item-action {
    display: inline-flex;
    color: var(--color-text-inverse);
  }
  .dropdown-menu .submenu__list {
    padding: 0 var(--readable-padding-side);
  }
  .dropdown-menu .submenu__list ul {
    padding: 0 var(--gutter-interior);
  }
}

.dropdown-menu__item:not(.dropdown-menu__item-back) {
  font-size: var(--font-size-24px);
}

@media (min-width: 1200px) and (max-width: 1419px) {
  .dropdown-menu__item:not(.dropdown-menu__item-back) {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .dropdown-menu__item:not(.dropdown-menu__item-back) {
    font-size: var(--font-size-24px);
  }
}

@media (max-width: 1200px) {
  .entorno-teatro .main-menu:not(.main-menu--smartphone-utils) .main-menu__item-action {
    background-color: var(--color-entorno-teatro);
    color: var(--color-text-inverse);
    text-align: left;
  }
  .entorno-congresos .main-menu:not(.main-menu--smartphone-utils) .main-menu__item-action {
    background-color: var(--color-entorno-congresos);
    color: #fff;
  }
}

/* ------------------------------------------------------------------------- *\
 *  MENÚS ESTANDARIZADOS
\* ------------------------------------------------------------------------- */

.dropdown-menu__item {
  list-style: none;
}

.dropdown-menu__item-action {
  color: var(--color-link-alt);
}

.dropdown-menu__item-action:hover {
  color: var(--color-link-alt--hover);
}

.dropdown-menu__item-action[role=link] {
  cursor: pointer;
}

.dropdown-menu__item:not(.dropdown-menu__item-back) .dropdown-menu__item-action[role=link]:hover {
  text-decoration: underline;
}

.dropdown-menu__item-action.is-active {
  font-family: var(--font-family-alt);
  pointer-events: none;
}

.entorno-turismo .dropdown-menu__item-action.is-active {
  font-family: var(--font-family-turismo-heading-bold);
}

.main-menu__item-action:focus {
  box-shadow: none;
  outline: 1px;
}

.main-menu:not(.main-menu--smartphone-utils) .main-menu__item-action:hover,
.main-menu:not(.main-menu--smartphone-utils) .main-menu__item-action[aria-expanded=true],
.main-menu:is(.main-menu--smartphone-utils) .main-menu__item--lang .main-menu__item-action:hover {
  background-color: var(--color-primario);
  color: var(--color-text-inverse);
}

.entorno-www .main-menu:not(.main-menu--smartphone-utils) .main-menu__item-action:hover,
.entorno-www .main-menu:not(.main-menu--smartphone-utils) .main-menu__item-action[aria-expanded=true],
.entorno-www .main-menu:is(.main-menu--smartphone-utils) .main-menu__item--lang .main-menu__item-action:hover {
  background-color: var(--color-secundario);
  color: var(--color-text);
}

.entorno-sede .main-menu:not(.main-menu--smartphone-utils) .main-menu__item-action:hover {
  background-color: transparent;
  color: var(--color-primario);
}
