#sectBusqueda {
  display: block;
}

/**
* CARDS
* -------------------------------------- */

.event-list {
  padding: 0;
  margin: 0 0 var(--rhythm);
  list-style: none;
}

.event-list__section {
  margin: 0;
}

.event-list__section::before {
  display: block;
  border-top: 3px solid var(--color-secundario);
  margin-bottom: 0;
  content: '';
}

.entorno-turismo .event-list__section::before {
  border-top: 3px solid var(--color-turismo-secundario);
}

.entorno-teatro .event-list__section::before {
  border-top: 3px solid var(--color-entorno-teatro);
}

.entorno-congresos .event-list__section::before {
  border-top: 3px solid var(--color-entorno-congresos);
}

@media (min-width: 768px) {
  .event-list__section::before {
    border-top-width: 5px;
  }
}

.event-list__section:first-child::before {
  border: 0;
}

.event-list__section .card-collection {
  margin-top: 0;
  gap: 0;
  grid-template-columns: auto;
}

.event-list__section:last-of-type .card-collection:last-of-type {
  margin-bottom: 0;
}

.event-view-list__section-title {
  position: sticky;
  z-index: 996;
  top: 0;
  display: block;
  padding: .5em 0;
  margin: 0;
  margin-top: calc(var(--margin) * var(--margin-top-factor) - .5em);
  margin-bottom: calc(var(--margin) * var(--margin-bottom-heading-factor) - .5em);
  background: transparent;
  background-color: white;
  color: var(--color-heading3);
  font-family: var(--font-family-alt);
  font-size: var(--font);
  line-height: var(--line);

  --font: minimalfont(strip(var(--font-size-31px)));
  --margin: factormargin(strip(var(--font-size-31px)));
  --line: 1.3em;
}

.event-list__section:first-of-type > .event-view-list__section-title:first-of-type {
  margin-top: 0;
}

.menuContextual .event-view-list__section-title {
  position: relative;
}

.event-view-list__section-title::first-letter {
  text-transform: capitalize;
}

@media (min-width: 320px) and (max-width: 1419px) {
  .event-view-list__section-title {
    --margin: fluidmargin(strip(var(--font-size-31px)));
    font-size: fluidfont(strip(var(--font-size-31px)));
    line-height: fluidline(strip(var(--line)), strip(var(--font-size-31px)));
  }
}

@media (min-width: 1420px) {
  .event-view-list__section-title {
    font-size: var(--font-size-31px);
  }
}

/**
  * [0] - Altura fija de la imagén.
 **/

.div__results .card-event__link {
  --border: 2px;
  display: flex;
  height: auto;
  flex-direction: column;
  border: var(--border) solid transparent;
  margin-bottom: 0;
  background: var(--background-color-alt-dark);
  color: currentColor;
}

.div__results .card-event__link:hover,
.div__results .card-event__link:focus {
  border: var(--border) solid var(--color-primario);
  outline: 0;
  text-decoration: none;
}

.div__results .card-event__link:hover .card-event__title,
.div__results .card-event__link:focus .card-event__title {
  color: var(--color-primario);
}


/**
** [0] - Anchura fija de la imagén.
**/
.div__results .card-event__image {
  position: relative;
  display: flex;
  flex-basis: 39%;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color-alt-darker);
}

@media (min-width: 768px) {
  /**
** [0] - Anchura fija de la imagén.
**/
  .div__results .card-event__image {
    max-width: 540px; /** [0] **/
  }
}

.div__results .card-event__image img,
.div__results .card-event__image picture {
  display: flex;
  object-position: center center;
}

/* @media (max-width: 1420px) {
  .div__results .card-event__image img,
  .div__results .card-event__image picture {
    height: 100%;
    object-fit: cover;
  }
} */

.div__results .card-event__metas {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: .5em;
  gap: .5em;
  overflow-x: auto;
  white-space: nowrap;
}

.div__results .card-event__metas:empty {
  display: none;
}

.meta__etiqueta {
  --font: minimalfont(strip(var(--font-size-21px)));
  padding: 0 var(--gutter-interior);
  background: var(--color-text-high-contrast);
  color: var(--color-text-inverse);
  font-size: var(--font);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .meta__etiqueta {
    font-size: fluidfont(strip(var(--font-size-21px)));
  }
}

@media (min-width: 1420px) {
  .meta__etiqueta {
    font-size: var(--font-size-21px);
  }
}

.card-event__title {
  margin-bottom: 1rem;
  font-family: var(--font-family-base-semi);
}

.card-event__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--gutter-interior-aside);
  font-family: var(--font-family-base);
  font-size: var(--font-size-18px);
  line-height: var(--rhythm);
}

@media (min-width: 768px) {
  .card-event__content {
    padding: 1.5rem 2rem;
  }
}

@media (min-width: 320px) and (max-width: 1419px) {
  .card-event__content {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .card-event__content {
    font-size: var(--font-size-24px);
  }
}

@media (min-width: 768px) {
  .div__results .card-event__link {
    flex-direction: row;
  }
  .div__results .card-event__metas {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    gap: 0 .5em;
    gap: .5em;
    white-space: normal;
  }
  .div__results .card-event__title {
    overflow: hidden;
    font-weight: 700;
    text-overflow: ellipsis;
  }
}

.card-event__info-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
}

.menuContextual .card-event__info-list {
  margin: 0;
}

.card-event__info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: .3em;
  gap: 0;
  list-style: none;
}

.card-event__info:last-child {
  margin: 0;
}

.card-event__info::before {
  width: 1.2em;
  height: 1.2em;
  flex-shrink: 0;
  margin-right: 1em;
  background-color: var(--color-text-high-contrast);
  content: '';
}

.card-event__info-calendar::before {
  mask-image: url(../img/svg/sprite.svg#icon-calendar-view);
}

.card-event__info-time::before {
  mask-image: url(../img/svg/sprite.svg#icon-clock-view);
}

.card-event__info-location::before {
  mask-image: url(../img/svg/sprite.svg#icon-location-view);
}

.card-event__info-public::before {
  mask-image: url(../img/svg/sprite.svg#icon-public-view);
}

.card-event__info-lang::before {
  mask-image: url(../img/svg/sprite.svg#icon-lang-view);
}

.card-event__info-repeat::before {
  mask-image: url(../img/svg/sprite.svg#icon-event-repeat-view);
}

.descargas__icalendar,
.descargas__rss {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-right: 2em;
}

.descargas__rss .icon-social-rss-small {
  margin-left: .5em;
}

.descargas__icalendar::after {
  width: 1.2em;
  height: 1.2em;
  margin-left: .5em;
  background-color: var(--color-primario);
  content: '';
  mask-image: url(../img/svg/sprite.svg#icon-calendar-download-view);
}

@media (max-width: 768px) {
  .event-list__section-active > .event-view-list__section-title {
    position: fixed;
    z-index: 999;
    top: 0;
    width: 100%;
    height: 2em;
    padding-top: .5em;
    margin-top: 0;
    background-color: var(--background-color);
  }
}

@keyframes tracking-out-contract {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    letter-spacing: -.5em;
    opacity: 0;
  }
}

.event-list__section[data-stuck] > .event-view-list__section-title {
  animation: tracking-out-contract .7s cubic-bezier(.55, .085, .68, .53) reverse both;
}

.event-list__section[data-stuck] + .event-list__section[data-stuck] > .event-view-list__section-title {
  animation: unset;
}

.event-list + .btn-mas {
  width: 100%;
  justify-content: center;
}
