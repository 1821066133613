.entorno-pwa.j22-01s .tab-content__header {
  background-color: #fff;
  color: #000;
}

.entorno-pwa.j22-01s .tab-data-bar__header h3 {
  --font: minimalfont(strip(var(--font-size-22px)));
  font-size: var(--font);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .entorno-pwa.j22-01s .tab-data-bar__header h3 {
    --font: fluidfont(strip(var(--font-size-22px)));
  }
}

@media (min-width: 1420px) {
  .entorno-pwa.j22-01s .tab-data-bar__header h3 {
    --font: var(--font-size-22px);
  }
}
