/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE: DETAILS SUMMARY                                         *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *  /components/_details.css                                            *
 * -------------------------------------------------------------------- *
 *  Tamaño de fuente base:                                              *
 *    - Movil:  24px                                                    *
 *    - Tablet: 24px                                                    *
 *    - Web:    24px                                                    *
 * -------------------------------------------------------------------- *
 *  Descripción:                                                        *
 *                                                                      *
 *  Se utiliza en:                                                      *
 *    - Preguntas frecuentes                                            *
 *                                                                      *
\* ==================================================================== */

/*
@styleguide

@title Accordions

El siguiente componente tiene como propósito **crear un listado de accordions** en los
que la información será visible cuando se cambia el estado a **open**.


```html
<details>
  <summary>
    titulo del accordion
  </summary>
  <div>
  Texto que despliega el accordion
  </div>
</details>
<details>
  <summary>
    titulo del accordion 2
  </summary>
  <div>
  Texto que despliega el accordion 2
  </div>
</details>

```

Al cargarse la página se plegarán todos los accordion menos aquellos que tengan
marcada como visible con la etiqueta `open` dentro de details (en el ejemplo la segunda).

<div class="psg-responsive-sample">
  <details>
    <summary>
      titulo del accordion
    </summary>
    <div>
    Texto que despliega el accordion
    </div>
  </details>
  <details open>
    <summary>
      titulo del accordion 2
    </summary>
    <div>
    Texto que despliega el accordion 2
    </div>
  </details>
</div>

*/

details {
  padding: 0;
  margin: 0 0 var(--rhythm) 0;
  background-color: var(--background-color);
}

/** Marcador por defecto del elemento summary **/
details summary::marker,
details summary::-webkit-details-marker {
  display: none;
  content: "";
}

/** Línea de separación superior de la capa **/
details::before {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000;
  content: "";
}

details summary {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: calc(var(--rhythm) * .5);
  margin-bottom: 0;
  column-gap: 1em;
  cursor: pointer;
}

details[open] summary {
  margin-bottom: calc(var(--rhythm) * .5);
}

/** Icono **/
details summary::after {
  display: block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  margin-right: .5em;
  background-color: var(--color-heading);
  content: "";
  font-size: inherit;
  mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiAkJCQvR2VuZXJhbFN0ci8xOTY9QWRvYmUgSWxsdXN0cmF0b3IgMjcuNi4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+DQo8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyMi42IDE0LjIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDIyLjYgMTQuMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGcgaWQ9IkNhcGFfMSI+DQoJPHBhdGggZD0iTTE5LjgsMGwyLjgsMi44TDExLjMsMTQuMkwwLDIuOUwyLjgsMGw4LjUsOC42TDE5LjgsMHoiLz4NCjwvZz4NCjxnIGlkPSJDYXBhXzIiPg0KPC9nPg0KPC9zdmc+DQo=);
  mask-position: center;
  mask-repeat: no-repeat;
  transition: transform 150ms cubic-bezier(.4, 0, .2, 1) 0ms, background-color .3s ease;
}

details[open] summary::after {
  transform: rotate(180deg);
}

details:not([open]) summary > :only-child:is(h1, h2, h3, h4, h5, h6) {
  margin: 0;
}

details[open] summary > :only-child:is(h1, h2, h3, h4, h5, h6) {
  margin: 0;
}

details summary:hover > * {
  color: var(--color-primario);
}

details summary:hover::after {
  background-color: var(--color-primario);
}

/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE: Details summary                                         *
 *                                                                      *
 *  MODIFICARDORES                 *
 *                                                                      *
 *  ------------------------------------------------------------------- *
 *  /components/_details.css                                           *
\* ==================================================================== */

/* Preguntas frecuentes */
.faq details summary {
  font-weight: bold;
}

.faq details:first-of-type {
  margin-top: calc(var(--rhythm) * var(--margin-top-factor));
}

.faq details[open] summary {
  margin-bottom: calc(var(--rhythm) * .5);
}

/* Desplegables pie de página */
.toolsMenu details {
  background: transparent;
}

.toolsMenu details::before,
details.details--summary::before {
  content: none;
}

.toolsMenu details summary,
details.details--summary summary {
  margin-top: 0;
}

@media (min-width: 768px) {
  .details--only-mobile summary,
  .toolsMenu details summary {
    pointer-events: none;
  }
  .details--only-mobile summary::after,
  .toolsMenu details summary::after {
    content: none;
  }
  details.details--summary {
    padding: 0 0 0 19px; /* 1 */
  }
}

.main__grid-box details summary,
.main-footer details summary {
  --font: minimalfont(strip(var(--font-size-39px)));
  font-size: var(--font);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .main__grid-box details summary,
  .main-footer details summary {
    --font: fluidfont(strip(var(--font-size-39px)));
  }
}

@media (min-width: 1420px) {
  .main__grid-box details summary,
  .main-footer details summary {
    --font: var(--font-size-39px);
  }
}
