/* ==================================================================== *\
 *                                                                      *
 *  Pagina de un contenido de WB021                                     *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *  /pages/_wb021-contenido.css                                         *
\* ==================================================================== */
.main--wb021 {
  position: relative;
}

.main--wb021::after {
  display: table;
  clear: both;
  content: '';
}

.main--wb021 h2::before,
.main--wb021 h3::before,
.main--wb021 h4::before {
  display: table;
  clear: both;
  content: '';
}

/**
 * Cabecera que incluye el readspeaker button
 */
.main--wb021 > header.section-header {
  position: absolute;
  top: calc(var(--gutter-interior-main-one-col) / 2);
  right: var(--gutter-interior-main-one-col);
  width: auto;
  padding: 0;
}

/**
 * Mensaje de contenido no vigente que aparece en la cabecera del contenido.
 */
.novigente {
  color: var(--color-error);
  font-family: var(--font-family-alt);
  font-size: 1.333em;
}

.noticias {
  font-weight: bold;
}

/* -------------------------------------------------------------------- *\
 *                                                                      *
 *  EVENTOS                                                                *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *  div#wb021contenido.evento                                           *
 *    article.main main--grid.main--grid-column-collapse                *
 *      header                                                          *
 *        h1                                                            *
 *        p                                                             *
 *      div.main__body.main__grid-row.row                               *
 *        div.columna1.main__grid-col.main__grid-col--left.left-col     *
 *           .col-xs-12.col-sm-6                                        *
 *                                                                      *
 *          div.main__grid-evento                                       *
 *            section.main__grid-seccion                                *
 *         div.columna1.main__grid-col.main__grid-col--right.right-col  *
 *           .col-xs-12.col-sm-6                                        *
 *          div.main__grid-evento                                       *
 *             section.main__grid-seccion                               *
\* -------------------------------------------------------------------- */

.main--grid-eventos .main__header {
  display: flex;
  flex-direction: column;
  padding: 0;
}

/* .main--grid-eventos .main__header .header__title {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: .5em;
  gap: 0 1em;
} */

/* .evento .main--grid .main__header,
.evento .main--grid .main__header h3 {
  padding: 0;
  margin: 0;
}

.evento .main--grid .main__header h3 {
  margin-right: 1em;
} */

/* -------------------------------------------------------------------- *
\* Clase "tag" para los diferentes tipos de eventos*
\* -------------------------------------------------------------------- */

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: .5em;
  gap: .1em .1em;
}

.tags:empty {
  display: none;
}

.tag {
  padding: 5px 10px;
  background: var(--color-text-high-contrast);
  color: var(--color-text-inverse);
  text-decoration: none;
}

/**
 * Mensaje de contenido no vigente, celebrado o cancelado que aparece en la cabecera del contenido de un evento.
 */
.tag.novigente,
.tag.cancelado,
.tag.celebrado {
  background-color: var(--color-warn--panel);
  color: var(--color-text-high-contrast);
}

/* .main__grid-evento .section--evento header {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: white;
}

.main__grid-evento .section--evento header img {
  width: 100%;
}

.main__grid-evento .section--evento > div {
  padding: 1em 0;
}

.main__grid-evento .section--evento dl {
  margin: 0;
}

@media (min-width: 768px) {
  .main__grid-col--left .main__grid-evento {
    padding-right: 15px;
  }
  .main__grid-col--right .main__grid-evento {
    padding-left: 15px;
  }
  .main__grid-evento section.section--evento:first-child header h4 {
    margin-top: 0;
  }
} */

/* -------------------------------------------------------------------- *\
 *                                                                      *
 *  FAQS                                                                *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *  div#wb021contenido.faq                                              *
 *    h3                                                                *
 *    ul.ordenada                                                       *
 *      loop {                                                          *
 *        li                                                            *
 *          a                                                           *
 *      }                                                               *
 *    dl.preguntarespuesta                                              *
 *      loop {                                                          *
 *        dt                                                            *
 *          strong                                                      *
 *            (pregunta)                                                *
 *        dd                                                            *
 *          (respuesta)                                                 *
 *          div.volver                                                  *
 *            a                                                         *
 *      }                                                               *
 *                                                                      *
\* -------------------------------------------------------------------- */

/**
 * Separación del menu de preguntas con el listado de preguntas/respuestas
 */
.preguntarespuesta {
  margin-top: 2em;
}

/**
 * Separación del enlace de volver al menu de preguntas. También utilizado
 * para separar pares pregunta/respuesta entre sí.
 */
.preguntarespuesta .volver {
  margin: .6em 0 2em;
}

/* -------------------------------------------------------------------- *\
 *                                                                      *
 *  ETIQUETAS IB021                                                     *
 *                                                                      *
\* -------------------------------------------------------------------- */
@media (max-width: 767px) {
  .wb021 img.pull-left,
  .wb021 img.pull-right {
    display: block;
    margin-bottom: .6em;
  }
}

@media (min-width: 768px) {
  p:has(> picture:only-child) {
    margin-bottom: 0;
  }
  .wb021 .pull-left,
  .entorno-www .pull-left {
    /* padding-top: 1em; */
    margin-right: 1.5em;
    margin-bottom: 1.5em;
    float: left;
  }
  .wb021 .pull-right,
  .entorno-www .pull-right {
    margin-bottom: 1.5em;

    /* padding-top: 1em; */
    margin-left: 1em;
    float: right;
  }
  .wb021 .figure.pull-left,
  .wb021 .figure.pull-right {
    margin-top: 0;
  }
  .entorno-turismo.wb021 .pull-left {
    max-width: 315px;
    margin-right: 2em;
  }
  .entorno-turismo.wb021 .pull-right {
    max-width: 315px;
    margin-left: 2em;
  }
}

/**
 * Contenedor en el que se incrusta el resultado de la etiquetas de IB021:
 *  - youtubeListTag
 *  - youtubeListTag
 *  - vimeoTag
 *  - flickrTag
 *  - videoTag
 *
 * Con esto conseguimos hacer responsive el iframe que incrustan los
 * servicios de Youtube, Vimeo y Flickr
 */
[class^="multimed-container-"] {
  position: relative;
  overflow: hidden;
  width: auto;
  height: 0;
  padding: 0 0 56.25%;
  margin-bottom: .6em;
}

[class^="multimed-container-"] iframe,
[class^="multimed-container-"] video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  /**
   * 1. Se observa que esta clase se utiliza en los menus contextuales, así que se
   *    asigna una anchura similar (en px) a los elementos del menu.
   */
  .multimed-container-min {
    width: 292px; /*  [1] */
    height: calc(292px * .5625);
    padding-bottom: 0;
  }
  .multimed-container-med {
    width: 500px;
    height: calc(500px * .5625);
    padding-bottom: 0;
  }
  .entorno-turismo .multimed-container-min {
    width: 318px; /* [1] */
    height: calc(318px * .5625);
  }
  .menuContextual .multimed-container-min {
    width: 100%;
  }
}

/* -------------------------------------------------------------------- *\
 *                                                                      *
 *  READSPEAKER                                                         *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *  Tamaño de fuente base:                                              *
 *    16px                                                              *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  Descripción:                                                        *
 *                                                                      *
 *  Aparece el botón de "Escuchar" antes del titulo de todos los        *
 *  contenidos y de forma flotante al seleccionar texto.                *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *  header.section-header.col-xs-12                                     *
 *    div#readspeaker_button1.rsbtn_colorskin.rs_skip.rs_preserve       *
 *      ...                                                             *
 *  div#readspeaker-content                                             *
 *    div#wb021contenido                                                *
 *      ...                                                             *
 *                                                                      *
\* -------------------------------------------------------------------- */

.main--wb021 #readspeaker_button1 {
  margin: 0;
}

/**
 * 1. El contenido del enlace tiene un float left del que hay que calcular
 *    la altura
 */
.main--wb021 #readspeaker_button1 .rsbtn_play {
  overflow: hidden; /* [1] */
  color: #000;
}

.entorno-turismo .main--wb021 #readspeaker_button1 .rsbtn_play {
  color: var(--color-primario);
}

.main--wb021 div:is(#wb021contenido, #wb21contenedoreditorial, #wb021buzon) h3:first-child,
.main--wb021 div:is(#wb021contenido, #wb21contenedoreditorial, #wb021buzon) h1:first-child,
.main--wb021 div:is(#wb021contenido, #wb21contenedoreditorial, #wb021buzon) :is(nav,p).breadcrumb + h3,
.main--wb021 div:is(#wb021contenido, #wb21contenedoreditorial, #wb021buzon) :is(nav,p).breadcrumb + h1,
.main-content .main .main__title,
.entorno-sede .main-content #wb021contenido h1,
.main-content article.events .main__title,
.main--wb021 div:is(#wb021contenido, #wb021contenedor) h1:first-child,
.main > div.contenido > h3:first-child,
.main > div.contenido > h1:first-child,
.main div.contenido > :is(section, div) > h3:first-child,
.main div.contenido > :is(section, div) > h1:first-child,
.main > div.contenido > :empty:first-child + h3,
.main > div.contenido > :empty:first-child + h1,
.j34-01w .result-elements header h1 {
  margin-top: 0;
}

/**
 * Utilizamos .rsbtn_colorskin en vez de #readspeaker_button1, porque
 * existe el caso de que aparezcan otros botones flotantes al seleccionar
 * texto.
 */
.rsbtn_colorskin {
  margin-right: 0;
  margin-bottom: 1em;
  font-size: 1rem;
}

.entorno-turismo .rsbtn_colorskin,
.entorno-www .rsbtn_colorskin {
  padding: 0;
  background: transparent;
  font-size: var(--font-size-18px);
}

.entorno-turismo .rsbtn_colorskin a,
.entorno-turismo .rsbtn_colorskin .rsbtn_left .rsbtn_text,
.entorno-www .rsbtn_colorskin a,
.entorno-www .rsbtn_colorskin .rsbtn_left .rsbtn_text {
  color: var(--color-primario);
}

.entorno-turismo .rsbtn_colorskin a:hover,
.entorno-turismo .rsbtn_colorskin a:hover .rsbtn_text,
.entorno-turismo .rsbtn_colorskin a:hover .rsbtn_text span,
.entorno-www .rsbtn_colorskin a:hover,
.entorno-www .rsbtn_colorskin a:hover .rsbtn_text,
.entorno-www .rsbtn_colorskin a:hover .rsbtn_text span {
  text-decoration: underline;
}

.rsbtn_text {
  padding-left: .5em;
}

/**
 * 1. Capturar el float de .col-xs-12 y/o .rsbtn_colorskin (aplicado en
 *    su propia css)
 */
#readspeaker-content {
  clear: both; /* 1 */
}

/* -------------------------------------------------------------------- *\
 *                                                                      *
 *  CONTENIDOS RELACIONADOS                                             *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  Descripción:                                                        *
 *                                                                      *
 *  Aparece al final de un contenido.                                   *
 *  bajo el formulario para comentar un contenido.                      *
 *  Lista los comentarios recibidos anteriormente.                      *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *  ul.linksInfo                                                        *
 *    loop {                                                            *
 *      li.icon-arrow_link1                                             *
 *        a                                                             *
 *    }                                                                 *
 *                                                                      *
\* -------------------------------------------------------------------- */

/**
 * NOTA:
 *
 * Ha dejado de necesitar estilos al incorporar li.icon-arrow_link1.
 *
 * Se deja el apartado para que haya información en el caso de necesitar
 * añadirle estilos propios en el futuro.
 */

/* .linksInfo {} */

/* -------------------------------------------------------------------- *\
 *                                                                      *
 *  SOLICITAR INFORMACIÓN DE UN CONTENIDO EN EL BUZÓN                   *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  Descripción:                                                        *
 *                                                                      *
 *  Aparece al final de un contenido.                                   *
 *                                                                      *
 *  Texto:                                                              *
 *                                                                      *
 *  "Si la información de la página no responde a sus necesidades       *
 *  informativas, solicítela a través del Buzón ciudadano."             *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *  p.stick-info                                                        *
 *    i.icon-chat                                                       *
 *    (texto)                                                           *
 *    a                                                                 *
 *                                                                      *
\* -------------------------------------------------------------------- */
.stick-info {
  padding: .6em .8em;
  margin-top: 2em;
  background-color: var(--background-color-alt-dark);
  color: var(--color-text-high-contrast);
}

.entorno-turismo .stick-info {
  background-color: var(--background-turismo-color);
}

.stick-info .icon-chat {
  margin-right: .4em;
}

.stick-info a {
  font-weight: bold;
}

/* -------------------------------------------------------------------- *\
 *                                                                      *
 *  COMPARTE ESTA INFORMACIÓN                                           *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  Descripción:                                                        *
 *                                                                      *
 *  Aparece al final de un contenido.                                   *
 *  Proporciona enlaces para compartir en redes sociales.               *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *  article#redessociales                                               *
 *    h4#tituloredes                                                    *
 *    ul.listSocial                                                     *
 *      li                                                              *
 *        a.icon-zz005-social-facebook-small                            *
 *          span.hidden                                                 *
 *      li                                                              *
 *        a.icon-zz004-social-twitter-small                             *
 *          span.hidden                                                 *
 *      li                                                              *
 *        a.icon-z002-social-google-small                               *
 *          span.hidden                                                 *
 *      li                                                              *
 *        a.icon-z001-social-linkedin-small                             *
 *          span.hidden                                                 *
 *      li                                                              *
 *        a.icon-zz006-social-mailto-small                              *
 *          span.hidden                                                 *
 *      li                                                              *
 *        a.icon-z003-social-delicious-small                            *
 *          span.hidden                                                 *
 *      li                                                              *
 *        a.icon-zz007-social-meneame-small                             *
 *          span.hidden                                                 *
 *    ul.listSocial.tweed                                               *
 *      li                                                              *
 *        iframe.twitter-share-button                                   *
 *      li                                                              *
 *        div.fb-like.fb_iframe_widget                                  *
 *                                                                      *
\* -------------------------------------------------------------------- */

/**
 * 1. En móviles "fb_iframe_widget" contiene un iframe con ancho fijo
 *    que ensancha el body sin dar casi señales. Con esto se pretende
 *    que no sobresalga más de la capa en la que esta contenido.
 */
.listSocial {
  display: inline-block;
  overflow: hidden;
  max-width: 100%; /* [1] */
  padding: 0; /* [1] */
  vertical-align: top;
}

/**
 * 1. Cuando se hace focus sobre un icono de red social hay que dejar espacio
 *    para que el outline de los enlaces no se corte.
 */
.listSocial li {
  display: inline-block;
  padding: .2em 0 0 .2em; /* [1] */
  margin: 0 .2em 0 0;
  list-style: none;
  vertical-align: top;
}

/**
 * 1. Aumentar la separación entre los widget de "Twittear" y "Me gusta"
 *    porque cuando la anchura no es suficiente y aparecen en columna
 *    están muy pegados.
 */
.listSocial.tweed li {
  margin-bottom: .4em; /* [1] */
}

/**
 * 1. Alinear el widget del "Me gusta" de FB con el de Twitter eliminando
 *    el espacio que deja la altura de linea que tiene por defecto
 */
.listSocial .fb_iframe_widget {
  line-height: .8; /* [1] */
}

/* -------------------------------------------------------------------- *\
 *                                                                      *
 *  relatedInformation (Información relacionada)                        *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *  Tamaño de fuente base:                                              *
 *    Heredada de .form                                                 *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  Descripción:                                                        *
 *                                                                      *
 *  Aparece con título "Información relacionada" al final de los        *
 *  contenidos y contiene:                                              *
 *                                                                      *
 *    - relatedInformation                                              *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *  div.relatedInformation                                              *
 *    h2                                                                *
 *      "Información relacionada"                                       *
 *    <ul>                                                              *
 *                                                                      *
 *                                                                      *
\* -------------------------------------------------------------------- */
.relatedInformation {
  padding: 0 1em 1em;
  border-top: 10px solid #24d32b;
  margin-bottom: 2.5em;
  background-color: #e0eaec;
}

.relatedInformation h2 {
  margin-top: 1em;
  margin-bottom: .5em;
}

/* -------------------------------------------------------------------- *\
 *                                                                      *
 *  SHARECOMMENTS (Tu opinión nos importa)                              *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *  Tamaño de fuente base:                                              *
 *    Heredada de .form                                                 *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  Descripción:                                                        *
 *                                                                      *
 *  Aparece con título "Tu opinión nos importa" al final de los         *
 *  contenidos y contiene:                                              *
 *                                                                      *
 *    - SHAREUTIL                                                       *
 *    - SHARECOMMENT                                                    *
 *    - COMENTARIOS RECIBIDOS                                           *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *  article.shareComments.col-xs-12                                     *
 *    h4.line                                                           *
 *      "Tu opinión nos importa"                                        *
 *    button.visible-xs.btn.btn-show-green.glyphicon.glyphicon-minus    *
 *    div.content-js                                                    *
 *                                                                      *
\* -------------------------------------------------------------------- */


/* -------------------------------------------------------------------- *\
 *                                                                      *
 *  SHAREUTIL (Ayudanos a mejorar)                                      *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *  Tamaño de fuente base:                                              *
 *    Heredada de .form                                                 *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  Descripción:                                                        *
 *                                                                      *
 *  Aparece bajo el título "Tu opinión nos importa" de los contenidos   *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *  form.form.shareUtil                                                 *
 *    fieldset                                                          *
 *      legend                                                          *
 *        "Ayúdanos a mejorar. ¿Te ha resultado útil esta información?" *
 *      label                                                           *
 *      input[type=radio]#VAL_SI                                        *
 *      &nbsp;&nbsp;&nbsp;                                              *
 *      label                                                           *
 *      input[type=radio]#VAL_NO                                        *
 *      &nbsp;&nbsp;&nbsp;                                              *
 *      label                                                           *
 *      input[type=radio]#VAL_UN_POCO                                   *
 *      &nbsp;&nbsp;&nbsp;                                              *
 *    input[type=submit].boton                                          *
 *                                                                      *
\* -------------------------------------------------------------------- */
.shareUtil {
  margin-bottom: 1em;
}

.shareUtil fieldset {
  margin-bottom: .8em;
}

.shareUtil legend,
.shareUtil label,
.shareUtil input {
  padding-right: .3em;
  margin-bottom: 0;
  font-size: 1em;
}

.shareUtil label {
  display: inline;
}

.shareUtil legend {
  display: block;
  border-bottom: 0;
  margin-bottom: .8em;
}

/* -------------------------------------------------------------------- *\
 *                                                                      *
 *  SHARECOMMENT                                                        *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  Descripción:                                                        *
 *                                                                      *
 *  Aparece en el apartado "Tu opinión nos importa" de los contenidos.  *
 *  Formulario para comentar en el buzón ciudadano un determinado       *
 *  contenido.                                                          *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *  form.form.shareComment                                              *
 *                                                                      *
 *    p.error (si no hay error es un p vacío)                           *
 *                                                                      *
 *    dl#formularioContenido.formulario                                 *
 *      dt                                                              *
 *        label                                                         *
 *      dd                                                              *
 *        textarea#descripcionComentario.form-control                   *
 *      dd.error                                                        *
 *                                                                      *
 *    dl#formularioEnContenidoOculto.formulario                         *
 *                                                                      *
 *      dt                                                              *
 *        label                                                         *
 *      dd                                                              *
 *        input#documento.medio                                         *
 *                                                                      *
 *      dt                                                              *
 *        label                                                         *
 *      dd                                                              *
 *        input#usuarioComentario.form-control                          *
 *                                                                      *
 *      dt                                                              *
 *        (texto sobre visibilidad de asunto)                           *
 *      dd.radio                                                        *
 *        fieldset                                                      *
 *          legend.hidden                                               *
 *          label                                                       *
 *          input#CAR_PUBLICO                                           *
 *          &nbsp;&nbsp;&nbsp;                                          *
 *          label                                                       *
 *          input#CAR_PRIVADO                                           *
 *      dd.error                                                        *
 *                                                                      *
 *      dt                                                              *
 *        (estado de animo)                                             *
 *      dd.radio                                                        *
 *        fieldset                                                      *
 *          legend.hidden                                               *
 *          div.opcion1                                                 *
 *            label                                                     *
 *            input#ALEGRE.radio                                        *
 *            img                                                       *
 *          div.opcion1                                                 *
 *            label                                                     *
 *            input#NORMAL.radio                                        *
 *            img                                                       *
 *          div.opcion1                                                 *
 *            label                                                     *
 *            input#ENFADADO.radio                                      *
 *            img                                                       *
 *          div.opcion1                                                 *
 *            label                                                     *
 *            input#IRACUNDO.radio                                      *
 *            img                                                       *
 *                                                                      *
 *      dt                                                              *
 *        (Tipo de asunto)                                              *
 *      dd.radio                                                        *
 *        fieldset                                                      *
 *          legend.hidden                                               *
 *          div.opcion1                                                 *
 *            label                                                     *
 *            input#tipoSug.radio                                       *
 *          div.opcion1                                                 *
 *            label                                                     *
 *            input#tipoRec.radio                                       *
 *          div.opcion1                                                 *
 *            label                                                     *
 *            input#tipoOtros.radio                                     *
 *                                                                      *
 *      dt                                                              *
 *        label                                                         *
 *      dd                                                              *
 *        input#correoElectronico.form-control                          *
 *      dd.error                                                        *
 *                                                                      *
 *    input[type=submit].boton                                          *
 *                                                                      *
\* -------------------------------------------------------------------- */
.shareComment textarea {
  height: 9em;
}

.mailbox .boton,
.mailbox .boton:hover,
.mailbox .boton:focus,
.mailbox .boton:active {
  background-color: #000;
  color: var(--color-text-inverse);
}

/**
 * 1. Sobreescribir estilos por defecto de Bootstrap para DT
 */
.shareComment dt {
  margin-top: 1.5em;
  font-weight: normal; /* 1 */
}

/**
   * Eliminar el margen superior del primer dt (el del textarea)
   */
.shareComment #formularioContenido dt {
  margin-top: 0;
}

/**
 * 1. Sobreescribir estilos de Bootstrap para .radio
 */
.shareComment .radio input[type="radio"] {
  position: relative; /* 1 */
  display: inline-block; /* 1 */
  margin-right: .3em;
  margin-left: .3em; /* 1 */
}

/**
 * 1. Sobreescribir estilos de Bootstrap para .radio
 */
.shareComment .radio label {
  display: inline-block;
  padding-left: 0; /* 1 */
}

/**
 * 1. En dispositivos móviles los input radio de .opcion1 se muestran
 *    en vertical
 * 2. A partir de tablets ya hay espacio para poner las opciones en linea
 */
.shareComment .opcion1 {
  margin-bottom: 1em; /* 1 */
}

@media (min-width: 768px) {
  .shareComment .opcion1 {
    display: inline-block; /* 2 */
    width: 23%; /* 2 */
    margin-bottom: 0; /* 2 */
  }
}

/* -------------------------------------------------------------------- *\
 *                                                                      *
 *  COMENTARIOS RECIBIDOS                                               *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  Descripción:                                                        *
 *                                                                      *
 *  Aparece en el apartado "Tu opinión nos importa" de los contenidos,  *
 *  bajo el formulario para comentar un contenido.                      *
 *  Lista los comentarios recibidos anteriormente.                      *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *  article.commentsReceived                                            *
 *    h4                                                                *
 *    div#migaPan.breadcrumb                                            *
 *    h3.ocultarEnContenido                                             *
 *    div.mapa                                                          *
 *    p (vacio)                                                         *
 *    div                                                               *
 *      div.izq                                                         *
 *        p                                                             *
 *      div.dcha (no necesario)                                         *
 *        a.btn-show-greenBuzon                                         *
 *    br                                                                *
 *    (loop de comentarios)                                             *
 *    form.ocultarEnContenido (no necesario)                            *
 *      (no necesario)                                                  *
 *                                                                      *
\* -------------------------------------------------------------------- */

/**
 * Ocultar información no necesaria
 *
 * .commentsReceived .dcha
 *   No se necesita el boton dentro de div.dcha pero carece de la clase
 *   .ocultarEnContenido (mejora: añadirle la clase)
 *
 * .commentsReceived :not(img):empty
 *   Ocultar multitud de elementos vacíos (IE9+)
 */
.commentsReceived .ocultarEnContenido,
.commentsReceived .dcha,
.commentsReceived :not(img):empty {
  display: none;
}

/**
 * 1. Al no necesitar el boton dentro de div.dcha no es necesario que
 *    div.izq tenga float a la izquierda
 */
.commentsReceived .izq {
  float: none; /* 1 */
}

/* -------------------------------------------------------------------- *\
 *                                                                      *
 *  MENSAJE DE AVISO PARA LOS CONTENIDOS                   *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  Descripción:                                                        *
 *                                                                      *
 *  Aparece debajo de la cabecera y se utiliza para resaltar un mensaje *
 *  de un contenido a modo alerta .                                     *
 *                                                                      *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *  p.alerta-info                                                       *
 *                                                                      *
\* -------------------------------------------------------------------- */

.alerta-info {
  padding: .6em .8em;
  border: 2px var(--color-error) solid;
}

/* esta clase se utiliza en el detalle de eventos cancelados */
.cancelado p:first-child {
  display: inline;
}

.cancelado p {
  margin-bottom: 0;
}

/* BUSCADOR NORMATIVA */

/* .shareComments.normativa .search {
  display: flex;
  flex-direction: column;
}

.shareComments.normativa .field__label,
.shareComments.normativa .fieldset__legend {
  font-size: 1.5rem;
  font-weight: 700;
}

.shareComments.normativa .field__input {
  border: 2px solid #000;
  background-color: #fff;
}

.shareComments.normativa .field__input:focus {
  border: 2px solid #fff;
  background-color: #fff;
}

.shareComments.normativa .field__select,
.shareComments.normativa .search .field__input {
  width: 80%;
}

.shareComments.normativa .search .submit {
  width: fit-content;
  order: 2;
  margin-top: 1em;
  background-color: #000;
}

.shareComments.normativa a {
  padding-left: 1.2em;
  margin-left: -10px;
  background: url(../img/svg/arrow_forward.svg) no-repeat;
  background-position: 0 center;
  background-size: 1em;
  color: #0043be;
  font-size: 1.5rem;
  font-weight: bold;
} */

div#wb021contenido .carousel picture {
  flex-grow: 1;
}


/* Twitter incrustado */
div#wb021contenido .twitter-container-223 {
  max-width: 223px;
}

div#wb021contenido .twitter-container-320 {
  max-width: 320px;
}

div#wb021contenido .twitter-container-425 {
  max-width: 425px;
}

div#wb021contenido .twitter-container-480 {
  max-width: 480px;
}

div#wb021contenido .twitter-container-640 {
  max-width: 640px;
}

div#wb021contenido > picture {
  display: block;
  margin-bottom: var(--rhythm);
}

div#wb021contenido > article.main__grid-box:first-of-type::before {
  margin-top: calc(var(--rhythm) * 3);
}
