/* ================================================================== *\
 *                                                                    *
 *  APLICACIÓN: A25-01W                                               *
 *                                                                    *
 * ------------------------------------------------------------------ *
 *  /pages/_a25-01i.css                                               *
\* ================================================================== */

/*
Nombre:
Recortes de prensa

/* Cabecera */
#cabecera #contenidocabecera {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

#contenidocabecera #textocabecera {
  margin-top: 1.6rem !important;
}

.prensa-google .results-prensa header h1:first-child {
  margin-top: 0;
}

.prensa-google .interior {
  padding: 0;
}

/* Solo muestra la paginacion del final */
.results-prensa .main__results > ul.pagination {
  display: none;
}

.results-prensa .main__results > ul.pagination ~ ul.pagination {
  display: block;
}

/* desplegable especifico en panatala ancha */
@media (min-width: 1420px) {
  #listAreastematicas:first-of-type.recortes h1 {
    display: flex;
    align-items: flex-end;
  }
}

/* Filtros de busqueda */
.menuContextual .area-result ul.lstWebsListado .btn.btn--nav {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  padding: var(--gutter-interior-filtros);
  margin-bottom: 0;
  margin-left: -1.2em;
  background-color: var(--background-color-alt-dark);
  font-family: var(--font-family-base);
}

.menuContextual .area-result ul.lstWebsListado .btn.btn--nav::after {
  width: .6em;
  height: .6em;
  margin-left: auto;
  background-color: #000;
  content: '';
  mask-image: url(../img/svg/sprite.svg#close-view);
}

.menuContextual .area-result ul.lstWebsListado a.btn.btn--nav span:first-child {
  max-width: 85%;
}

.menuContextual .area-result ul.lstWebsListado a.btn.btn--nav:hover {
  color: #0043be;
  text-decoration: underline;
}

.menuContextual .area-result ul.lstWebsListado a.btn.btn--nav:hover::after {
  background-color: #0043be;
}

.menuContextual .area-result ul.lstWebsListado a.btn.btn--nav:hover span {
  color: #0043be;
}
