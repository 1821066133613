/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE:   NOTICIA                                               *
 *                                                                      *
 *  ------------------------------------------------------------------- *
 *  /components/_news.css                                               *
 *  ------------------------------------------------------------------- *
 *  Tamaño de fuente base: heredado                                     *
 * -------------------------------------------------------------------- *
 *  DOM:                                                                *
 *                                                                      *
 *    article.news                                                      *
 *      a                                                               *
 *        img.news__image                                               *
 *      h1.news__title                                                  *
 *        a.news__link                                                  *
 *      p.news__breadcrumbs                                             *
 *        (Nota: en Fichas de orgAdministrativa y orgMunicipal)         *
 *      p.news__summary                                                 *
 *                                                                      *
\* ==================================================================== */

/**
 * 1. Si una noticia pertenece a una lista no debe tener punto o número.
 */
.news {
  display: flex;
  height: 100%;
  line-height: var(--rhythm);
  list-style: none; /* [1] */
}

.news__image-container {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: .5em;
  background-color: var(--background-color-alt-darker);
}

.news__image {
  display: block;
  max-height: 325px;
  object-fit: contain;
}

.news__content {
  flex-grow: 1;
  padding: var(--gutter-interior-aside);
  background-color: var(--background-color-alt);
}

/**
   * Titulo de la noticia
   *
   * 1. Sobreescribir el valor que se da por defecto a los titulos dentro
   *    de .main-content (ej: .main-content h1)
   *    A revisar en el futuro, por si se puede eliminar este selector.
   * 2. Sobreescribir el valor que se da por defecto a los enlaces dentro
   *    de un contenido estático.
   */

.news__title,
.main-content .news__title,
.news__title > a {
  margin-top: 0;
  background-image: none;
  color: var(--color-link-alt);
  font-family: var(--font-family-base-semi);
  font-size: var(--font-size-18px);
  line-height: var(--rhythm);
}

.entorno-turismo .news__title,
.entorno-turismo .main-content .news__title,
.entorno-turismo .news__title > a {
  font-family: var(--font-family-turismo-base);
  font-weight: bold;
}

.news__title > a:hover {
  color: var(--color-link);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .news__title,
  .main-content .news__title,
  .news__title > a {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .news__title,
  .main-content .news__title,
  .news__title > a {
    font-size: var(--font-size-24px);
  }
}

.news--highlight picture + .news__title::before,
.news--highlight img + .news__title::before {
  display: block;
  height: .5em;
  margin: 0 -2.813rem 1em;
  background-color: #fff;
  content: "";
}

@media (max-width: 660px) {
  .news--highlight picture + .news__title::before,
  .news--highlight img + .news__title::before {
    margin: 0 -1.563rem 1em;
  }
}

.entorno-turismo .news__summary {
  color: var(--color-text-high-contrast);
}

.news__summary {
  padding: 0;
  color: var(--color-text);
  font-family: var(--font-family-base);
  font-size: var(--font-size-18px);
  line-height: var(--rhythm);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .news__summary {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .news__summary {
    font-size: var(--font-size-24px);
  }
}

.news .news__link {
  flex-grow: 1;
}

/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE:   NOTICIA                                               *
 *  MODIFICADOR:  --highlight (NOTICIA DESTACADA)                       *
 *                                                                      *
 *  ------------------------------------------------------------------- *
 *  /components/_news.css                                               *
 *  ------------------------------------------------------------------- *
 *  Tamaño de fuente base: heredado                                     *
 * -------------------------------------------------------------------- *
 *  DOM:                                                                *
 *                                                                      *
 *    article.news.news--highlight                                      *
 *      a.news__link                                                    *
 *        h3.news__title                                                *
 *        img.news__image                                               *
 *        p.news__summary.news__summary--after-image                    *
 *                                                                      *
\* ==================================================================== */
.news.news--highlight:nth-child(2n+1) {
  justify-self: flex-start;
}

.news.news--highlight:nth-child(2n) {
  justify-self: flex-end;
}

.news--highlight .news__link {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.news--highlight::after,
.news--highlight .news__link::after {
  display: table;
  clear: both;
  content: " ";
}

.news__title {
  display: block;
  padding: 0;
  color: var(--color-heading);
}

.news--highlight .news__link:hover,
.news .news__link:hover {
  text-decoration: none;
}

.news--highlight .news__link:hover .news__title,
.news .news__link:hover .news__title {
  color: var(--color-link);
  text-decoration: underline;
}

.news--highlight p:last-child > .icon-extension {
  margin: 0 2.813rem;
}



/**
 * La imagen y la entradilla están a la misma altura porque la imagen está flotada
 * a la izquierda
 */
@media (min-width: 768px) {
  .news__image--float-left {
    width: 40%;
    padding-right: .6em;
    float: left;
  }

  /**
   * 1. Eliminar el espacio en blanco del comienzo del texto para que se
   *    alinee con el comienzo de la foto.
   *    Se sube el texto en vez de añadir espacio a la imagen para que no
   *    afecte al espacio en blanco que hay bajo el título de la noticia.
   */
  .news__image--float-left + .news__summary {
    width: 59%;
    margin-top: -.3em; /* [1] */
    float: left;
  }
}

/**
 * Noticias donde la image aparece antes del texto pero no ocupa todo el ancho
 */

/* .news__image--medium-size {} */

/* ==================================================================== *\
 *                                                                      *
 *  CSS RESIDUAL: NOTICIA DE LA HOME                                    *
 *                                                                      *
 *  ------------------------------------------------------------------- *
 *  /components/_news.css                                               *
 * -------------------------------------------------------------------- *
 *  DOM:                                                                *
 *                                                                      *
 *    article.news.col-xs-12.col-sm-6.col-md-3.masonryElement           *
 *      img.imgnoticiahome                                              *
 *      h2.titulonoticiahome                                            *
 *        (titulo)                                                      *
 *      p                                                               *
 *        (entradilla)                                                  *
 *                                                                      *
\* ==================================================================== */
article.news.masonryElement {
  margin-bottom: 0;
}

article.news .imgnoticiahome {
  margin-bottom: var(--rhythm);
}

article.news .titulonoticiahome {
  font-size: 1.167em;
}
