.entorno-www .header {
  font-family: var(--font-family-alt);
}

.entorno-www .header-main {
  border-bottom: 5px solid #23b42b;
}

@media (min-width: 1200px) {
  .entorno-www .header-main {
    padding-right: 0;
  }
}

.header--www .header-main__highlighted {
  text-align: right;
}

.entorno-www .header-main__search--nav {
  display: flex;
  max-width: none;
  flex-grow: 0;
  align-items: center;
  background-color: inherit;
}

@media (min-width: 1200px) {
  .entorno-www .header-main__search--nav {
    height: 100%;
  }
}

.entorno-www .header-main__search-menu .header-main__search {
  max-width: calc(var(--readable-area) + (var(--readable-padding-side) * 2));
  padding: 0 var(--readable-padding-side);
  margin: 1em auto;
}

@media (min-width: 768px) and (max-width: 991px) {
  .entorno-www .header-main__search-menu .header-main__search {
    --readable-area: 920px;
    --readable-padding-side: 2em;
  }
}

@media (min-width: 992px) and (max-width: 1419px) {
  .entorno-www .header-main__search-menu .header-main__search {
    --readable-area: 1220px;
    --readable-padding-side: 2em;
  }
}

@media (min-width: 1420px) {
  .entorno-www .header-main__search-menu .header-main__search {
    --readable-area: 1420px;
    --readable-padding-side: 2em;
  }
}

@media (max-width: 1200px) {
  .header-main__menu-button {
    display: flex;
    margin-left: 1em;
  }
  .header-main__menu-button::before {
    padding-right: 1em;
    border-left: 2px solid black;
    content: '';
  }
}

@media (max-width: 768px) {
  /* .entorno-www {
    overflow-x: hidden;
    overflow-y: hidden;
  } */
  .entorno-congresos .header-main__menu-button::before,
  .entorno-turismo .header-main__menu-button::before,
  .header.header--sede .header-main__menu-button::before {
    display: none;
  }
  .header-main__search--nav svg {
    padding: 0 !important;
  }
}

.header-main__search:hover,
.header-main__search:focus {
  background-color: inherit;
}

/** NO-WWW */
.header--congresos .header-main__networks-all,
.header--teatro .header-main__networks-all,
.header--turismo .header-main__networks-all {
  display: none;
}

.header-main__logo svg {
  max-width: 8em;
}

.entorno-www .header-main__search-dropdown-toggle::before {
  width: 1.2em;
  height: 1.2em;
  background-color: #000;
  content: '';
  mask-image: url(../img/svg/sprite.svg#search-view);
  mask-repeat: no-repeat;
  transition: all .3s;
}

@media (max-width: 1199px) {
  .entorno-www .header-main__search-dropdown-toggle::before {
    width: 42px;
    height: 42px;
  }
}

@media (max-width: 1024px) {
  .entorno-www .header-main__search-dropdown-toggle::before {
    width: 1.8em;
    height: 1.8em;
  }
}

@media (min-width: 1200px) {
  .entorno-www .header-main__search-dropdown-toggle {
    height: 100%;
    padding: 0 var(--gutter-interior);
  }
  .entorno-www .header-main__search-dropdown-toggle:hover,
  .entorno-www .header-main__search-dropdown-toggle[aria-expanded="true"] {
    background-color: var(--color-secundario);
  }
  .entorno-www .header-main__search-menu .field__submit::before {
    width: 1em;
    height: 1em;
    margin-right: .5rem;
    background-color: #fff;
    content: '';
    mask-image: url(../img/svg/sprite.svg#search-view);
  }
  .entorno-www .header-main__search-dropdown-toggle[aria-expanded="true"]::before {
    width: 1.2em;
    height: 1.2em;
    background-color: #000;
    content: '';
    mask-image: url(../img/svg/sprite.svg#close-view);
    transition: all .3s;
  }
}
