/* ------------------------------------------------------------------ *\
 *  Header
\* ------------------------------------------------------------------ */
.entorno-blogs .header {
  font-family: var(--font-family-alt);
}

.entorno-blogs .header-main {
  border-bottom: 5px solid #23b42b;
}

.entorno-blogs .header-main__highlighted {
  justify-content: flex-start;
  padding-right: 0;
  padding-left: 2%;
  border: none;
  font-size: 2em;
}

.entorno-blogs .header-main__highlighted * {
  margin: 0;
  font-family: var(--font-family-alt);
  font-size: 1em;
  line-height: 1.1;
}

.entorno-blogs .header-main__highlighted h1 {
  font-size: var(--font-size-31px);
}

@media (min-width: 992px) and (max-width: 1200px) {
  .entorno-blogs .header-main__highlighted h1 {
    font-size: fluidfont(strip(var(--font-size-40px)));
  }
}

@media (min-width: 1201px) and (max-width: 1419px) {
  .entorno-blogs .header-main__highlighted h1 {
    font-size: fluidfont(strip(var(--font-size-48px)));
  }
}

@media (min-width: 1420px) {
  .entorno-blogs .header-main__highlighted h1 {
    font-size: var(--font-size-48px);
  }
}

@media (max-width: 767px) {
  .entorno-blogs .header-main__highlighted * {
    display: none;
  }
}

.entorno-blogs .header-main__logo svg {
  max-width: 8em;
}

.entorno-blogs .header-utils__nav li::after {
  content: none;
}

.entorno-blogs .header-utils__lang {
  display: block;
  flex-basis: 0;
}

@media (max-width: 1199px) {
  .entorno-blogs .header-utils__nav li,
  .entorno-blogs .header-utils .header-utils__lang {
    display: flex;
  }
}

@media (max-width: 991px) {
  .entorno-blogs .header-utils__nav li:not(:first-child) {
    display: none;
  }
  .entorno-blogs .header-utils .header-utils__lang {
    display: block;
    padding: 0;
  }
}

/* ------------------------------------------------------------------ *\
 *  Main-content
\* ------------------------------------------------------------------ */
.entorno-blogs .main-content .post {
  padding: 0;
}

/* ------------------------------------------------------------------ *\
 *  Menu contextual
\* ------------------------------------------------------------------ */
.entorno-blogs .extra-content h2 {
  --font: minimalfont(strip(var(--font-size-31px)));
  --margin: factormargin(strip(var(--font-size-31px)));
  --line: 1.3em;
  margin: 0;
  margin-top: calc(var(--margin) * var(--margin-top-factor));
  margin-bottom: calc(var(--margin) * var(--margin-bottom-heading-factor));
  color: var(--color-heading);
  font-family: var(--font-family-alt);
  font-size: var(--font);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .entorno-blogs .extra-content h2 {
    --margin: fluidmargin(strip(var(--font-size-31px)));
    font-size: fluidfont(strip(var(--font-size-31px)));
    line-height: fluidline(strip(var(--line)), strip(var(--font-size-31px)));
  }
}

@media (min-width: 1420px) {
  .entorno-blogs .extra-content h2 {
    font-size: var(--font-size-31px);
  }
}

.entorno-blogs .extra-content h2:first-child {
  margin-top: 0;
}

.entorno-blogs .extra-content li {
  display: block;
  margin-bottom: calc(var(--gutter-outer) * .5);
}

.entorno-blogs li + li {
  margin-top: 0;
}

.entorno-blogs .extra-content .searchform input[type="text"] {
  width: 100%;
}

.entorno-blogs .extra-content table th {
  border: 0;
}

.entorno-blogs .extra-content table td {
  display: table-cell;
  padding: .25em .5em;
}

@media (max-width: 1023px) {
  .entorno-blogs .extra-content table tr {
    display: table-row;
  }
  .entorno-blogs .extra-content table td {
    display: table-cell;
  }
}

.entorno-blogs hr {
  margin: 1em 0;
}

.entorno-blogs .post__content div {
  margin-bottom: 1.5em;
}
