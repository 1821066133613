/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE: CAROUSEL                                                *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *  /components/_carousel.css                                           *
 * -------------------------------------------------------------------- *
 *  Tamaño de fuente base:                                              *
 *    - Movil:  24px                                                    *
 *    - Tablet: 24px                                                    *
 *    - Web:    24px                                                    *
 * -------------------------------------------------------------------- *
 *  Descripción:                                                        *
 *                                                                      *
 *  Se utiliza en:                                                      *
 *    - home                                                            *
 *    - teatro                                                          *
 *    - turismo                                                         *
 *    - espaciosCongresos                                               *
 *    - contenidos                                                      *
 *    - Konpondu                                                        *
 *                                                                      *
\* ==================================================================== */

/*
@styleguide

@title Carruseles

El siguiente componente tiene como propósito **crear un carrusel de imagenes** que irá pasando
automaticamente de imagen a imagen a no ser que el usuario le de al pause. Puede ser un carrusel
sólo de imagenes o de enlaces con titulo y/o descripción con imagenes.


```html
<section id="carousel" class="carousel slide carousel-fade" data-interval="5500" data-ride="carousel">

  <button aria-label="titulo del botón anterior" class="carousel-control carousel-prev" aria-controls="carousel-1.674.460.179.618">
    <svg enable-background="new 8 6 7.41 12" viewBox="8 6 7.41 12" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.41,7.41L14,6l-6,6l6,6l1.41-1.41L10.83,12L15.41,7.41z"></path>
    </svg>
  </button>
  <button aria-label="titulo del botón siguiente" class="carousel-control carousel-next" aria-controls="carousel-1.674.460.179.618">
    <svg enable-background="new 8.59 6 7.41 12" viewBox="8.59 6 7.41 12" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
      <path d="M10,6L8.59,7.41L13.17,12l-4.58,4.59L10,18l6-6L10,6z"></path>
    </svg>
  </button>
  <div class="carousel-indicators" aria-controls="carousel-1.674.460.179.618">
      <button type="button" data-target="#carousel" data-slide-to="0" class="carousel-btn active" aria-label="titulo de la primera diapositiva" aria-current="true"></button>
      <button type="button" data-target="#carousel" data-slide-to="1" class="carousel-btn" aria-label="titulo de la segunda diapositiva"></button>
      <button type="button" class="play-indicators glyphicon glyphicon-pause" aria-label="titulo de continuar/pausar"></button>
  </div>
  <div class="carousel-inner" id="carousel-1.674.460.179.618" aria-live="polite">
    <div class="item active" aria-hidden="false" role="group" aria-current="true">
      <a href="https://www.vitoria-gasteiz.org">
        <picture>
          <source type="image/webp" srcset="https://dwww.vitoria-gasteiz.org/docs/wb021/img/gestor/10/41/gi_61041.webp">
          <source type="image/jpg" srcset="https://dwww.vitoria-gasteiz.org/docs/wb021/img/gestor/10/41/gi_61041.jpg">
          <img src="https://dwww.vitoria-gasteiz.org/docs/wb021/img/gestor/10/41/gi_61041.jpg" alt="">
        </picture>
        <div class="carousel-caption">
          <div>
            <p class="carousel-title"><strong>Abierto el plazo de inscripción en plazas libres</strong></p>
            <p>Para actividades de promoción social, ludotecas, práctica artística, cursos culturales, promoción de la salud y red Bizan</p>
          </div>
        </div>
      </a>
    </div>
    <div class="item" aria-hidden="true" role="group">
      <a href="https://www.vitoria-gasteiz.org" tabindex="-1">
        <picture>
          <source type="image/webp" srcset="https://dwww.vitoria-gasteiz.org/docs/wb021/img/gestor/10/51/gi_61051.webp">
          <source type="image/jpg" srcset="https://dwww.vitoria-gasteiz.org/docs/wb021/img/gestor/10/51/gi_61051.jpg">
          <img src="https://dwww.vitoria-gasteiz.org/docs/wb021/img/gestor/10/51/gi_61051.jpg" alt="">
        </picture>
        <div class="carousel-caption">
          <div>
            <p class="carousel-title"><strong>Cumbre conmemorativa del 10º aniversario de la Green Capital: "Modelos urbanos sostenibles"</strong></p>
              <p></p><p>29 y 30 de septiembre en el Palacio Europa. Apúntate y participa</p><p></p>
          </div>
        </div>
      </a>
    </div>
	</div>
</section>

<details>
  <summary>
    titulo del accordion
  </summary>
  <div>
  Texto que despliega el accordion
  </div>
</details>
<details>
  <summary>
    titulo del accordion 2
  </summary>
  <div>
  Texto que despliega el accordion 2
  </div>
</details>

```

Al cargarse la página se plegarán todos los accordion menos aquellos que tengan
marcada como visible con la etiqueta `open` dentro de details (en el ejemplo la segunda).

<div class="psg-responsive-sample">
  <details>
    <summary>
      titulo del accordion
    </summary>
    <div>
    Texto que despliega el accordion
    </div>
  </details>
  <details open>
    <summary>
      titulo del accordion 2
    </summary>
    <div>
    Texto que despliega el accordion 2
    </div>
  </details>
</div>

*/
.carousel {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-direction: column;
  align-items: flex-end;
  margin: calc(var(--rhythm) * .5) 0 var(--rhythm);
  color: var(--color-text-high-contrast);
  font-family: var(--font-family-base);
}

@media (max-width: 768px) {
  .carousel {
    width: 100vw;
    align-items: center;
    margin-bottom: var(--rhythm);
    margin-left: 50%;
    background-color: var(--background-color-alt-dark);
    transform: translateX(-50%);
  }
  .main--www .carousel,
  .main--turismo .carousel,
  .main--teatro .carousel,
  .main--congresos .carousel {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    transform: none;
  }
}

.entorno-www .carousel:hover,
.entorno-www .carousel:focus {
  color: var(--color-primario);
}

/* ------------------------------------------------------------------ *\
 *  Elemento del carrusel                                             *
\* ------------------------------------------------------------------ */
.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.main--www .carousel-inner {
  border-top: 3px solid #000;
  border-bottom: 3px solid #000;
}

.carousel-inner > .item {
  position: relative;
  display: none;
  overflow: hidden;
  text-align: left;
  transition: 1s ease-out;
}

.carousel-inner > .item > a {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: flex-end;
  padding: 0;
  color: inherit;
  overflow-x: hidden;
}

.carousel-inner > .item > a:focus {
  outline-offset: -.1em;
}

@media (max-width: 768px) {
  .carousel-inner > .item > a {
    flex-direction: column;
  }
  .main--www .carousel {
    border-top: 3px solid #000;
    border-bottom: 3px solid #000;
  }
  .main--www .carousel-inner {
    border: none;
  }
}

/*
 * Ocultar los svg de external link que
 * esten dentro de los carruseles
\* ------------------------------------------------------------------ */
.carousel-inner > .item > a > .svg__external {
  position: absolute !important;
  display: inline;
  clip: rect(1px 1px 1px 1px);

  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

/*
 * Imagen del elemento
 * ------------------------------------------------------------------
 * Dimensiones originales de imagen:
 *  Home:
 *     893 x 294 px
 *  Resto de entornos (carrusel ocupando todo el ancho):
 *    1200 x 288 px
\* ------------------------------------------------------------------ */

.carousel picture {
  display: flex;
  flex-grow: 1;
}

.carousel-inner > .item > a > img,
.carousel-inner > .item > a > picture > img {
  display: block;
  max-width: none;
  margin: 0;
}

@media (max-width: 767px) {
  .carousel picture {
    width: 100%;
  }
  .carousel-inner > .item > a > img,
  .carousel-inner > .item > a > picture > img {
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .carousel-inner > .item > a > img,
  .carousel-inner > .item > a > picture > img {
    width: 100%;
    height: 100%;
  }
}

/*
 *  Texto del elemento
\* ------------------------------------------------------------------ */
.carousel-caption {
  --readable-padding-side: var(--gutter-interior);
  position: relative;
  width: 100%;
  padding: 1.5em var(--readable-padding-side);
}

.carousel:hover .carousel-caption .carousel-title,
.carousel:focus .carousel-caption .carousel-title {
  color: var(--color-link--hover);
  text-decoration: underline;
}

.carousel:hover .carousel-caption p:not(.carousel-title),
.carousel:focus .carousel-caption p:not(.carousel-title) {
  color: var(--color-text);
}

/* trato especial teatro */
.entorno-teatro .carousel:hover .carousel-caption .carousel-title,
.entorno-teatro .carousel:focus .carousel-caption .carousel-title {
  color: inherit;
  text-decoration: underline;
}

.entorno-teatro .carousel:hover .carousel-caption p:not(.carousel-title),
.entorno-teatro .carousel:focus .carousel-caption p:not(.carousel-title) {
  color: inherit;
}

.carousel-caption:empty {
  display: none;
}

.carousel-caption .btn {
  text-shadow: none;
}

@media (min-width: 768px) {
  .carousel .carousel-caption {
    position: absolute;
    z-index: 10;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: 2em;
    padding: .4em .8em;
    background-color: rgba(255, 255, 255, .8);
  }
  .entorno-www .main--www .carousel .carousel-caption,
  .entorno-turismo .main--turismo .carousel .carousel-caption {
    position: absolute;
    right: 0;
    bottom: unset;
    left: 0;
    box-sizing: content-box;
    padding: 0;
    margin: 0 auto;
    background-color: transparent;
  }
  .entorno-www .main--www .carousel .carousel-caption {
    font-family: var(--font-family-base);
  }
  .entorno-www .main--www .carousel .carousel-caption div,
  .entorno-turismo .main--turismo .carousel .carousel-caption div {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 2em;
    margin: 0 var(--readable-padding-side);
    background-color: rgba(255, 255, 255, .8);
  }
  .entorno-turismo .main--turismo .carousel .carousel-caption div {
    display: flex;
    padding: .4em .8em;
    margin: 0;
  }
  .entorno-teatro .carousel .carousel-caption {
    background: rgba(0, 0, 0, .7);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .entorno-www .main--www .carousel .carousel-caption,
  .entorno-turismo .main--turismo .carousel .carousel-caption {
    --readable-padding-side: 3.5em;
    --readable-area: 920px;
  }
  .entorno-www .main--www .carousel .carousel-caption div,
  .entorno-turismo .main--turismo .carousel .carousel-caption div {
    max-width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1419px) {
  .entorno-www .main--www .carousel .carousel-caption {
    --readable-padding-side: 5em;
    --readable-area: 1220px;
  }
  .entorno-www .main--www .carousel .carousel-caption div {
    max-width: 55%;
  }
}

@media (min-width: 1420px) {
  .entorno-www .main--www .carousel .carousel-caption {
    --readable-padding-side: 5em;
    --readable-area: 1420px;
  }
  .entorno-www .main--www .carousel .carousel-caption div {
    max-width: 35%;
  }
}

.carousel h1,
.carousel .carousel-title {
  --line: 1.3em;
  --font: minimalfont(strip(var(--font-size-35px)));
  margin: 0;
  color: inherit;
  font-family: var(--font-family-alt-semi);
  line-height: var(--line);
}

.entorno-turismo .carousel,
.entorno-turismo .carousel h1,
.entorno-turismo .carousel-title {
  font-family: var(--font-family-turismo-base);
}

.carousel p {
  margin: 0;
  font-size: var(--font-size-18px);
  line-height: var(--rhythm);
}

.entorno-www .main--www .carousel p:not(.carousel-title),
.entorno-teatro .main--teatro .carousel p:not(.carousel-title),
.entorno-turismo .main--turismo .carousel p:not(.carousel-title),
.entorno-congresos .main--congresos .carousel p:not(.carousel-title) {
  margin-top: .5em;
}

@media (min-width: 768px) {
  .carousel p:not(.carousel-title) {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}

@media (min-width: 320px) and (max-width: 1419px) {
  .carousel h1,
  .carousel .carousel-title {
    font-size: fluidfont(strip(var(--font-size-35px)));
    line-height: fluidline(strip(var(--line)), strip(var(--font-size-35px)));
  }
  .carousel p {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .carousel h1,
  .carousel .carousel-title {
    font-size: var(--font-size-35px);
  }
  .carousel p {
    font-size: var(--font-size-24px);
  }
}

.carousel .carousel-title strong {
  font-weight: normal;
}

/* ------------------------------------------------------------------ *\
 *  Controles del carrusel                                            *
\* ------------------------------------------------------------------ */
.carousel-indicators {
  display: flex;
  align-items: center;
  justify-content: center;
  order: 1;
  padding: 0;
  margin: 0;
  list-style: none;
}

.carousel-prev,
.carousel-next {
  display: none;
}

.carousel-indicators li,
.carousel-indicators button {
  display: inline-flex;
  width: 48px;
  height: 48px;
  min-height: 18px;
  align-items: center;
  padding: 0;
  border: none;
  margin: 0;
  background-color: transparent;
  border-radius: 50%;
  color: var(--color-text-high-contrast);
  text-indent: 0;
}

.carousel-indicators .carousel-btn::before {
  display: block;
  width: 20px;
  height: 20px;
  margin: auto;
  background: #000;
  border-radius: 50%;
  content: '';
}

.carousel-indicators .active::before {
  background: var(--color-secundario);
}

.carousel-indicators .play-indicators {
  width: 48px;
  border: none;
  background-color: transparent;
  font-size: 1.2em;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

@media (min-width: 768px) {
  .carousel-indicators {
    justify-content: flex-end;
    margin-top: .2em;
    margin-bottom: 0;
  }
  .carousel-indicators li,
  .carousel-indicators button {
    width: 18px;
    height: 18px;
    border: 0;
    margin-top: 2px;
    margin-right: .3em;
    background-color: transparent;
    color: var(--color-text-high-contrast);
  }
  .carousel-indicators .carousel-btn::before {
    width: 100%;
    height: 100%;
  }
  .carousel-indicators .play-indicators {
    width: 27px;
    height: 27px;
    margin-top: 0;
    margin-left: .2em;
    font-size: 1.1em;
  }
}

/* ------------------------------------------------------------------ *\
 *  Animación del carrusel en 3d no es necesaria                      *
 *  NO TOCAR                                                          *
\* ------------------------------------------------------------------ */

/* @media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
    transition: transform .6s ease-in-out;

    backface-visibility: hidden;
    perspective: 1000px;
  }
  .carousel-inner > .item.next,
  .carousel-inner > .item.active.right {
    left: 0;

    transform: translate3d(100%, 0, 0);
  }
  .carousel-inner > .item.prev,
  .carousel-inner > .item.active.left {
    left: 0;

    transform: translate3d(-100%, 0, 0);
  }
  .carousel-inner > .item.next.left,
  .carousel-inner > .item.prev.right,
  .carousel-inner > .item.active {
    left: 0;

    transform: translate3d(0, 0, 0);
  }
} */

/* ------------------------------------------------------------------ *\
 *  Animación 2D del carrusel                                         *
 *  NO TOCAR                                                          *
\* ------------------------------------------------------------------ */
.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block;
}

.carousel-inner > .active {
  left: 0;
}

.carousel-inner > .next,
.carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}

.carousel-inner > .next {
  left: 100%;
}

.carousel-inner > .prev {
  left: -100%;
}

.carousel-inner > .next.left,
.carousel-inner > .prev.right {
  left: 0;
}

.carousel-inner > .active.left {
  left: -100%;
}

.carousel-inner > .active.right {
  left: 100%;
}

.entorno-turismo .carousel-fade .carousel-inner .item,
.entorno-www .carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}

.entorno-turismo .carousel-fade .carousel-inner .active,
.entorno-www .carousel-fade .carousel-inner .active {
  opacity: 1;
}

.entorno-turismo .carousel-fade .carousel-inner .active.left,
.entorno-turismo .carousel-fade .carousel-inner .active.right,
.entorno-www .carousel-fade .carousel-inner .active.left,
.entorno-www .carousel-fade .carousel-inner .active.right {
  z-index: 1;
  left: 0;
  opacity: 0;
  text-align: left;
}

.entorno-turismo .carousel-fade .carousel-inner .next.left,
.entorno-turismo .carousel-fade .carousel-inner .prev.right,
.entorno-www .carousel-fade .carousel-inner .next.left,
.entorno-www .carousel-fade .carousel-inner .prev.right {
  opacity: 1;
  text-align: left;
}

/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE: CAROUSEL                                                *
 *                                                                      *
 *  MODIFICARDOR: ENTORNOS TORISMO / CONGRESOS / TEATRO                 *
 *                                                                      *
 *  ------------------------------------------------------------------- *
 *  /components/_carousel.css                                           *
\* ==================================================================== */
.entorno-congresos .carousel h1,
.entorno-turismo .carousel h1,
.entorno-www .carousel h1 {
  font-size: 1.33em;
}

.entorno-www .carousel h1 {
  font-weight: bold;
}

.entorno-www .main--www .carousel,
.entorno-teatro .main--teatro .carousel,
.entorno-turismo .main--turismo .carousel,
.entorno-congresos .main--congresos .carousel {
  margin-top: 0;
  margin-bottom: 0;
}

.entorno-teatro .carousel {
  background: #000;
  color: var(--color-text-inverse);
}

.carousel .glyphicon-play::before,
.carousel .glyphicon-pause::before {
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--color-text);
  content: "";
  mask-image: url(../img/svg/sprite.svg#icon-play-view);
}

.entorno-teatro .carousel .glyphicon-play::before,
.entorno-teatro .carousel .glyphicon-pause::before {
  background-color: var(--color-text-inverse);
}

@media (max-width: 768px) {
  .carousel .glyphicon-play::before,
  .carousel .glyphicon-pause::before {
    width: 34px;
    height: 34px;
    margin: auto;
  }
}

.carousel .glyphicon-pause::before {
  mask-image: url(../img/svg/sprite.svg#icon-pause-view);
}

.carousel .glyphicon-play__inactive {
  display: none;
  pointer-events: none;
}

.carousel .glyphicon-play__inactive::before {
  background-color: var(--background-color-alt-darker);
}

.entorno-teatro .carousel .glyphicon-play__inactive::before {
  background-color: #fff;
}

.entorno-teatro .carousel-indicators .carousel-btn::before {
  background: white;
}

.entorno-teatro .carousel-indicators .active::before {
  background: var(--color-entorno-teatro);
}
