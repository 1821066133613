/* ==================================================================== *\
 *                                                                      *
 *  FICHA: SUSCRIPCIONES A INFORMACIOn                                  *
 *                                                                      *
 *  ------------------------------------------------------------------  *
 *  /pages/_ficha--suscripcionesRSS.css                                 *
 *  ------------------------------------------------------------------  *
 *  Estilos propios de la ficha                                         *
\* ==================================================================== */

/* -------------------------------------------------------------------- *\
 *  LISTA DE CANALES RSS                                                *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *    article.newsRSS                                                   *
 *      h1                                                              *
 *        img                                                           *
 *      ul                                                              *
 *        li.icon-arrow_link1                                           *
 *          a                                                           *
 *                                                                      *
\* -------------------------------------------------------------------- */
.newsRSS {
  padding-top: .5em;
  margin-bottom: 1em;
}

.newsRSS > h1 {
  font-size: 1.167em;
}

.newsRSS > h1 > img,
.newsRSS > h1 > picture > img {
  width: 1em;
  height: 1em;
  margin-top: .1em;
  margin-right: .3em;
  vertical-align: text-top;
}
