/* ================================================================== *\
 *                                                                    *
 *  APLICACIÓN:                                                       *
 *  h05-01w                                                           *
 *  KONPONDU                                                          *
 *                                                                    *
 *  URL:                                                              *
 *                                                                    *
 * ------------------------------------------------------------------ *
 *  /pages/_h05-01w.css                                               *
\* ================================================================== */

.h05 .network__list {
  display: flex;
  flex-flow: wrap;
  column-gap: 1em;
  list-style-image: none;
  list-style-type: none;
}

.h05 .network__list li {
  margin-right: 1em;
}

.h05 .network__list a {
  display: flex;
  align-items: center;
  padding-left: 3em;
  word-break: break-word;
}

.h05 .btn:not(:last-of-type) {
  margin-right: .6em;
}

.h05 .btn + .btn {
  margin-left: 0;
}
