@font-face {
  font-display: swap;
  font-family: "Pathway Gothic One";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/PathwayGothicOne-Regular.eot");
  src: url("../fonts/PathwayGothicOne-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/PathwayGothicOne-Regular.svg#Pathway Gothic One") format("svg"), url("../fonts/PathwayGothicOne-Regular.woff2") format("woff2"), url("../fonts/PathwayGothicOne-Regular.woff") format("woff"), url("../fonts/PathwayGothicOne-Regular.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "PT Sans Narrow";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/PTN57F.eot");
  src: url("../fonts/PTN57F.eot?#iefix") format("embedded-opentype"), url("../fonts/PTN57F.svg#PT Sans Narrow") format("svg"), url("../fonts/PTN57F.woff2") format("woff2"), url("../fonts/PTN57F.woff") format("woff"), url("../fonts/PTN57F.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Oswald Bold";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Oswald-Bold.eot");
  src: url("../fonts/Oswald-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Oswald-Bold.svg#Oswald Bold") format("svg"), url("../fonts/Oswald-Bold.woff2") format("woff2"), url("../fonts/Oswald-Bold.woff") format("woff"), url("../fonts/Oswald-Bold.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Oswald Light";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Oswald-Light.eot");
  src: url("../fonts/Oswald-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Oswald-Light.svg#Oswald Light") format("svg"), url("../fonts/Oswald-Light.woff2") format("woff2"), url("../fonts/Oswald-Light.woff") format("woff"), url("../fonts/Oswald-Light.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Oswald Regular";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Oswald-Regular.eot");
  src: url("../fonts/Oswald-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Oswald-Regular.svg#Oswald Regular") format("svg"), url("../fonts/Oswald-Regular.woff2") format("woff2"), url("../fonts/Oswald-Regular.woff") format("woff"), url("../fonts/Oswald-Regular.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  src: local('Lato Regular'), local('Lato-Regular'), url('../fonts/subset-Lato-Regular.woff2') format('woff2'), url('../fonts/subset-Lato-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  src: local('Lato Bold'), local('Lato-Bold'), url('../fonts/subset-Lato-Bold.woff2') format('woff2'), url('../fonts/subset-Lato-Bold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: normal;
  src: local('Merriweather Regular'), local('Merriweather-Regular'), url('../fonts/subset-Merriweather-Regular.woff2') format('woff2'), url('../fonts/subset-Merriweather-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: bold;
  src: local('Merriweather Bold'), local('Merriweather-Bold'), url('../fonts/subset-Merriweather-Bold.woff2') format('woff2'), url('../fonts/subset-Merriweather-Bold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url('../fonts/subset-OpenSans-Bold.woff2') format('woff2'), url('../fonts/subset-OpenSans-Bold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url('../fonts/subset-OpenSans-Regular.woff2') format('woff2'), url('../fonts/subset-OpenSans-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Montserrat Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url("../fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: 'Montserrat Light';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat light'), local('Montserrat-Light'), url("../fonts/Montserrat-Light.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: 'Montserrat Bold';
  font-style: normal;
  font-weight: bolder;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url("../fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: 'Montserrat Semi Bold';
  font-style: normal;
  font-weight: bold;
  src: local('Montserrat Semi Bold'), local('Montserrat-SemiBold'), url("../fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: 'Source Sans Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url("../fonts/SourceSansPro-Regular.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: 'Source Sans Pro Bold';
  font-style: normal;
  font-weight: bold;
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url("../fonts/SourceSansPro-Bold.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: 'Source Sans Pro Semi Bold';
  font-style: normal;
  font-weight: bold;
  src: local('Source Sans Pro Semi Bold'), local('SourceSansPro-SemiBold'), url("../fonts/SourceSansPro-SemiBold.ttf") format("truetype");
}
