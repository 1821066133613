:root {
  --litepicker-multiselect-is-selected-color-bg: #2196f3;
  --litepicker-multiselect-is-selected-color: #fff;
  --litepicker-multiselect-hover-color-bg: #2196f3;
  --litepicker-multiselect-hover-color: #fff;
}

.litepicker[data-plugins*=multiselect] .container__days .day-item {
  position: relative;
  z-index: 1;
}

.litepicker[data-plugins*=multiselect] .container__days .day-item:not(.is-locked)::after {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  content: "";
  transform: translate(-50%, -50%);
}

.litepicker[data-plugins*=multiselect] .container__days .day-item:not(.is-locked):hover {
  box-shadow: none;
  color: var(--litepicker-day-color);
  font-weight: bold;
}

.litepicker[data-plugins*=multiselect] .container__days .day-item.is-selected,
.litepicker[data-plugins*=multiselect] .container__days .day-item.is-selected:hover {
  color: var(--litepicker-multiselect-is-selected-color);
}

.litepicker[data-plugins*=multiselect] .container__days .day-item.is-selected::after {
  background-color: var(--litepicker-multiselect-is-selected-color-bg);
  color: var(--litepicker-multiselect-is-selected-color);
}
