.div__loader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.calendar-spinner {
  width: 2em;
  height: 2em;
  animation: spin .6s ease-in-out infinite both;
  background-color: var(--background-color-alt-darker);
  content: '';
  mask-image: url(../img/svg/sprite.svg#icon-load-view);
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
