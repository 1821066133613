/* ==================================================================== *\
 *                                                                      *
 *  ESTILOS BÁSICOS DE FORMULARIOS                                      *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *  /base/_forms.css                                                    *
 * -------------------------------------------------------------------- *
 *  Tamaño de fuente base:                                              *
 *    - Movil:  18px                                                    *
 *    - Tablet: 18px                                                    *
 *    - Web:    18px                                                    *
\* ==================================================================== */
@custom-selector :--base-form__btn input[type="button"], input[type="reset"], input[type="submit"], form button;

form {
  font-size: var(--font-size-18px);
  line-height: var(--rhythm);
}

@media (min-width: 320px) and (max-width: 1419px) {
  form {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  form {
    font-size: var(--font-size-24px);
  }
}

fieldset {
  padding: 0;
  border: none;
}

/**
 * Margen inferior de un formulario que está seguido de algun otro
 * elemento. Es decir, no es lo último de la capa contenedora.
 * Para la etiqueta en general sólo se aplicará dentro de main-content
 * para evitar desajustes en los antiguos estilos.
 */
.main-content form + * {
  margin-top: var(--rhythm);
}

input,
select,
textarea,
form button,
.form-control {
  --rhythm-increase: .5;
  height: 1.667em;
  padding: 0 var(--gutter-interior-form);
  border: 1px solid var(--input-border-color);
  margin-bottom: calc(var(--rhythm) * strip(var(--rhythm-increase, 1)));
  background-color: var(--input-background-color);
  border-radius: 0;
  color: var(--input-color);
  font-size: var(--font-size-18px);
  line-height: var(--rhythm);
}

input::placeholder,
select::placeholder,
textarea::placeholder {
  color: var(--color-text-grey);
}

@media (min-width: 320px) and (max-width: 1419px) {
  input,
  select,
  textarea,
  form button,
  .form-control {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  input,
  select,
  textarea,
  form button,
  .form-control {
    font-size: var(--font-size-24px);
  }
}

input:focus,
select:focus,
textarea:focus,
.form-control:focus {
  border-color: var(--color-focused);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px var(--color-focused);
}

input[type="radio"],
input[type="checkbox"] {
  display: inline-block;
  width: auto;
  height: auto;
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
  line-height: normal;
}

input[type="file"] {
  height: auto;
  padding: 0;
  box-shadow: none;
}

/**
 * 1. No afectar a IE8
 */
.flexbox input[type="file"] {
  border: none; /* [1] */
  background-color: transparent; /* [1] */
}

select {
  height: auto;
  padding: 0 2rem 0 var(--gutter-interior-form);
  appearance: none;
  background-image: url(../img/svg/sprite.svg#icon-menu-down-view);
  background-position: right 0 top 50%;
  background-repeat: no-repeat;
  background-size: 1em 1em;
}

select + input[type="button"],
select + input[type="submit"] {
  margin-left: .4em;
}

textarea {
  height: auto;
}

:--base-form__btn {
  padding: 0 var(--gutter-interior);
  border: 0;
  background-color: var(--btn-background-color);
  border-radius: 0;
  color: var(--btn-color);

  /* font-size: 1em; */
}

.input--date-polyfill,
input.hasDatepicker {
  width: var(--input-date-width);
}

.entorno-turismo .input--date-polyfill,
input.hasDatepicker {
  width: var(--input-turismo-date-width);
}

@media (max-width: 767px) {
  input,
  select {
    width: 100%;
  }

  /**
   * 1. Los campos con el polyfill de input[type="date"] presentan una imagen a su derecha.
   *    Se ajusta la anchura del campo restando lo que ocuparía el icono de calendario.
   * 2. Los campos con date-picker de jQuery-UI presentan tambien una imagen a su derecha.
   *    Se ajusta la anchura del campo restando lo que ocuparía el icono de calendario.
   */
  .input--date-polyfill,
  input.hasDatepicker {
    width: calc(100% - 2.2em) !important;
  }
  :--base-form__btn,
  input[type="radio"],
  input[type="checkbox"] {
    width: auto;
  }

  /* Fix del input que desbordaba sala de prensa */
  #medios-agenda-ecalendar input {
    width: 0;
  }
  textarea {
    width: 100%;
  }
}

[type="text"] + img,
[type="text"] + picture > img {
  height: 1.7em;
  margin-left: .2em;
  vertical-align: top;
}

[readonly],
[disabled],
button[readonly],
button[disabled],
.form-control[readonly],
.form-control[disabled] {
  box-shadow: none;
  cursor: not-allowed;
  opacity: .6;
}

[disabled],
button[disabled],
.form-control[disabled] {
  background-color: #f2f2e9;
  color: var(--color-disabled);
  cursor: not-allowed;
}

/* Checckbox y label */
[disabled] + label {
  background-color: transparent;
  color: var(--color-disabled);
  cursor: not-allowed;
}

:--base-form__btn[readonly],
:--base-form__btn[disabled] {
  background-color: var(--color-disabled);
  color: #fff;
  opacity: .5;
}

form label {
  display: block;
}

label {
  --rhythm-increase: .5;
  max-width: none;

  /* line-height: 1em; */

  /* margin: fluidmargin(var(--rhythm-increase, 1)); */
  color: var(--color-text);
  font-weight: normal;
}

label > input,
label > select,
label > textarea {
  margin-top: .4em;
}

label > input[type="radio"],
label > input[type="checkbox"] {
  margin-top: unset;
}

/**
 * Se han encontrado páginas donde hay labels dentro de labels:
 * https://sedeelectronica.vitoria-gasteiz.org/lke-01s/auth/inicioAction.do
 *
 * 1. Se eliminan los márgenes para que no se dupliquen al sumarlos a los del
 *    label padre
 */
label > label {
  margin: 0; /* [1] */
}

input + label,
select + label,
textarea + label {
  margin-top: 1em;
}

/**
 * 1. Antes estaba como inline-block pero habia un fallo de visualización
 *    cuando el label era muy largo. Ejemplo:
 *    https://sedeelectronica.vitoria-gasteiz.org/i01-20s/gestionAction.do?accion=introducirDatosPlaza
 */
input[type="radio"] + label,
input[type="checkbox"] + label {
  display: inline; /* [1] */
  margin-top: 0;
  margin-left: .4em;
}

label + input[type="radio"],
label + input[type="checkbox"] {
  display: inline-block;
  margin-right: .4em;
}

input[type="radio"] + img,
input[type="radio"] + picture > img,
input[type="checkbox"] + img,
input[type="checkbox"] + picture > img {
  height: auto;
}

/**
 * Estandarizar el aspecto de los formularios antiguos que usan dt y dd en su interior.
 */
form dl.formulario dt {
  margin-bottom: 0;
}

/* input[type=file] {
  visibility: hidden;
}

input[type=file]::before {
  display: inline-flex;
  height: 2em;
  align-items: center;
  padding: 0 .556em;
  border: 0;
  background-color: var(--color-primario);
  color: var(--color-text-inverse);
  content: attr(data-text);
  cursor: pointer;
  visibility: visible;
} */
