:root {
  --litepicker-container-months-color-bg: #fff;
  --litepicker-container-months-box-shadow-color: #ddd;
  --litepicker-footer-color-bg: #fafafa;
  --litepicker-footer-box-shadow-color: #ddd;
  --litepicker-tooltip-color-bg: #fff;
  --litepicker-month-header-color: #333;
  --litepicker-button-prev-month-color: #9e9e9e;
  --litepicker-button-next-month-color: #9e9e9e;
  --litepicker-button-prev-month-color-hover: #2196f3;
  --litepicker-button-next-month-color-hover: #2196f3;
  --litepicker-day-width: 50px;
  --litepicker-day-color: #333;
  --litepicker-day-color-hover: #2196f3;
  --litepicker-month-width: calc(var(--litepicker-day-width) * 7);
  --litepicker-month-weekday-color: #9e9e9e;
  --litepicker-month-week-number-color: #9e9e9e;
  --litepicker-is-today-color: #f44336;
  --litepicker-is-in-range-color: #bbdefb;
  --litepicker-is-locked-color: #9e9e9e;
  --litepicker-is-start-color: #fff;
  --litepicker-is-start-color-bg: #2196f3;
  --litepicker-is-end-color: #fff;
  --litepicker-is-end-color-bg: #2196f3;
  --litepicker-button-cancel-color: #fff;
  --litepicker-button-cancel-color-bg: #9e9e9e;
  --litepicker-button-apply-color: #fff;
  --litepicker-button-apply-color-bg: #2196f3;
  --litepicker-button-reset-color: #909090;
  --litepicker-button-reset-color-hover: #2196f3;
  --litepicker-highlighted-day-color: #333;
  --litepicker-highlighted-day-color-bg: #ffeb3b;
}

.litepicker {
  font-family: var(--font-family-base);
  font-size: 1em;
}

.litepicker button {
  color: var(--color-text);
}

.litepicker input,
.litepicker select,
.litepicker textarea {
  width: auto;
}

.litepicker .container__months .month-item-header div > .month-item-year {
  padding: .085em 0 .085em .15em;
}

.litepicker .container__months .month-item-header .button-previous-month:hover {
  background-color: transparent;
}

.litepicker .container__months .month-item-header .button-next-month:hover {
  background-color: transparent;
}

.litepicker .container__months .month-item:first-child .button-previous-month {
  margin: 0;
}

.litepicker .container__months .month-item:last-child .button-next-month {
  margin: 0;
}
