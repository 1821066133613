table {
  width: 100%;
  margin-bottom: var(--rhythm);
  border-collapse: collapse;
  color: var(--color-text-high-contrast);
  font-size: inherit;
}

body:not(.entorno-www.wb021) table {
  font-size: var(--font-size-18px);
}

caption {
  --font: minimalfont(strip(var(--font-size-24px)));
  --margin: factormargin(strip(var(--font-size-24px)));
  margin: 0;
  margin-bottom: calc(var(--margin) * var(--margin-bottom-heading-factor));
  font-family: var(--font-family-heading);
  font-size: var(--font);
  text-align: left;
}

@media (min-width: 320px) and (max-width: 1419px) {
  caption {
    --margin: fluidmargin(strip(var(--font-size-24px)));
    --font: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  caption {
    --font: var(--font-size-24px);
  }
}

td,
th {
  padding: .25em .5em .25em 1em;
  border: 2px solid var(--background-color);
  text-align: left;
}

th {
  background-color: var(--color-text-high-contrast);
  color: var(--color-text-inverse);
  color-adjust: exact;
  -webkit-print-color-adjust: exact;
  text-align: left;
}

th > * {
  color: var(--color-text-inverse);
}

tr:nth-child(even) > td {
  background-color: rgb(231, 242, 244, .5);
  color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

tr:nth-child(odd) > td {
  background-color: rgb(244, 248, 250, .5);
  color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

/**
 * 1. Bootstrap aplica negrita en los label por defecto. Se debe anular
 *    dentro de las tablas, porque magic-tables.js parece utilizarlos.
 */

table label {
  font-weight: normal; /* [1] */
}

/**
 * 1. Aplicar el "display: block" sólo a los label que no se utilicen dentro de una
 *    tabla de Magic Tables
 */
.rt-table label,
.magic-table label {
  display: inline; /* [1] */
}

label.rt-visible,
label.rt-block,
.rt-table .rt-skip {
  display: none;
}

/* =================================================================== *\
   *  MODIFICADORES PARA LAS NUEVAS TABLAS                               *
  \* =================================================================== */

@custom-selector :--td td, .rt-table tr td;

@media screen and (max-width: 1023px) {
  table:not(.ui-datepicker-calendar) tr,
  table:not(.ui-datepicker-calendar) td {
    display: block;
  }
  table:not(.ui-datepicker-calendar) th,
  table:not(.ui-datepicker-calendar) td {
    border: none;
  }
  .rt-table .rt-visible {
    display: block;
  }

  /* .entorno-www #wb021contenido :--td {
    display: grid;
    grid-gap: 1em .5em;
    grid-template-columns: 30% auto;
  } */
}

@media screen and (min-width: 1024px) {
  table:not(.ui-datepicker-calendar) td {
    vertical-align: text-top;
  }
  table:not(.ui-datepicker-calendar) th {
    vertical-align: bottom;
  }
  table:not(.ui-datepicker-calendar) th,
  table:not(.ui-datepicker-calendar) td {
    text-align: left;
  }
}

@media screen {
  [role="region"][tabindex] {
    overflow: auto;
    width: 100%;
    background:
      linear-gradient(to right, #fff 30%, rgba(255, 255, 255, 0)),
      linear-gradient(to right, rgba(255, 255, 255, 0), #fff 70%) 0 100%,
      radial-gradient(
        farthest-side at 0% 50%,
        rgba(0, 0, 0, .2),
        rgba(0, 0, 0, 0)
      ),
      radial-gradient(
        farthest-side at 100% 50%,
        rgba(0, 0, 0, .2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
    background-attachment: local, local, scroll, scroll;
    background-color: #fff;
    background-position: 0 0, 100%, 0 0, 100%;
    background-repeat: no-repeat;
    background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
  }
  [role="region"][tabindex] table {
    border: none;
  }
}
