/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE: CABECERA DE INTRANET                                    *
 *                                                                      *
 *  ------------------------------------------------------------------- *
 *  /components/_intranet-header.css                                    *
 *  ------------------------------------------------------------------- *
 *  Tamaño de fuente base:                                              *
 *    - Movil:  16px                                                    *
 *    - Tablet: 16px                                                    *
 *    - Web:    16px                                                    *
\* ==================================================================== */
#intranet-header {
  height: auto;
  padding: 0;
  margin: 0;

  /* height: 40px; */
  background: #02604b;
  color: var(--color-text-inverse);
  font-family: var(--font-family-base);
  font-size: initial;
}

#intranet-header::after {
  display: table;
  clear: both;
  content: '';
}

#intranet-header .container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-around;
  padding: 4px 25px;
}

#intranet-header ul {
  display: inline-block;
  padding: 0;
  margin: 0 auto 0 0;
}

#intranet-header li {
  display: inline;
  padding-right: .5em;
  border-right: 1px solid;
  margin-right: .5em;
  margin-left: 0;
}

#intranet-header li:nth-child(3) {
  border-right: none;
}

#intranet-header li a {
  color: var(--color-text-inverse);
}

#intranet-header .idioma,
#intranet-header form {
  padding: 0;
  margin: 0;
  background: 0 0;
  float: none;
  line-height: 0;
}

#intranet-header label {
  display: none;
}

#intranet-header #terminoIntranet {
  width: auto;
  height: 1.5em;
  min-height: initial;
  padding: 0 .5rem;
  border: 1px solid var(--input-border-color);
  margin: 0;
  color: var(--input-color);
  font-size: var(--font-size-18px);
}

#intranet-header .botonCabecera {
  width: auto;
  height: 1.5em;
  min-height: initial;
  padding: 0 1.5rem;
  border: 2px solid #000;
  margin: 0;
  background-color: #000;
  color: #fff;
  font-family: var(--font-family-base);
  font-size: var(--font-size-18px);
}

@media (max-width: 767px) {
  #intranet-header .container {
    height: auto;
    flex-direction: column;
    padding: 2px 20px 5px;
  }
  #intranet-header ul {
    margin: .5em 0 1em;
  }
  #intranet-header li {
    padding: 0;
    margin: 0;
  }
  #intranet-header li a {
    padding: 15px 10px;
  }
}
