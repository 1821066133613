/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE: LISTADO DE NOTICIAS                                     *
 *                                                                      *
 *  ------------------------------------------------------------------- *
 *  /components/lists/_news-list.css                                    *
 *  ------------------------------------------------------------------- *
 *  Tamaño de fuente base:                                              *
 *    - Movil:  18px                                                    *
 *    - Tablet: 18px                                                    *
 *    - Web:    18px                                                    *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *    div.news-list                                                     *
 *      article.news (COMPONENTE: NOTICIA)                              *
 *                                                                      *
\* ==================================================================== */

/* .news-list {
  font-size: var(--font-size-18px);
} */

.news-list .news {
  padding: 0;
}

.menuContextual .news-list .news {
  padding: 0;
  margin: 0 0 calc(var(--rhythm));
}

.news-list .news--highlight {
  padding: 0;
  margin: 0;
}

.news-list .news p {
  margin: 0;
}

.news-list .news p.news__title {
  margin-bottom: calc(var(--rhythm) * .5);
}

.news-list .news *:last-child {
  margin-bottom: 0;
}

/**
 * 1. Si la lista de items es una UL, no se presenta de forma normal,
 *    ya que las noticias nunca tienen punto a su izquierda.
 *    Se elimina el padding por defecto de las listas.
 */
.news-list__group-items {
  padding-left: 0; /* [1] */
  margin: 0;
  list-style: none;
}

.news-list__group-items > li {
  margin-bottom: calc(var(--rhythm) * 2);
}

.news-list__group-items > li:last-child {
  margin-bottom: 0;
}

.entorno-turismo .news-list__group-items .news {
  display: block;
  padding-left: 15px;
  margin-left: .5em;
  background: url(../img/arrow_link2.png) no-repeat;
  background-position: 0 .35em;
  list-style: none;
}

/**
 * Listados incluidos en el menu contextual.
\* ------------------------------------------------------------------- */

/**
 * 1. Es necesario añadirles la masa de fondo, ya que el menú no la tiene
 *    por defecto.
 * 2. Se añade en este caso un espacio inferior con dicha masa de fondo.
 */
.menuContextual .news-list {
  padding-bottom: .5em; /* [2] */
  background-color: transparent; /* [1] */
}

/**
 * 1. La visualización interior varía.
 *    Las imagenes llegan hasta el borde de la masa blanca, mientras
 *    que el padding lo llevan la fecha y el titulo de la noticia.
 */

.news-list .news__link > .news__title:last-child {
  padding: var(--gutter-interior-aside);
  margin: 0;
}

.news-list .news__link > .news__title:not(:last-child) {
  padding: 1.2rem var(--gutter-interior) 0;
}

.news-list .news__link > .news__summary {
  padding: 0 var(--gutter-interior) 1.2rem;
}

/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE:   LISTADO DE NOTICIAS                                   *
 *  MODIFICADOR:  NOTICIAS DESTACADAS                                   *
 *                                                                      *
 *  ------------------------------------------------------------------- *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *    div.news-list.news-list--highlights                               *
 *      article.news.news--highlight                                    *
 *                                                                      *
\* ==================================================================== */

/* .news-list--hightlights {} */

/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE:   LISTADO DE NOTICIAS                                   *
 *  MODIFICADOR:  NOTICIAS AGRUPADAS POR FECHA                          *
 *                                                                      *
 *  ------------------------------------------------------------------- *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *    div.news-list.news-list--group-by--date                           *
 *      section.news-list__group                                        *
 *        hX.news-list__group-title                                     *
 *          time                                                        *
 *        article.news                                                  *
 *                                                                      *
\* ==================================================================== */

/**
 * Titulo de la sección: Fecha
 *
 * 1. El listado de noticias puede ir incrustado dentro de un contenido
 *    estático, por lo que debe sobreescribir estilos que por defecto
 *    se añaden a etiqutas como h2.
 *    A revisar en el futuro, por si se puede eliminar este selector.
 *    .interior #wb021contenido .news-list__group-title
 */
.news-list--group-by--date .news-list__group-title,
.interior #wb021contenido .news-list__group-title {
  position: sticky;
  z-index: 996;
  top: 0;
  display: block;
  padding: .5em 0;
  margin: 0;
  margin-top: calc(var(--margin) * var(--margin-top-factor) - .5em);
  margin-bottom: calc(var(--margin) * var(--margin-bottom-heading-factor) - .5em);
  background-color: var(--background-color);
  color: var(--color-heading3);
  font-family: var(--font-family-alt);
  font-size: var(--font);
  line-height: var(--line);

  --font: minimalfont(strip(var(--font-size-31px)));
  --margin: factormargin(strip(var(--font-size-31px)));
  --line: 1.3em;
}

.menuContextual .news-list--group-by--date .news-list__group-title {
  position: relative;
  background-color: transparent;
}

.news-list__group:first-child .news-list__group-title {
  margin-top: 0;
}

.news-list--group-by--date .news-list__group-title::first-letter,
.interior #wb021contenido .news-list__group-title::first-letter {
  text-transform: capitalize;
}

@media (min-width: 320px) and (max-width: 1419px) {
  .news-list--group-by--date .news-list__group-title,
  .interior #wb021contenido .news-list__group-title {
    --margin: fluidmargin(strip(var(--font-size-31px)));
    font-size: fluidfont(strip(var(--font-size-31px)));
    line-height: fluidline(strip(var(--line)), strip(var(--font-size-31px)));
  }
}

@media (min-width: 1420px) {
  .news-list--group-by--date .news-list__group-title,
  .interior #wb021contenido .news-list__group-title {
    font-size: var(--font-size-31px);
  }
}

.entorno-turismo .news-list--group-by--date .news-list__group-title,
.entorno-turismo .interior #wb021contenido .news-list__group-title {
  padding: .2em .5em;
  background-color: var(--background-turismo-color);
  color: var(--color-text-high-contrast);
  font-family: var(--font-family-turismo-base);
  font-size: var(--font-size-18px);
  font-weight: bold;
}
