.litepicker {
  font-family: var(--font-family-base);
  font-size: 1em;
}

.litepicker button {
  color: var(--color-text);
}

.litepicker input,
.litepicker select,
.litepicker textarea {
  width: auto;
}

.litepicker[data-plugins*=ranges] > .container__main > .container__predefined-ranges,
.litepicker .container__months {
  border-radius: 0;
}

.litepicker .container__months .month-item-header div > .month-item-name,
.litepicker .container__months .month-item-header div > .month-item-year {
  margin-bottom: 0;
}

.litepicker .container__months .month-item-header div > .month-item-year {
  padding: .085em 0 .085em .15em;
}

.litepicker .container__months .month-item-header .button-previous-month:hover {
  background-color: transparent;
}

.litepicker .container__months .month-item-header .button-next-month:hover {
  background-color: transparent;
}

.litepicker .container__months .month-item:first-child .button-previous-month {
  margin: 0;
}

.litepicker .container__months .month-item:last-child .button-next-month {
  margin: 0;
}
