.show-week-numbers {
  --litepicker-month-width: calc(var(--litepicker-day-width) * 8);
}

.litepicker {
  display: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: .8em;
}

.litepicker button {
  border: none;
  background: none;
}

.litepicker .container__main {
  display: flex;
}

.litepicker .container__months {
  display: flex;
  width: calc(var(--litepicker-month-width) + 10px);
  box-sizing: content-box;
  flex-wrap: wrap;
  background-color: var(--litepicker-container-months-color-bg);
  border-radius: 5px;
  box-shadow: 0 0 5px var(--litepicker-container-months-box-shadow-color);
}

.litepicker .container__months.columns-2 {
  width: calc((var(--litepicker-month-width) * 2) + 20px);
}

.litepicker .container__months.columns-3 {
  width: calc((var(--litepicker-month-width) * 3) + 30px);
}

.litepicker .container__months.columns-4 {
  width: calc((var(--litepicker-month-width) * 4) + 40px);
}

.litepicker .container__months.split-view .month-item-header .button-previous-month,
.litepicker .container__months.split-view .month-item-header .button-next-month {
  visibility: visible;
}

.litepicker .container__months .month-item {
  width: var(--litepicker-month-width);
  box-sizing: content-box;
  padding: 5px;
}

.litepicker .container__months .month-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px;
  color: var(--litepicker-month-header-color);
  font-weight: 500;
  text-align: center;
}

.litepicker .container__months .month-item-header div {
  flex: 1;
}

.litepicker .container__months .month-item-header div > .month-item-name {
  margin-right: 5px;
}

.litepicker .container__months .month-item-header div > .month-item-year {
  padding: 0;
}

.litepicker .container__months .month-item-header .reset-button {
  color: var(--litepicker-button-reset-color);
}

.litepicker .container__months .month-item-header .reset-button > svg {
  fill: var(--litepicker-button-reset-color);
}

.litepicker .container__months .month-item-header .reset-button * {
  pointer-events: none;
}

.litepicker .container__months .month-item-header .reset-button:hover {
  color: var(--litepicker-button-reset-color-hover);
}

.litepicker .container__months .month-item-header .reset-button:hover > svg {
  fill: var(--litepicker-button-reset-color-hover);
}

.litepicker .container__months .month-item-header .button-previous-month,
.litepicker .container__months .month-item-header .button-next-month {
  padding: 3px 5px;
  border-radius: 3px;
  cursor: default;
  text-decoration: none;
  transition: color .3s, border .3s;
  visibility: hidden;
}

.litepicker .container__months .month-item-header .button-previous-month *,
.litepicker .container__months .month-item-header .button-next-month * {
  pointer-events: none;
}

.litepicker .container__months .month-item-header .button-previous-month {
  color: var(--litepicker-button-prev-month-color);
}

.litepicker .container__months .month-item-header .button-previous-month > svg,
.litepicker .container__months .month-item-header .button-previous-month > img {
  fill: var(--litepicker-button-prev-month-color);
}

.litepicker .container__months .month-item-header .button-previous-month:hover {
  color: var(--litepicker-button-prev-month-color-hover);
}

.litepicker .container__months .month-item-header .button-previous-month:hover > svg {
  fill: var(--litepicker-button-prev-month-color-hover);
}

.litepicker .container__months .month-item-header .button-next-month {
  color: var(--litepicker-button-next-month-color);
}

.litepicker .container__months .month-item-header .button-next-month > svg,
.litepicker .container__months .month-item-header .button-next-month > img {
  fill: var(--litepicker-button-next-month-color);
}

.litepicker .container__months .month-item-header .button-next-month:hover {
  color: var(--litepicker-button-next-month-color-hover);
}

.litepicker .container__months .month-item-header .button-next-month:hover > svg {
  fill: var(--litepicker-button-next-month-color-hover);
}

.litepicker .container__months .month-item-weekdays-row {
  display: flex;
  justify-content: flex-start;
  color: var(--litepicker-month-weekday-color);
  justify-self: center;
}

.litepicker .container__months .month-item-weekdays-row > div {
  width: var(--litepicker-day-width);
  flex: 1;
  padding: 5px 0;
  font-size: 85%;
  text-align: center;
}

.litepicker .container__months .month-item:first-child .button-previous-month {
  visibility: visible;
}

.litepicker .container__months .month-item:last-child .button-next-month {
  visibility: visible;
}

.litepicker .container__months .month-item.no-previous-month .button-previous-month {
  visibility: hidden;
}

.litepicker .container__months .month-item.no-next-month .button-next-month {
  visibility: hidden;
}

.litepicker .container__days {
  display: flex;
  box-sizing: content-box;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-self: center;
  text-align: center;
}

.litepicker .container__days > div,
.litepicker .container__days > a {
  width: var(--litepicker-day-width);
  padding: 5px 0;
}

.litepicker .container__days .day-item {
  border-radius: 3px;
  color: var(--litepicker-day-color);
  cursor: default;
  text-align: center;
  text-decoration: none;
  transition: color .3s, border .3s;
}

.litepicker .container__days .day-item:hover {
  box-shadow: inset 0 0 0 1px var(--litepicker-day-color-hover);
  color: var(--litepicker-day-color-hover);
}

.litepicker .container__days .day-item.is-today {
  color: var(--litepicker-is-today-color);
}

.litepicker .container__days .day-item.is-locked {
  color: var(--litepicker-is-locked-color);
}

.litepicker .container__days .day-item.is-locked:hover {
  box-shadow: none;
  color: var(--litepicker-is-locked-color);
  cursor: default;
}

.litepicker .container__days .day-item.is-in-range {
  background-color: var(--litepicker-is-in-range-color);
  border-radius: 0;
}

.litepicker .container__days .day-item.is-start-date {
  background-color: var(--litepicker-is-start-color-bg);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 0;
  color: var(--litepicker-is-start-color);
}

.litepicker .container__days .day-item.is-start-date.is-flipped {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 5px;
}

.litepicker .container__days .day-item.is-end-date {
  background-color: var(--litepicker-is-end-color-bg);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 5px;
  color: var(--litepicker-is-end-color);
}

.litepicker .container__days .day-item.is-end-date.is-flipped {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 0;
}

.litepicker .container__days .day-item.is-start-date.is-end-date {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.litepicker .container__days .day-item.is-highlighted {
  background-color: var(--litepicker-highlighted-day-color-bg);
  color: var(--litepicker-highlighted-day-color);
}

.litepicker .container__days .week-number {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--litepicker-month-week-number-color);
  font-size: 85%;
}

.litepicker .container__footer {
  padding: 10px 5px;
  margin: 0 5px;
  background-color: var(--litepicker-footer-color-bg);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: inset 0 3px 3px 0 var(--litepicker-footer-box-shadow-color);
  text-align: right;
}

.litepicker .container__footer .preview-date-range {
  margin-right: 10px;
  font-size: 90%;
}

.litepicker .container__footer .button-cancel {
  padding: 3px 7px 4px;
  border: 0;
  background-color: var(--litepicker-button-cancel-color-bg);
  border-radius: 3px;
  color: var(--litepicker-button-cancel-color);
}

.litepicker .container__footer .button-cancel * {
  pointer-events: none;
}

.litepicker .container__footer .button-apply {
  padding: 3px 7px 4px;
  border: 0;
  margin-right: 10px;
  margin-left: 10px;
  background-color: var(--litepicker-button-apply-color-bg);
  border-radius: 3px;
  color: var(--litepicker-button-apply-color);
}

.litepicker .container__footer .button-apply:disabled {
  opacity: .7;
}

.litepicker .container__footer .button-apply * {
  pointer-events: none;
}

.litepicker .container__tooltip {
  position: absolute;
  padding: 4px 8px;
  margin-top: -4px;
  background-color: var(--litepicker-tooltip-color-bg);
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .25);
  font-size: 11px;
  pointer-events: none;
  visibility: hidden;
  white-space: nowrap;
}

.litepicker .container__tooltip::before {
  position: absolute;
  bottom: -5px;
  left: calc(50% - 5px);
  border-top: 5px solid rgba(0, 0, 0, .12);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: "";
}

.litepicker .container__tooltip::after {
  position: absolute;
  bottom: -4px;
  left: calc(50% - 4px);
  border-top: 4px solid var(--litepicker-tooltip-color-bg);
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
}
