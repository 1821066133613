/**
 * 1. La tabla tiene un width en linea con medida en px que hace que en
 *    móvil no quepa en la capa. Se le asigna una anchura al 100% para que
 *    se adapte a la capa contenedora y ocupe siempre el máximo.
 */
#tablaAnunciosEncontrados {
  width: 100% !important; /* [1] */
}

/**
 * 1. Separación entre los diferentes datos que se presentan en el detalle de
 *    un anuncio.
 *
 *    El html de cada dato tiene una estructura semejante a la del siguiente
 *    ejemplo:
 *
 *    <div class="lineaFormulario">
 *      <div class="nombre" style="text-align:left;"><b>Tipo de Anuncio</b></div>
 *    </div>
 *    <div class="lineaFormulario">
 *      <span>Municipal con publicación en Boletín</span>
 *    </div>
 */
#formDetalleAnuncio .nombre {
  margin-top: .6em; /* [1] */
}

/**
 * PAGINACION
 * ---------------------------------------------------------------- */

/**
 * Como la paginación lleva float a izquierda y derecha, se añade el clearfix
 * al padre.
 */
.dataTables_wrapper::after {
  display: table;
  clear: both;
  content: "";
}

.dataTables_wrapper th.sorting {
  /* background-image: url(../img/svg/sprite.svg#icon-menu-swap-view);
  background-position: right;
  background-repeat: no-repeat; */
  cursor: pointer;
}

.dataTables_wrapper th.sorting::after,
.dataTables_wrapper th.sorting_asc::after,
.dataTables_wrapper th.sorting_desc::after {
  position: absolute;
  width: 1em;
  height: 1em;
  margin-top: .5em;
  background-color: #fff;
  content: " ";
  float: right;
  mask-image: url(../img/svg/sprite.svg#icon-menu-swap-view);
}

/**
 * Información de la pagina
 */
.dataTables_info {
  float: left;
  font-size: .889em;
}

/**
 * Navegación entre paginas
 */
.dataTables_paginate {
  float: right;
  font-size: .889em;
}

.dataTables_paginate .paginate_active,
.dataTables_paginate .paginate_button {
  margin: 0 .4em;
}

.dataTables_paginate .paginate_button {
  color: var(--color-link);
  cursor: pointer;
}
