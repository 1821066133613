/* ==================================================================== *\
 *                                                                      *
 *   BUTTONS                                                            *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *  /base/_buttons.css                                                  *
 * -------------------------------------------------------------------- *
 *  Tamaño de fuente base:                                              *
 *    - Movil:  18px                                                    *
 *    - Tablet: 18px                                                    *
 *    - Web:    18px                                                    *
\* ==================================================================== */

/*
@styleguide

@title Botones

Existen principalmente dos tipos de botones:

- **Boton por defecto**

  Similar al submit de formularios.

- **Botón de navegación**

  Utilizado en la cabecera y/o pie de algunas secciones para hacer más visible un
  enlace que conduce a una url con información ampliada o un listado completo.

Además, están disponibles otros tipos de botones con una funcionalidad muy específica:

- **Botón mostrar/ocultar**

  Despliega u oculta una capa a la que está asociado
*/

/* -------------------------------------------------------------------- *\
 *  BOTON POR DEFECTO                                                   *
\* -------------------------------------------------------------------- */

/*
@styleguide

## Botón por defecto

Se puede aplicar la clase `btn` tanto a la etiqueta `<button>` como a `<a>`.
También se aplica a los botones de un formulario.

<p>
  <button class="btn">Button</button>
  <a class="btn" href="https://www.vitoria-gasteiz.org" title="Ir a vitoria-gasteiz.org">Enlace</a>
  <input class="btn" type="button" value="Input button">
  <input class="btn" type="reset" value="Input reset">
  <input class="btn" type="submit" value="Input submit">
</p>

El uso de las diferentes opciones depende del propósito del botón.

- `<a>`: Si la acción derivada del botón **redirige a otra pantalla**.
- `<button>`: Si se realiza una acción que **manipula una página sin cambiar de url**.
- `<input type="button|submit|reset">`: Si se realiza una acción **dentro de un formulario**.
  - `button`: Botón sin un comportamiento específico.
  - `reset`:  Botón que resetea el contenido del formulario a valores por defecto.
  - `submit`: Botón que valida y envía el formulario.

```html
<a class="btn" href="..." title="..."">[texto del botón]</a>
<button class="btn">[texto del botón]</button>
<input class="btn" type="button" value="[texto del botón]">
<input class="btn" type="reset" value="[texto del botón]">
<input class="btn" type="submit" value="[texto del botón]">
```
*/

/* Clase del botón por defecto (.btn)
 *
 * El resto de selectores son fallbacks para unificar y asignar estilos por defecto a los botones
 * con denominaciones variadas.
 */
@custom-selector :--btn .btn, button, input[type="button"], input[type="reset"], input[type="submit"], a.boton, .btn-show-greenBuzon, .btn-show-greenBuzonPaginado, .btn-show-greyBuzon, .j28TMCbotonera a, #j31Adjunto, .btnlogin, .boton-white, .ui-dialog .ui-dialog-buttonpane button;
@custom-selector :--btn-focus .keynav-mode .btn:focus, .keynav-mode button:focus, .keynav-mode input[type="button"]:focus, .keynav-mode input[type="reset"]:focus, .keynav-mode input[type="submit"]:focus, .keynav-mode a.boton:focus, .keynav-mode .btn-show-greenBuzon:focus, .keynav-mode .btn-show-greenBuzonPaginado:focus, .keynav-mode .btn-show-greyBuzon:focus, .keynav-mode .j28TMCbotonera a:focus, .keynav-mode #j31Adjunto:focus, .keynav-mode .btnlogin:focus, .keynav-mode .boton-white:focus, .ui-dialog .ui-dialog-buttonpane button:focus;

/**
  * 1. Resetear estilos heredados de los enlaces
  */
:--btn {
  display: inline-flex;
  min-height: 2.625rem;
  align-items: center;
  padding: 0 .556em;
  border: 0;
  margin-bottom: var(--rhythm);
  background-color: var(--btn-background-color);
  border-radius: 0;
  color: var(--btn-color); /* [1] */
  cursor: pointer;
  font-size: var(--font-size-18px);
  line-height: var(--rhythm);
  text-decoration: none; /* [1] */
  transition: background-color .3s ease;
}

@media (min-width: 320px) and (max-width: 1419px) {
  :--btn {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  :--btn {
    font-size: var(--font-size-24px);
  }
}

:--btn:hover,
:--btn:focus {
  background-color: #00128e;
  color: var(--btn-color); /* [1] */
  text-decoration: none; /* [1] */
}

:--btn-focus {
  box-shadow: 0 0 4px 3px #00128e;
  outline: 2px solid rgba(255, 255, 255, .9);
  outline-offset: 0;
}

:--btn:active {
  background-color: #00128e;
  background-image: linear-gradient(0deg, rgba(33, 33, 33, .1), rgba(33, 33, 33, .1) 60%, rgba(0, 0, 0, .2));
  box-shadow: none;
}

:--btn + :--btn {
  margin-left: .6em;
}

/* -------------------------------------------------------------------- *\
  *  BOTON DE NAVEGACION                                                 *
 \* -------------------------------------------------------------------- */

/*
 @styleguide

 ## Botón de navegación

 Utilizarlo **siempre como enlace** ya que su propósito es navegar a otra pantalla.

 Se debe añadir la clase modificador `btn--nav` a la básica `btn`.

 <a class="btn btn--nav" href="https://www.vitoria-gasteiz.org" title="Ir a vitoria-gasteiz.org">Botón de navegación</a>

 ```html
 <a class="btn btn--nav" href="..." title="...">[texto]</a>
 ```
 */
.btn--nav,
.btn--nav:hover {
  background-color: var(--background-color-alt-dark);
  color: var(--color-text-high-contrast);
  font-family: var(--font-family-alt);
}

.btn--nav:focus {
  background-color: var(--background-color-alt-darker);
}

.entorno-turismo .btn--nav.btn--heading-nav {
  background-color: var(--background-turismo-color);
  float: right;
  font-family: var(--font-family-turismo-alt);
  font-size: var(--font-size-18px);
}

.entorno-turismo .btn--nav.btn--heading-nav:hover {
  background-color: var(--btn-background-color);
  color: var(--btn-color) !important;
  text-decoration: none;
}

/* -------------------------------------------------------------------- *\
  *  MODIFICADOR: --right                                                *
  * -------------------------------------------------------------------- *
  *  Posiciona el boton a la derecha de la capa                          *
 \* -------------------------------------------------------------------- */
.btn--right {
  float: right;
}

/**
  * Como se flota el botón de la derecha se obliga a que el siguiente hermano
  * haga un clear right.
  *
  * En caso de ser el último elemento de la capa es necesario que algun antecesor tenga:
  *  ::after {
  *    content: "";
  *    display: table;
  *    clear:both;
  *  }
  */
.btn--right + * {
  clear: right;
}

/* -------------------------------------------------------------------- *\
  *  BOTONES MOSTRAR / OCULTAR                                           *
 \* -------------------------------------------------------------------- */

/*
 @styleguide

 ## Botón mostrar/ocultar una información

 Utilizarlo **siempre como button** ya que su propósito es modificar la pantalla actual.

 Se debe añadir la clase `btn-toggle` a la básica `btn`.

 Se debe asociar una capa al botón para que automáticamente se muestre/oculte. Para ello hay
 que utilizar el atributo `aria-controls` y asignarle como valor el ID del elemento asociado.

 Como se ve en el ejemplo, No hace falta ocultar inicialmente la capa, ya que automáticamente
 se oculta si el botón no tiene el atributo `aria-expanded="true"` al cargar la página.

 ```html
 <button class="btn-toggle btn" aria-controls="showHideSpan">mostrar / ocultar</button>
 <span id="showHideSpan">Este texto es el que se muestra o no</span>
 ```

 Visualización del código de ejemplo:

 <p>
 <button class="btn-toggle btn" aria-controls="showHideSpan">mostrar / ocultar</button>
 <span id="showHideSpan">Este texto es el que se muestra o no</span>
 </p>

 */

.btn-toggle.btn,
.btn-close.btn {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 42px;
  height: 42px;
  padding: 0;
  margin: 0;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  color: transparent;
  font-size: 0;
  text-indent: 100%;
  vertical-align: middle;
}

.btn-toggle.btn {
  /* background-image: url('data:image/svg+xml;base64,PHN2ZyBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA0MiA0MiIgdmlld0JveD0iMCAwIDQyIDQyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cmVjdCB3aWR0aD0iNDIiIGhlaWdodD0iNDIiIGZpbGw9IiMwMDQzQkUiLz4KPHBvbHlnb24gcG9pbnRzPSIzMS44MzMgMTguNzA3IDIzLjI5MyAxOC43MDcgMjMuMjkzIDEwLjE2NyAxOC43MjUgMTAuMTY3IDE4LjcyNSAxOC43MDcgMTAuMTg2IDE4LjcwNyAxMC4xODYgMjMuMjcyIDE4LjcyNSAyMy4yNzIgMTguNzI1IDMxLjgxMiAyMy4yOTMgMzEuODEyIDIzLjI5MyAyMy4yNzIgMzEuODMzIDIzLjI3MiIgZmlsbD0iI2ZmZiIvPgo8L3N2Zz4='); */
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgiIHdpZHRoPSI0OCI+PHBhdGggZmlsbD0iIzAwMCIgZD0iTTE0LjE1IDMwLjc1IDEyIDI4LjZsMTItMTIgMTIgMTEuOTUtMi4xNSAyLjE1TDI0IDIwLjg1WiIvPjwvc3ZnPg==");
  background-size: 60px;
  transform: rotate(180deg);
}

.menuFooter .btn-toggle.btn {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgiIHdpZHRoPSI0OCI+PHBhdGggZmlsbD0iIzAwMCIgZD0iTTE0LjE1IDMwLjc1IDEyIDI4LjZsMTItMTIgMTIgMTEuOTUtMi4xNSAyLjE1TDI0IDIwLjg1WiIvPjwvc3ZnPg==");
}

/** Imagen con fondo transparente para el entorno de teatro */
.main-footer--teatro .btn-toggle.btn {
  background-color: transparent !important;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgiIHdpZHRoPSI0OCI+PHBhdGggZmlsbD0iIzAwMCIgZD0iTTE0LjE1IDMwLjc1IDEyIDI4LjZsMTItMTIgMTIgMTEuOTUtMi4xNSAyLjE1TDI0IDIwLjg1WiIvPjwvc3ZnPg==");

  /* background-image: url("data:image/svg+xml;base64,PHN2ZyBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA0MiA0MiIgdmlld0JveD0iMCAwIDQyIDQyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cG9seWdvbiBwb2ludHM9IjMxLjgzMyAxOC43MDcgMjMuMjkzIDE4LjcwNyAyMy4yOTMgMTAuMTY3IDE4LjcyNSAxMC4xNjcgMTguNzI1IDE4LjcwNyAxMC4xODYgMTguNzA3IDEwLjE4NiAyMy4yNzIgMTguNzI1IDIzLjI3MiAxOC43MjUgMzEuODEyIDIzLjI5MyAzMS44MTIgMjMuMjkzIDIzLjI3MiAzMS44MzMgMjMuMjcyIiBmaWxsPSIjZmZmIi8+Cjwvc3ZnPg=="); */
}

.btn-toggle[aria-expanded="true"] {
  /* background-image: url('data:image/svg+xml;base64,PHN2ZyBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA0MiA0MiIgdmlld0JveD0iMCAwIDQyIDQyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cmVjdCB3aWR0aD0iNDIiIGhlaWdodD0iNDIiIGZpbGw9IiMwMDQzQkUiLz4KPHBvbHlsaW5lIHBvaW50cz0iMjMuMTg4IDIzLjI3MSAxMC4xODYgMjMuMjcxIDEwLjE4NiAxOC43MDcgMjMuMjUgMTguNzA3IDIzLjI5MyAxOC43MDcgMzEuODMzIDE4LjcwNyAzMS44MzMgMjMuMjcxIDIzLjI5MyAyMy4yNzEiIGZpbGw9IiNmZmYiLz4KPC9zdmc+'); */
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgiIHdpZHRoPSI0OCI+PHBhdGggZmlsbD0iIzAwMCIgZD0iTTE0LjE1IDMwLjc1IDEyIDI4LjZsMTItMTIgMTIgMTEuOTUtMi4xNSAyLjE1TDI0IDIwLjg1WiIvPjwvc3ZnPg==');
  transform: rotate(0deg);
}

.menuFooter .btn-toggle[aria-expanded="true"] {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgiIHdpZHRoPSI0OCI+PHBhdGggZmlsbD0iIzAwMCIgZD0iTTE0LjE1IDMwLjc1IDEyIDI4LjZsMTItMTIgMTIgMTEuOTUtMi4xNSAyLjE1TDI0IDIwLjg1WiIvPjwvc3ZnPg==");
  transform: rotate(0deg);
}

/** Imagen con fondo transparente para el entorno de teatro */
.main-footer--teatro .btn-toggle[aria-expanded="true"] {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgiIHdpZHRoPSI0OCI+PHBhdGggZmlsbD0iIzAwMCIgZD0iTTE0LjE1IDMwLjc1IDEyIDI4LjZsMTItMTIgMTIgMTEuOTUtMi4xNSAyLjE1TDI0IDIwLjg1WiIvPjwvc3ZnPg==");
  transform: rotate(0deg);

  /* background-image: url("data:image/svg+xml;base64,PHN2ZyBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA0MiA0MiIgdmlld0JveD0iMCAwIDQyIDQyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cG9seWxpbmUgcG9pbnRzPSIyMy4xODggMjMuMjcxIDEwLjE4NiAyMy4yNzEgMTAuMTg2IDE4LjcwNyAyMy4yNSAxOC43MDcgMjMuMjkzIDE4LjcwNyAzMS44MzMgMTguNzA3IDMxLjgzMyAyMy4yNzEgMjMuMjkzIDIzLjI3MSIgZmlsbD0iI2ZmZiIvPgo8L3N2Zz4="); */
}

.tree-node__btn-toggle.btn-toggle[aria-expanded="true"]::after {
  /* background-image: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6NDJweDtoZWlnaHQ6NDJweCIgdmlld0JveD0iMCAwIDI0IDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiMwMDAiPg0KICAgIDxwYXRoIGZpbGw9IiMwMDQzQkUiIGQ9Ik0xOSwxOVY1SDVWMTlIMTlNMTksM0EyLDIgMCAwLDEgMjEsNVYxOUEyLDIgMCAwLDEgMTksMjFINUEyLDIgMCAwLDEgMywxOVY1QzMsMy44OSAzLjksMyA1LDNIMTlNMTcsMTFWMTNIN1YxMUgxN1oiIC8+DQo8L3N2Zz4=); */
  mask-image: url(../img/svg/sprite.svg#icon-minus-view);
}

.btn-close.btn {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiDQoJIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiBvdmVyZmxvdz0idmlzaWJsZSIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTAwIDEwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQoJPHBhdGggc3Ryb2tlPSIjMDAwIiBzdHJva2Utd2lkdGg9IjEyIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBkPSJNIDEwLDEwIDkwLDkwIE0gMTAsOTAgOTAsMTAiLz4NCjwvc3ZnPg0K');
}

/** Imagen con fondo transparente para el entorno de teatro */
.main-footer--teatro .btn-close.btn {
  background-image: url("data:image/svg+xml;base64,PHN2ZyBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA0MiA0MiIgdmlld0JveD0iMCAwIDQyIDQyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cG9seWxpbmUgcG9pbnRzPSIyNC4xNjMgMjEuMDYzIDE0Ljk3IDMwLjI1NyAxMS43NDIgMjcuMDI5IDIwLjk4IDE3Ljc5MSAyMS4wMSAxNy43NjEgMjcuMDQ5IDExLjcyMiAzMC4yNzYgMTQuOTUgMjQuMjM4IDIwLjk4OCIgZmlsbD0iI2ZmZiIvPgo8cG9seWxpbmUgcG9pbnRzPSIyMC45MzYgMjQuMTQ0IDExLjc0MiAxNC45NSAxNC45NjkgMTEuNzIzIDI0LjIwOCAyMC45NiAyNC4yMzggMjAuOTkxIDMwLjI3NiAyNy4wMjkgMjcuMDQ5IDMwLjI1NyAyMS4wMSAyNC4yMTkiIGZpbGw9IiNmZmYiLz4KPC9zdmc+Cg==");
}

/** Ocultar el texto de dentro de algunos botones que tienen span dentro */
.btn-toggle span {
  display: none;
}

@media (min-width: 768px) {
  /* .btn-toggle.btn, */
  .btn-close.btn {
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
  }
  .entorno-turismo .btn-toggle.btn,
  .entorno-turismo .btn-close.btn {
    width: 24px;
    height: 24px;
  }
}

.no-svg .btn-toggle {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAIJJREFUSIntlVEKgCAQRGe1G0RH6BSev1N0A6Mj2PYjZMLaEgpBvj9xZGZlRKDzAGmFzDwDsHEZiGitmoSZPV947TlTNUU3+KTBraaxiougHZNAB4Bd0Lm0wkO2aQFMimCmoLO5sCn5BAHAJmi1VxReJekvuRuI5DUt4ZB8OA2y/JUT/R4yA/OTTusAAAAASUVORK5CYII=');
}

.no-svg .btn-toggle[aria-expanded="true"] {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAE1JREFUSIljYBgFo2AUDH3AiMz5//+/KgMDw1EKzbRmZGS8DeOwoEkyMzAwiFJoATMyh4lCwwgCdB/8ZWBgeE2hmX8p1D8KRsEoGHQAAEZeCAxtEM2iAAAAAElFTkSuQmCC');
}

* > .btn:last-child {
  margin-bottom: 0;
}

.btn--nd {
  padding: 0;
  background-color: inherit;
  font-size: inherit;
}

.btn--nd:hover,
.btn--nd:focus {
  background-color: inherit;
  text-decoration: inherit;
}
