/* -------------------------------------------------------------------- *\
 *                                                                      *
 *  HELPERS                                                             *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *  /components/utils/_helpers.css                                      *
 * -------------------------------------------------------------------- *
 *  Descripción:                                                        *
 *                                                                      *
 *  Diferentes clases que realizan una función específica en el layout  *
 *                                                                      *
\* -------------------------------------------------------------------- */

/* ------------------------------------------------------------------ *\
 *  Helpers: Errores                                                  *
\* ------------------------------------------------------------------ */
.error {
  color: var(--color-error);
}

.error:empty {
  display: none;
}

/* ------------------------------------------------------------------ *\
 *  Helpers: Posicionamiento                                          *
\* ------------------------------------------------------------------ */
.right {
  text-align: right;
}

.izq {
  float: none;
}

.dcha {
  float: none; /* margin-top: 15px; */
}

@media (min-width: 480px) {
  .izq,
  .alignleft {
    float: left;
  }
  .dcha,
  .alignright {
    float: right;
  }
  .blogs .alignleft {
    margin: 0 1em 1em 0;
  }
  .blogs .alignright {
    margin: 0 0 1em 1em;
  }
}

.center {
  text-align: center;
}

/* ------------------------------------------------------------------ *\
 *  Helpers: Visibilidad                                              *
\* ------------------------------------------------------------------ */
.visibility-hidden {
  visibility: hidden !important;
}

/**
 * 1. Se utiliza por ejemplo para ocultar categorías en el menu contextual
 *    de una búsqueda. Si tuviese important, el style="display: block",
 *    que se aplica al visualizarlas no tendría efecto.
 */
.hidden-element {
  display: none; /* 1 */
}

/**
 * Ocultar textos de forma accesible para lectores de pantalla
 * Fuente: http://olgacarreras.blogspot.com.es/2011/11/ocultar-contenido-sin-comprometer-la.html
 * El método anterior parece incompleto. Aquí estaría el completo
 * https://a11yproject.com/posts/how-to-hide-content/
 */
.visually-hidden,
.hide,
.hidden,
.ocultar,
.oculto,
.screen-reader-text {
  position: absolute !important;
  display: inline !important; /* Invalidar "display: none" heredados */
  overflow: hidden;
  width: 1px;
  height: 1px;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

/**
 * "It’s important to show these elements visually when they receive focus,
 * otherwise a keyboard-only user might not know which element currently has
 * focus." https://a11yproject.com/posts/how-to-hide-content/
 */
.visually-hidden:focus,
.hide:focus,
.hidden:focus,
.oculto:focus,
.ocultar:focus,
.screen-reader-text:focus {
  position: static !important;
  clip: auto;
}

/* ------------------------------------------------------------------ *\
 *  Helpers: Scroll                                                   *
\* ------------------------------------------------------------------ */
.scroll-disabled {
  overflow-y: hidden;
}

/* ------------------------------------------------------------------ *\
 *  Helpers: Modificadores de la fuente                               *
\* ------------------------------------------------------------------ */
.negrita {
  font-weight: bold;
}

ul.unstyled {
  list-style: none;
}

a.pointer-none {
  pointer-events: none;
}
