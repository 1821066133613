/* ================================================================== *\
 *                                                                    *
 *  APLICACIÓN:                                                       *
 *  j34-01w                                                           *
 *  Catálogo de datos abiertos.                                       *
 *                                                                    *
 *  URL: https://www.vitoria-gasteiz.org/j34-01w/catalogo              *
 *                                                                    *
 * ------------------------------------------------------------------ *
 *  /pages/_j34-01w.css                                               *
\* ================================================================== */

.j34-01w .result-elements > header ~ .result-count {
  margin-top: 0; /* 1 */
}

.j34-distribuciones {
  padding-left: 20px;
}

.j34-distribuciones dt {
  display: list-item;
  list-style-type: disc;
}

.j34-distribuciones .icon-extension {
  margin-left: 1em;
}

.j34-01w .azul {
  color: var(--color-secundario);
}

.j34-01w .main__grid-box .btn {
  margin-right: .6em;
}

/**
 * 1. Sobreescribir los estilos de una clase de buttons,
 * debido a una mala alineación debido al número de enlaces rápidos.
 */
.j34-01w .main__grid-box .btn + .btn {
  margin-left: 0; /* 1 */
}

.j34 .btn:not(:last-of-type) {
  margin-right: .6em;
}

.j34 .btn + .btn {
  margin-left: 0;
}

.j34-01w .result-elements header h1 {
  margin-top: 0;
}

.j34-01w .news--highlight p:last-child > .icon-extension {
  margin: 0 2.813rem 0 0;
}

.j34-01w .btn--nav .icon-extension {
  margin-right: 1em;
}

.j34-01w .news--highlight {
  flex-direction: column;
}

.j34-01w .news--highlight > .news__link + .news__summary {
  padding: 0 var(--gutter-interior);
}
