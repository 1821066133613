/**
 * 1. Separacion con el enlace volver que precede al formulario en "Detalle de un tributo"
 */
#domiciliacionesForm {
  margin-top: 1em; /* [1] */
}

@media (min-width: 768px) {
  #domiciliacionesForm label.peq {
    display: inline-block;
    margin: .4em 0 0 1em;
  }
  #domiciliacionesForm label.peq:first-child {
    margin-left: 0;
  }
}

#domiciliacionesForm label[for="cuentaHabitual"] {
  margin-top: 1em;
}
