/* ================================================================== *\
 *                                                                    *
 *  APLICACIÓN: m01-10s                                               *
 *  NOMBRE: Consulta e inscripción de actividades                     *
 *                                                                    *
 * ------------------------------------------------------------------ *
 *  /pages/_m01-10s.css                                               *
\* ================================================================== */

/**
 * URLS DE PRUEBA
 *
 * Consulta e inscripción
 * https://dsedeelectronica.vitoria-gasteiz.org/m01-10s/actividadAction.do?accion=inicio&anio=2016
 *
 * Actividades inscritas
 * https://dsedeelectronica.vitoria-gasteiz.org/m01-10s/auth/consultaAction.do?accion=consulta&anio=2016
 */

.m01-10s .destacado {
  padding: var(--gutter-interior);
  background-color: var(--background-color-alt-dark);
}

/**
 * ESTILOS PARA AFORO DE PISCINAS
 *
 */

.aforo {
  margin-top: 2em;
  margin-right: 0;
  margin-bottom: .75em;
  margin-left: 0;
}

.tituloCentro {
  padding: .5em .6em .5em .6em;
  background-color: var(--background-color-alt-dark);
  font-weight: bold;
}

.tituloCentro p {
  padding: 0;
  margin: 0;
}

.cuerpoCentro {
  display: table;
  width: 100%;
  padding: .5em .6em .5em .6em;
}

.aforoOcupacion [data-percent^='1'] {
  background-color: #ff2e12;
}

.aforoOcupacion [data-percent^='0.75'],
.aforoOcupacion [data-percent^='0.76'],
.aforoOcupacion [data-percent^='0.77'],
.aforoOcupacion [data-percent^='0.78'],
.aforoOcupacion [data-percent^='0.79'],
.aforoOcupacion [data-percent^='0.8'],
.aforoOcupacion [data-percent^='0.9'] {
  background-color: #f7b502;
}

.aforoOcupacion [data-percent='0'],
.aforoOcupacion [data-percent^='0.0'],
.aforoOcupacion [data-percent^='0.1'],
.aforoOcupacion [data-percent^='0.2'],
.aforoOcupacion [data-percent^='0.3'],
.aforoOcupacion [data-percent^='0.4'],
.aforoOcupacion [data-percent^='0.5'],
.aforoOcupacion [data-percent^='0.6'],
.aforoOcupacion [data-percent^='0.70'],
.aforoOcupacion [data-percent^='0.71'],
.aforoOcupacion [data-percent^='0.72'],
.aforoOcupacion [data-percent^='0.73'],
.aforoOcupacion [data-percent^='0.74'] {
  background-color: #31d728;
}

.cuerpoCentro div {
  display: inline-block;
  margin-right: 2em;
  font-family: var(--font-family-turismo-heading);
}

.cuerpoCentro div span {
  font-weight: bold;
}

.cuerpoCentroMayus {
  text-transform: uppercase;
}

.puppets {
  width: 100%;
}

.redPuppet {
  display: inline-block;
  width: 33%;
}

.brownPuppet {
  display: inline-block;
  width: 35%;
}

.greenPuppet {
  display: inline-block;
  width: 30%;
}

.redPuppet svg {
  width: 2.5em;
  margin-right: -.3em;
}

.brownPuppet svg {
  width: 2.5em;
  margin-right: -.3em;
}

.greenPuppet svg {
  width: 2.5em;
  margin-right: -.3em;
}

.redPuppetSubDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.brownPuppetSubDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.greenPuppetSubDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.redPuppetSubDiv div {
  margin-right: .6em;
}

.brownPuppetSubDiv div {
  margin-right: .6em;
}

.redPuppetSubDiv label {
  font-size: 1rem;
}

.brownPuppetSubDiv label {
  font-size: 1rem;
}

.greenPuppetSubDiv label {
  font-size: 1rem;
}

.tablaPantallaFooter {
  padding: .5em;
  background-color: #000;
  color: #fff;
  font-size: var(--font-size-18px);
}

.bold {
  font-weight: bold;
}

.m01-10s td > form {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.m01-10s td > form a {
  margin-bottom: 0;
}

.m01-10s ul > li.listaSinEstilos {
  margin-bottom: calc(var(--rhythm) * 1.5);
}

.m01-10s ul li.listaSinEstilos > ul {
  margin-bottom: 0;
}

.m01-10s ul li.listaSinEstilos > p,
.m01-10s ul li.listaSinEstilos > ul li {
  margin-bottom: calc(var(--rhythm) * .5);
}
