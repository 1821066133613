/**
 * Enlaces que se destacan con una flecha a su izquierda.
 * Marcado muy mejorable. A refactorizar.
 *
 * Ej:
 *  - Seleccionar fecha
 *  - Ver programación de fechas anteriores;
 */
.botNavegacion p a {
  padding-left: 15px;
  background: url(../img/arrow_link1.png) no-repeat;
  background-position: 0 7px;
}

/**
 * EVENTOS DESTACADOS
\* ------------------------------------------------------- */
.diary-meetings dl {
  font-size: 1em;
}

.diary-meetings dl dt {
  color: var(--color-heading2);
  font-weight: normal;
}

.diary-meetings dl dt a {
  font-weight: bold;
}

.diary-meetings dl dd {
  margin-bottom: .5em;
}
