/*
@styleguide

@title Card-Collection

Las agrupaciones de Cards son listas no ordenadas (`<ul>`) con la clase `card-collection`.

Las sucesivas `<li>` deberán tener la clase `card-collection__item`.

```html
<ul class="card-collection [has-X-cols@Y...]">
  <li class="card-collection__item">
    <!-- html de una card-->
  </li>
  <li class="card-collection__item">
    <!-- html de una card-->
  </li>
  ...
</ul>
```

## Número de columnas de una card-collection

A una `card-collection` se le pueden añadir diferentes clases para determinar
el número de columnas en las que se visualizarán sus elementos.

Los puntos de corte (**breakpoints**) son tres:

- `sm`: Resoluciones a partir de 768px (tablets y superiores)
- `md`: Resoluciones a partir de 992px
- `lg`: Resoluciones a partir de 1200px

**Por defecto** el numero de columnas de una `card-collection` son:

- inicial (móviles): 1
- `sm`: 2
- `md`: 3
- `lg`: 3 (herencia de md)

## Personalizar el número de columnas

Para modificar el número de columnas se pueden añadir a la `ul` clases con el
siguiente formato:

`has-X-cols@Y`

Siendo:
- `X` = numero de columnas (1 a 4)
- `Y` = breakpoint (`sm`, `md`, `lg`)

### Ejemplos de diferentes formatos de columnas

Por ejemplo, para una coleccion de dos columnas siempre (excepto en móvil que es
siempre una):

```html
<ul class="card-collection has-2-cols@sm">
...
</ul>
```

De la misma forma, una coleccion de tres columnas en tablet y cuatro columnas
en resoluciones superiores sería:

```html
<ul class="card-collection has-3-cols@sm has-4-cols@md">
...
</ul>
```
 */
.card-collection {
  padding: 0;
  margin: calc(var(--rhythm) * .5) 0 var(--rhythm);
  list-style: none;
}

.card-collection__item {
  padding-right: 0;
  padding-left: 0;
  border: 2px solid var(--background-color-alt-darker);
  margin: 0;
  margin-bottom: calc(var(--rhythm) * .5);
}

.entorno-congresos .card-collection__item {
  border: 0;
  border: 2px solid var(--color-entorno-congresos);
  background-color: #fff;
}

.card-collection__item:last-child {
  margin-bottom: 0;
}

.card-collection__item:hover {
  border-color: var(--color-link);
}

@media (min-width: 768px) {
  .card-collection {
    display: grid;
    column-gap: .4166666666666667em;
    row-gap: .4166666666666667em;
  }
  .card-collection.has-1-cols\@sm {
    grid-template-columns: auto;
  }
  .card-collection.has-2-cols\@sm,
  ul[class="card-collection"] {
    grid-template-columns: repeat(2, 1fr);
  }
  .card-collection.has-3-cols\@sm {
    grid-template-columns: repeat(3, 1fr);
  }
  .card-collection.has-4-cols\@sm {
    grid-template-columns: repeat(4, 1fr);
  }
  .card-collection__item {
    margin: 0;
  }
}

@media (min-width: 992px) {
  .card-collection.has-2-cols\@md {
    grid-template-columns: repeat(2, 1fr);
  }
  .card-collection.has-3-cols\@md,
  ul[class="card-collection"] {
    grid-template-columns: repeat(3, 1fr);
  }
  .card-collection.has-4-cols\@md {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1200px) {
  .card-collection.has-2-cols\@lg {
    grid-template-columns: repeat(2, 1fr);
  }
  .card-collection.has-3-cols\@lg,
  ul[class="card-collection"] {
    grid-template-columns: repeat(3, 1fr);
  }
  .card-collection.has-4-cols\@lg {
    grid-template-columns: repeat(4, 1fr);
  }
}


/* @media (min-width: 768px) {
  .card-collection__item {
    width: 50%;
    float: left;
  }
  .card-collection.has-1-cols\@sm .card-collection__item {
    width: 100%;
    float: none;
  }
  .card-collection.has-2-cols\@sm .card-collection__item {
    width: 50%;
  }
  .card-collection.has-3-cols\@sm .card-collection__item {
    width: 33.33%;
  }
}

@media (min-width: 992px) {
  .card-collection__item {
    width: 33.33%;
  }
  .card-collection.has-2-cols\@md .card-collection__item {
    width: 50%;
  }
  .card-collection.has-3-cols\@md .card-collection__item {
    width: 33.33%;
  }
  .card-collection.has-4-cols\@md .card-collection__item {
    width: 25%;
  }
}

@media (min-width: 1420px) {
  .card-collection__item {
    width: 33.33%;
  }
  .card-collection.has-2-cols\@lg .card-collection__item {
    width: 50%;
  }
  .card-collection.has-3-cols\@lg .card-collection__item {
    width: 33.33%;
  }
  .card-collection.has-4-cols\@lg .card-collection__item {
    width: 25%;
  }
} */

.card-collection__item .card {
  margin-right: auto;
  margin-left: auto;
}
