.square {
  display: inline-block;
}

.square::before {
  display: inline-block;
  width: 0;
  padding-top: 100%;
  content: '';
  vertical-align: middle;
}

.square__content {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
