/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE: MENU CONTEXTUAL                                         *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *  /layout/_menuContextual.css                                     *
 * -------------------------------------------------------------------- *
 *  Tamaño de fuente base:                                              *
 *    - Movil:  18px                                                    *
 *    - Tablet: 18px                                                    *
 *    - Web:    18px                                                    *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  Se renderizan siempre dos menus contextuales similares pero con     *
 *  propositos deferentes:                                              *
 *                                                                      *
 *    - MOVIL / TABLET: aside.aside-tabletMobile                        *
 *    - DESKTOP: aside.extra-content                                    *
 *                                                                      *
 *  Los menus contextuales puede ser de dos tipos:                      *
 *                                                                      *
 *    - ESTANDAR. Su DOM comienza con:                                  *
 *      <aside>                                                         *
 *        <div class="menuContextual">                                  *
 *          <ul class="menus">                                          *
 *                                                                      *
 *    - NAVEGACION (eliminado). Su DOM comienza con:                    *
 *      <aside>                                                         *
 *        <div class="ctxNavegacion">                                   *
 *          <ul class="link-board">                                     *
 *                                                                      *
\* ==================================================================== */
.aside-tabletMobile,
.extra-content {
  margin-top: 3rem;
  margin-bottom: 5rem;
  font-size: var(--font-size-18px);
  line-height: var(--rhythm);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .aside-tabletMobile,
  .extra-content {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 991px) {
  .aside-tabletMobile,
  .extra-content {
    min-width: 25%;
    flex: 1;
  }
}

@media (min-width: 1420px) {
  .aside-tabletMobile,
  .extra-content {
    max-width: 24.6875rem;
    font-size: var(--font-size-24px);
    line-height: calc(var(--font-size-24px) * strip(var(--rhythm)));
  }
}

.aside-tabletMobile ul,
.extra-content ul,
.aside-tabletMobile li,
.extra-content li {
  padding: 0;
  margin: 0 0 calc(var(--rhythm));
  list-style: none;
}

/*
.aside-tabletMobile {
  position: absolute;
  z-index: 100100;
  right: -40%;

  display: none;

  width: 40%;
  padding: 10px;

  background: var(--background-color-alt-dark);
}

@media (max-width: 767px) {
  .aside-tabletMobile {
    right: -80%;

    width: 80%;
  }
}

.aside-tabletMobile.open {
  right: 0;

  display: block;
}

/* ------------------------------------------------------------------ *\
 *  Reset de algunos estilos confusos, importados de otras capas.     *
 *  Nota: A eliminar al refactorizar el HTML y dejar de usarlos.      *
\* ------------------------------------------------------------------ */
.menuContextual .botnsencillo {
  display: none;
}

/* ------------------------------------------------------------------ *\
 *  Capa que oscurece el resto de la pantalla al desplegar el menu    *
\* ------------------------------------------------------------------ *
.lightoff {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;

  background: rgba(0, 0, 0, .4);
}

/* ------------------------------------------------------------------ *\
 *  Control para desplegar u ocultar el menu                          *
\* ------------------------------------------------------------------ */
.aside-control {
  display: none !important;

  /* position: absolute;
  z-index: 11111;
  right: 0;

  cursor: pointer; */
}

/* ------------------------------------------------------------------ *\
 *  Menu móvil / tablet: .aside-tabletMobile                          *
\* ------------------------------------------------------------------ */
.aside-tabletMobile {
  display: none !important;

  /*
  padding-top: 15px;

  background: var(--background-color-alt-darker); */
}

/* ------------------------------------------------------------------ *\
 *  Menu desktop: .extra-content                                      *
\* ------------------------------------------------------------------ */
.extra-content__toogle-btn {
  display: none;
}

.extra-content-close {
  display: none;
}

@media (max-width: 991px) {
  .extra-content {
    position: fixed;
    z-index: 998;
    bottom: 0;
    left: 0;
    display: block !important;
    width: 100%;
    height: 100%;
    padding: 0 var(--readable-padding-side);
    margin-top: 0;
    margin-bottom: 0;
    background: var(--background-color-alt-darker);
    overflow-x: visible;
    transform: translateY(calc(100% - var(--font-size-46px)));
    transition: var(--menu-toggle-transition);
  }

  /* .extra-content::before {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background: var(--background-color-alt-darker);
    content: '';
  } */
  .extra-content--opened {
    z-index: 998;
    top: 0;
    max-height: 100%;
    transform: translateY(0);
  }

  /* .extra-content--opened + .extra-content-close {
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background: rgba(0, 0, 0, .4);
  } */
  .extra-content__toogle-btn,
  .extra-content__toogle-btn:active {
    position: absolute;

    /* left: -35px; */
    right: 0;

    /* top: 25%; */
    display: flex;
    overflow: hidden;
    width: 100%;

    /* height: var(--font-size-42px); */
    flex-flow: column;
    align-items: center;
    padding: .5rem;
    background-color: var(--background-color-alt-darker);
    color: #000;
    font-family: var(--font-family-base-semi);
    white-space: nowrap;
  }
  .entorno-teatro .extra-content:not(.extra-content--opened) .extra-content__toogle-btn,
  .entorno-teatro .extra-content:not(.extra-content--opened) .extra-content__toogle-btn:active {
    background-color: var(--color-entorno-teatro);
    color: var(--color-text-inverse);
  }
  .entorno-turismo .extra-content:not(.extra-content--opened) .extra-content__toogle-btn,
  .entorno-turismo .extra-content:not(.extra-content--opened) .extra-content__toogle-btn:active {
    background-color: var(--background-turismo-menu-header-color);
    color: var(--color-text-high-contrast);
  }
  .entorno-congresos .extra-content:not(.extra-content--opened) .extra-content__toogle-btn,
  .entorno-congresos .extra-content:not(.extra-content--opened) .extra-content__toogle-btn:active {
    background-color: var(--color-entorno-congresos);
    color: var(--color-text-inverse);
  }
  .extra-content:not(.extra-content--opened) .extra-content__toogle-btn::before {
    display: block;
    width: 24px;
    height: 2.5px;
    border: 0;

    /* margin-top: .5em; */
    background-color: #fff;

    /* width: 1.5em;
    height: 1.5em;
    margin-bottom: -.6rem;
    background-color: #000; */
    content: '';
    outline: none;
  }
  .entorno-teatro .extra-content:not(.extra-content--opened) .extra-content__toogle-btn::before,
  .entorno-congresos .extra-content:not(.extra-content--opened) .extra-content__toogle-btn::before {
    background-color: #fff;
  }
  .extra-content__toogle-btn:focus,
  .extra-content__toogle-btn:hover {
    background-color: var(--background-color-alt-darker);
    box-shadow: none;
    color: var(--color-text-high-contrast);
  }
  .extra-content--opened .extra-content__toogle-btn,
  .extra-content--opened .extra-content__toogle-btn:active {
    flex-direction: row;
    align-items: center;
    padding: 0 var(--readable-padding-side) 0;
    background-color: var(--color-text-high-contrast);
    background-image: none;
    color: var(--color-text-high-contrast);
  }
  .extra-content--opened .extra-content__toogle-btn::before,
  .extra-content--opened .extra-content__toogle-btn:active::before {
    background-image: none;
    color: var(--color-text-inverse);
    content: attr(data-text);
    font-size: 20px;
    font-weight: 600;
    text-align-last: left;
  }
  .extra-content--opened .extra-content__toogle-btn::after {
    width: 1.2em;
    height: 1.2em;
    margin-left: auto;
    background-color: #fff;
    content: "";
    mask-image: url(../img/svg/sprite.svg#close-view);
  }
  .extra-content--opened .extra-content__toogle-btn.menu::before,
  .extra-content--opened .extra-content__toogle-btn.menu:active::before {
    content: attr(data-text);
  }
  .extra-content__content {
    position: absolute;
    z-index: 1000;
    top: 4rem;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 var(--readable-padding-side);
    overflow-y: scroll;
  }

  /**
   * Si no se soporta CSS transform se queda oculto de momento
   * Hay que buscar una alternativa mejor para Opera Mini.
   * IE 8 no importa.
   */
  .no-csstransforms .extra-content {
    display: none !important;
  }
}

/* ------------------------------------------------------------------ *\
 *  Elemento: Titulo                                                  *
\* ------------------------------------------------------------------ */
.menuContextual .titulo {
  --font: minimalfont(strip(var(--font-size-31px)));
  --margin: factormargin(strip(var(--font-size-31px)));
  --line: 1.3em;
  margin: 0;
  margin-top: calc(var(--margin) * var(--margin-top-factor));
  margin-bottom: calc(var(--margin) * var(--margin-bottom-heading-factor));
  color: var(--color-heading);
  font-family: var(--font-family-alt);
  font-size: var(--font);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .menuContextual .titulo {
    --margin: fluidmargin(strip(var(--font-size-31px)));
    font-size: fluidfont(strip(var(--font-size-31px)));
    line-height: fluidline(strip(var(--line)), strip(var(--font-size-31px)));
  }
}

@media (min-width: 1420px) {
  .menuContextual .titulo {
    font-size: var(--font-size-31px);
  }
}

.menuContextual .titulo.primero {
  margin-top: 0;
}

/* ------------------------------------------------------------------ *\
 *  Elementos:                                                        *
 *  Banner (.banner)                                                  *
 *  Banner con texto (.title-banner)                                  *
\* ------------------------------------------------------------------ */

/* Estilos comunes
 * ------------------------------------------------------------------ */
.menuContextual .banner a,
.menuContextual .title-banner a {
  display: block;
}

/* Banner con texto
 * ------------------------------------------------------------------ */
.menuContextual .title-banner {
  margin-bottom: var(--rhythm);
  background-color: var(--background-color-new);
  font-family: var(--font-family-base);
  font-size: var(--font-size-18px);
  line-height: var(--rhythm);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .menuContextual .title-banner {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .menuContextual .title-banner {
    font-size: var(--font-size-24px);
  }
}

.menuContextual .title-banner span {
  display: block;
  padding: var(--gutter-interior-aside);
}

/* Banner
 * ------------------------------------------------------------------ */
.menuContextual .banner {
  padding: 0;
  background-color: var(--background-color);
}

/**
 * 1. Evitar un espacio en blanco extra no deseado bajo la imagen
 */
.menuContextual .banner img,
.menuContextual .banner picture,
.menuContextual .title-banner img {
  display: block; /* [1] */
  width: 100%;
  max-width: 100%;
}

/* ------------------------------------------------------------------ *\
 *  Elemento: Enlace simple                                           *
\* ------------------------------------------------------------------ */
.menuContextual li.enlace {
  display: block;

  /* padding-left: 15px; */
  margin-bottom: calc(var(--gutter-outer) * .5);

  /* background: url(../img/arrow_link2.png) no-repeat;
  background-position: 0 .35em; */
}

.menuContextual .enlace + .enlace {
  margin-top: 0;
}

.menuContextual .menus > li.texto.primero:not(:empty) {
  padding-bottom: .2em;
  border-bottom: 2px solid #000;
  margin-bottom: 1em;
}

.menuContextual .menus > li.texto.primero > a,
.menuContextual .menus > li.texto.primero > span {
  --line: 1.2em;
  display: block;
  padding: 0 0 0 calc(42px + .5rem);
  margin: 0;
  margin-top: 0;
  margin-bottom: 0;
  background-position: center left;
  background-repeat: no-repeat;
  background-size: 42px 42px;
  font-family: var(--font-family-alt);
  font-size: minimalfont(strip(var(--font-size-32px)));
  line-height: var(--line);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .menuContextual .menus > li.texto.primero > a,
  .menuContextual .menus > li.texto.primero > span {
    --margin: fluidmargin(strip(var(--font-size-32px)));
    font-size: fluidfont(strip(var(--font-size-32px)));
    line-height: fluidline(strip(var(--line)), strip(var(--font-size-32px)));
  }
}

@media (min-width: 1420px) {
  .menuContextual .menus > li.texto.primero > a,
  .menuContextual .menus > li.texto.primero > span {
    font-size: var(--font-size-32px);
  }
}

.menuContextual .menus > li.texto.primero > a {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNi4yLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDQ4IDQ4IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0OCA0ODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZD0iTTgsNDJWMThMMjQuMSw2TDQwLDE4djI0SDI4LjNWMjcuOGgtOC42VjQySDh6IiBmaWxsPSIjMDA0ZGRiIi8+DQo8L3N2Zz4NCg==);
}

.menuContextual .menus > li.texto.primero > span {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNi4yLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDQ4IDQ4IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0OCA0ODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZD0iTTgsNDJWMThMMjQuMSw2TDQwLDE4djI0SDI4LjNWMjcuOGgtOC42VjQySDh6IiAvPg0KPC9zdmc+DQo=);
  font-family: var(--font-family-alt);
}

.menuContextual .texto > a,
.menuContextual .texto > a:active,
.menuContextual .texto > a:hover,
.menuContextual .texto > a:visited {
  color: var(--color-link);
  font-weight: bold;
}

/* ------------------------------------------------------------------ *\
 *  Elemento: Videos de youtube, flickr                               *
\* ------------------------------------------------------------------ */
.menuContextual iframe,
.menuContextual object {
  width: 100%;
  max-width: 480px;
  margin: 0;
}

/* ------------------------------------------------------------------ *\
 *  Elemento: Lista de enlaces destacados                             *
\* ------------------------------------------------------------------ */
.menuContextual .destacadosynovedades {
  padding: 0;
  background-color: var(--background-color);
}

.menuContextual .destacadosynovedades li {
  display: block;
  padding-left: 0;
  margin-bottom: var(--gutter-outer);

  /* background: url(../img/arrow_link2.png) no-repeat;
  background-position: 0 .45em; */
}

.menuContextual .destacadosynovedades a,
.menuContextual .destacadosynovedades a:active,
.menuContextual .destacadosynovedades a:hover,
.menuContextual .destacadosynovedades a:visited {
  display: block;
  color: var(--color-link);
  font-weight: normal;
}

/* ------------------------------------------------------------------ *\
 *  Elemento: Filtro de búsqueda por areas tematicas                  *
 * ------------------------------------------------------------------ *
 *  Sólo disponible en menuContextual.                                *
 *  (Ejemplo de uso: Agenda)                                          *
\* ------------------------------------------------------------------ */
.menuContextual .diary h1 {
  padding: calc(var(--gutter-interior-aside) * 2 / 3) var(--gutter-interior-aside);
  margin: 0;
  background-color: var(--color-primario);
  color: var(--color-text-inverse);
  font-size: 1.333em;
}

.menuContextual .listadoareas {
  padding-top: var(--gutter-interior-aside);
}

.menuContextual .listadoareas li {
  padding: 0 0 .2em 14px;
  background: url(../img/arrow_link1.png) no-repeat;
  background-position: 0 .45em;
}

.menuContextual .listadoareas a {
  color: var(--color-link);
  font-weight: normal;
}

.menuContextual .listadoareas span {
  /* Sólo aparece en el area seleccionada */
  color: var(--color-text-high-contrast);
  font-weight: bold;
}

.menuContextual .listadoareas li.deshabilitado {
  color: var(--color-disabled);
}

/* ------------------------------------------------------------------ *\
 *  Elemento: Filtro de búsqueda con numero de resultados por area    *
 * ------------------------------------------------------------------ *
 *  Sólo disponible en menuContextual.                                *
 *  (Ejemplo de uso: Resultados de busqueda general)                  *
\* ------------------------------------------------------------------ */
.menuContextual .result {
  margin-bottom: calc(var(--gutter-outer));
  background: transparent;
}

.menuContextual .result li.area-result {
  --rhythm-increase: 1.5;
  background: var(--background-color);

  /* margin: fluidmargin(var(--rhythm-increase, 1)); */
}

.menuContextual .result li.area-result:first-child {
  margin-top: 0;
}

.menuContextual .result ul li {
  margin-left: 1.2em;
}

.menuContextual .result ul li:not(:last-child) {
  margin-bottom: calc(var(--gutter-outer) * .5);
}

/* Desplegable */
.menuContextual .area-result.closed ul {
  display: none;
}

.menuContextual .area-result :is(h1,h2) {
  --font: minimalfont(strip(var(--font-size-31px)));
  padding: var(--gutter-interior-aside);
  margin-top: 0;
  margin-bottom: calc(var(--gutter-outer) * .5);
  background-color: var(--color-turismo-secundario);
  color: var(--color-text-inverse);
  font-size: var(--font);
}

.menuContextual .area-result > :is(h1,h2) {
  padding: var(--gutter-interior-filtros);
  margin-bottom: calc(var(--gutter-outer) * .5);
  background-color: var(--color-turismo-secundario);
  color: var(--color-text-inverse);
  cursor: pointer;
}

@media (min-width: 320px) and (max-width: 1419px) {
  .menuContextual .area-result :is(h1,h2) {
    font-size: fluidfont(strip(var(--font-size-31px)));
  }
}

@media (min-width: 1420px) {
  .menuContextual .area-result :is(h1,h2) {
    font-size: var(--font-size-31px);
  }
}

.menuContextual .area-result > h1::after,
.menuContextual .area-result > h2::after {
  width: 1.5em;
  height: 1.5em;
  background-color: #fff;
  content: '';
  float: right;
  mask-image: url(../img/svg/sprite.svg#icon-arrow-view);
  transform: rotate(-180deg) translateY(10px);
  transition: transform .15s;
  vertical-align: middle;
}

.menuContextual .area-result > h1[aria-expanded="true"]::after,
.menuContextual .area-result > h2[aria-expanded="true"]::after {
  transform: rotate(0) translateY(0);
  transition: transform .15s;
}

.menuContextual .area-result label {
  font-weight: normal;
}

.menuContextual .area-result li {
  display: block;
  padding: calc(var(--gutter-interior-aside) * 1 / 3) 0  calc(var(--gutter-interior-aside) * 1 / 3) 0;

  /* background-image: url("data:image/svg+xml;base64,PHN2ZyBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA3LjIwNSAxMS4zNzIiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDcuMjA1IDExLjM3MiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBvbHlsaW5lIHBvaW50cz0iMC43MDcgMC43MDcgNS43OTEgNS43OSAwLjkxNiAxMC42NjUiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg=="); */
  background-position: 0 1.14em;
  background-repeat: no-repeat;
  background-size: 10px 10px;
}

.menuContextual .area-result li ul {
  margin: calc(var(--gutter-outer) * .5) 0 calc(var(--gutter-outer) * 1.5);
}

.menuContextual .area-result li:last-child ul:last-child {
  margin-bottom: 0;
}

.menuContextual .area-result li ul li {
  padding: calc(var(--gutter-interior-aside) * 1 / 3) 0  calc(var(--gutter-interior-aside) * 1 / 3) 12px;
  background-size: 7px 7px;
}

.menuContextual .area-result .linea__result {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.menuContextual .area-result li .linea__result .btn.btn--nav {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  padding: var(--gutter-interior-filtros);
  margin-bottom: 0;
  margin-left: -1.2em;
  background-color: var(--background-color-alt-dark);
  font-family: var(--font-family-base);
}

.menuContextual .area-result li .linea__result .btn.btn--nav::after {
  width: .6em;
  height: .6em;
  margin-left: auto;
  background-color: #000;
  content: '';
  mask-image: url(../img/svg/sprite.svg#close-view);
}

.menuContextual .area-result li .linea__result .btn.btn--nav:hover::after {
  background-color: var(--color-primario);
}

.menuContextual .area-result li span {
  color: var(--color-text-high-contrast);
}

/**
 * 1. Desactivar la imagen heredada de .icon-arrow_link1 y
 que no afecte a los enlaces de .btn-close
 */
.menuContextual .area-result a:not(.btn-close) {
  min-height: 42px;
  padding: 10px 0 0;
  background-image: none; /* [1] */
  color: var(--color-link);
  font-weight: normal;
}

.menuContextual .area-result li .linea__result a {
  color: var(--color-text-high-contrast);
}

.menuContextual .area-result li .linea__result a:hover,
.menuContextual .area-result li .linea__result a:hover span,
.menuContextual .area-result li a.icon-arrow_link1:hover,
.menuContextual .area-result li a.icon-arrow_link1:hover span {
  color: var(--color-primario);
  text-decoration: underline;
}

.menuContextual .area-result li a.icon-arrow_link1:hover .icon-extension {
  background-color: var(--color-primario);
  text-decoration: none;
}

.menuContextual .area-result li .linea__result a span:first-child {
  max-width: 85%;
}

.menuContextual .area-result hr {
  margin: .5em 0;
}

.menuContextual .area-result .container-hide-cat {
  font-size: .889em;
  text-align: right;
}

.menuContextual .area-result .container-hide-cat img {
  margin-left: 8px;
}

@media (min-width: 768px) {
  .menuContextual .area-result li {
    background-position: 0 .6em;
  }
  .menuContextual .area-result a:not(.btn-close) {
    min-height: 24px;
    padding: 0;
  }
}

@media (min-width: 1024px) {
  .menuContextual .area-result li .linea__result .btn.btn--nav {
    max-width: 17em;
  }
}

/* ------------------------------------------------------------------ *\
 *  Elemento: Formulario                                              *
 * ------------------------------------------------------------------ *
 *  Sólo disponible en menuContextual.                                *
 *  (Ejemplo de uso: Organización municipal)                          *
\* ------------------------------------------------------------------ */
.menuContextual form,
.menuContextual .diary form.estiloBlanco {
  padding-top: 0;
  margin: 0 0 var(--gutter-outer);
  background: var(--background-color);
}

.menuContextual .diary form.estiloBlanco label,
.menuContextual .diary form.estiloBlanco input,
.menuContextual .diary form.estiloBlanco select,
.menuContextual .diary form.estiloBlanco textarea {
  font-size: 1em;
}

/**
 * 1. Para que la negación del valor de una variable se produzca en Firefox
 *    es necesario utilizar "calc(var(--gutter-interior-aside) * -1)", ya que
 *    no reconoce "-var(--gutter-interior-aside)" (como Chrome).
 */
.menuContextual form > fieldset {
  display: block;
  padding: 0;
  margin: 0 calc(var(--gutter-interior-aside) * -1); /* [1] */
}

/**
 * 1. Para que la negación del valor de una variable se produzca en Firefox
 *    es necesario utilizar "calc(var(--gutter-interior-aside) * -1)", ya que
 *    no reconoce "-var(--gutter-interior-aside)" (como Chrome).
 */
.menuContextual form > fieldset:first-child,
.menuContextual form > fieldset:first-of-type {
  margin-top: calc(var(--gutter-interior-aside) * -1); /* [1] */
}

/**
 * 1. Para que la negación del valor de una variable se produzca en Firefox
 *    es necesario utilizar "calc(var(--gutter-interior-aside) * -1)", ya que
 *    no reconoce "-var(--gutter-interior-aside)" (como Chrome).
 */
.menuContextual form > fieldset:last-child {
  margin-bottom: calc(var(--gutter-interior-aside) * -1); /* [1] */
}

/**
 * 1. Para que la negación del valor de una variable se produzca en Firefox
 *    es necesario utilizar "calc(var(--gutter-interior-aside) * -1)", ya que
 *    no reconoce "-var(--gutter-interior-aside)" (como Chrome).
 */
.menuContextual form legend,
.menuContextual form .titulo {
  padding: calc(var(--gutter-interior-aside) * 2 / 3) var(--gutter-interior-aside);
  border: none;
  margin: calc(var(--gutter-interior-aside) * -1) calc(var(--gutter-interior-aside) * -1) var(--gutter-interior-aside) calc(var(--gutter-interior-aside) * -1); /* [1] */
  background-color: var(--color-primario);
  color: var(--color-text-inverse);
  font-family: var(--font-family-heading);
  font-size: 1.333em;
  font-weight: normal;
}

/**
 * 1. Bug en Firefox:
 *      calc((var(--gutter-interior-aside) * 2) + 100%)
 *      se convierte en
 *      calc((var(--gutter-interior-aside) * 2) + 1)
 *    La anchura no se fija bien. Por eso pasamos a:
 *      calc(30px + 100%)
 */
.menuContextual form fieldset legend {
  width: calc(30px + 100%); /* [1] */

  /* width: calc((var(--gutter-interior-aside) * 2) + 100%); */
  max-width: fit-content;
  float: left;
}

.menuContextual form select {
  width: 100%;
}

.menuContextual .diary form .menus {
  padding-top: var(--gutter-interior-aside);
}

.menuContextual .diary form .menus li {
  margin-bottom: 0;
}

/* ------------------------------------------------------------------ *\
 *  Elemento: Lista de eventos                                        *
 * ------------------------------------------------------------------ *
 *  Para la integración de calendarios en el contextual               *
 * ------------------------------------------------------------------ */
.menuContextual .event-list__section {
  margin: 0 0 1.5em;
}

.extra-content li.area-result {
  --margin: factormargin(strip(var(--font-size-31px)));
  margin-top: calc(var(--margin) * var(--margin-top-factor));
  margin-bottom: calc(var(--margin) * var(--margin-bottom-heading-factor));
}

/* ------------------------------------------------------------------ *\
 *  Elemento:  listado de noticias NOTICIA                            *
 * ------------------------------------------------------------------ *
 *  Para la integración del componente _news.css en el contextual     *
 * ------------------------------------------------------------------ */
.menuContextual .news-list__group-title {
  font-size: var(--font-size-26px);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .menuContextual .news-list__group-title {
    font-size: fluidfont(strip(var(--font-size-26px)));
    line-height: fluidline(strip(var(--line)), strip(var(--font-size-26px)));
  }
}

@media (min-width: 1420px) {
  .menuContextual .news-list__group-title {
    font-size: var(--font-size-26px);
  }
}
