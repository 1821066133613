/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE: PIE DE PAGINA                                           *
 *                                                                      *
 *  ------------------------------------------------------------------- *
 *  /layout/footer/_footer.css                                          *
\* ==================================================================== */
.main-footer {
  background-color: var(--background-color);
}

.entorno-www .main-footer,
.entorno-sede .main-footer--sede {
  border-top: 5px solid var(--color-secundario);
  background-color: var(--background-turismo-color);
}

.main-footer::before {
  display: block;
  border-top: 3px #000 solid;
  content: "";
}

.main-footer .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .main-footer ul {
    width: 100%;
  }
  .entorno-www .main-footer ul {
    padding-right: 0;
  }
}

.main-footer h2 {
  --font: minimalfont(strip(var(--font-size-39px)));
  --margin: factormargin(strip(var(--font-size-39px)));
  --line: 1.2em;
  margin: 0;
  margin-top: calc(var(--margin) * var(--margin-top-factor));
  margin-bottom: calc(var(--margin) * var(--margin-bottom-heading-factor));
  font-size: var(--font);
  font-weight: 700;
  line-height: var(--line);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .main-footer h2 {
    --font: fluidfont(strip(var(--font-size-39px)));
    --margin: fluidmargin(strip(var(--font-size-39px)));
    line-height: fluidline(strip(var(--line)), strip(var(--font-size-39px)));
  }
}

@media (min-width: 1420px) {
  .main-footer h2 {
    --font: var(--font-size-39px);
  }
}

@media (min-width: 768px) {
  .main-footer--www .container {
    padding-right: 2em;
    padding-left: 2em;
  }
}

/*  Estilos generales del pie
 *  Modificador: CONGRESOS
\* ------------------------------------------------------------------ */

.main-footer--congresos .congresosFooter {
  margin: var(--rhythm) auto;
}

.main-footer--congresos h2 {
  color: var(--color-entorno-congresos);
}

/*  Estilos generales del pie
 *  Modificador: TEATRO
\* ------------------------------------------------------------------ */
.main-footer--teatro h2 {
  color: var(--color-entorno-teatro);
}

/*  Estilos generales del pie
 *  Modificador: TURISMO
\* ------------------------------------------------------------------ */

.main-footer--turismo {
  background-color: var(--color-entorno-turismo);
  font-family: var(--font-family-turismo-base);
}

.entorno-turismo .main-footer--turismo .container h2 {
  margin: 0;
  color: var(--color-heading);
  font-family: var(--font-family-turismo-heading);
}

/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE: PIE DE PAGINA > MENU                                    *
 *                                                                      *
 *  ------------------------------------------------------------------- *
 *  /components/_footer.css                                             *
 *  ------------------------------------------------------------------- *
 *                                                                      *
 *  Entre los diferentes entornos es difícil buscar un punto de unión   *
 *  en el HTML. A falta de remaquetar se usará el siguiente selector    *
 *  para delimitar el MENU:                                             *
 *                                                                      *
 *  .main-footer :first-child                                           *
 *                                                                      *
 *  ------------------------------------------------------------------- *
 *  Tamaño de fuente base:                                              *
 *    - Movil:  27px                                                    *
 *    - Tablet: 18px                                                    *
 *    - Web:    18px                                                    *
\* ==================================================================== */

/**
 * Márgenes con respecto al contenido y al pie final.
 */

/* .main-footer :first-child .row {
  padding-top: 1em;
  padding-bottom: 1em;
} */

/**
 * Los diferentes menús de navegación van casi siempre dentro de SECTION
 * junto con un título
 */

/* .main-footer :first-child section {
  position: relative;
  padding-top: 1em;
  padding-bottom: 1em;
} */

.main-footer :first-child .toolsMenu {
  padding: 0;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .main-footer :first-child .toolsMenu {
    width: 40%;
  }
}

/**
 * El título del menú no debe tener margen inferior para mantener
 * la separación correcta cuando se pliegan los enlaces en el móvil.
 * (ver siguiente regla)
 */
.main-footer :first-child h2 {
  margin: 0;
}

.main-footer :first-child .toolsMenu__header {
  display: flex;
  align-items: center;
}

/* Boton de mostrar / ocultar enlaces de un menú en el móvil
\* ------------------------------------------------------------------ */

/* .main-footer :first-child .btn {
  position: absolute;
  top: 1.3rem;
  right: calc(var(--gutter-outer) / 2);
} */

.main-footer :first-child .btn {
  position: relative;
  top: unset;
  right: unset;
  min-width: 20%;
  margin-left: auto;
}

.main-footer .head .btn {
  position: relative;
  top: 0;
  right: 0;
}

.main-footer--turismo section {
  padding-left: 0;
}

.main-footer--turismo nav > ul {
  font-family: var(--font-family-turismo-alt);
}

.main-footer--turismo .list-footer nav > ul > li > a {
  display: flex;
  height: 48px;
  align-items: center;
}

@media (max-width: 767px) {
  /**
   * Entorno: TEATRO
   * Personalización de las listas de enlaces para móviles
   */
  .main-footer--teatro section a {
    display: block;
    padding: .2em .6em;
    margin-bottom: .3em;
    background-color: var(--color-entorno-teatro);
    color: var(--color-text-inverse);
  }
  .entorno-turismo .main-footer--turismo .head {
    padding: 5px 5px 5px 15px;
    background-color: var(--background-turismo-color);
  }
  .entorno-turismo .main-footer--turismo :first-child nav > ul {
    padding-left: 15px;
  }
  body:not(.entorno-turismo) .main-footer :first-child section.toolsMenu + section.toolsMenu::before,
  body:is(.entorno-teatro) .main-footer :first-child section.toolsMenu:last-of-type::after {
    display: block;
    border-top: 1px #000 solid;

    /* margin-right: calc(var(--readable-padding-side) * -1); */
    margin-bottom: var(--rhythm);

    /* margin-left: calc(var(--readable-padding-side) * -1); */
    content: "";
  }
  body:is(.entorno-teatro) .main-footer :first-child section.toolsMenu + section.toolsMenu::before,
  body:is(.entorno-teatro) .main-footer :first-child section.toolsMenu:last-of-type::after {
    border-top-color: var(--color-entorno-teatro);
  }
  .main-footer :first-child h2 {
    margin-right: 1rem;
  }
}

@media (max-width: 991px) {
  .main-footer .menuFooter {
    flex-direction: column;
  }
}

@media (max-width: 1199px) {
  .main-footer .menuFooter {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .main-footer .menuFooter {
    row-gap: var(--rhythm);
  }
}

@media (min-width: 1199px) {
  .main-footer .menuFooter {
    justify-content: space-around;
  }
  .entorno-turismo .main-footer .menuFooter {
    justify-content: space-between;
  }
}

@media (max-width: 991px) {
  /**
   * En móviles y tablets los menús aparecen con letra más grandes
   */
  .main-footer :first-child section.toolsMenu:not(:last-child) .toolsMenu__header {
    margin-bottom: var(--rhythm);
  }

  /**
   * Visualización por defecto en móviles y tablets de los menús
   */
  .main-footer--turismo :first-child section {
    padding-top: 1em;
    padding-bottom: 1em;
    border-top: 2px solid #fff;
  }
}

@media (max-width: 767px) {
  .main-footer :first-child section {
    width: 100%;
  }
}

@media (min-width: 768px) {
  /**
   * Entorno: TURISMO
   * El primer elemento pierde el borde por defecto en tablets
   */
  .main-footer--turismo :first-child section {
    border-top: none;
  }
  .main-footer--turismo .list-footer nav > ul > li > a {
    display: block;
    height: 100%;
    align-items: center;
  }
}

/**
 * Linea divisoria vertical entre los grupos de menús en ordenadores
 */
@media (min-width: 992px) {
  .main-footer--www .toolsMenu:first-child {
    max-width: 65%;
  }
  .main-footer--www .toolsMenu {
    padding-right: 2em;
  }
}

/**
 * Texto de LOPD en sede
 */
.main-footer--sede .menuFooter + p {
  font-family: var(--font-family-base);
  font-size: .875rem;
}

/* ------------------------------------------------------------------ *\
 *  ELEMENTO: RSS y Redes sociales                                    *
 *  Listado de iconos de diferentes redes sociales                    *
\* ------------------------------------------------------------------ */
.main-footer--turismo .shareLinks-footer {
  padding: 1em 0 0;
  text-align: center;
}

.main-footer .networks li {
  display: inline;
  margin: 0;
}

.main-footer--turismo .shareLinks-footer li {
  display: inline;
  margin: 0 .5em;
}

/*
 *  ELEMENTO: RSS y Redes sociales (movil)
 *  MODIFICADOR: TURISMO
\* ------------------------------------------------------------------ */
.main-footer--turismo :first-child .shareLinks-footer.visible-xs {
  clear: left;
}

/*
 *  ELEMENTO: RSS y Redes sociales (tablet y laptop)
 *  MODIFICADOR: TURISMO
\* ------------------------------------------------------------------ */

/**
 * 1. Eliminar scrollbar horizontal que aparece en turismo provocado por
 *    el posicionamiento a la derecha del hijo ul.list-social-footer.
 */
.main-footer--turismo .shareLinks-footer.hidden-xs {
  position: relative;
  display: flex;
  overflow: hidden; /* [1] */
  flex-wrap: wrap;
  padding-top: 0;
  padding-bottom: 2em;
  margin: .7em 0;
  text-align: left;
}

.main-footer--turismo .shareLinks-footer.hidden-xs h2 {
  color: var(--color-heading);
  font-family: var(--font-family-turismo-heading-bold);
}

.main-footer--turismo .shareLinks-footer.hidden-xs ul {
  padding: 0;
  margin: 0;
}

.main-footer--turismo .shareLinks-footer.hidden-xs > ul > li {
  display: table-cell;
  height: 37px;
  padding: 0 15px;
  border-left: 3px solid #d0dadc;
  vertical-align: bottom;
}

.main-footer--turismo .shareLinks-footer.hidden-xs li li {
  margin: 0 .1em;
}

.main-footer--turismo .shareLinks-footer.hidden-xs li:first-child {
  border-left: none;
}

.main-footer--turismo .shareLinks-footer.hidden-xs .icon-social-twitter,
.main-footer--turismo .shareLinks-footer.hidden-xs .icon-social-facebook,
.main-footer--turismo .shareLinks-footer.hidden-xs .icon-social-youtube,
.main-footer--turismo .shareLinks-footer.hidden-xs .icon-social-instagram {
  display: inline-block;
}

.main-footer--turismo .list-social-footer a {
  vertical-align: middle;
}

/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE: PIE DE PAGINA > INFO FINAL                              *
 *                                                                      *
 *  ------------------------------------------------------------------- *
 *  /components/_footer.css                                             *
 *  ------------------------------------------------------------------- *
 *  Tamaño de fuente base:                                              *
 *    - Movil:  16px                                                    *
 *    - Tablet: 16px                                                    *
 *    - Web:    14px                                                    *
\* ==================================================================== */
.main-footer .final {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #000;
  color: var(--color-text-inverse);
  font-family: var(--font-family-base);
  font-size: var(--font-size-18px);
  line-height: var(--rhythm);
}

.entorno-www .main-footer .final:nth-child(3) {
  background-color: #89909c;
}

.entorno-teatro .main-footer .final {
  background-color: var(--color-entorno-teatro);
}

.entorno-congresos .main-footer .final {
  background-color: var(--color-entorno-congresos);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .main-footer .final,
  .main-footer .container {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .main-footer .final,
  .main-footer .container {
    font-size: var(--font-size-24px);
  }
}

.main-footer .final a {
  color: var(--color-text-inverse);
}

@media (max-width: 991px) {
  .main-footer .final {
    font-size: .889em;
    text-align: center;
  }
}

.main-footer--congresos .final {
  padding-top: 0;
  padding-bottom: 0;
  background-color: inherit;

  /* font-family: var(--font-family-congresos-base); */
  font-size: .889em;
  letter-spacing: normal;
}

/**
 * El pie final de los entornos de turismo y congresos no entra en
 * el max-width del container para dichos breakpoints.
 */
@media (min-width: 992px) and (max-width: 1200px) {
  .main-footer--congresos .final > .container,
  .main-footer--turismo .final > .container {
    max-width: none;
  }
}

.main-footer .flex-column {
  display: flex;
  flex-direction: column;
}

.main-footer .final-footer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: var(--rhythm) 0;
}

.main-footer .final-footer .footer-address {
  display: flex;
  flex-direction: column;
}

@media (max-width: 991px) {
  .main-footer .final-footer {
    flex-direction: column-reverse;
    align-items: center;
  }
  .main-footer .flex-column {
    width: 100%;
    align-items: center;
    padding-right: 0;
    padding-left: 0;
    text-align: initial;
  }
}

/* ------------------------------------------------------------------ *\
 *  ELEMENTO 1: Dirección y datos de contacto                         *
\* ------------------------------------------------------------------ */
.main-footer .footer-address {
  font-style: inherit;
}

@media (max-width: 991px) {
  .main-footer .footer-address {
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    margin: var(--rhythm) 0;
  }
  .main-footer .footer-address a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 13px 0;
    background: none;
  }
  .entorno-turismo .main-footer .footer-address {
    display: flex;
    flex-direction: column;
  }
  .entorno-turismo .main-footer .footer-address a {
    width: fit-content;
    margin: 0 auto;
    background-image: none;
  }
  .main-footer .footer-address > a::after {
    content: '\A';
    white-space: pre;
  }
}

@media (min-width: 992px) {
  .main-footer .footer-address {
    flex: 0 0 max-content;
    padding: 0;
    padding-left: 1em;
    margin: 0;
  }
  .main-footer .footer-address a {
    display: inline-flex;
    align-items: center;
    margin: 0;
    margin-bottom: 1em;
  }
}

.main-footer .footer-address .secundary-telephone {
  padding-left: 0;
}

.main-footer--congresos .footer-address a:first-child {
  align-items: flex-start;
}

.main-footer--congresos .footer-address a {
  margin-bottom: calc(var(--rhythm) * .5);
}

.main-footer--congresos .footer-address__big {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-footer--congresos .footer-address__big p {
  margin: 0;
}

.main-footer--congresos .icon-place::before {
  margin-top: .2em;
}

@media (max-width: 991px) {
  .main-footer--congresos .icon-place::before {
    margin-right: -1.2em;
  }
}

@media (min-width: 992px) {
  .main-footer--congresos .footer-address__big {
    align-items: flex-start;
  }
}

/* ------------------------------------------------------------------ *\
 *  ELEMENTO (PANTALLA): Banners especiales                           *
\* ------------------------------------------------------------------ */
@media (min-width: 992px) {
  .footer-logos {
    /* width: 30%; */
    padding: 0;
    text-align: center;
  }
  .footer-logos img {
    max-width: 27%;
    margin-right: 3%;
  }
  .main-footer .footer-logo a.hidden-xs {
    background-image: url(../img/svg/sprite.svg#logo-ayunta__texto-view);
  }
  .main-footer .footer-logo p {
    padding-top: 1.5em;
    margin: 0;
    white-space: nowrap;
  }
  .main-footer .footer-logo a {
    width: 9em;
    height: 4.2em;
  }
}

.main-footer .footer-logo {
  display: flex;
  flex-direction: column;
}

.main-footer .footer-logo a {
  background-color: transparent;
  background-position-x: 0;
  background-position-y: 0;
  background-repeat: no-repeat;
}

.main-footer .footer-logo.hidden-md.hidden-lg a {
  background-image: url(../img/svg/sprite.svg#logo-ayunta-view);
}

@media (max-width: 992px) {
  .main-footer .footer-logo {
    align-items: center;
  }
  .main-footer .footer-logo a {
    width: 6em;
    height: 7.2em;
  }
  .main-footer .footer-logo p {
    margin: calc(var(--rhythm) / 2) 0 0;
  }
  .main-footer .footer-logo.hidden-md.hidden-lg a {
    margin-bottom: 0;
    background-image: url(../img/svg/sprite.svg#logo-ayunta__texto-movil-view);
  }
  .main-footer .footer-menu ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    gap: var(--rhythm);
  }
}


/* ------------------------------------------------------------------ *\
 *  ELEMENTO 3: Copyright y enlaces útiles                            *
\* ------------------------------------------------------------------ */
.footer-menu {
  margin-bottom: calc(var(--rhythm) * 2);
}

.footer-menu li {
  display: inline-block;
  padding-left: 0;
  margin: 0;
}

.footer-follow-us {
  --font: minimalfont(strip(var(--font-size-31px)));
  --line: 1.3em;
  margin: 0;
  margin-bottom: calc(var(--rhythm) * .5);
  color: #fff;
  font-family: var(--font-family-alt-semi);
  font-size: var(--font);
  line-height: var(--line);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .footer-follow-us {
    --font: fluidfont(strip(var(--font-size-31px)));
    line-height: fluidline(strip(var(--line)), strip(var(--font-size-31px)));
  }
}

@media (min-width: 1420px) {
  .footer-follow-us {
    --font: var(--font-size-31px);
  }
}

@media (min-width: 992px) {
  .main-footer .footer-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0;
    margin: calc(var(--rhythm)) 0;
  }
  .main-footer .final.teatro .footer-menu {
    margin: 0;
  }
  .main-footer .footer-menu ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 0;
    column-gap: 2em;
  }
  .footer-plus {
    margin-left: auto;
  }
}

@media (max-width: 767px) {
  .main-footer :first-child nav {
    margin-bottom: var(--rhythm);
  }
}

.main-footer :first-child nav {
  margin-top: calc(var(--rhythm) / 2);
}

.main-footer :first-child .toolsMenu__content ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.main-footer :first-child .toolsMenu__content li:last-child {
  margin-bottom: 0;
}

.main-footer .footer-menu,
.main-footer .final-footer .footer-address {
  font-size: var(--font-size-16px);
  line-height: var(--rhythm);
}

.main-footer--www .final-footer div:last-child a:last-child {
  font-family: var(--font-family-base-semi);
  font-size: var(--font-size-18px);
  line-height: var(--rhythm);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .main-footer .footer-menu,
  .main-footer .final-footer .footer-address {
    font-size: fluidfont(strip(var(--font-size-21px)));
    line-height: fluidline(strip(var(--rhythm)), strip(var(--font-size-21px)));
  }
  .main-footer-www .final-footer div:last-child a:last-child {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .main-footer .footer-menu,
  .main-footer .final-footer .footer-address {
    font-size: var(--font-size-21px);
  }
  .main-footer--www .final-footer div:last-child a:last-child {
    font-size: var(--font-size-24px);
  }
}

.main-footer--www .final-footer .footer-plus > a:last-child::before {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin-right: .5em;
  background-color: #fff;
  content: '';
  mask-image: url(../img/svg/sprite.svg#icon-arrow-right-view);
  vertical-align: middle;
}

.main-footer--www .final-footer .footer-plus > a:last-child:hover::before {
  animation: slide-right .5s ease-in-out both;
}

/* ==================================================================== *\
 *  Footers en común                                                    *
\* ==================================================================== */

.main-footer div.container {
  padding-top: 0;
  padding-right: var(--readable-padding-side);
  padding-bottom: 0;
  padding-left: var(--readable-padding-side);
  margin: calc(var(--rhythm) * 2) auto;
}

.main-footer .final div.container {
  margin-top: 0;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .main-footer div.container {
    margin: calc(var(--rhythm) * .75) auto;
  }
}

@media (min-width: 768px) {
  .main-footer div.container {
    --readable-padding-side: 2em;
  }
}

.footer-plus ul {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-bottom: calc(var(--rhythm) * .5);
  list-style: none;
}

.footer-plus ul li {
  padding-left: 1em;
  margin: 0;
}

.footer-plus ul li:first-child {
  padding-left: 0;
}

@media (min-width: 1200px) {
  .main-footer--sede .toolsMenu:nth-child(1) .toolsMenu__header:first-child,
  .main-footer--sede .toolsMenu:nth-child(3) .toolsMenu__header:first-child {
    width: 60%;
  }
}

@media (max-width: 767px) {
  .shareLinks-footer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
  }
  .shareLinks-footer .networks {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    gap: .5em;
  }
}

@media (min-width: 768px) {
  .shareLinks-footer {
    display: none;
  }
}

/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE: PIE DE PAGINA > NEXT GENERATION                         *
 *                                                                      *
 *  ------------------------------------------------------------------- *
 *  /components/_footer_next-generation.css                             *
 *  ------------------------------------------------------------------- *
 *  Tamaño de fuente base:                                              *
 *    - Movil:  16px                                                    *
 *    - Tablet: 16px                                                    *
 *    - Web:    14px                                                    *
\* ==================================================================== */
.next-list {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0;
  margin: calc(var(--rhythm)) 0 calc(var(--rhythm) * 2);
  list-style: none;
  row-gap: var(--rhythm);
}

.next-list > li {
  display: flex;
  flex: 0 1 50%;
  justify-content: space-evenly;
  margin: 0;
}

@media (min-width: 400px) {
  .next-list {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .next-list > li {
    display: flex;
    flex: 0 1 50%;
    justify-content: space-evenly;
    margin: 0;
  }
}

@media (min-width: 768px) {
  .next-list {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    row-gap: 0;
  }
  .next-list > li {
    flex: none;
    margin: 0;
  }
}

@media (min-width: 992px) {
  .next-list {
    margin: calc(var(--rhythm)) 0;
  }
}

.next-item {
  display: block;
  width: 8.5em;
  height: 2em;
  background-repeat: no-repeat;
}

.next-item__financiado {
  background-image: url(../img/svg/sprite.svg#logo-ng-financiado-view);
}

.next-item__ministerio {
  width: 8em;
  background-image: url(../img/svg/sprite.svg#logo-ng-ministerio-view);
}

.next-item__plan {
  width: 9.5em;
  background-image: url(../img/svg/sprite.svg#logo-ng-plan-view);
}

.next-item__ayuntamiento {
  width: 4.5em;
  background-image: url(../img/svg/sprite.svg#logo-ayuntamiento-positivo-view);
}
