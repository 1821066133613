/* -------------------------------------------------------------------- *
\* Clase "settings" para mostrar varias opciones                        *
\* -------------------------------------------------------------------- */

.settings {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.settings__item {
  position: relative;
  margin: 0;
}

.settings-item__button {
  position: relative;
  z-index: 2;
  display: flex;
  height: 40px;
  align-items: center;
  margin: 0;
  border-radius: 50%;
}

.settings-item__button svg {
  pointer-events: none;
}

.settings__item-body {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 12em;
  height: auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .12);
  color: #222633;
  transform: scale(0);
  transform-origin: top left;
  transition: .5s;
  transition-delay: 0s;
}

.settings__item-body.active {
  transform: scale(1);
  transition-delay: 0s;
}

@media (min-width: 768px) {
  .settings__item-body {
    width: 16em;
  }
}

.settings__item-body .share__header {
  padding: 10px 0;
  border-bottom: solid 1px #222633;
  text-align: center;
}

.settings__item-body .share__body {
  padding: 20px 10px;
}

.settings__item-body .share__items {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style: none;
}

.share__items .item {
  display: block;
  margin-bottom: var(--rhythm);
}

.share__items .item:last-child {
  margin-bottom: 0;
}

.share__items .item .item__enlace {
  display: flex;
  min-height: 36px;
  align-items: center;
  background-color: #fff;
  color: #000;
  text-decoration: none;
}

.item .item__enlace .enlace__titulo {
  margin-left: .5em;
}

.item .item__enlace.item__enlace--sinsvg .enlace__titulo {
  margin-left: 2.2em;
}

.item .item__enlace .enlace__svg {
  width: 32px;
  height: 32px;
}

.item .item__enlace .enlace__svg.enlace__svg--whatsapp {
  fill: #09ba17;
}

.item .item__enlace .enlace__svg.enlace__svg--facebook {
  fill: #345390;
}

.item .item__enlace .enlace__svg.enlace__svg--twitter {
  fill: #24afe1;
}

.item .item__enlace .enlace__svg.enlace__svg--linkedin {
  fill: #167bb6;
}

.item .item__enlace .enlace__svg.enlace__svg--mail {
  fill: #000;
}
