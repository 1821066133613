/**
 * 1. El contenido principal de sede tiene un HTML incorrecto.
 *
 *    <div class="main-content col-xs-12 col-md-9">
 *
 *    Se debería controlar en la plantilla que si no hay menu contextual
 *    no se añadiese la clase col-md-9.
 *
 *    Mientras tanto, por CSS, se le da una anchura de 100% al contenido
 *    de la home de SEDE.
 */
.j30-01s .home .main-content {
  width: 100%; /* [1] */
}

/* -------------------------------------------------------------------- *\
 *  FORMULARIOS                                                         *
\* -------------------------------------------------------------------- */
.main--sede legend {
  margin: inherit;
  font-size: 1.167em;
}

/**
 * 1. Los label ya tienen display: block por defecto, el br que les siga es
 *    innecesario.
 */
.main--sede label + br {
  display: none; /* [1] */
}

/**
 * 1. La anterior regla se deshabilita para labels no semanticos de magic table
 */
.main--sede label.rt-lbl + br {
  display: inline; /* [1] */
}

/* -------------------------------------------------------------------- *\
 *  PAGINACION                                                          *
\* -------------------------------------------------------------------- */
.main--sede .paginacionsup,
.main--sede .paginacionsupSinBorde,
.main--sede .paginacioninf,
.main--sede .paginacioninfSinBorde {
  margin: 1em 0;
  font-size: .889em;
}

.main--sede .paginacionsup::after,
.main--sede .paginacionsupSinBorde::after,
.main--sede .paginacioninf::after,
.main--sede .paginacioninfSinBorde::after {
  display: table;
  clear: both;
  content: "";
}

.main--sede .paginacionDcha {
  float: right;
}

.main--sede .paginacionIzq {
  float: left;
}

.margen {
  padding-bottom: 1em;
}

.tabulacion1 {
  padding-right: .2em;
  line-height: 1.5em;
  vertical-align: top;
}

.tabulacion2,
.tabulacion2B,
.tabulacion2C {
  padding-top: 0 !important;
  line-height: 1.5em !important;
}

.tabulacion1B,
.tabulacion1C {
  padding-right: .2em;
  line-height: 1.5em !important;
}
