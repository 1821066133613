/* ==================================================================== *\
 *                                                                      *
 *  FICHA: CEA                                                          *
 *                                                                      *
 *  ------------------------------------------------------------------  *
 *  /pages/_ficha--cea.css                                              *
 *  ------------------------------------------------------------------  *
 *  Estilos propios de la ficha                                         *
\* ==================================================================== */

/* -------------------------------------------------------------------- *\
 *  NAVEGACIÓN DEL CEA                                                  *
 * -------------------------------------------------------------------- *
 *                                                                      *
 *  Situada bajo el banner principal                                    *
 *                                                                      *
 *  DOM:                                                                *
 *                                                                      *
 *    ul.listcea                                                        *
 *      li.icon-arrow_link1                                             *
 *        a                                                             *
 *                                                                      *
\* -------------------------------------------------------------------- */
.subhome .listcea {
  display: block;
  padding: 0;
  margin: 0 0 .8em;
}

/**
 * 1. Como a las LI se les aplica la clase "icon-arrow_link1":
 *    posicionar icono e indentar texto
 */
.subhome .listcea li {
  display: inline-block;
  padding-left: 10px; /* 1 */
  margin-right: .5em;
  background-position: 0 .45em; /* 1 */
  white-space: nowrap;
}
