
/*
@styleguide

@title Formularios: Fileset

Este componente hace referencia a un conjunto variable de archivos adjuntos
que puede incluirse dentro de un formulario.

Se debe añadir la clase `fileset`.

```html
// siempre fieldset (campos relacionados)
<fieldset class="fileset"
  data-name="[...]"
  data-maxfiles="[X]"
  data-maxfilesize="[bytes]"
  data-ext="[ext1,ext2...]">
  <legend>...</legend>
</fieldset>
```

<div class="psg-responsive-sample">
  <fieldset class="fileset"
    data-name="proba"
    data-maxfiles="2"
    data-maxfilesize="256000"
    data-ext="pdf">
    <legend>Archivos asociados a tu petición</legend>
  </fieldset>
</div>

## Servidor

### Grails

```
// Para un <fieldset class="fileset" data-name="adjuntos" data-maxfiles="5">
request.getFiles("adjuntos").each{f ->
  if (!f.isEmpty()) {
    ...
  }
});
```

### Struts 1

```
// Para un <fieldset class="fileset" data-name="adjuntos" data-maxfiles="5">

private FormFile[] adjuntos = new FormFile[5];

public void setAdjuntos(int index, FormFile file) {
  adjuntos[index] = file;
}

public FormFile getAdjuntos(int index) {
  return adjuntos[index];
}
```
*/

.fileset,
.uploaded-fileset {
  margin-right: 0;
  margin-bottom: var(--rhythm);
  margin-left: 0;
}

.fileset__legend.fileset__legend,
.uploaded-fileset__label {
  margin-bottom: var(--rhythm);
  font-size: var(--font-size-18px);
  font-weight: normal;
}

.fileset > button,
.fileset__btn-add-file {
  margin-right: var(--rhythm);
}

.fileset > span,
.fileset__restrictions {
  display: inline-block;
  font-size: 1rem;
  font-style: italic;
}

.fileset > span::before,
.fileset__restrictions::before {
  content: '(';
}

.fileset > span::after,
.fileset__restrictions::after {
  content: ')';
}

.fileset > ul:empty {
  display: none;
}

.fileset li,
.fileset__list-item,
.uploaded-fileset li {
  margin-bottom: var(--rhythm);
  list-style-type: disc;
}

.fileset li > a,
.uploaded-fileset li > a,
.uploaded-fileset__filename {
  display: inline-block;
  vertical-align: top;
}

.fileset li > button,
.uploaded-fileset li > button,
.uploaded-fileset__delete {
  margin-bottom: 0;
  margin-left: 1em;
}

.fileset .field__input,
.fileset li > a {
  width: 20rem;
}
