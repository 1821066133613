.a29-01w #show-plan h1:first-child {
  margin-top: 0;
}

.a29-01w .col-sm-6 h2 {
  min-height: 2.5em;
}

.a29-01w .a29-accordion__header :is(h2, h3, h4) {
  margin: 0;
}

.a29-01w .a29-estadosTipologia li span.a29-porcentaje {
  width: 5rem;
  min-width: 5rem;
}

.a29-01w .a29-level-3.a29-level-3 .a29-accordion__header .ui-accordion-header-icon {
  top: 1em;
  left: auto;
  margin-left: .2em;
}

.a29-01w .a29-level-3.a29-level-3 .a29-accordion__header[aria-expanded="true"] .ui-accordion-header-icon {
  top: 1.2em;
}

/* Tabla de a29 Rendicion de cuentas */
.a29-pie-table .a29-pie-table__color {
  vertical-align: text-bottom;
}
