
.header--sede .header-main {
  border-bottom: 5px solid #23b42b;
}

.header--sede .header-main__menu-button::before {
  content: none;
}

.header-main__extra {
  --font: minimalfont(strip(var(--font-size-21px)));
  display: none;
  flex-grow: 1;
  justify-content: flex-end;
  margin-bottom: 0;
  font-size: var(--font);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .header-main__extra {
    --font: fluidfont(strip(var(--font-size-21px)));
  }
}

@media (min-width: 1420px) {
  .header-main__extra {
    --font: var(--font-size-21px);
  }
}

@media (max-width: 767px) {
  .header--sede .header-main {
    flex-wrap: wrap;
    padding: 3% var(--readable-padding-side);
  }
  .header--sede .header-main__logo {
    flex-grow: 1;
  }
  .header--sede .header-main__logo img {
    max-height: 3em;
  }
}

@media (min-width: 1200px) {
  .header--sede .header-main {
    align-items: center;
    border: 0;
  }
  .header--sede .header-menu__nav.header-menu__nav {
    margin-top: .5em;
  }
  .header-main__extra {
    display: flex;
  }
}

.entorno-sede .home .sede-base:first-child h1:first-child {
  margin-top: 0;
}

.entorno-sede .home .sede-base h1 {
  --font: minimalfont(strip(var(--font-size-48px)));
  --margin: factormargin(strip(var(--font-size-48px)));
  --line: 1.2em;
  margin: 0;
  margin-top: calc(var(--margin) * var(--margin-top-factor));
  margin-bottom: calc(var(--margin) * var(--margin-bottom-heading-factor));
  font-size: var(--font);
  line-height: var(--line);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .entorno-sede .home .sede-base h1 {
    --font: fluidfont(strip(var(--font-size-48px)));
    --margin: fluidmargin(strip(var(--font-size-48px)));
    line-height: fluidline(strip(var(--line)), strip(var(--font-size-48px)));
  }
  .entorno-sede #busqueda-tramites-form .field--search .field__submit::before {
    top: .3em;
    left: auto;
    display: block;
  }
  .entorno-sede .header-menu__search .field--search .field__submit::before {
    top: .5em;
    left: auto;
    display: block;
  }
  .entorno-sede .main--b05-02s .margenSuperior {
    display: flex;
  }
  .entorno-sede .main--b05-02s .botonesConsulta .boton {
    margin-left: 0;
  }
}

@media (min-width: 1420px) {
  .entorno-sede .home .sede-base h1 {
    --font: var(--font-size-48px);
  }
}
