body:not(.entorno-www) .header-main ul,
body:not(.entorno-www) .header-main li {
  margin-bottom: 0;
  list-style: none;
}

.header-main {
  display: flex;
  height: 5em;
  align-items: center;
  justify-content: flex-end;
  padding: 0 var(--readable-padding-side);
  background: var(--background-color);
}

.header-main__top {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

.header-main img {
  display: block;
}

.header-main__logo {
  margin: 0 auto 0 0;
}

@media (min-width: 768px) {
  .entorno-congresos .header-main__logo,
  .entorno-blogs .header-main__logo {
    margin-right: 1em;
  }
}

@media (min-width: 1200px) {
  .entorno-teatro .header-main__logo,
  .entorno-turismo .header-main__logo,
  .entorno-sede .header-main__logo {
    margin-right: 3%;
  }
}

/* .header-main__logo a {
  display: block;
} */

.header-main__logo img,
.header-main__logo svg {
  max-height: 2.94em;
}

.header-main__btn-search {
  padding: 0;
  margin: 0;
  background: transparent;
}

.header-main__btn-search:focus,
.header-main__btn-search:hover {
  background-color: inherit;
}

.header-main__btn-search * {
  pointer-events: none;
}

.header-main__ico-search {
  width: 3em;
  height: 3em;
  fill: var(--color-text);
}

.header-main__search {
  max-width: 30em;
  flex-grow: 1;
  padding: 0;

  /* padding: 13.4px 0; */
  margin: 0;
  background-color: transparent;
}

@media (max-width: 1200px) {
  .header-main__search {
    display: none;
  }
}

/**
 * Invalidar .form + * { margin-top: 25px }
 */
.header-main__search + * {
  margin-top: 0;
}

.header-main__search .field {
  margin-bottom: 0;
}

.header-main__search .field--search .field__input {
  border: 3px solid;
}

.header-main__highlighted {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  padding-right: 2%;
  margin-top: 0;
  margin-right: 2%;
  margin-bottom: 0;
}

.header-main__networks {
  display: flex;
  display: grid;
  flex-shrink: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin: 0 -.2em;
  grid-template-columns: repeat(4, 1fr);
  justify-items: end;

  /* grid-column-gap: 5px; */
}

@media (max-width: 1200px) {
  .header-main__networks,
  .header--turismo .header-main .field.field--search,
  .header--sede .header-main .field.field--search {
    display: none;
  }
}

.header-main__networks > li {
  display: block;
  margin-right: .2em;
  margin-left: .2em;
}

.header-main__networks > :nth-child(1) {
  -ms-grid-column: 1;
}

.header-main__networks > :nth-child(2) {
  -ms-grid-column: 2;
}

.header-main__networks > :nth-child(3) {
  -ms-grid-column: 3;
}

.header-main__networks > :nth-child(4) {
  -ms-grid-column: 4;
}

.header-main__networks span {
  position: absolute !important;
  display: inline !important; /* Invalidar "display: none" heredados */
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

/* .header-main__menu {
  display: none;
}

@media (min-width: 1024px) {
  .header-main__menu {
    display: flex;
    flex-direction: row;
  }
}

.header-main__menu-item {
  padding-right: 1em;
  border-right: 3px solid #23b42b;
  margin-left: 1em;
  font-family: var(--font-family-alt-semi);
  font-size: var(--font-size-26px);
  line-height: .8em;
}

.header-main__menu-item:last-child {
  border: none;
}

.header-main__menu-item a {
  color: #000;
}

.header-main__menu-item a:hover,
.header-main__menu-item a:focus {
  color: #004ddb;
} */

.header-main__search-dropdown-toggle {
  display: flex;
  align-items: center;
  padding: 0 .3em;
  margin: 0;
  background-color: inherit;
  cursor: pointer;
}

.header-main__search-dropdown-toggle:hover,
.header-main__search-dropdown-toggle:focus {
  background-color: inherit;
}

.header-main__search-dropdown-toggle * {
  pointer-events: none;
}

@media (min-width: 1200px) {
  .header-main__search-menu {
    position: inherit;
    z-index: 1000;
    display: none;
    height: 0;
    padding: 0;
    margin: 0;
    background-clip: padding-box;
    background-color: #fff;
    border-radius: 0;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  }
  .header-main__search-menu.is-active {
    display: flex;
    height: auto;
  }
  .header-main__search-menu .header-main__search {
    display: flex;
    width: 100%;
    height: 50px;
  }
  .header-main__search-menu .header-main__search .field--search {
    width: 90%;
    height: 100%;
    padding-right: .5em;
  }
  .header-main__search-menu .header-main__search .field__submit {
    display: flex;
    height: 100%;
    align-items: center;
    background-color: #000;
    color: var(--color-text-inverse);
    font-family: var(--font-family-base-semi);
  }
  .header-main__search-menu .header-main__search .field__input {
    height: 100%;
  }
  .main-nav .main-nav-smartphone-header {
    display: none;
  }
}

/* buscador Movil */
@media (max-width: 1200px) {
  .header-main__search-menu {
    display: none;
  }
  .main-nav--visible .main-nav-smartphone-header {
    position: fixed;
    width: 100%;
    min-width: 60px;
    max-height: 5rem;
    padding: var(--gutter-interior-aside);
    background-color: #000;
  }
  .main-nav--visible .main-nav-smartphone-header .header-main__logo {
    max-height: 48px;
  }
  .main-nav--visible .main-nav__search-form[hidden] {
    display: none;
  }
  .main-nav--visible .main-nav__search-form {
    height: 40px;
    padding: 0;
  }
  .main-nav--visible .main-nav__search-form .field--search {
    height: 100%;
  }
  .main-nav--visible .main-nav__search-form .field--search .field__input {
    height: 100%;
  }
  .main-nav--visible .main-nav__search-form .field--search .field__input::placeholder {
    color: var(--color-text-grey);
  }
  .field--search .field__submit {
    width: auto;
    height: auto;
  }

  /* .field--search .field__submit::before {
    display: none;
  } */
  .field--search .field__submit .svg-search {
    width: 28px;
    height: 28px;
  }
  .header-main__logo svg {
    max-height: 2.5em;
  }
}
