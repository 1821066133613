/* ==================================================================== *\
 *                                                                      *
 *  MAIN CONTENT                                                        *
 *                                                                      *
 *  ------------------------------------------------------------------  *
 *  /layout/_main-content.css                                            *
 *  ------------------------------------------------------------------  *
 *  Estilos para el contenido principal de las páginas del sitio        *
\* ==================================================================== */

/*
* Se pone display:block para que los blogs
*
*/
.main-content {
  --line: var(--rhythm);
  display: block;
  max-width: 100%;
  font-size: var(--font-size-18px);
  line-height: var(--line);
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.main-content.main-content__full {
  width: 100%;
  max-width: 100%;
}

.flex {
  display: flex;
}

.flex .field--date {
  display: flex;
  align-items: center;
  margin: 0 0 0 1em;
}

/**
 * Se utiliza para marcar que no existe menu contextual y que el contenido
 * ocupa todo el ancho de la web
 */
.main-content#completa {
  width: 100%;
  float: none;
}

@media (min-width: 992px) and (max-width: 1419px) {
  .main-content {
    min-width: 70%;
    max-width: 40rem;
  }
}

@media (min-width: 320px) and (max-width: 1419px) {
  .main-content {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .main-content {
    width: 100%;
    min-width: 60%;
    max-width: 58.75rem;
    font-size: var(--font-size-24px);
  }
}
