/* ================================================================== *\
 *                                                                    *
 *  APLICACIÓN: A24-02                                                *
 *                                                                    *
 * ------------------------------------------------------------------ *
 *  /pages/_a24-02.css                                                *
\* ================================================================== */

/* ------------------------------------------------------------------ *\
 *  BLOQUE: Banner                                                    *
 *  PANTALLA: Inicio                                                  *
\* ------------------------------------------------------------------ *
.a24DivImgBuscador {
  margin-bottom: 1em;
}

/* ------------------------------------------------------------------ *\
 *  BLOQUE: Caja de búsqueda                                          *
 *  PANTALLA: Buscar                                                  *
 * ------------------------------------------------------------------ *
 *                                                                    *
 *  DOM:                                                              *
 *                                                                    *
 *    form#frmBuscador                                                *
 *      fieldset                                                      *
 *      div.a24DivBuscador                                            *
 *        input[type="text"]                                          *
 *        input[type="submit"]                                        *
 *      div.a24DivBuscador                                            *
 *        input[type="checkbox"]                                      *
 *        (Texto sin label)                                           *
 *                                                                    *
\* ------------------------------------------------------------------ *
.a24DivBuscador {
  margin: 0 0 .6em 0;
}

.a24DivBuscador input[type="checkbox"] {
  margin-right: .3em;
}

/* ------------------------------------------------------------------ *\
 *  BLOQUE: Resultado de búsqueda                                     *
 *  PANTALLA: Buscar                                                  *
 * ------------------------------------------------------------------ *
 *                                                                    *
 *  El listado de bloques está contenido dentro de una capa con id    *
 *  "a24panelcentral"                                                 *
 *                                                                    *
 *  DOM:                                                              *
 *                                                                    *
 *    div.resulBusqueda                                               *
 *      div.a24BuscCajaIzqPad                                         *
 *        img                                                         *
 *      div.a24BuscCajaIzq                                            *
 *        dl                                                          *
 *          dt                                                        *
 *            a                                                       *
 *              strong                                                *
 *                (titulo foto)                                       *
 *          dd                                                        *
 *            (descripcion)                                           *
 *      div.a24Clr                                                    *
 *                                                                    *
\* ------------------------------------------------------------------ */

/**
 * 1. Al selector se le añade especificidad con el id de la capa contenedora
 *    porque .resulBusqueda es muy genérico y podría aparecer en otras páginas.
 *    Para evitar problemas desconocidos se añade #a24panelcentral.
 *
#a24panelcentral .resulBusqueda {
  margin-bottom: 2em;
}

.a24BuscCajaIzqPad {
  display: inline-block;

  margin-right: .6em;
  padding-top: .2em;

  vertical-align: top;
}

.a24BuscCajaIzq {
  display: inline-block;
}

/**
 * 1. La separación entre resultados se controla desde la capa padre.
 *
.a24BuscCajaIzq dl,
.a24BuscCajaIzq dd {
  margin-bottom: 0; /* [1] *
}

/* ------------------------------------------------------------------ *\
 *  BLOQUE: Información de busqueda                                   *
 *  PANTALLA: Buscar                                                  *
 * ------------------------------------------------------------------ *
 *                                                                    *
 *  Está contenido dentro de una capa con id "a24panelcentral" y      *
 *  precede al listado de resultados                                  *
 *                                                                    *
 *  DOM:                                                              *
 *                                                                    *
 *    p                                                               *
 *      span.paginas                                                  *
 *        "Del"                                                       *
 *        strong                                                      *
 *          (resultado inicial)                                       *
 *        "al"                                                        *
 *        strong                                                      *
 *          (resultado final)                                         *
 *        "de un total de aprox. "                                    *
 *        strong                                                      *
 *          (numero de resultados                                     *
 *        "resultado(s) de "                                          *
 *        strong                                                      *
 *          (termino)                                                 *
 *      span.paginas                                                  *
 *        (nombre filtro)                                             *
 *        strong                                                      *
 *          filtro                                                    *
 *                                                                    *
\* ------------------------------------------------------------------ *
#a24panelcentral .paginas {
  display: inline-block;

  margin-bottom: 1em;
}

#a24panelcentral .paginas strong {
  color: var(--color-secundario);

  font-weight: normal;
}

/* ------------------------------------------------------------------ *\
 *  BLOQUE: Paginación                                                *
 *  PANTALLA: Buscar                                                  *
 * ------------------------------------------------------------------ *
 *                                                                    *
 *  DOM:                                                              *
 *                                                                    *
 *    div.a24Paginacion                                               *
 *      a ("<<")                                                      *
 *      &nbsp;&nbsp;&nbsp;                                            *
 *      a ("< anterior")                                              *
 *      "|"                                                           *
 *      (Número de pagina actual)                                     *
 *      "|"                                                           *
 *      a ("siguiente >")                                             *
 *                                                                    *
\* ------------------------------------------------------------------ *
.a24Paginacion a {
  margin-left: 5px;
}
*/
.a24-02w .main .media {
  margin-bottom: 2em;
}

.a24-02w .main .media__img {
  width: 5.8em;
  height: 5.8em;
}

.tech-group {
  font-weight: bold;
}

.info-tech dd > dl {
  padding: 0 0 0 22px;
}

.info-tech > ol {
  padding: 0;
  counter-reset: item;
  list-style-type: none;
}

.info-tech .tech-group::before {
  display: inline-block;
  margin-right: .5em;
  content: counter(item) ".";
  counter-increment: item;
}

.a24 .card-header__imagen {
  background-color: transparent;
}

.a24 .swiper-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--rhythm);
  column-gap: 1em;
}

.a24 .swiper-controls .btn {
  margin: 0;
}

.a24 .swiper-controls .btn::before {
  display: block;
  width: 1em;
  height: 1em;
  background-color: var(--background-color);
  content: '';
}

.btn.see-slide::before {
  mask-image: url(../img/svg/sprite.svg#icon-see-view);
}

.btn.download-slide::before {
  mask-image: url(../img/svg/sprite.svg#icon-download-view);
}

.btn.download-folder::before {
  mask-image: url(../img/svg/sprite.svg#icon-zip-view);
}

.btn.go-slide::before {
  mask-image: url(../img/svg/sprite.svg#icon-arrow-right-view);
}

.a24 .swiper-pagination {
  position: relative;
  z-index: auto;
  bottom: auto;
  width: auto;
}

.a24 .swiper-button-prev::after {
  display: block;
  width: 1em;
  height: 1em;
  content: url(../img/svg/sprite.svg#icon-chevron-left-view);
}

.a24 .swiper-button-next::after {
  display: block;
  width: 1em;
  height: 1em;
  content: url(../img/svg/sprite.svg#icon-chevron-right-view);
}

.a24 .input__go-slide {
  width: 4em;
  margin: 0;
}

.a24 .swiper img {
  max-height: 500px;
}

/* .a24 .carousel {
  background-color: transparent;
}

.a24 .carousel-inner > .item > a > picture > img {
  width: auto;
  height: auto;
}

.a24 .carousel-next,
.a24 .carousel-prev {
  position: absolute;
  z-index: 10;
  top: 50%;
  display: block;
  width: 2em;
  height: 2em;
  background-color: transparent;
  cursor: pointer;
}

.a24 .carousel-next {
  right: 1em;
}

.a24 .carousel-prev {
  left: -1em;
}
*/
