/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE: PESTAÑAS DE NAVEGACIÓN                                  *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *  /components/_tablist.css                                            *
 * -------------------------------------------------------------------- *
 *  Tamaño de fuente base:                                              *
 *    - Movil:  21px                                                    *
 *    - Tablet: 21px                                                    *
 *    - Web:    21px                                                    *
 * -------------------------------------------------------------------- *
 *  Descripción:                                                        *
 *                                                                      *
 *  Componente .tablist                                                 *
 *  Personalización de un elemento de Bootstrap: .nav-tabs              *
 *                                                                      *
 *  Se utiliza en:                                                      *
 *    - Listado de noticias de un contenedor editorial                  *
 *    - Listado de la hemeroteca de un contenedor editorial             *
 *    - Boletines                                                       *
 *                                                                      *
\* ==================================================================== */

/*
@styleguide

@title Pestañas

Las pestañas deben estar compuestas por un elemento padre `<ul>`, cuyas `<li>` contendrán enlaces
que se corresponderán a las pestañas.

Al padre hay que añadirle la clase `tablist` y a los hijos `tablist__tab`.

A la pestaña que se desee mostrar por defecto se le debe añadir su estado con la clase `is-selected`.
Si no, se seleccionara la primera.

El contenido de cada una de las pestañas debe estar contenido en una capa con un id determinado.
Cada enlace deberá apuntar en su href al id de su correspondiente capa.

**Las pestañas no se deben utilizar en caso de que los enlaces redirijan a otras páginas. Su objetivo
es el de mostrar u ocultar información dentro de una misma página. No elijas el patron si pretendes
hacer un menu de navegación a otras páginas, porque el javascript asociado y su accesibilidad es
diferente.**

```html
<ul class="tablist">
  <li>
    <a href="#tab-1-content" class="tablist__tab is-selected">Pestaña 1</a>
  </li>
  <li>
    <a href="#tab-2-content" class="tablist__tab">Pestaña 2</a>
  </li>
  <li>
    <a href="#tab-3-content" class="tablist__tab">Pestaña 3</a>
  </li>
</ul>
<div id="tab-1-content">Contenido de la primera pestaña</div>
<div id="tab-2-content">Contenido de la segunda pestaña</div>
<div id="tab-3-content">Contenido de la tercera pestaña</div>
```

En el código puede observarse que todas las capas son visibles. El código así es funcional sin
Javascript.

El comportamiento se controla automáticamente por el Javascript que acompaña por defecto a
los estilos por lo que no es necesario añadir ningún Javascript para mostrar/ocultar las
capas o seleccionar la pestaña.

Al cargarse la página se ocultarán todas las capas de contenido excepto la de
aquella marcada como visible con la clase `is-selected` (en el ejemplo la segunda).
En caso de no marcar ninguna, la primera pestaña será seleccionada por defecto.

<div class="psg-responsive-sample">
  <ul class="tablist">
    <li>
      <a href="#tab-1-content" class="tablist__tab">Primera pestaña</a>
    </li>
    <li>
      <a href="#tab-2-content" class="tablist__tab is-selected">Segunda pestaña</a>
    </li>
    <li>
      <a href="#tab-3-content" class="tablist__tab">Tercera pestaña</a>
    </li>
  </ul>
  <div id="tab-1-content">Contenido de la primera pestaña</div>
  <div id="tab-2-content">Contenido de la segunda pestaña</div>
  <div id="tab-3-content">Contenido de la tercera pestaña</div>
</div>

*/

/* Pestañas de navegación */
.tablist,
.nav-tabs {
  display: flex;
  flex-wrap: nowrap;
  padding: 0;
  border-bottom: none;
  margin-bottom: 0;
  background-color: var(--background-color-alt);
  font-size: var(--font-size-18px);
  line-height: var(--rhythm);
  list-style: none;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
}

body:not(.entorno-pwa):not(.entorno-www.j16-02) .tablist,
body:not(.entorno-pwa):not(.entorno-www.j16-02) .nav-tabs {
  border-top: 1px solid #bfd9e2;
  border-right: 1px solid #bfd9e2;
  background-attachment: local, local, scroll, scroll;
  background-image:
    linear-gradient(to right, #f4f8fa, #f4f8fa),
    linear-gradient(to left, #f4f8fa, #f4f8fa),
    linear-gradient(to right, rgb(231, 242, 244), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgb(231, 242, 244), rgba(255, 255, 255, 0));
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-size: 20px 100%, 20px 100%;
}

.tablist::-webkit-scrollbar,
.nav-tabs::-webkit-scrollbar {
  display: none;
}

@media (min-width: 768px) {
  .tablist,
  .nav-tabs {
    max-width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 1419px) {
  .tablist,
  .nav-tabs {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .tablist,
  .nav-tabs {
    font-size: var(--font-size-24px);
  }
}

.entorno-turismo .tablist,
.entorno-turismo .nav-tabs,
.entorno-pwa .tablist {
  background-color: var(--background-turismo-color);
}

.tablist > li {
  margin: 0;
}

.tablist ~ div[role=tabpanel],
.nav-tabs ~ div[role=tabpanel] {
  padding: 1em;
  padding-left: 25px;
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: transparent #bfd9e2 #bfd9e2;
  margin-bottom: var(--rhythm);
}

.tablist ~ div[role=tabpanel] :is(h1, h2, h3, h4, h5):first-child,
.nav-tabs ~ div :is(h1, h2, h3, h4, h5):first-child {
  margin-top: 0;
}

@custom-selector :--tablist__tab .tablist__tab, .nav-tabs__tab, .nav-tabs > li;
@custom-selector :--tablist__tab--selected .tablist__tab.is-selected, .nav-tabs__tab--active, .nav-tabs > li.active;

/**
 * Pestañas
 * 1. Se elimina regla de Bootstrap "margin-bottom: -1px" y se añade 1px de separación
 *    entre pestañas.
 * 2. Selectores para sobrecargar estilos de li en menus contextuales.
 */
:--tablist__tab,
.menuContextual :--tablist__tab,
.ctxNavegacion :--tablist__tab {
  display: flex;
  height: 100%;
  align-items: flex-start;
  padding: 10px 25px;
  border-width: 5px 1px 1px;
  border-style: solid;
  border-color: #bfd9e2;
  border-top-color: transparent;
  margin: 0; /* 1 */
  background-color: transparent;
  color: var(--color-link);
  cursor: pointer;
  font-family: var(--font-family-base);
}

body:not(.entorno-pwa):not(.entorno-www.j16-02) .tablist__tab,
body:not(.entorno-pwa):not(.entorno-www.j16-02) .nav-tabs__tab,
body:not(.entorno-pwa):not(.entorno-www.j16-02) .nav-tabs > li {
  min-width: 12em;
}

@media (min-width: 768px) and (max-width: 991px) {
  :--tablist__tab,
  .menuContextual :--tablist__tab,
  .ctxNavegacion :--tablist__tab {
    --readable-padding-side: 2em;
  }
}

@media (min-width: 992) {
  :--tablist__tab,
  .menuContextual :--tablist__tab,
  .ctxNavegacion :--tablist__tab {
    width: calc((100% - var(--readable-padding-side) * 2) / 3);
  }
}

/* .entorno-pwa :--tablist__tab {
  border-color: #000;
  border-top-color: #2954c2;
} */

/**
 * Efecto hover sobre todas las pestaña.
 * En las que se consideran actuales se sobreescribirán en la siguiente regla y se dejara
 * ésta sin efecto.
 */
:--tablist__tab:hover {
  border-top-color: var(--color-link);
  background-color: var(--background-color);
}

/**
 * Pestaña actual
 */
:--tablist__tab--selected,
:--tablist__tab--selected:active,
:--tablist__tab--selected:hover,
:--tablist__tab--selected:focus {
  border-top-color: var(--color-secundario);
  border-bottom: 1px solid #fff;
  background-color: var(--background-color);
  color: var(--color-text);
  cursor: default;
  font-family: var(--font-family-base-semi);
  pointer-events: none;
}

.entorno-turismo :--tablist__tab--selected,
.entorno-turismo :--tablist__tab--selected:active,
.entorno-turismo :--tablist__tab--selected:hover,
.entorno-turismo :--tablist__tab--selected:focus,
.entorno-pwa :--tablist__tab--selected,
.entorno-pwa :--tablist__tab--selected:active,
.entorno-pwa :--tablist__tab--selected:hover,
.entorno-pwa :--tablist__tab--selected:focus {
  border-top-color: var(--color-turismo-secundario);
  color: var(--color-text-high-contrast);
}

/**
 * 1. Se eliminan estilos heredados de Bootstrap.
 */
.nav-tabs > li > a,
.nav-tabs > li > a:active,
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus,
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:active,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  display: inline;
  flex-grow: 1;
  padding: 0;
  border: none; /* [1] */
  margin: 0; /* [1] */
  background: none;
  border-radius: 0; /* [1] */
  color: inherit;
}

/**
 * 1. Se eliminan estilos heredados de Bootstrap.
 */
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:active,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: var(--color-text); /* [1] */
}

/**
 * Cuando se está visualizando un listado se da la opción de suscribirse
 * al RSS del contenedor mostrando un icono RSS
 *
 * 1. Se sobrescriben los estilos de enlaces de Bootstrap para las
 *    pestañas, ya que sólo están pensados para enlaces de texto que
 *    ocupen toda la pestaña.
 * 2. Se sobrescriben estilos de .icon-social-rss-small, la clase
 *    por defecto del sitio que utiliza un icono 22x22, mientras que
 *    aquí se usa uno de 15x15
 *
 * Selector:
 * -  Se añaden dos .icon-social-rss-small para ganar en especificidad a
 *    los estilos de bootstrap ".nav-tabs > li.active > a..." que aún se
 *    utilizan en las "falsas" pestañas de los contenedores.
 */
:--tablist__tab .icon-social-rss-small.icon-social-rss-small.icon-social-rss-small {
  display: inline-block; /* [1] */
  overflow: hidden;
  width: 15px;
  height: 15px;
  min-height: auto; /* [2] */
  flex-grow: 0;
  padding: 0; /* [1, 2] */
  border: none; /* [1] */
  margin: 0 0 0 .2em; /* [1] */
  background: url(../img/iconoRSS2.png) no-repeat; /* [2] */
  cursor: pointer; /* [1] */
  line-height: 15px; /* [1] */
  text-indent: 100%;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .entorno-turismo .tablist ~ div,
  .entorno-turismo .nav-tabs ~ div {
    padding: 2em .5em;
  }
}

/**
 * Estilos para tablets mínimo ya que sólo deben aplicarse cuando las pestañas se
 * presentan en horizontal.
 *
 * 1. Entre pestañas hermanas se elimina el borde izquierdo, para que la separación siga
 *    siendo de un pixel y no se duplique.
 * 2. Utilizamos virguilla (~) en vez de "+" porque en los contenedores con boletin
 *    se intercalan unos input[type="hidden"] que hace que los li de las pestañas no
 *    sean hermanos directos. HTML mal formado.
 */
@custom-selector :--tablist__tab--sibling .tablist__tab ~ .tablist__tab, .nav-tabs__tab ~ .nav-tabs__tab, .nav-tabs > li ~ li; /* [2] */

@media (min-width: 768px) {
  :--tablist__tab--sibling,
  .menuContextual :--tablist__tab--sibling,
  .ctxNavegacion :--tablist__tab--sibling {
    border-left: 0; /* [1] */
  }

  /**
  * 1. Se elimina el margin lateral ya que sólo se utilizan dos nav-tabs en los
  *    menus contextuales y se obliga a que se dividan la anchura entre ambas.
  *    Dicho margen provoca que haya salto de linea al ocupar cada una 50% + 1px;
  * 2. Al tener fijada una anchura y el centrado de texto, se elimina el padding
  *    lateral, ya que se observan problemas cuando la anchura es demasiado pequeña
  *    para el padding por defecto.
  */
  .nav-tabs--aside > li,
  .menuContextual .nav-tabs > li,
  .ctxNavegacion .nav-tabs > li {
    width: 50%; /* [1] */
    padding-right: 0; /* [2] */
    padding-left: 0; /* [2] */
    margin: 0; /* [1] */
    text-align: center;
  }
}

/* ------------------------------------------------------------------ *\
 *  Caso especial:                                                    *
 *  Pestañas de navegación dentro de menu contextual                  *
 * ------------------------------------------------------------------ *
 *  Resultados de la etiqueta: [[ctdcnt:X]]                           *
 * ------------------------------------------------------------------ *
 * En dicho caso, como siempre son dos pestañas (actualidad y         *
 * hemeroteca), se elimina el padding por defecto y se fuerza a que   *
 * se repartan el espacio al 50%                                      *
\* ------------------------------------------------------------------ */

/**
 * Nota:
 * Dentro de un componente no debería influir en qué lugar está colocado.
 *
 * Se aplica una mala práctica:
 *  Utilizar un selector anidado con el padre para no tocar el HTML:
 *   .menuContextual .nav-tabs
 *
 * Buena práctica recomendada:
 *  Aplicarla en el HTML de las nav-tabs del menu contextual la siguiente
 *  clase modificador (BEM):
 *   .nav-tabs--aside
 */

/**
 * 1. !important para sobreescribir el important añadido más arriba a
 *    al selector: .nav-tabs
 */
.menuContextual .nav-tabs + *,
.ctxNavegacion .nav-tabs + *,
.nav-tabs--aside {
  padding: 1em 0 0;
  margin-bottom: 0;
}

/* 1. Se elimina el padding por defecto */
.nav-tabs--aside > li > a,
.menuContextual .nav-tabs > li > a,
.ctxNavegacion .nav-tabs > li > a {
  padding-right: 0; /* [1] */
  padding-left: 0; /* [1] */
}

/**
** PWA
**/

.entorno-pwa .tablist {
  margin: 0;
}

@media (max-width: 767px) {
  .entorno-pwa .tablist {
    display: flex;
    overflow: hidden;
    flex: 0 0 5em;
    flex-direction: row;
    order: 1;
    font-size: .9rem;
  }
  .entorno-pwa .tablist > .is-selected {
    order: 0;
  }
  .entorno-pwa .tablist > li {
    flex: 1 0 auto;
  }
  .entorno-pwa .tablist__tab {
    width: auto;
    padding: 3%;
    text-align: center;
  }
  .entorno-pwa .tablist__tab,
  .consulta-paradas .tablist__tab {
    flex-direction: column;
    align-items: center;
  }
  .entorno-pwa .tablist__tab:hover {
    text-decoration: none;
  }
  .entorno-pwa .tablist__ico {
    display: block;
    width: 2em;
    height: 2em;
    margin: .3em auto .4em;
  }
}

@media (min-width: 768px) {
  .entorno-pwa .tablist__ico {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    margin-right: .1em;
    vertical-align: sub;
  }
}

.entorno-pwa :--tablist__tab use,
.entorno-www.j16-02 :--tablist__tab use {
  fill: var(--color-primario) !important;
  stroke: var(--color-primario) !important;
}

.entorno-pwa :--tablist__tab--selected use,
.entorno-www.j16-02 :--tablist__tab--selected use {
  fill: #000 !important;
  stroke: #000 !important;
}
