.btn-menu {
  padding: 0 .3em;
  margin: 0;
  background-color: transparent;
  color: #000;
  vertical-align: middle;
}

.btn-menu-close {
  display: flex;
  width: 48px;
  height: 48px;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

@media (min-width: 1200px) {
  .header-main__menu-button,
  .btn-menu,
  .btn-menu-close {
    display: none;
  }
}

.btn-menu:active,
.btn-menu-close:active,
.btn-menu:hover,
.btn-menu-close:hover,
.btn-menu:focus,
.btn-menu-close:focus {
  background-color: transparent;
  background-image: none;
  color: var(--color-link);
}

.btn-menu__icon {
  display: block;
  width: 1em;
  border-width: .2em 0;
  border-style: solid;
  border-color: currentColor;
  font-size: 1.8em;
}

.btn-menu__icon::before {
  display: block;
  height: .2em;
  margin: .18em 0;
  background: currentColor;
  content: '';
}

.btn-menu__text {
  position: absolute;
  display: inline;
  overflow: hidden;
  width: 1px;
  height: 1px;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.btn-menu-close__icon {
  display: inline-block;
  width: 22px;
  height: 22px;
  margin: auto;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjEwIDEwIDI4IDI4Ij4KPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTM4LDEyLjgyTDM1LjE4LDEwTDI0LDIxLjE4TDEyLjgyLDEwTDEwLDEyLjgyTDIxLjE4LDI0TDEwLDM1LjE4TDEyLjgyLDM4TDI0LDI2LjgyTDM1LjE4LDM4TDM4LDM1LjE4TDI2LjgyLDI0TDM4LDEyLjgyeiIvPgo8L3N2Zz4=);
  background-repeat: no-repeat;
}

.btn-menu-close__text {
  position: absolute;
  display: inline;
  overflow: hidden;
  width: 1px;
  height: 1px;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}
