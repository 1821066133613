/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE: AVISO DE COOKIES                                        *
 *                                                                      *
 *  ------------------------------------------------------------------- *
 *  /components/_avisoCookies.css                                       *
 *  ------------------------------------------------------------------- *
 *  Tamaño de fuente base:                                              *
 *    - Movil:  18px                                                    *
 *    - Tablet: 18px                                                    *
 *    - Web:    18px                                                    *
 *  ------------------------------------------------------------------- *
 *                                                                      *
 *  Capa que se fija en la parte superior de la pantalla para informar  *
 *  de la política de cookies de la web.                                *
 *                                                                      *
\* ==================================================================== */
#avisoCookies {
  position: fixed;
  z-index: 99999 !important;
  bottom: 0;
  width: 100%;
  padding-top: 1em;
  background-color: var(--background-color-alt-darker);
  color: var(--color-text-high-contrast);
  font-size: var(--font-size-18px);
}

#avisoCookies p:first-of-type {
  margin-bottom: calc(var(--rhythm) * .5);
}

#avisoCookies section.container {
  padding-bottom: 0;
  margin-bottom: 0;
}

@media (min-width: 320px) and (max-width: 1419px) {
  #avisoCookies {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  #avisoCookies {
    top: 0;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  #avisoCookies button.btn {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: .5em;
    margin-left: 0;
  }
  #avisoCookies a.btn {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 1.5rem;
    margin-left: 0;
  }
  #avisoCookies div.botonesCookies {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 768px) {
  #avisoCookies {
    padding-bottom: 2em;
  }
  #frmAvisoCookies .fieldset--checkboxset {
    display: flex;
  }
}

@media (min-width: 1420px) {
  #avisoCookies {
    font-size: var(--font-size-24px);
  }
}

#avisoCookies::after {
  display: table;
  clear: both;
  content: '';
}

#avisoCookies > .container {
  position: relative;
}

#avisoCookiesIzq {
  margin: 0 25px 0 0;
}

#avisoCookiesDcha {
  position: absolute;
  top: .1em;
  right: 15px;
  cursor: pointer;
}

#frmAvisoCookies #idDivTerc {
  padding-right: 3rem;
}

#frmAvisoCookies .field--checkbox {
  width: auto;
}
