@media (max-width: 1199px) {
  .header--teatro .header-main {
    border-bottom: 5px solid var(--color-entorno-teatro);
  }
}

.header--teatro .header-main__highlighted ul,
.header--teatro .header-main__highlighted li {
  display: flex;
  align-items: center;
}

.header--teatro .header-main__highlighted li {
  flex-grow: 1;
  margin-left: 3vw;
}

.header--teatro .header-main__venta-entradas {
  position: relative;
  display: block;
  color: var(--color-entorno-teatro);
  font-size: var(--font-size-18px);
  line-height: 1.1;
}

@media (max-width: 767px) {
  .header--teatro .header-main__venta-entradas {
    font-size: .875rem;
  }
}

.header--teatro .header-main__venta-entradas--tel {
  padding-left: 35px;
}

.header--teatro .header-main__venta-entradas--web {
  width: 4em;
  padding-left: 35px;
}

.header--teatro .header-main__venta-entradas span {
  display: block;
  white-space: nowrap;
}

.header--teatro .header-main__venta-entradas::before {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 100%;
  height: 34px;
  content: '';
  transform: translateY(-50%);
}

.header--teatro .header-main__venta-entradas--tel::before {
  background: url(../img/teatro/ico-movil.svg) no-repeat;
}

.header--teatro .header-main__venta-entradas--web::before {
  background: url(../img/teatro/ico-raton.svg) no-repeat;
}

.header--teatro .header-main__networks {
  grid-template-columns: repeat(4, 1fr);
}

.list-footer.teatro {
  margin-bottom: var(--rhythm);
}

.header--teatro .header-main__menu-button::before {
  content: none;
}

@media (max-width: 767px) {
  .header--teatro .header-main__highlighted {
    padding: 0;
    margin-right: auto;
  }
  .header--teatro .header-main__highlighted li {
    margin: 0;
  }
  .header-main__highlighted ul li:first-child::after {
    height: 2em;
    border: 1px solid #000;
    margin-right: 1em;
    margin-left: 1em;
    content: '';
  }
  .header--teatro .header-main__logo svg {
    max-width: 6.5em;
  }
  .header--teatro .header-main__venta-entradas--tel {
    width: 5em;
    padding: 0;
  }
  .header--teatro .header-main__venta-entradas--web {
    width: 3.5em;
    padding: 0;
  }
  .header--teatro .header-main__venta-entradas span {
    white-space: inherit;
  }
  .header--teatro .header-main__venta-entradas span + span {
    display: none;
  }
  .header--teatro .header-main__venta-entradas::before {
    content: none;
  }
}

/* @media (min-width: 768px) {
  .eventCalendar .event-card::before,
  .event-card::before {
    display: block;
    padding-top: 68%;
    content: '';
  }
}

@media (min-width: 992px) and (max-width: 1419px) {
  .eventCalendar .event-card::before,
  .event-card::before {
    padding-top: 70%;
  }
}
*/
