.header-utils ul,
.header-utils li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.header-utils {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: .3em 1em;
  margin-top: 0;
  margin-bottom: 0;
  background-color: var(--color-header-turismo);
  font-family: 'Source Sans Pro Regular', sans-serif;
  font-size: var(--font-size-18px);
}

.header-utils__nav {
  flex-grow: 1;
  margin-right: auto;
}

.entorno-pwa .header-utils__nav {
  flex-grow: 1;
}

.header-utils__nav ul {
  display: flex;
  align-items: stretch;
}

.header-utils__nav li {
  display: flex;
  align-items: center;
  padding: 0;
  text-align: center;
}

@media (max-width: 1199px) {
  .header-utils__nav li:not(:nth-child(-n+3)) {
    display: none;
  }
  .entorno-pwa .icon-council-header {
    overflow: visible;
  }
}

.header-utils__nav ul > :first-child {
  flex-grow: 1;
  text-align: left;
}

/**
 * Enlaces de la cabecera
 * (por defecto: negros)
 */

.header-utils__lang {
  position: relative;
}

.header-utils__lang::before {
  content: none;
}

.header-utils__lang::after {
  content: none;
}

.header-utils__lang-select {
  width: 100%;
  height: 1.5em;
  padding: 0 0 0 .5em;
  border: 1px solid var(--input-border-color);
  margin: 0;
  appearance: none;
  background-color: white;
  font-family: inherit;
  font-size: 1em;
  line-height: 1.5em;
}

.entorno-turismo .header-utils__lang-select,
.entorno-turismo .header-utils__lang::after {
  height: auto;
}

.header-utils .header-utils__lang-menu {
  position: absolute;
  z-index: 1011;
  top: 100%;
  left: 0;
  min-width: 160px;
  padding: 10px 20px;
  border-color: #63b67c;
  margin: 10px 0 0 0;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 0;
  -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  list-style: none;
  text-align: left;
}

@media (max-width: 1199px) {
  .header-utils .header-utils__lang-menu {
    right: 0;
    left: unset;
  }
}

.header-utils__lang-menu__item {
  display: block;
  background: #fff;
}

.header-utils__lang-menu__item a {
  line-height: 1.428571429;
  white-space: nowrap;
}

.header-utils__lang-menu__item.is-active a {
  color: var(--color-text-high-contrast);
  font-weight: 700;
  pointer-events: none;
}

@media (max-width: 1199px) {
  .header-utils__nav li:nth-child(2)::after,
  .header-utils__nav li:nth-child(3)::after {
    height: 1.5em;
    border-right: 2px solid #fff;
    content: "";
  }
}

@media (min-width: 1200px) {
  .btn-ear__icon,
  .btn-language__icon {
    display: none;
  }
}

.header-utils__nav li:is(:nth-child(4)) > button {
  margin-bottom: 0;
}

/* -------------------------------------------------------------------- *\
 *  BOTONES DEL NAV NEGRO DEL HEADER                                    *
\* -------------------------------------------------------------------- */

.btn-header-utils {
  display: flex;
  min-height: 2.625rem;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0 1em;
  background-color: inherit;
  color: var(--color-text-inverse);
  column-gap: .5em;
  cursor: pointer;
  font-size: inherit;
}

.btn-header-utils:hover,
.btn-header-utils:focus {
  background-color: inherit;
  color: var(--color-text-inverse);
  font-size: inherit;
  text-decoration: underline;
}

button.btn-header-utils:focus {
  outline: 2px solid var(--color-primario);
}

.btn-header-utils * {
  pointer-events: none;
}

.btn-header-utils__icon {
  padding: 2px;
  font-size: inherit;
}

.btn-header-utils__icon::after {
  display: block;
  width: 1em;
  height: 1em;
  background-color: var(--background-color);
  content: "";
  transform: translateY(calc(((var(--font-size-18px) * strip(var(--rhythm))) - var(--font-size-18px)) * .25));
}

@media (max-width: 1200px) {
  .btn-header-utils__icon::after {
    width: 1.5em;
    height: 1.5em;
  }
}

.btn-header-utils__icon-share::after {
  mask-image: url(../img/svg/sprite.svg#icon-share-view);
}

.btn-header-utils__icon-language::after {
  mask-image: url(../img/svg/sprite.svg#icon-language-view);
}

.btn-header-utils__icon-ear::after {
  mask-image: url(../img/svg/sprite.svg#icon-ear-view);
}

.btn-header-utils__icon-accesible::after {
  mask-image: url(../img/svg/sprite.svg#icon-accesible-view);
}

.btn-header-utils.conexion__btn > .btn-header-utils__icon::after {
  mask-image: url(../img/svg/sprite.svg#icon-user-login-view);
  transform: translateY(calc(((1.125rem*1.5) - 1.125rem) * .25)) scale(1.2);
}

.btn-header-utils.conexion__btn[data-username] > .btn-header-utils__icon::after {
  background-color: var(--color-secundario);
  mask-image: url(../img/svg/sprite.svg#icon-user-logged-view);
}

.btn-header-utils__icon-prensa::after {
  mask-image: url(../img/svg/sprite.svg#icon-prensa-view);
}

.btn-header-utils__icon-boletin::after {
  mask-image: url(../img/svg/sprite.svg#icon-boletin-view);
}

.btn-header-utils__icon-reclamaciones::after {
  mask-image: url(../img/svg/sprite.svg#icon-reclamaciones-view);
}

.btn-header-utils__icon-location::after {
  mask-image: url(../img/svg/sprite.svg#icon-location-view);
}

.btn-header-utils__icon-congreso::after {
  mask-image: url(../img/svg/sprite.svg#icon-congreso-view);
}

.btn-header-utils__text {
  font-size: inherit;
}

.btn-header-utils__text::first-letter {
  text-transform: capitalize;
}

@media (max-width: 1199px) {
  .btn-header-utils__text {
    position: absolute !important;
    display: inline !important; /* Invalidar "display: none" heredados */
    overflow: hidden;
    width: 1px;
    height: 1px;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
  }
  .btn-header-utils__text:focus {
    position: static !important;
    clip: auto;
  }
}

.btn-header-utils.conexion__btn {
  margin-right: 0;
}
