/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE: BREADCRUMBS                                             *
 *                                                                      *
 *  ------------------------------------------------------------------- *
 *  /components/_breadcrumbs.css                              *
 *  ------------------------------------------------------------------- *
 *  Tamaño de fuente base:                                              *
 *    - Movil:  18px                                                    *
 *    - Tablet: 18px                                                    *
 *    - Web:    18px                                                    *
 *  ------------------------------------------------------------------- *
 *                                                                      *
 *  Migas de pan                                                        *
 *                                                                      *
\* ==================================================================== */
.breadcrumb {
  padding: 0 0 20px;
  margin: 0;
  background-color: inherit;
  font-size: var(--font-size-18px);
}

/**
 * De momento se usan estilos antiguos y maquetación deficientes.
 *
 * Se debería estudiar migrar a una maquetación más versátil y adecuada para los lectores
 * de pantalla. El elemento más adecuado parece el OL según la encuesta:
 * http://webaim.org/projects/screenreadersurvey5/#breadcrumb
 */
.breadcrumb p {
  margin: 0;
  font-weight: bold;
}

.breadcrumb a,
.breadcrumb span {
  color: var(--color-text);
  font-weight: normal;
}

.breadcrumb a {
  font-weight: bold;
}

.breadcrumb a[href] {
  color: var(--color-link);
  font-weight: normal;
}

/* Separadores ">" */
.breadcrumb a + span {
  margin: 0 2px;
}

/**
 * ESTILOS NUEVOS DE MIGAS UNIFICADOS
**/

nav.breadcrumb {
  --font: minimalfont(strip(var(--font-size-21px)));
  padding: 0;
  padding-bottom: 2rem;
  margin-bottom: -1rem;
  font-family: var(--font-family-base);
  font-size: var(--font);
}

@media (min-width: 320px) and (max-width: 1419px) {
  nav.breadcrumb {
    --font: fluidfont(strip(var(--font-size-21px)));
  }
}

@media (min-width: 1420px) {
  nav.breadcrumb {
    --font: var(--font-size-21px);
  }
}

.breadcrumb__list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
}

.breadcrumb__item {
  display: inline-flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

@media (min-width: 768px) {
  .breadcrumb__item:not(.breadcrumb__item--current):not(:last-child)::after {
    display: flex;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    align-items: center;
    margin: 0 .5em;
    background-color: #000;
    content: "";
    mask-image: url(../img/svg/sprite.svg#icon-chevron-right-view);
    mask-position: top;
  }
}

@media (max-width: 767px) {
  nav.breadcrumb {
    width: min(calc(100vw - (2 * var(--readable-padding-side))), var(--readable-area));
  }
  .breadcrumb__item {
    display: none;
  }
  .breadcrumb__item:nth-last-of-type(2) {
    display: flex;
    align-items: center;
  }
  .breadcrumb__item:nth-last-of-type(2)::before {
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    margin-right: .5rem;
    background-color: var(--color-text);
    content: '';
    mask-image: url(../img/svg/sprite.svg#icon-arrow-right-view);
    transform: rotate(180deg);
    vertical-align: middle;
  }
}
