/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE: FORMULARIO                                              *
 *                                                                      *
 * -------------------------------------------------------------------- *
 *  /components/forms/_form.css                                         *
 * -------------------------------------------------------------------- *
 *  Tamaño de fuente base:                                              *
 *    - Movil:  16px                                                    *
 *    - Tablet: 18px                                                    *
 *    - Web:    18px                                                    *
\* ==================================================================== */

/*
@styleguide

@title Formularios: Tipos

## Tipos

### Formulario estándar

Utiliza siempre la clase `form` en los elementos `<form>`.

```html
<form class="form"></form>
```

<div class="psg-responsive-sample">
  <form class="form">
    <div class="field">
      <label class="field__label" for="input-sample-01">Campo 1</label>
      <input class="field__input" id="input-sample-01" placeholder="Escribe aquí" type="text">
    </div>
    <div class="field">
      <label class="field__label" for="input-sample-02">Campo 2</label>
      <input class="field__input" id="input-sample-02" placeholder="Escribe aquí" type="text">
    </div>
    <input type="submit" class="form__submit btn" value="Buscar">
  </form>
</div>

### Formulario en linea

Añade la clase `form--inline` para formularios en una linea

```html
<form class="form form--inline"></form>
```

<div class="psg-responsive-sample">
  <form class="form form--inline">
    <div class="field">
      <label class="field__label" for="input-sample-03">Campo 1</label>
      <input class="field__input" id="input-sample-03" placeholder="Escribe aquí" type="text">
    </div>
    <div class="field">
      <label class="field__label" for="input-sample-04">Campo 2</label>
      <input class="field__input" id="input-sample-04" placeholder="Escribe aquí" type="text">
    </div>
    <input type="submit" class="form__submit btn" value="Buscar">
  </form>
</div>

*/
.form {
  font-size: var(--font-size-18px);
  line-height: var(--rhythm);
}

@media (min-width: 320px) and (max-width: 1419px) {
  .form {
    font-size: fluidfont(strip(var(--font-size-24px)));
  }
}

@media (min-width: 1420px) {
  .form {
    font-size: var(--font-size-24px);
  }
}

.form::before,
.form::after {
  display: table;
  content: " ";
}

.form::after {
  clear: both;
}

/**
 * Margen inferior de un formulario que está seguido de algun otro
 * elemento. Es decir, no es lo último de la capa contenedora.
 */
.form + * {
  margin-top: var(--rhythm);
}

/**
 * Fallback para los formularios que a pesar de ser el último (visual)
 * hijo de una capa, no lo son en el DOM porque les sigue algun tipo de
 * clearfix.
 */
.form + .clearfloats {
  margin-top: 0;
}

/* ==================================================================== *\
 *                                                                      *
 *  COMPONENTE:   FORMULARIO (.form)                                    *
 *  MODIFICADOR:  FORM. EN LINEA (.form--inline)                        *
 *                                                                      *
\* ==================================================================== */
.form.form--inline > *,
.form.form--inline > fieldset > * {
  display: inline-block;
}

@media (min-width: 768px) {
  .form.form--inline > *,
  .form.form--inline > fieldset > * {
    margin-right: 1em;
  }
  .form.form--inline > fieldset {
    margin-right: 0;
    vertical-align: bottom;
  }
  .form.form--inline > a,
  .form.form--inline > .btn {
    display: inline-block;
    margin-bottom: var(--rhythm);
  }
}

/**
 * 1. Los textarea no se pueden poner en linea porque su altura es especial
 */
.form--inline > .field--textarea {
  display: block; /* [1] */
}

/**
 * 1. Invalidar los margenes que se pone en los campos y botones de los formularios por defecto
 */
.form--inline > .btn,
.form--inline > input[type=button],
.form--inline > input[type=reset],
.form--inline > input[type=submit] {
  margin-top: 0;
  margin-right: 1em;
  margin-bottom: calc(var(--rhythm) * .5);
}

.form.form--inline > script {
  display: none;
}

/**
 * Compatibilidad con propuestas antiguas
.form.form--inline .form__field,
.form.form--inline .form__fieldset,
.form.form--inline .form__submit {
  vertical-align: inherit;
}
 */
